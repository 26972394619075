import React from 'react'
import { EditElementPanel } from '../editElementPanel'
import './compressorStationsEditPanel.scss'
import { ITableColumn } from 'components/Table/types'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCompressorStations,
  selectProjectID,
  setUpdatedElement,
  updateCompressorStation,
} from 'store/projectSlice'
import { Table } from 'components/Table/Table/Table'
import Resizable from 'components/Resizeable/resizable'
import Handle from 'components/Handle/handle'
import useGetData from './hooks/useGetData'
import { columns } from './tableConfig'
import update from 'immutability-helper'
import useSaveHandbookItem from './hooks/useSaveHandbookItem'
import { createCompressorPlantRequest, deleteCompressorPlantByIDRequest } from 'services/apiRequests'
import { ISelectParams } from 'store/types'
import classnames from 'classnames'

const CompressorStationsEditPanel: React.FC = () => {
  const dispatch = useDispatch()
  const projectID = useSelector(selectProjectID)
  const compressorStations = useSelector(selectCompressorStations) as definitions['CompressorStation'][]
  const [fullScreenMode, setFullScreenMode] = React.useState<boolean>(false)

  const dataSource = useGetData()
  useSaveHandbookItem()

  const updateStore = (
    updatedField: { [key: string]: any },
    compressorStation: definitions['CompressorStation'] | undefined,
  ) => {
    if (compressorStation) {
      dispatch(
        updateCompressorStation({
          ...compressorStation,
          ...updatedField,
        } as definitions['CompressorStation']),
      )
    }
  }

  const setValue = (objectID: string, value: any, fieldName: string) => {
    const compressorStation = compressorStations?.find((station) =>
      station.compressor_plants.find((plant) => plant.id === objectID),
    )
    if (['name', 'pressure__MPa'].includes(fieldName)) {
      const cs = compressorStations?.find((station) => station.id === objectID)
      updateStore({ [fieldName]: value }, cs)
      if (cs?.id) {
        dispatch(
          setUpdatedElement({
            elementID: cs.id,
            type: 'COMPRESSOR_STATIONS',
            params: { [fieldName]: value },
          }),
        )
      }
    } else if (
      [
        'n_gpu_first_stage',
        'n_gpu_second_stage',
        'n_gpu_total',
        'max_temperature',
        'temperature_gtf',
        'temperature_ambient',
        'min_allowable_relative_value',
        'max_allowable_relative_value',
        'gas_inflow',
        'n_aca_first_stage',
        'n_throws_aca_first_stage',
        'n_aca_second_stage',
        'n_throws_aca_second_stage',
        'inlet_pressure_drop',
        'exit_pressure_drop',
        'aca_pressure_drop',
        'gas_share',
        'auto_gas_share',
        'cooling_level',
      ].includes(fieldName)
    ) {
      const plantIx = compressorStation?.compressor_plants.findIndex((plant) => plant.id === objectID)
      if (plantIx !== undefined && plantIx > -1 && compressorStation) {
        updateStore(
          {
            compressor_plants: update(compressorStation.compressor_plants, {
              [plantIx]: {
                $set: {
                  ...compressorStation.compressor_plants[plantIx],
                  [fieldName]: value,
                },
              } as any,
            }),
          },
          compressorStation,
        )
        dispatch(
          setUpdatedElement({
            elementID: objectID,
            type: 'COMPRESSOR_PLANT',
            params: { [fieldName]: value },
          }),
        )
      }
    } else if (['gpu', 'acu'].includes(fieldName)) {
      openHandbook(objectID ? objectID : '', fieldName.toUpperCase() as 'ACU' | 'GPU', value?.id)
    } else if (fieldName === 'compressor_plants_number') {
      const cs = compressorStations?.find((station) => station.id === objectID)
      if (cs && value !== cs.compressor_plants.length) {
        switch (value) {
          case 1:
            if (projectID)
              deleteCompressorPlantByIDRequest(projectID, cs.compressor_plants[1].id as string).then(() =>
                updateStore({ compressor_plants: [cs.compressor_plants[0]] }, cs),
              )
            break
          case 2:
            if (projectID)
              createCompressorPlantRequest(projectID, cs.id as string).then((res) =>
                updateStore({ compressor_plants: [...(cs?.compressor_plants || []), res.data] }, cs),
              )
        }
      }
    }
  }

  const openHandbook = (plantId: string, type: 'ACU' | 'GPU', id: string) => {
    dispatch(
      setBlockingWindow({
        type: 'SELECT',
        params: { objectID: id, objectName: plantId, objectType: type, plantID: plantId } as ISelectParams,
      }),
    )
  }

  return (
    <Resizable
      className={classnames('trunk-pipeline__resizable-container', fullScreenMode && 'full-screen')}
      right
      handleElement={<Handle />}
    >
      <EditElementPanel
        className={'trunk-pipeline__panel compressor-stations__panel'}
        title={'Общие свойства компрессорных станций'}
        fullScreenAllow={true}
        changeFullScreenMode={setFullScreenMode}
      >
        {dataSource.length ? (
          <Table
            className={'compressor-stations__table'}
            columns={columns as ITableColumn[]}
            dataSource={dataSource}
            setValue={setValue}
          />
        ) : (
          <></>
        )}
      </EditElementPanel>
    </Resizable>
  )
}

export default CompressorStationsEditPanel
