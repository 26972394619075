import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import './directoryLinearUnitCosts.scss'
import { Table } from 'components/Table/Table/Table'
import useGetLinearUnitCosts from './hooks/useGetLinearUnitCosts'
import useCreateLinearUnitCostsVersion from './hooks/useCreateLinearUnitCostsVersion'

interface IDirectoryLinearUnitCostsProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryLinearUnitCosts: React.FC<IDirectoryLinearUnitCostsProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as definitions['LinearUnitCosts']
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  useGetLinearUnitCosts(requestData)

  const saveResult = useCreateLinearUnitCostsVersion(saveData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, value: number) => {
    dispatch(
      updateItemField({
        value,
        fieldName: id,
      }),
    )
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: { [key: string]: any }[] = [
      {
        id: 'outer_covering',
        title: (
          <>
            Наружное покрытие труб, руб./м<span className={'superscript-rel'}>2</span>
          </>
        ),
      },
      {
        id: 'inner_covering',
        title: (
          <>
            Внутреннее покрытие труб, руб./м<span className={'superscript-rel'}>2</span>
          </>
        ),
      },
      {
        id: 'margin',
        title: (
          <>
            Наценка сбытовых организаций и транспорт, % к цене
            <br /> &quot;черной трубы&quot;
          </>
        ),
      },
      {
        id: 'equipment',
        title: (
          <>
            Базовая стоимость оборудования, тыс.руб./км
            <br />
            <p style={{ fontStyle: 'italic' }}>
              Для трубы диаметром 1420 мм,
              <br />
              рабочее давление 7,4 МПа, марка стали К60
            </p>
          </>
        ),
      },
      {
        id: 'construction_works',
        title: (
          <div className={'complex-column-header first'}>
            <span className={'complex-column-header-cell'}>
              Отвод земли,
              <br /> компенсации
            </span>
            <span className={'complex-column-header-cell'}>СМР, тыс.руб./км</span>
          </div>
        ),
      },
      {
        id: 'extra_construction_works',
        title: (
          <div className={'complex-column-header second'}>
            <span className={'complex-column-header-cell'} />
            <span className={'complex-column-header-cell'}>Прочие, тыс.руб./км</span>
          </div>
        ),
      },
      {
        id: 'land_transfer',
        title: (
          <div className={'complex-column-header first'}>
            <span className={'complex-column-header-cell'}>
              Строительно-
              <br />
              монтажные работы
            </span>
            <span className={'complex-column-header-cell'}>Укладка газопровода, тыс.руб./км</span>
          </div>
        ),
      },
      {
        id: 'extra_land_transfer',
        title: (
          <div className={'complex-column-header second'}>
            <span className={'complex-column-header-cell'} />
            <span className={'complex-column-header-cell'}>Прочие объекты, тыс.руб./км</span>
          </div>
        ),
      },
      {
        id: 'temporary_building',
        title: (
          <div className={'complex-column-header first'}>
            <span className={'complex-column-header-cell'}>Прочие</span>
            <span className={'complex-column-header-cell'}>Временные сооружения, %</span>
          </div>
        ),
      },
      {
        id: 'extra',
        title: (
          <div className={'complex-column-header second'}>
            <span className={'complex-column-header-cell'} />
            <span className={'complex-column-header-cell'}>Прочие затраты, %</span>
          </div>
        ),
      },
      {
        id: 'management',
        title: (
          <div className={'complex-column-header first'}>
            <span className={'complex-column-header-cell'}>
              Управление,
              <br />
              проектирование
            </span>
            <span className={'complex-column-header-cell'}>Содержание дирекции, %</span>
          </div>
        ),
      },
      {
        id: 'design_and_survey_works',
        title: (
          <div className={'complex-column-header second'}>
            <span className={'complex-column-header-cell'} />
            <span className={'complex-column-header-cell'}>ПИР, %</span>
          </div>
        ),
      },
      {
        id: 'reserve',
        title: <>Резерв, %</>,
      },
    ]
    if (directoryItem) {
      Object.values(directoryItem).forEach((value, ix) => {
        data[ix]['base_cost'] = value
      })
    }
    return data
  }, [directoryItem])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      isModified={isModified}
      title={getHandbookName(directoryKey)}
    >
      {directoryItem && (
        <div className={'directory__content'}>
          <Table
            className={'directory-table__container basic-costs-table__container'}
            columns={columns(editMode)}
            dataSource={dataSource}
            setValue={onSaveItemField}
          />
        </div>
      )}
    </DirectoryWrapper>
  )
}

export default DirectoryLinearUnitCosts
