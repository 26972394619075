import React from 'react'
import { definitions } from 'generated/apiTypes'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Dropdown from 'components/Dropdown/Dropdown'
import { layingMethodOptions } from './BarChart'
import { RouteLayingMethod } from '../config'
import { updateLayingMethodSection } from '../../../../../../services/apiRequests'
import { useSelector } from 'react-redux'
import { selectProjectID, selectTrunkPipelineID } from '../../../../../../store/projectSlice'

export const layingTypeOptions: definitions['RouteProfileLayingTypeSection']['laying_type'][] = [
  'Наземная',
  'Подземная',
  'Надземная',
]

interface ILayingTypePopupProps {
  position: { top: number; left: number } | undefined
  setPosition(position: { top: number; left: number } | undefined): void
  layingType: { type: RouteLayingMethod; sectionId: string } | undefined
  layingTypeUpdated: boolean
  setLayingTypeUpdated(value: boolean): void
  popupRef: React.RefObject<any>
  panelRef: React.RefObject<any>
}

const LayingTypePopup: React.FC<ILayingTypePopupProps> = ({
  position,
  setPosition,
  layingType,
  layingTypeUpdated,
  setLayingTypeUpdated,
  popupRef,
  panelRef,
}) => {
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const [option, setOption] = React.useState<{ value: RouteLayingMethod; name: string }>()

  React.useEffect(() => {
    setOption(layingMethodOptions.find((item) => item.value === layingType?.type))
  }, [layingType])

  const saveOption = (value: { value: RouteLayingMethod; name: string }) => {
    if (value.value !== option?.value) {
      if (trunkPipelineId && projectId && layingType?.sectionId && value?.name) {
        updateLayingMethodSection(projectId, trunkPipelineId, layingType.sectionId, {
          laying_type: value.name as 'Подземная' | 'Наземная' | 'Надземная',
        }).then(() => setLayingTypeUpdated(!layingTypeUpdated))
      }
    }
    setOption(value)
    setPosition(undefined)
  }

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!popupRef?.current?.contains(event.target)) {
        setPosition(undefined)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return position ? (
    <div ref={popupRef} className={'laying-type-popup__container'} style={{ left: position.left, top: position.top }}>
      <div className={'blocking-window__header'} style={{ fontSize: 16 }}>
        {`Способ прокладки`}
        <CloseBtn close={() => setPosition(undefined)} />
      </div>
      <div className={'laying-type-popup__dropdown-container'}>
        <Dropdown
          options={layingMethodOptions}
          displayedField={'name'}
          panelRef={panelRef}
          selectedValue={option}
          setSelectedValue={saveOption}
          //containerClassNames={'pressed-dropdown'}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default LayingTypePopup
