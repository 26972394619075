import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskCalculationProgress } from 'store/DNLayersSlice'
import { Link } from 'react-router-dom'
import Button from '../../Button'
import { selectBlockingWindow, selectTaskIsCanceling, setBlockingWindow } from 'store/commonSlice'
import { handleStorage } from 'services/handleStorage'
import { IBlockingWindow, ICalcProgressParams, IStopTaskConfirmParams } from 'store/types'

export const DNLayersProgressWindow: React.FC = () => {
  const dispatch = useDispatch()
  const taskCalculationProgress = useSelector(selectTaskCalculationProgress)
  const taskIsCanceling = useSelector(selectTaskIsCanceling)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICalcProgressParams)

  const stopTask = () => {
    dispatch(
      setBlockingWindow({
        type: 'STOP_TASK_CONFIRM',
        params: { taskName: 'Расчет слоев МДР', taskID: blockingWindowParams?.taskID } as IStopTaskConfirmParams,
      }),
    )
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container'>
        <h1 className={'project-list__projects-title no-margin'}>Идет расчет</h1>
        <div>
          <div className='progress__loader-container'>
            <div className='progress__loader' />
            <div className={'progress__percentage'}>{taskCalculationProgress || 0}%</div>
          </div>
          <div className={'progress__hint normal-text'}>{'Выполняется расчет слоев МДР'}</div>
          <Link className={'link'} to={'/'} state={{ tab: 'tasks' }}>
            Очередь задач
          </Link>
        </div>
        <Button
          className={'progress__stop-task-btn'}
          mode={'secondary'}
          onClick={stopTask}
          disabled={
            taskIsCanceling ||
            (handleStorage.getIsStaff() === 'false' &&
              handleStorage.getUserId() !== blockingWindowParams?.taskUserID) ||
            !['true', 'false'].includes(handleStorage.getIsStaff() as string)
          }
        >
          Отменить расчет
        </Button>
      </div>
    </div>
  )
}
