import React, { PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'
import Checkbox from '../Checkbox'

interface IOptionProps<OptionType> {
  item: OptionType
  displayedField: string
  selectedValues?: OptionType[]
  setSelectedValue?(value: OptionType): void
  setSelectedValues?(values: OptionType[]): void
  setAllValues?(): void
  closeOptionsList(): void
  multiselect?: boolean
}

const Option = <OptionType,>({
  item,
  displayedField,
  selectedValues,
  setSelectedValue,
  setSelectedValues,
  setAllValues,
  closeOptionsList,
  multiselect,
}: PropsWithChildren<IOptionProps<OptionType>>): ReactElement => {
  const optionRef = React.useRef<HTMLDivElement>(null)
  const valueRef = React.useRef<HTMLSpanElement>(null)

  const onOptionClick = () => {
    if (!(item as any)?.disabled && !multiselect) {
      if (setSelectedValue) setSelectedValue(item)
      closeOptionsList()
    }
  }

  const setCheckedValue = (value: boolean) => {
    if ((item as any)?.value === 'ALL') {
      if (setAllValues) {
        if (value) setAllValues()
      }
    } else {
      if (setSelectedValues && selectedValues) {
        if (value) setSelectedValues([...selectedValues, item])
        else setSelectedValues(selectedValues?.filter((el) => (el as any).value !== (item as any)?.value) || [])
      }
    }
  }

  return (
    <div
      className={classNames('dropdown__option', (item as OptionType & { disabled: boolean })?.disabled && 'disabled')}
      onClick={onOptionClick}
      ref={optionRef}
    >
      {multiselect ? (
        <Checkbox
          checkedValue={selectedValues?.some((el) => (el as any)?.value === (item as any)?.value) || false}
          classNames={'multiselect-dropdown__checkbox'}
          setCheckedValue={setCheckedValue}
          disabled={
            (item as any).value === 'ALL' && selectedValues?.some((el) => (el as any)?.value === (item as any)?.value)
          }
        >
          {(item as any)[displayedField] || (displayedField === '' && item) || 'Не задано'}
        </Checkbox>
      ) : (
        <span
          className={classNames(
            'dropdown__option-value normal-text',
            (item as OptionType & { disabled: boolean })?.disabled && 'disabled',
          )}
          ref={valueRef}
        >
          {(item as any)[displayedField] || (displayedField === '' && item) || 'Не задано'}
        </span>
      )}
    </div>
  )
}

export default Option
