import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { visitTask } from 'services/apiRequests'
import { ToolTabKey } from '../types'
import { selectProjectID, setNotVisited } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'

const useVisitTask = (toolTabKey: ToolTabKey, notVisited: definitions['ProjectNotVisitedTaskStates'] | undefined) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)

  const tryToVisitTask = async (taskType: definitions['CalculationVisit']['task_type']) => {
    if (projectId) {
      await visitTask(projectId, { task_type: taskType }).then(() => {
        const getNotVisited = () => {
          switch (toolTabKey) {
            case 'OPTIMIZATION_CALC':
              return { ...notVisited, optimization_status: undefined }
            case 'ECONOMIC_CALC':
              return { ...notVisited, economics_status: undefined }
            case 'HYDRAULIC_CALC':
              return { ...notVisited, hydraulics_status: undefined }
            case 'AUTO_CS_CALC':
              return { ...notVisited, cs_placement_status: undefined }
            default:
              return {
                ...notVisited,
                optimization_status: undefined,
                economics_status: undefined,
                hydraulics_status: undefined,
                cs_placement_status: undefined,
              }
          }
        }
        dispatch(setNotVisited(getNotVisited() as any))
      })
    }
  }

  useEffect(() => {
    const isVisited =
      (['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(notVisited?.optimization_status!) &&
        toolTabKey === 'OPTIMIZATION_CALC') ||
      (['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(notVisited?.economics_status!) &&
        toolTabKey === 'ECONOMIC_CALC') ||
      (['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(notVisited?.hydraulics_status!) &&
        toolTabKey === 'HYDRAULIC_CALC') ||
      (['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(notVisited?.cs_placement_status!) &&
        toolTabKey === 'AUTO_CS_CALC')
    const getTaskType = () => {
      switch (toolTabKey) {
        case 'OPTIMIZATION_CALC':
          return 1
        case 'ECONOMIC_CALC':
          return 3
        case 'HYDRAULIC_CALC':
          return 2
        case 'AUTO_CS_CALC':
          return 4
        default:
          return undefined
      }
    }
    const taskType = getTaskType()
    if (isVisited && taskType) {
      tryToVisitTask(taskType as definitions['CalculationVisit']['task_type']).then()
    }
  }, [toolTabKey, notVisited])

  return
}

export default useVisitTask
