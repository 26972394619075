import React from 'react'
import '../styleGuide.scss'

const Typography: React.FC = () => {
  return (
    <div className='style-guide__panel style-guide__columns'>
      <div className='style-guide__panel-title'>Typography</div>
      <h1 className='header1 color-dark'>header1</h1>
      <h2 className='header2 color-dark'>header2</h2>
      <h3 className='header3 color-dark'>header3</h3>
      <h4 className='header4 color-dark'>header4</h4>
      <p className='normal-text'>normal-text</p>
      <p className='label'>label</p>
      <p className='label-primary'>label-primary</p>
    </div>
  )
}

export default Typography
