import React from 'react'

const getTitle = (field: string, editMode: boolean) => {
  return [
    {
      title: '5.4',
      type: 'Number',
      key: `${field}/pressure_5_4_props`,
      editable: editMode,
      noEmpty: true,
    },
    {
      title: '7.4',
      type: 'Number',
      key: `${field}/pressure_7_4_props`,
      editable: editMode,
      noEmpty: true,
    },
    {
      title: '8.2',
      type: 'Number',
      key: `${field}/pressure_8_2_props`,
      editable: editMode,
      noEmpty: true,
    },
    {
      title: '9.8',
      type: 'Number',
      key: `${field}/pressure_9_8_props`,
      editable: editMode,
      noEmpty: true,
    },
    {
      title: '11.8',
      type: 'Number',
      key: `${field}/pressure_11_8_props`,
      editable: editMode,
      noEmpty: true,
    },
  ]
}
export const columns = (editMode: boolean) => [
  {
    title: 'Диаметр трубы, мм',
    type: 'Column',
    editable: false,
    key: 'title',
    sticky: true,
  },
  {
    title: '1420',
  },
  {
    title: '1220',
  },
  {
    title: '1020',
  },
  {
    title: '920',
  },
  {
    title: '820',
  },
  {
    title: '720',
  },
  {
    title: '630',
  },
  {
    title: '530',
  },
  {
    title: '426',
  },
  {
    title: '375',
  },
  {
    title: '325',
  },
  {
    title: '275',
  },
  {
    title: '225',
  },
  {
    title: '159',
  },
  {
    title: '114',
  },
  {
    title: '89',
  },
  {
    title: '57',
  },
  {
    title: 'Рабочее давление, МПа',
    sticky: true,
  },
  ...getTitle('diameter_1420_props', editMode),
  ...getTitle('diameter_1220_props', editMode),
  ...getTitle('diameter_1020_props', editMode),
  ...getTitle('diameter_920_props', editMode),
  ...getTitle('diameter_820_props', editMode),
  ...getTitle('diameter_720_props', editMode),
  ...getTitle('diameter_630_props', editMode),
  ...getTitle('diameter_530_props', editMode),
  ...getTitle('diameter_426_props', editMode),
  ...getTitle('diameter_375_props', editMode),
  ...getTitle('diameter_325_props', editMode),
  ...getTitle('diameter_275_props', editMode),
  ...getTitle('diameter_225_props', editMode),
  ...getTitle('diameter_159_props', editMode),
  ...getTitle('diameter_114_props', editMode),
  ...getTitle('diameter_89_props', editMode),
  ...getTitle('diameter_57_props', editMode),
  {
    title: (
      <>
        Цена “черной”
        <br />
        трубы, руб./т
      </>
    ),
    sticky: true,
  },
]
