import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cancelTask, getTasksQueue } from 'services/apiRequests'
import { setTasks } from 'store/tasksSlice'
import { IBlockingWindow, IErrorParams, IStopTaskConfirmParams } from 'store/types'

const useStopTask = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IStopTaskConfirmParams)

  const tryToGetTasks = async () => {
    await getTasksQueue()
      .then((response) => {
        dispatch(setTasks(response.data))
      })
      .catch(() => {
        dispatch(
          setBlockingWindow({
            type: 'ERROR',
            params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
          }),
        )
      })
  }

  const tryToStopTask = async (taskId: string | undefined | null) => {
    if (taskId) {
      await cancelTask(taskId)
        .then(() => tryToGetTasks().then())
        .catch((error) => {
          if (error.response?.status === 403) {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Непредвиденная ошибка',
                  message: error.response?.data?.detail || 'В процессе отмены расчета произошла ошибка',
                } as IErrorParams,
              }),
            )
          } else {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Непредвиденная ошибка',
                  message: 'В процессе отмены расчета произошла ошибка',
                } as IErrorParams,
              }),
            )
          }
        })
    }
  }

  useEffect(() => {
    if (blockingWindow.type === 'STOP_TASK_CONFIRM' && blockingWindowParams?.isConfirmed) {
      tryToStopTask(blockingWindowParams?.taskID).then()
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindowParams?.isConfirmed])

  return
}

export default useStopTask
