/* eslint-disable @typescript-eslint/no-extra-semi */
import React, { useEffect, useRef, useState } from 'react'

import './map.scss'
import { LngLatLike, Map as MaplibreMap, ScaleControl } from 'maplibre-gl'
import { useSelector } from 'react-redux'
import { selectMapLayers, selectMapMode, selectMapSubmode } from 'store/mapSlice'
import classNames from 'classnames'
import maplibregl from 'maplibre-gl'
import { tilesSources, rulerSource, kilometerMarksSource } from './sources'
import { Layers } from './layers'
import useGetGISConfiguration from './hooks/useGetGISConfiguration'
import useGetDNLayers from './hooks/useGetDNLayers'

// @ts-ignore
export const MapContext: React.Context<MaplibreMap> = React.createContext(null)
interface IMapProps {
  children: JSX.Element | JSX.Element[]
  toolTabClassName: string
}
export const defaultCenter: LngLatLike = [120, 55]
export const defaultZoomDelta = 0.5
const Map: React.FC<IMapProps> = ({ children, toolTabClassName }) => {
  const mapContainer = useRef<any>(null)
  const mapMode = useSelector(selectMapMode)
  const mapSubmode = useSelector(selectMapSubmode)
  const layers = useSelector(selectMapLayers)
  const [loaded, setLoaded] = useState<boolean>(false)
  const map = useRef<any>(null)
  useGetGISConfiguration()
  useGetDNLayers()
  useEffect(() => {
    if (layers && !loaded) {
      mapContainer.current.innerHTML = ''
      map.current?.remove()
      map.current = new maplibregl.Map({
        container: mapContainer.current as HTMLElement,
        style: {
          version: 8,
          zoom: 4,
          center: defaultCenter,
          sprite: `${window.location.protocol}//${window.location.host}${window?.env?.BASENAME || '/'}/sprites/sprite`,
          sources: { ...tilesSources(layers), ...rulerSource(), ...kilometerMarksSource },
          glyphs: `${window.location.protocol}//${window.location.host}${
            window?.env?.BASENAME || '/'
          }/glyphs/{fontstack}/{range}.pbf`,
          layers: [...Layers],
        },
      })
      map.current.doubleClickZoom.disable()
      map.current.boxZoom.disable()
      map.current.dragRotate.disable()
      map.current.addControl(new ScaleControl({ maxWidth: 110 }))
      map.current.once('style.load', () => {
        setLoaded(true)
        ;(window as any).map = map.current
      })
      map.current.once('remove', () => {
        setLoaded(false)
      })
    }
  }, [layers])
  useEffect(() => {
    document.getElementById('project-map')!.addEventListener('contextmenu', (event) => {
      event.preventDefault()
    })
  }, [])
  return (
    <div ref={mapContainer} id={'project-map'} className={classNames('map', mapMode, mapSubmode, toolTabClassName)}>
      {map.current !== undefined && loaded && <MapContext.Provider value={map.current}>{children}</MapContext.Provider>}
    </div>
  )
}

export default Map
