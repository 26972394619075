import React from 'react'
import './style.scss'
import { addToValidation, removeFromValidation } from 'store/validationSlice'
import NumberInput from './numberInput'
import { useDispatch } from 'react-redux'

interface ICoordinateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string
  classNames?: string
  field: 'latitude' | 'longitude'
  setValue?(value: number, label?: string): void
  label?: any
  fieldName?: string
}

const CoordinateInput: React.FC<ICoordinateInputProps> = ({ id, value, field, label, setValue, ...props }) => {
  const dispatch = useDispatch()
  const setCoords = (value: number) => {
    let errorMessage = null
    if (field === 'latitude')
      if (value > 90) {
        errorMessage = `Широта не может быть больше 90`
      } else if (value < -90) {
        errorMessage = `Широта не может быть меньше -90`
      }
    if (field === 'longitude')
      if (value > 180) {
        errorMessage = `Долгота не может быть больше 180`
      } else if (value < -180) {
        errorMessage = `Долгота не может быть меньше -180`
      }
    if (errorMessage) {
      dispatch(
        addToValidation({
          id: id as string,
          field,
          errorMessage: [errorMessage],
        }),
      )
    } else {
      setValue && setValue(value)
      dispatch(
        removeFromValidation({
          id: id as string,
          field,
        }),
      )
    }
  }
  return (
    <NumberInput
      {...props}
      id={id}
      key={`${id}_${field}`}
      value={value}
      fieldName={field}
      setValue={setCoords}
      label={label}
      signed
      noEmpty
    />
  )
}

export default CoordinateInput
