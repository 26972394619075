import React from 'react'
import '../styleGuide.scss'
import BarIcon from 'images/BarIcon'
import AddPointIcon from 'images/AddPointIcon'
import AddSegmentIcon from 'images/AddSegmentIcon'
import BackIcon from 'images/BackIcon'
import Icon from './Icon'
import AlertIcon from 'images/AlertIcon'
// @ts-ignore
import { ReactComponent as ArrowResizeIcon } from 'images/icn-arrow-resize.svg'
import AutoCSIcon from 'images/AutoCSIcon'
import CloseIcon from 'images/CloseIcon'
import CopyIcon from 'images/CopyIcon'
import CursorIcon from 'images/CursorIcon'
import DeleteIcon from 'images/DeleteIcon'
import DotsIcon from 'images/DotsIcon'
import DownloadIcon from 'images/DownloadIcon'
import EditIcon from 'images/EditIcon'
import ErrorIcon from 'images/ErrorIcon'
import EyeIcon from 'images/EyeIcon'
import FileUploadedIcon from 'images/FileUploadedIcon'
import FilterIcon from 'images/FilterIcon'
import FocusIcon from 'images/FocusIcon'
import FullScreenIcon from 'images/FullScreenIcon'
import GISIcon from 'images/GISIcon'
import RulerIcon from 'images/RulerIcon'
import IconArrayDown from 'images/IconArrayDown'
import IconExpandedArrow from 'images/IconExpandedArrow'
import InfoIcon from 'images/InfoIcon'
import ListIcon from 'images/ListIcon'
import LockedIcon from 'images/LockedIcon'
import MinimizeIcon from 'images/MinimizeIcon'
import MinusIcon from 'images/MinusIcon'
import PathAutoIcon from 'images/PathAutoIcon'
import PathIcon from 'images/PathIcon'
import PencilIcon from 'images/PencilIcon'
import PersonIcon from 'images/PersonIcon'
import PlusIcon from 'images/PlusIcon'
import RedoIcon from 'images/RedoIcon'
import ResizeIcon from 'images/ResizeIcon'
import RubIcon from 'images/RubIcon'
import SearchIcon from 'images/SearchIcon'
import SelectAreaIcon from 'images/SelectAreaIcon'
import TableIcon from 'images/TableIcon'
import TaskIcon from 'images/TaskIcon'
import UnlockedIcon from 'images/UnlockedIcon'
import WarningIcon from 'images/WarningIcon'
import IconHeight from 'images/IconHeight'

const Icons: React.FC = () => {
  return (
    <div className='style-guide__panel'>
      <div className='style-guide__panel-title'>Icons</div>
      <div className={'style-guide__rows'}>
        <Icon name={'BarIcon'}>
          <BarIcon />
        </Icon>
        <Icon name={'AutoCSIcon'}>
          <AutoCSIcon />
        </Icon>
        <Icon name={'PathIcon'}>
          <PathIcon />
        </Icon>
        <Icon name={'PathAutoIcon'}>
          <PathAutoIcon />
        </Icon>
        <Icon name={'RubIcon'}>
          <RubIcon />
        </Icon>
      </div>
      <div className={'style-guide__rows'}>
        <Icon name={'AddPointIcon'} iconContainerClassName={'bg-primary'}>
          <AddPointIcon />
        </Icon>
        <Icon name={'AddSegmentIcon'} iconContainerClassName={'bg-primary'}>
          <AddSegmentIcon />
        </Icon>
        <Icon name={'PencilIcon'} iconContainerClassName={'bg-primary'}>
          <PencilIcon />
        </Icon>
        <Icon name={'SelectAreaIcon'} iconContainerClassName={'bg-primary'}>
          <SelectAreaIcon />
        </Icon>
        <Icon name={'RulerIcon'} iconContainerClassName={'bg-primary'}>
          <RulerIcon />
        </Icon>
        <Icon name={'PlusIcon'} iconContainerClassName={'bg-primary'}>
          <PlusIcon />
        </Icon>
        <Icon name={'MinusIcon'} iconContainerClassName={'bg-primary'}>
          <MinusIcon />
        </Icon>
        <Icon name={'IconHeight'} iconContainerClassName={'bg-primary'}>
          <IconHeight />
        </Icon>
        <Icon name={'FocusIcon'} iconContainerClassName={'bg-primary'}>
          <FocusIcon />
        </Icon>
        <Icon name={'CursorIcon'}>
          <CursorIcon />
        </Icon>
      </div>
      <div className={'style-guide__rows'}>
        <Icon name={'AlertIcon'}>
          <AlertIcon />
        </Icon>
        <Icon name={'InfoIcon'}>
          <InfoIcon />
        </Icon>
        <Icon name={'WarningIcon'}>
          <WarningIcon />
        </Icon>
        <Icon name={'ErrorIcon'}>
          <ErrorIcon />
        </Icon>
      </div>
      <div className={'style-guide__rows'}>
        <Icon name={'ArrowResizeIcon'} iconContainerClassName={'bg-primary'}>
          <ArrowResizeIcon />
        </Icon>
        <Icon name={'FullScreenIcon'}>
          <FullScreenIcon />
        </Icon>
        <Icon name={'MinimizeIcon'} iconContainerClassName={'bg-primary'}>
          <MinimizeIcon />
        </Icon>
        <Icon name={'ResizeIcon'}>
          <ResizeIcon />
        </Icon>
      </div>
      <div className={'style-guide__rows'}>
        <Icon name={'BackIcon'} iconContainerClassName={'bg-primary'}>
          <BackIcon />
        </Icon>
        <Icon name={'CloseIcon'}>
          <CloseIcon />
        </Icon>
        <Icon name={'CopyIcon'}>
          <CopyIcon />
        </Icon>
        <Icon name={'DeleteIcon'}>
          <DeleteIcon />
        </Icon>
        <Icon name={'DotsIcon'}>
          <DotsIcon />
        </Icon>
        <Icon name={'DownloadIcon'} iconContainerClassName={'bg-primary'}>
          <DownloadIcon />
        </Icon>
        <Icon name={'EditIcon'}>
          <EditIcon />
        </Icon>
        <Icon name={'EyeIcon'}>
          <EyeIcon />
        </Icon>
        <Icon name={'FileUploadedIcon'}>
          <FileUploadedIcon />
        </Icon>
        <Icon name={'FilterIcon'}>
          <FilterIcon />
        </Icon>
        <Icon name={'GISIcon'}>
          <GISIcon />
        </Icon>
        <Icon name={'IconArrayDown'}>
          <IconArrayDown />
        </Icon>
        <Icon name={'IconExpandedArrow'}>
          <IconExpandedArrow />
        </Icon>
        <Icon name={'ListIcon'}>
          <ListIcon />
        </Icon>
        <Icon name={'LockedIcon'} iconContainerClassName={'bg-primary'}>
          <LockedIcon />
        </Icon>
        <Icon name={'UnlockedIcon'}>
          <UnlockedIcon />
        </Icon>
        <Icon name={'PersonIcon'} iconContainerClassName={'bg-primary'}>
          <PersonIcon />
        </Icon>
        <Icon name={'RedoIcon'}>
          <RedoIcon />
        </Icon>
        <Icon name={'SearchIcon'}>
          <SearchIcon />
        </Icon>
        <Icon name={'TableIcon'}>
          <TableIcon />
        </Icon>
        <Icon name={'TaskIcon'}>
          <TaskIcon />
        </Icon>
      </div>
    </div>
  )
}

export default Icons
