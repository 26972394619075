import React from 'react'

import classnames from 'classnames'

import CheckedIcon from './CheckedIcon'

import './style.scss'

interface ICheckboxProps {
  checkedValue: boolean
  setCheckedValue?(value: boolean): void
  label?: string
  children?: React.ReactNode
  disabled?: boolean
  classNames?: string
}

const Checkbox: React.FC<ICheckboxProps> = ({
  checkedValue,
  setCheckedValue,
  label,
  disabled,
  classNames,
  children,
  ...props
}) => {
  const toggleValue = () => {
    if (setCheckedValue) {
      setCheckedValue(!checkedValue)
    }
  }

  return (
    <button
      {...props}
      className={classnames('checkbox-row', children && 'start-aligned', classNames)}
      onClick={toggleValue}
      disabled={disabled}
      data-testvalue={checkedValue}
    >
      <div className='checkbox-icon'>{checkedValue && <CheckedIcon className='checked-icon' />}</div>
      <div className='checkbox-content'>
        <>
          {label && <span className='checkbox-label'>{label}</span>}
          {children}
        </>
      </div>
    </button>
  )
}

export default Checkbox
