import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow, setTaskIsCanceling } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cancelTask } from 'services/apiRequests'
import { IBlockingWindow, IStopTaskConfirmParams } from 'store/types'

const useStopTask = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IStopTaskConfirmParams)

  const tryToStopTask = async (taskId: string | undefined | null) => {
    if (taskId) {
      await cancelTask(taskId).then(() => dispatch(setTaskIsCanceling(true)))
    }
  }

  useEffect(() => {
    if (blockingWindow.type === 'STOP_TASK_CONFIRM' && blockingWindowParams?.isConfirmed) {
      tryToStopTask(blockingWindowParams?.taskID).then(() => dispatch(resetBlockingWindow()))
    }
  }, [blockingWindowParams?.isConfirmed])

  return
}

export default useStopTask
