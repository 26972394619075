import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setTaskId, setCreateTask, setTaskStatus } from 'store/optimizationSlice'
import { createOptimizationTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID, resetProjectElements } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { resetValidation } from 'store/validationSlice'
import { IErrorParams } from 'store/types'

const useCreateTask = (settings?: definitions['OptimizationCreate']) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToCreateTask = async () => {
    if (trunkPipelineId && settings && projectId) {
      await createOptimizationTask(projectId, trunkPipelineId, settings)
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
          dispatch(resetProjectElements())
          dispatch(resetValidation())
        })
        .catch((err) => {
          if (err.response.status === 400) {
            let message = ''
            if (
              err.response.data.detail ===
              'Pipelines are too long. Total length of pipelines must be less than 10000km.'
            ) {
              message = 'Общая длина трубопровода не должна превышать 10000 км.'
            } else if (
              err.response.data.detail === 'Pipelines are too long. Length of each pipeline must be less than 3000km.'
            ) {
              message = 'Длина каждого участка трубопровода не должна превышать 3000 км.'
            }
            dispatch(
              setBlockingWindow({
                params: {
                  title: 'Ошибка запуска расчета',
                  message: message || 'Непредвиденная ошибка',
                } as IErrorParams,
                type: 'ERROR',
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
                type: 'ERROR',
              }),
            )
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
