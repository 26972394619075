import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IEconomicSlice } from './types'

export const initialState: IEconomicSlice = {
  taskId: undefined,
  createTask: false,
  downloadResults: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  taskResults: undefined,
}

export const economicSlice = createSlice({
  name: 'economic',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IEconomicSlice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IEconomicSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IEconomicSlice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setDownloadResults: (state, action: PayloadAction<IEconomicSlice['downloadResults']>) => {
      return { ...state, downloadResults: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IEconomicSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IEconomicSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    setTaskResults: (state, action: PayloadAction<IEconomicSlice['taskResults']>) => {
      return { ...state, taskResults: action.payload }
    },
    resetEconomicSliceState: () => {
      return initialState
    },
  },
})

export const {
  setCreateTask,
  setTaskId,
  setTaskCalculationProgress,
  setDownloadResults,
  setTaskStatus,
  setTaskResults,
  setErrorMessage,
  resetEconomicSliceState,
} = economicSlice.actions

export const selectCreateTask = (state: RootState) => state.economic.createTask

export const selectTaskId = (state: RootState) => state.economic.taskId

export const selectTaskCalculationProgress = (state: RootState) => state.economic.taskCalculationProgress

export const selectDownloadResults = (state: RootState) => state.economic.downloadResults

export const selectTaskStatus = (state: RootState) => state.economic.taskStatus

export const selectTaskResults = (state: RootState) => state.economic.taskResults

export const selectErrorMessage = (state: RootState) => state.economic.errorMessage

export default economicSlice.reducer
