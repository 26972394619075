import React, { useMemo } from 'react'
import { RowsTable } from 'components/Table/RowsTable/RowsTable'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { definitions } from 'generated/apiTypes'
import useChartData from '../hooks/useChartData'
import { Line } from 'react-chartjs-2'
import { options } from '../Chart/options'
import { ITableColumn } from '../../../../../../../components/Table/types'

const columns = [
  {
    title: 'Скорость газа, м/с',
    type: 'Text',
    key: 'velocity',
    editable: false,
    sticky: true,
  },
  {
    title: 'Точка трассы, км',
    type: 'Text',
    key: 'distance',
    editable: false,
    sticky: true,
  },
]

type VelocityResultsType = { id: string; velocity: number; distance: number; unknown: boolean; ks: boolean }

const Velocity: React.FC = () => {
  const taskResults = useSelector(selectTaskResults)

  const getData = (pipelineList: definitions['HydraulicResultRetrievePipeline'][]) => {
    const data: VelocityResultsType[] = []
    pipelineList.forEach((pipeline) => {
      data.push({
        id: pipeline.id,
        velocity: pipeline.gas_velocity__m_per_s,
        distance: pipeline.start_point__km,
        unknown: pipeline.start_node === null,
        ks: pipeline.start_node && pipeline.start_node.node_type === 'КС',
      })
    })
    return {
      tableData: data.map((item) => {
        return {
          id: item.id,
          velocity: item.velocity?.toFixed(2),
          distance: `${item.distance?.toFixed(2)}${item.ks ? ' (КС)' : item.unknown ? ' (Неизвестный)' : ''}`,
        }
      }),
      sourceData: data,
    }
  }

  const { tableData, sourceData } = useMemo(
    () => (taskResults.pipelines.length > 0 ? getData(taskResults.pipelines) : { tableData: [], sourceData: [] }),
    [taskResults.pipelines],
  )
  const chartData = useChartData('VELOCITY')

  return (
    <>
      {sourceData.length > 0 && (
        <div className={'results__chart-container'}>
          <Line
            data={chartData}
            options={options(
              columns[0].title,
              columns[1].title,
              taskResults.pipelines[taskResults.pipelines.length - 1]?.start_point__km || 0,
              sourceData.map((item) => {
                return { value: item.distance, ks: item.ks }
              }),
            )}
          />
        </div>
      )}
      <div className={'results__under-chart-container'}>
        <RowsTable className={'rows-table__container'} columns={columns as ITableColumn[]} dataSource={tableData} />
      </div>
    </>
  )
}

export default Velocity
