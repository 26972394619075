import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDirectoryItems,
  updateItemFieldById,
  setModified,
  addSelectedItem,
  deselectSelectedItemById,
  selectSelectedItems,
} from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import './directoryRegionCoefficients.scss'
import useGetRegionCoefficients from './hooks/useGetRegionCoefficients'
import useCreateRegionCoefficientsVersion from './hooks/useCreateRegionCoefficientsVersion'
import useGetRegions from '../../hooks/useGetRegions'
import { MultiSelectTable } from 'components/Table/MultiSelectTable/MultiSelectTable'
import { generateUID } from '../../../utils'

interface IDirectoryNextGISLayersProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryRegionCoefficients: React.FC<IDirectoryNextGISLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as definitions['RegionCoefficients'][]
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [newItemProperties, setNewItemProperties] = React.useState<any>()
  const [regions, setRegions] = React.useState<definitions['NextgisRegionName'][]>([])
  const [disableAdding, setDisableAdding] = React.useState<boolean>(false)
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const selectedItems = useSelector(selectSelectedItems)
  useGetRegionCoefficients(requestData)
  const nextGISRegions = useGetRegions()

  React.useEffect(() => {
    const filteredOptions = nextGISRegions.filter(
      (record) => !directoryItems.some((region) => record.name === region.name),
    )
    setRegions(filteredOptions)
    if (filteredOptions.length < 1) setDisableAdding(true)
    else if (disableAdding) setDisableAdding(false)
    setNewItemProperties({
      id: generateUID(),
      created: true,
      modified: false,
      name: (filteredOptions[0] as any)?.name,
      contract_work_linear_coeff: 1,
      contract_work_cs_coeff: 1,
      contract_work_gasline_coeff: 1,
      customer_stuff_coeff: 1,
    })
  }, [nextGISRegions, directoryItems?.length])

  const saveResult = useCreateRegionCoefficientsVersion(saveData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, value: number | any, fieldName: string) => {
    if (fieldName === 'name') {
      dispatch(updateItemFieldById({ id, value: value?.name, fieldName }))
    } else {
      dispatch(updateItemFieldById({ id, value, fieldName }))
    }
    dispatch(setModified(id))
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const onItemSelect = (id: string) => {
    const itemToAdd = directoryItems?.find((item) => item.id === id)
    if (itemToAdd) dispatch(addSelectedItem(itemToAdd))
  }

  const onItemDeselect = (id: string) => {
    dispatch(deselectSelectedItemById(id))
  }

  const dataSource = React.useMemo(() => {
    const data: any[] = []
    directoryItems.forEach((item: definitions['RegionCoefficients']) => {
      const { name, ...rest } = item
      data.push({ name: { name }, ...rest })
    })
    return data
  }, [directoryItems])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      newItemToAdd={newItemProperties}
      disableAdding={disableAdding}
      isModified={isModified}
      setIsModified={setIsModified}
    >
      <div className={'directory__content'}>
        <MultiSelectTable
          className={'directory-table__container region-coefficients-table__container'}
          columns={columns(editMode, regions)}
          dataSource={dataSource}
          setValue={onSaveItemField}
          onItemSelect={onItemSelect}
          onItemDeselect={onItemDeselect}
          selectedItems={selectedItems}
          editMode={editMode}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryRegionCoefficients
