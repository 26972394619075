import { EditElementPanel } from '../../ElementPanel/editElementPanel'
import Dropdown from 'components/Dropdown/Dropdown'
import React, { useEffect, useState } from 'react'
import NumberInput from 'components/Input/numberInput'
import { setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectProject } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'
import { FilterButton } from '../../filterButton'
import { classOptions } from 'pages/utils'
import { selectTaskSettings, setTaskSettings, setTaskSettingsPipeType } from 'store/optimizationSlice'
import useCreateTask from './hooks/useCreateTask'
import IntegerInput from 'components/Input/integerInput'
import './settings.scss'
import useGetCalcProps from './hooks/useGetCalcProps'
import { IEditPipeTypeParams } from 'store/types'
import Checkbox from 'components/Checkbox'
import { layingTypeOptions } from 'components/Map/PipelineProfile/ProfilePanel/components/BarChart/LayingTypePopup'

const Settings: React.FC = () => {
  const dispatch = useDispatch()
  const taskSettings = useSelector(selectTaskSettings)
  const [updatePipeType, setUpdatePipeType] = useState<definitions['PipeTypeUpdate']>()
  const project = useSelector(selectProject)

  useGetCalcProps()
  useCreateTask(taskSettings)
  const updateStore = (params: any) => {
    dispatch(
      setTaskSettings({
        ...taskSettings,
        ...params,
      }),
    )
  }
  useEffect(() => {
    if (updatePipeType && taskSettings?.pipe_type)
      dispatch(
        setTaskSettingsPipeType({
          ...taskSettings.pipe_type,
          ...updatePipeType,
        }),
      )
  }, [updatePipeType])

  return (
    <EditElementPanel
      className={'optimization-settings__edit-element-panel'}
      title={'Автоматическое построение трубопровода'}
    >
      <div className={'edit-element-panel__subtitle'}>Параметры построения</div>
      <Checkbox
        checkedValue={taskSettings?.use_angles || false}
        setCheckedValue={(value) => updateStore({ use_angles: value })}
      >
        Учитывать углы пересечения с линейными объектами
      </Checkbox>
      <Checkbox
        checkedValue={taskSettings?.use_slopes || false}
        setCheckedValue={(value) => updateStore({ use_slopes: value })}
      >
        Учитывать перепады высот
      </Checkbox>
      <div className={'common-row'}>
        <hr />
      </div>
      <div className={'edit-element-panel__subtitle'}>Параметры трубопровода</div>
      <div className={'edit-element-panel__table'}>
        <Dropdown
          options={classOptions}
          displayedField={'name'}
          selectedValue={classOptions.find((option) => option.id === taskSettings?.class_.toString())}
          label={'Класс'}
          setSelectedValue={(value) => updateStore({ class_: value.id })}
        />
        <IntegerInput
          value={taskSettings?.n_pipes}
          label={'Кол-во нитей'}
          fieldName={'n_pipes'}
          setValue={(value, field: string) => updateStore({ [field]: value })}
          noEmpty
        />
        <Dropdown
          options={layingTypeOptions}
          displayedField={''}
          label={'Способ прокладки'}
          selectedValue={layingTypeOptions.find((option) => option === taskSettings?.target_laying_type.toString())}
          setSelectedValue={(value) => updateStore({ target_laying_type: value })}
        />
        <div className={'common-row'}>
          <hr />
        </div>
      </div>
      <div className={'edit-element-panel__subtitle optimization-settings__complex-subtitle'}>
        Параметры трубы
        <FilterButton
          onClick={() => {
            dispatch(
              setBlockingWindow({
                type: 'EDIT_PIPE_TYPE',
                params: {
                  objectID: project.detail?.trunk_pipeline.id,
                  objectType: 'TRUNK_PIPELINE',
                  currentPipeTypeID: taskSettings?.pipe_type?.id,
                } as IEditPipeTypeParams,
              }),
            )
          }}
        />
      </div>
      <div className={'edit-element-panel__table'}>
        <NumberInput value={taskSettings?.pipe_type?.diameter__mm} unit={'мм'} label={'Внешний диаметр'} disabled />
        <NumberInput
          value={taskSettings?.pipe_type?.thickness__mm}
          unit={'мм'}
          label={'Толщина стенки'}
          fieldName={'thickness__mm'}
          setValue={(value) => {
            setUpdatePipeType({ thickness__mm: value })
          }}
        />
        <NumberInput
          value={taskSettings?.pipe_type?.roughness__mm}
          unit={'мм'}
          label={'Шероховатость'}
          fieldName={'roughness__mm'}
          setValue={(value) => {
            setUpdatePipeType({ roughness__mm: value })
          }}
        />
        <Dropdown
          options={[{ steel_name: 'K 60' }, { steel_name: 'K 65' }, { steel_name: 'X 70' }, { steel_name: 'X 80' }]}
          displayedField={'steel_name'}
          selectedValue={{ steel_name: taskSettings?.pipe_type?.steel_name }}
          setSelectedValue={setUpdatePipeType}
          label={'Марка стали'}
        />
      </div>
    </EditElementPanel>
  )
}

export default Settings
