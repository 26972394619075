import React from 'react'
import { useSelector } from 'react-redux'
import { selectBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, ICalcProgressParams } from 'store/types'
import { AutoCSProgressWindow } from './AutoCSProgressWindow'
import { HydroProgressWindow } from './HydroProgressWindow'
import { EconomicProgressWindow } from './EconomicProgressWindow'
import { DNLayersProgressWindow } from './DNLayersProgressWindow'
import { OptimizationProgressWindow } from './OptimizationProgressWindow'
import { ProfileProgressWindow } from './ProfileProgressWindow'

export const CalcProgressWindow: React.FC = () => {
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICalcProgressParams)

  return (
    <>
      {blockingWindowParams?.taskType === 'AUTO_CS_TASK' && <AutoCSProgressWindow />}
      {blockingWindowParams?.taskType === 'HYDRO_TASK' && <HydroProgressWindow />}
      {blockingWindowParams?.taskType === 'ECONOMIC_TASK' && <EconomicProgressWindow />}
      {blockingWindowParams?.taskType === 'DN_LAYERS_TASK' && <DNLayersProgressWindow />}
      {blockingWindowParams?.taskType === 'OPTIMIZATION_TASK' && <OptimizationProgressWindow />}
      {blockingWindowParams?.taskType === 'PROFILE_TASK' && <ProfileProgressWindow />}
    </>
  )
}

export default CalcProgressWindow
