import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IProfileSlice } from './types'

export const initialState: IProfileSlice = {
  taskId: undefined,
  createTask: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  profileHeights: undefined,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IProfileSlice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IProfileSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IProfileSlice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IProfileSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IProfileSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    setProfileHeights: (state, action: PayloadAction<IProfileSlice['profileHeights']>) => {
      return { ...state, profileHeights: action.payload }
    },
    resetProfileSliceState: () => {
      return initialState
    },
  },
})

export const {
  setCreateTask,
  setTaskId,
  setTaskCalculationProgress,
  setTaskStatus,
  setErrorMessage,
  setProfileHeights,
  resetProfileSliceState,
} = profileSlice.actions

export const selectCreateTask = (state: RootState) => state.profile.createTask

export const selectTaskId = (state: RootState) => state.profile.taskId

export const selectTaskCalculationProgress = (state: RootState) => state.profile.taskCalculationProgress

export const selectTaskStatus = (state: RootState) => state.profile.taskStatus

export const selectErrorMessage = (state: RootState) => state.profile.errorMessage

export const selectProfileHeights = (state: RootState) => state.profile.profileHeights

export default profileSlice.reducer
