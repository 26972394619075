import * as React from 'react'

function CheckedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={13} height={10} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0406 0.959557C12.4311 1.35008 12.4311 1.98325 12.0406 2.37377L5.37394 9.04044C4.98341 9.43096 4.35025 9.43096 3.95972 9.04044L0.626389 5.7071C0.235865 5.31658 0.235865 4.68342 0.626389 4.29289C1.01691 3.90237 1.65008 3.90237 2.0406 4.29289L4.66683 6.91912L10.6264 0.959557C11.0169 0.569033 11.6501 0.569033 12.0406 0.959557Z'
        fill='#0074BC'
      />
    </svg>
  )
}

export default CheckedIcon
