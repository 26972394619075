import { definitions } from 'generated/apiTypes'

interface IACUColumn {
  title: string | JSX.Element
  type: 'Text' | 'Number'
  key: keyof definitions['HandbookACU']
  editable: boolean
  signed?: boolean
  sticky?: boolean
}

export const columns: IACUColumn[] = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
    sticky: true,
  },
  {
    title: 'Критическое давление, кгс/см2',
    type: 'Number',
    key: 'critical_pressure__kgs_per_cm2',
    editable: true,
  },
  {
    title: 'Критическая температура, К',
    type: 'Number',
    key: 'critical_temperature__K',
    editable: true,
  },
  {
    title: 'Барометр. давление воздуха, мм.рт.ст',
    type: 'Number',
    key: 'air_pressure__mmHg',
    editable: true,
  },
  {
    title: 'Точн. расч. температуры охлаждения, °C',
    type: 'Number',
    key: 'cooling_temperature_calculation_precision__degC',
    editable: true,
  },
  {
    title: 'Теплоемкость воздуха, ккал/кг.град',
    type: 'Number',
    key: 'air_heat_capacity__kcal_per_kg_deg',
    editable: true,
  },
  {
    title: 'Поверхность охлаждения одного АВО, м2',
    type: 'Number',
    key: 'unit_cooling_surface__m2',
    editable: true,
  },
  {
    title: 'Число ходов труб в секциях, шт.',
    type: 'Number',
    key: 'n_pipes_passes_in_section',
    editable: true,
  },
  {
    title: 'Число рядов труб в секциях, шт.',
    type: 'Number',
    key: 'n_pipes_rows_in_section',
    editable: true,
  },
  {
    title: 'Внутренний диаметр труб, м',
    type: 'Number',
    key: 'pipes_inner_diameter__m',
    editable: true,
  },
  {
    title: 'Коэф. увеличения  поверхности',
    type: 'Number',
    key: 'surface_enlargement_coefficient',
    editable: true,
  },
  {
    title: 'Число труб в АВО, шт.',
    type: 'Number',
    key: 'n_pipes_per_unit',
    editable: true,
  },
  {
    title: 'Длина труб в АВО, м',
    type: 'Number',
    key: 'pipes_length_per_unit__m',
    editable: true,
  },
  {
    title: 'Тепловое сопр. загрязнений (внут.), м2.час.оС/ккал',
    type: 'Number',
    key: 'internal_fouling_thermal_resistance__m2_hour_degC_per_kcal',
    editable: true,
  },
  {
    title: 'Тепловое сопр. загрязнений (нар.), м2.час.°C/ккал',
    type: 'Number',
    key: 'external_fouling_thermal_resistance__m2_hour_degC_per_kcal',
    editable: true,
  },
  {
    title: 'Площ. свобод. сечения перед секциями оребренных труб, м2',
    type: 'Number',
    key: 'free_cross_section_area_in_front_of_finned_tube_sections__m2',
    editable: true,
  },
  {
    title: 'Коэф. сужения  сечений',
    type: 'Number',
    key: 'section_contraction_coefficient',
    editable: true,
  },
  {
    title: 'Угол поворота лопастей вентилятора, ° (град)',
    type: 'Number',
    key: 'fan_blade_rotation_angle__deg',
    editable: true,
  },
  {
    title: 'Объем. расход воздуха вентилятора, м3/час',
    type: 'Number',
    key: 'fan_volumetric_airflow_rate__m3_per_hour',
    editable: true,
  },
  {
    title: 'Коэф. полезного действия вентилятора',
    type: 'Number',
    key: 'fan_efficiency',
    editable: true,
  },
]

export const readOnlyColumns = structuredClone(columns).map((column) => {
  column.editable = false
  return column
})
