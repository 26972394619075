import React from 'react'
import './cs.scss'
import NumberInput from 'components/Input/numberInput'
import Dropdown from 'components/Dropdown/Dropdown'
import { definitions } from 'generated/apiTypes'
import Tabs, { ITab } from 'components/Tabs/tabs'
import Plant from './plant'
import Checkbox from 'components/Checkbox'
import useGetValidationMessage from 'components/hooks/useGetValidationMessage'
import useGetDefaultPlant from '../hooks/useGetDefaultPlant'

type tabKey = 'Plant1' | 'Plant2'

interface ICSProps {
  CSStation: definitions['CSPlacementProperties']['draft_compressor_station'] | undefined
  setCSStation(station: definitions['CSPlacementProperties']['draft_compressor_station']): void
}

const CS: React.FC<ICSProps> = ({ CSStation, setCSStation }) => {
  const [plantsNumber, setPlantsNumber] = React.useState<number>()
  const [activeTab, setActiveTab] = React.useState<tabKey>('Plant1')
  const [tabsList, setTabsList] = React.useState<ITab<tabKey>[]>([
    { key: 'Plant1', tabContent: 'Цех 1' },
    { key: 'Plant2', tabContent: 'Цех 2' },
  ])
  const isPlant1Errors =
    useGetValidationMessage('compressor-stations-placement', 'draft_compressor_station', 'first_compressor_plant') ||
    false
  const isPlant2Errors =
    useGetValidationMessage('compressor-stations-placement', 'draft_compressor_station', 'second_compressor_plant') ||
    false

  const defaultPlant = useGetDefaultPlant(plantsNumber)

  React.useEffect(() => {
    setTabsList([
      { key: 'Plant1', tabContent: 'Цех 1', error: isPlant1Errors as boolean },
      { key: 'Plant2', tabContent: 'Цех 2', error: isPlant2Errors as boolean },
    ])
  }, [isPlant1Errors, isPlant2Errors])

  const setCSStationValue = (value: number, fieldName?: string) => {
    setCSStation({ ...(CSStation as any), [fieldName as string]: value })
  }

  const setCSPlantValue = (value: number | string | boolean, fieldName?: string) => {
    const csPlant = activeTab === 'Plant1' ? CSStation?.first_compressor_plant : CSStation?.second_compressor_plant
    if (csPlant) {
      setCSStation({
        ...(CSStation as any),
        [activeTab === 'Plant1' ? 'first_compressor_plant' : 'second_compressor_plant']: {
          ...(CSStation && CSStation[activeTab === 'Plant1' ? 'first_compressor_plant' : 'second_compressor_plant']),
          [fieldName as string]: value,
        },
      })
    }
  }

  React.useEffect(() => {
    if (CSStation?.second_compressor_plant) {
      setPlantsNumber(2)
    } else setPlantsNumber(1)
  }, [CSStation?.second_compressor_plant])

  const changePlantsNumber = (num: number | undefined) => {
    if (num === 1) {
      setCSStation({ ...(CSStation as any), second_compressor_plant: undefined })
    }
    setPlantsNumber(num)
    setActiveTab('Plant1')
  }

  React.useEffect(() => {
    if (defaultPlant && plantsNumber === 2 && !CSStation?.second_compressor_plant) {
      setCSStation({ ...(CSStation as any), second_compressor_plant: defaultPlant })
    }
  }, [defaultPlant])

  const currentPlant = activeTab === 'Plant1' ? CSStation?.first_compressor_plant : CSStation?.second_compressor_plant

  return (
    <div className={'cs-settings__container'}>
      <div className={'cs-settings__subtitle'}>Параметры КС</div>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          unit={'МПа'}
          id={'compressor-stations-placement'}
          label={'Макс. давление нагнетания'}
          value={CSStation?.pressure__MPa}
          level1FieldName={'draft_compressor_station'}
          fieldName={'pressure__MPa'}
          setValue={setCSStationValue}
          signed
        />
        <Dropdown
          options={[{ num: 1 }, { num: 2 }]}
          displayedField={'num'}
          selectedValue={{ num: plantsNumber }}
          setSelectedValue={(option) => {
            changePlantsNumber(option.num)
          }}
          label={'Кол-во цехов'}
        />
      </div>
      {plantsNumber !== 2 ? (
        <div className={'cs-settings__subtitle'}>Цех КС</div>
      ) : (
        <>
          <Tabs
            tabList={tabsList}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            containerClassName={'auto-cs-calc__tabs'}
            tabClassName={'auto-cs-calc__tab'}
          />
          {activeTab === 'Plant1' && currentPlant && (
            <>
              <div className={'cs-settings__grid-row'}>
                <NumberInput
                  unit={'%'}
                  id={'compressor-stations-placement'}
                  value={currentPlant?.auto_gas_share ? undefined : currentPlant?.gas_share}
                  fieldName={'gas_share'}
                  setValue={setCSPlantValue}
                  disabled={currentPlant?.auto_gas_share}
                  label={'Доля газа переходящая на Цех 2'}
                />
                <div className={'cs-settings__checkbox-container normal-text'}>
                  <Checkbox
                    checkedValue={currentPlant?.auto_gas_share!}
                    setCheckedValue={(value) => setCSPlantValue(value, 'auto_gas_share')}
                  />
                  <div>Рассчитать автоматически</div>
                </div>
              </div>
              <div className={'cs-settings__divider'} />
            </>
          )}
        </>
      )}
      {currentPlant ? (
        <Plant plant={currentPlant} setPlant={setCSPlantValue} plantIx={activeTab === 'Plant1' ? 0 : 1} />
      ) : (
        <span className={'hydraulic-settings__loading-message normal-text'}>Подождите...</span>
      )}
    </div>
  )
}

export default CS
