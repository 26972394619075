import { useSelector } from 'react-redux'
import { RootState } from 'store/configureStore'
import React, { useEffect, useRef, useState } from 'react'
import { selectSourceID } from 'store/projectSlice'
import { concatLines, last } from '../utils'
import { getCoords, lineString, segmentReduce, Position, lineChunk, length, featureCollection } from '@turf/turf'

import { Point } from 'geojson'
import { Layer } from '../Layer'
import { segmentLengthByZoom } from '../utils/segmentLengthByZoom'
interface IKilometerMarksLayerProps {
  zoom: number
  visible?: boolean
}
export const KilometerMarksLayer: React.FC<IKilometerMarksLayerProps> = ({ zoom, visible }) => {
  const pipelines = useSelector((store: RootState) => store.map.pipelines)
  const [pipelinesSorted, setPipelineSorted] = useState<Position[]>([])
  const sourceID = useSelector(selectSourceID)
  const [marks, setMarks] = useState<Point[]>([])
  const marksRef = useRef<{ [key: string]: Point[] }>({})
  const [segmentLength, setSegmentLength] = useState<number | undefined>()

  const sortPipeline = () => {
    const pipes = []
    let currentNode = sourceID as string
    const visited: string[] = []
    while (currentNode) {
      const pipeline = pipelines.find(
        (obj) =>
          (obj.start_node_id === currentNode && !visited.includes(obj.end_node_id as string)) ||
          (obj.end_node_id === currentNode && !visited.includes(obj.start_node_id as string)),
      )

      if (pipeline) {
        pipes.push(pipeline)
        visited.push(currentNode)
        currentNode = (pipeline.start_node_id === currentNode ? pipeline.end_node_id : pipeline.start_node_id) as string
      } else break
    }
    return pipes.reduce((prev: any, cur: any) => {
      if (prev.length) return concatLines(getCoords(cur.line), prev)
      else {
        return getCoords(cur.line)
      }
    }, [])
  }
  const runWorker = async (gap: number) => {
    const featCollection = lineChunk(lineString(pipelinesSorted), gap).features.slice(0, -1)

    const commonLength = Math.trunc(length(lineString(pipelinesSorted)) / gap)
    const segments = segmentReduce(
      lineString(pipelinesSorted),
      (prev: any, cur: any) => {
        prev?.push(cur)
        return prev
      },
      [],
    )
    let markDistance = 0
    const maxSegmentNumber = 1000
    let featureList = []
    for (const fetIndex in featCollection) {
      const fet = featCollection[fetIndex]
      const isLastFeature = Number(fetIndex) === featCollection.length - 1
      featureList.push(fet)
      if (featureList.length >= maxSegmentNumber || isLastFeature) {
        const worker = new Worker(new URL('./worker.ts', import.meta.url))
        worker.onmessage = (e) => {
          Object.keys(marksRef.current).includes(e.data.segmentLength.toString())
            ? (marksRef.current[e.data.segmentLength.toString()] = [
                ...marksRef.current[e.data.segmentLength.toString()],
                ...e.data.points,
              ])
            : (marksRef.current[e.data.segmentLength.toString()] = e.data.points)

          marksRef.current[e.data.segmentLength.toString()].length >= commonLength &&
            setMarks(marksRef.current[e.data.segmentLength.toString()])

          worker.terminate()
        }

        worker.postMessage({
          segments,
          startDistance: markDistance,
          allCoords: featureList.reduce(
            (prev: any, cur: any) => {
              prev.push(last(getCoords(cur)))
              return prev
            },
            [getCoords(featureList[0])[0]],
          ),
          gap,
        })
        markDistance += featureList.length * gap
        featureList = []
      }
    }
  }
  useEffect(() => {
    if (segmentLength && pipelinesSorted.length) {
      !Object.keys(marksRef.current).includes(segmentLength.toString())
        ? runWorker(segmentLength)
        : setMarks(marksRef.current[segmentLength.toString()])
    }
  }, [segmentLength, pipelinesSorted])

  useEffect(() => {
    if (pipelines.length) {
      setMarks([])
      marksRef.current = {}
      const lines = sortPipeline()
      setPipelineSorted(lines)
    }
  }, [pipelines])

  useEffect(() => {
    if (!pipelines.length) return
    const l = segmentLengthByZoom(zoom)
    segmentLength !== l && setSegmentLength(l)
  }, [zoom])

  return (
    <>
      <Layer
        sourceID={`kilometer-marks__source`}
        baseLayer={'kilometer-marks__layer'}
        features={visible ? marks : []}
        layerType={'symbol'}
        source
      />
    </>
  )
}
