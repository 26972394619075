import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import './directoryCSUnitCosts.scss'
import { Table } from 'components/Table/Table/Table'
import useGetCSUnitCosts from './hooks/useGetCSUnitCosts'
import useCreateCSUnitCostsVersion from './hooks/useCreateCSUnitCostsVersion'

interface IDirectoryNextGISLayersProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryCSUnitCosts: React.FC<IDirectoryNextGISLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as definitions['PowerToCompressorStationUnitCosts']
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  useGetCSUnitCosts(requestData)

  const saveResult = useCreateCSUnitCostsVersion(saveData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, value: number, fieldName: string) => {
    if (id === 'expenses_not_related_to_power') {
      Object.keys(directoryItem).forEach((key) => {
        dispatch(
          updateItemField({
            value: {
              ...directoryItem[key as keyof definitions['PowerToCompressorStationUnitCosts']],
              [id]: value,
            },
            fieldName: key,
          }),
        )
      })
    } else {
      const fieldObj = {
        ...directoryItem[fieldName as keyof definitions['PowerToCompressorStationUnitCosts']],
        [id]: value,
      }
      dispatch(
        updateItemField({
          value: fieldObj,
          fieldName,
        }),
      )
    }
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: { [key: string]: any }[] = [
      {
        id: 'equipment',
        title: <>Оборудование, тыс.руб./МВт</>,
      },
      {
        id: 'preparing_area',
        title: (
          <>
            Подготовка территории,
            <br />
            компенсации, тыс.руб./МВт
          </>
        ),
      },
      {
        id: 'construction_works',
        title: (
          <>
            СМР Основные объекты,
            <br />
            тыс.руб./МВт
          </>
        ),
      },
      {
        id: 'other_construction_works',
        title: (
          <>
            СМР Прочие объекты,
            <br />
            тыс.руб./МВт
          </>
        ),
      },
      {
        id: 'temporary_structures',
        title: <>Временные сооружения, %</>,
      },
      {
        id: 'other_expenses',
        title: <>Прочие затраты, %</>,
      },
      {
        id: 'customer_service_maintenance',
        title: (
          <>
            Содержание службы
            <br />
            заказчика, строительный
            <br />
            контроль, %
          </>
        ),
      },
      {
        id: 'design_and_survey_works',
        title: (
          <>
            ПИР (вкл. экспертизу и
            <br />
            авторский надзор), %
          </>
        ),
      },
      {
        id: 'reserve',
        title: <>Резерв, %</>,
      },
      {
        id: 'expenses_not_related_to_power',
        title: (
          <>
            Затраты, не зависящие от
            <br />
            мощности КС, тыс.руб./КС
          </>
        ),
      },
    ]
    if (directoryItem) {
      Object.entries(directoryItem).forEach(([key, value], ix) => {
        data[0][key] = value.equipment
        data[1][key] = value.preparing_area
        data[2][key] = value.construction_works
        data[3][key] = value.other_construction_works
        data[4][key] = value.temporary_structures
        data[5][key] = value.other_expenses
        data[6][key] = value.customer_service_maintenance
        data[7][key] = value.design_and_survey_works
        data[8][key] = value.reserve
        if (ix === 0) data[9][key] = value.expenses_not_related_to_power
      })
    }
    return data
  }, [directoryItem])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
    >
      {directoryItem && (
        <div className={'directory__content'}>
          <Table
            className={'directory-table__container cs-unit-costs-table__container'}
            columns={columns(editMode)}
            dataSource={dataSource}
            setValue={onSaveItemField}
          />
        </div>
      )}
    </DirectoryWrapper>
  )
}

export default DirectoryCSUnitCosts
