import { Text } from './text'
import { endpoint } from '../utils'

export const EndPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, start: boolean, text: string) => {
  const width = 7
  const height = 27
  endpoint(ctx, x - width * 0.5, y - height * 0.5)
  Text(ctx, text, x, y - height * 0.5, {
    fontSize: 16,
    fontWeight: 800,
    color: 'black',
    textAlign: start ? 'right' : 'left',
  })
}
