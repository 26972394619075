import React, { useState } from 'react'
import classnames from 'classnames'
import IconExpandedArrow from 'images/IconExpandedArrow'
interface IExpandTableRow extends React.HTMLAttributes<HTMLElement> {
  id: string
  expandable?: boolean
  expandRow?(key: string): any
  expandColumn?: boolean
  expandCellContent?(key: any): JSX.Element
  children?: any
}
export const TableRow: React.FC<IExpandTableRow> = ({
  id,
  expandRow,
  expandable,
  children,
  expandCellContent,
  expandColumn,
}) => {
  const [expandRowDisplay, setExpandRowDisplay] = useState<boolean>(false)
  return (
    <>
      {expandable && (
        <div
          key={`expand-cell_${id}`}
          className={classnames('grid-table__cell expand-cell', expandRowDisplay && 'open')}
          onClick={() => setExpandRowDisplay(!expandRowDisplay)}
        >
          <IconExpandedArrow />
          {expandCellContent && expandCellContent(id)}
        </div>
      )}
      {children}
      <div
        key={`${id}_expand-row`}
        className={'grid-table__expand-row'}
        style={{
          display: expandRowDisplay ? 'flex' : 'none',
          gridColumnStart: 1,
          gridColumnEnd: React.Children.count(children) + (expandColumn ? 2 : 1),
        }}
      >
        {expandRow && expandRow(id)}
      </div>
    </>
  )
}
