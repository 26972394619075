import React, { useContext, useEffect } from 'react'
import { MapContext } from './map'
import { SourceSpecification } from 'maplibre-gl'
import { AnyLayer } from './types'
import { useDispatch } from 'react-redux'
import { increaseUpdateCount } from 'store/DNLayersSlice'

interface IDNLayerProps {
  layer: AnyLayer
  source: { [p: string]: SourceSpecification }
}

export const DNLayer: React.FC<IDNLayerProps> = (props) => {
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()

  useEffect(() => {
    const sourceId = Object.keys(props.source)[0]
    !mapContext?.getSource(sourceId) && mapContext?.addSource(sourceId, props.source[sourceId])
    !mapContext?.getLayer(props.layer.id) && mapContext?.addLayer(props.layer, 'points-ruler-line')
    dispatch(increaseUpdateCount())
    return () => {
      mapContext?.getSource(sourceId) && mapContext?.removeLayer(props.layer.id)
      mapContext?.getLayer(props.layer.id) && mapContext?.removeSource(sourceId)
    }
  }, [])

  return <></>
}
