import * as React from 'react'

function RedoIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.375 9.16643C3.58574 7.55872 4.37518 6.08291 5.59557 5.01529C6.81595 3.94766 8.38359 3.36139 10.0051 3.36624C11.6265 3.37109 13.1906 3.96672 14.4046 5.04162C15.6186 6.11653 16.3992 7.59703 16.6003 9.20598C16.8014 10.8149 16.4093 12.442 15.4972 13.7827C14.5852 15.1233 13.2158 16.0857 11.6455 16.4895C10.0751 16.8933 8.41136 16.711 6.96573 15.9766C5.5201 15.2422 4.39166 14.0061 3.79167 12.4998M3.375 16.6664V12.4998H7.54167'
        stroke='#373737'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default RedoIcon
