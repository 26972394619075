import { useDispatch, useSelector } from 'react-redux'
import { selectGetTaskResults, setGetTaskResults } from 'store/optimizationSlice'
import { getPipelinesByProjectIDRequest, getNodesByProjectIDRequest } from 'services/apiRequests'
import React from 'react'
import { selectProjectID } from 'store/projectSlice'
import { all, spread } from 'axios'
import { setMapNodes, setMapPipelines } from 'store/mapSlice'

const useGetOptimizationResults = () => {
  const dispatch = useDispatch()
  const getTaskResults = useSelector(selectGetTaskResults)
  const projectId = useSelector(selectProjectID)

  const tryToGetResults = async () => {
    if (projectId) {
      all([getPipelinesByProjectIDRequest(projectId), getNodesByProjectIDRequest(projectId) as any]).then(
        spread((pipelinesRes, nodesRes) => {
          dispatch(setMapPipelines(pipelinesRes.data))
          dispatch(setMapNodes(nodesRes.data))
        }),
      )
      dispatch(setGetTaskResults(false))
    }
  }

  React.useEffect(() => {
    if (getTaskResults) tryToGetResults()
  }, [getTaskResults])

  return
}

export default useGetOptimizationResults
