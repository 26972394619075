import React, { PropsWithChildren, ReactElement } from 'react'
import { ITab } from './tabs'
import classnames from 'classnames'
import ErrorIcon from '../../images/ErrorIcon'

interface ITabProps<tabKeyType> {
  tab: ITab<tabKeyType>
  setActiveTab(key: tabKeyType): void
  active: boolean
  className?: string
}

const Tab = <tabKeyType,>({
  tab,
  setActiveTab,
  className,
  active,
}: PropsWithChildren<ITabProps<tabKeyType>>): ReactElement | null => {
  const onTabClick = () => {
    setActiveTab(tab.key)
  }
  return (
    <button
      className={classnames(className, { active }, tab.error && 'error')}
      onClick={onTabClick}
      disabled={tab.disabled}
    >
      {tab.tabContent}
      {tab.error && <ErrorIcon />}
    </button>
  )
}

export default Tab
