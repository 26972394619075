import * as React from 'react'

function BackIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12.5 5l-5 5 5 5' stroke='#fff' strokeWidth={2.083} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default BackIcon
