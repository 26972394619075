import { definitions } from 'generated/apiTypes'

export const LayerTypes: definitions['HandbookAreaType']['type'][] = [
  'Дороги',
  'Железные дороги',
  'Местности',
  'Озера, водохранилища, крупные реки',
  'ООПТ',
  'Реки',
]

interface ILayersColumn {
  title: string | JSX.Element
  type: 'Text' | 'Boolean' | 'Option'
  options?: any[]
  displayedField?: string
  key: keyof definitions['HandbookAreaType']
  editable: boolean
}

export const columns: ILayersColumn[] = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
  },
  {
    title: 'Запретный слой',
    type: 'Boolean',
    key: 'is_restricted',
    editable: true,
  },
  {
    title: 'Тип',
    type: 'Option',
    key: 'type',
    options: LayerTypes,
    editable: true,
    displayedField: '',
  },
]
