import React from 'react'
import { useDispatch } from 'react-redux'

import { setGetProjects } from 'store/projectsListSlice'
import { copyProjectRequest } from 'services/apiRequests'
import { useNavigate } from 'react-router-dom'

const useCopyProject = (projectID?: string) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const tryToCopyProject = async () => {
    if (projectID)
      copyProjectRequest(projectID).then((res) => {
        dispatch(setGetProjects(true))
        navigate(`/project/${res.data.id}`)
      })
  }

  React.useEffect(() => {
    projectID && tryToCopyProject()
  }, [projectID])

  return
}

export default useCopyProject
