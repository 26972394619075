import * as React from 'react'

function PathAutoIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_3739_29513)' strokeOpacity={0}>
        <path
          d='M7 5C7 6.10457 6.10457 7 5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5Z'
          fill='#2C3E50'
        />
        <path
          d='M17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15Z'
          fill='#2C3E50'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.41516 7.97572L4.01025 5.14136L5.99015 4.85852L6.39506 7.69288L6.98528 7.84043C7.87561 8.06302 8.5002 8.86298 8.5002 9.78072V11.1326L10.888 11.4738C11.2208 11.5213 11.5362 11.652 11.8052 11.8537L13.5589 13.169L16.0147 14.6424L14.9857 16.3574L12.4415 14.8309L10.6052 13.4537L8.21736 13.1125C7.23206 12.9718 6.5002 12.1279 6.5002 11.1326L6.5002 9.78072L5.90999 9.63316C5.1211 9.43594 4.53016 8.78072 4.41516 7.97572Z'
          fill='#2C3E50'
        />
        <path
          d='M-0.0200195 20L3.27998 12.95H4.77998L8.09998 20H6.24998L5.39998 18.06L6.10998 18.57H1.96998L2.66998 18.06L1.82998 20H-0.0200195ZM4.01998 14.85L2.84998 17.64L2.56998 17.17H5.49998L5.24998 17.64L4.06998 14.85H4.01998Z'
          fill='#2C3E50'
        />
        <circle cx='17' cy='3' r='3' fill='#00BC84' />
      </g>
      <defs>
        <clipPath id='clip0_3739_29513'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PathAutoIcon
