import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { options as lineChartOptions } from './lineChartOptions'
import { ProfileConfig } from '../config'
import { verticalLineAtCursor } from '../chartPlugins'
import { IChartProps, IChartsProps, IControlChartProps } from '../ProfileChart'
import useGetRouteProfileHeights from '../../hooks/useGetRouteProfileHeights'
import useChartData from '../../hooks/useLineChartData'
import { profileRound } from '../utils'
import withControl from '../withControl'
import Dropdown from 'components/Dropdown/Dropdown'

const LineChart: React.FC<IControlChartProps & IChartsProps & IChartProps> = ({
  requestRange,
  startPoint,
  setStartPoint,
  finishPoint,
  setFinishPoint,
  minPoint,
  setMinPoint,
  maxPoint,
  setMaxPoint,
  setCurrentMaxPoint,
  setCurrentMinPoint,
  chartRef,
  panelRef,
  chartTypeOptions,
  chartTypeOption,
  setChartTypeOption,
}) => {
  const { profileData, profileIsLoading } = useGetRouteProfileHeights(requestRange, chartTypeOption.value)
  const lineChartData = useChartData(profileData || [])
  const [minmaxInit, setMinmaxInit] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (profileData && !minmaxInit) {
      setMinmaxInit(true)
      if (setMinPoint) setMinPoint(profileRound(profileData[0].distance__km))
      if (setMaxPoint) setMaxPoint(profileRound(profileData[profileData.length - 1].distance__km))
      if (startPoint === undefined) setStartPoint(profileRound(profileData[0].distance__km))
      if (finishPoint === undefined) setFinishPoint(profileRound(profileData[profileData.length - 1].distance__km))
    }
    if (profileData) {
      if (startPoint !== undefined && finishPoint) {
        setCurrentMaxPoint(finishPoint)
        setCurrentMinPoint(startPoint)
      } else {
        setCurrentMaxPoint(profileRound(profileData[profileData.length - 1].distance__km))
        setCurrentMinPoint(profileRound(profileData[0].distance__km))
      }
    }
  }, [profileData])

  const chart = useMemo(() => {
    const isDataLoaded = lineChartData.datasets[0] && lineChartData.datasets[0].data.length
    return isDataLoaded > 0 && minPoint !== undefined && maxPoint !== undefined && !profileIsLoading ? (
      <Line
        ref={chartRef}
        data={lineChartData}
        options={lineChartOptions(
          'Высотная отметка, м',
          'Точка трубопровода, км',
          ProfileConfig.minRange,
          minPoint,
          maxPoint,
          setStartPoint,
          setFinishPoint,
        )}
        plugins={[verticalLineAtCursor()]}
      />
    ) : (
      <div className={'height-profile-panel__loading-container'}>
        <div className='progress__loader' />
      </div>
    )
  }, [lineChartData, minPoint, maxPoint, profileIsLoading])

  return (
    <>
      <div className={'height-profile-panel__select-row'}>
        <Dropdown
          options={chartTypeOptions}
          selectedValue={chartTypeOption}
          displayedField={'name'}
          setSelectedValue={setChartTypeOption}
          containerClassNames={'pressed-dropdown'}
          panelRef={panelRef}
        />
      </div>
      {chartTypeOption.value !== 'NONE' && <div className={'height-profile-panel__chart-container'}>{chart}</div>}
    </>
  )
}

export default withControl(LineChart)
