import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectValidation, setValidation } from 'store/validationSlice'
import update from 'immutability-helper'

const useResetValidationMessage = (
  reset: number,
  id?: string,
  level1Field?: string,
  level2Field?: string,
  field?: string,
) => {
  const validation = useSelector(selectValidation)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (reset > 0) {
      if (id && level1Field && !level2Field && field) {
        const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
        if (index > -1) {
          let newValidation = update(validation, { [index]: { errorMessage: { $unset: [field] } } })
          if (Object.keys(newValidation[index]?.errorMessage as Record<any, any>).length === 0) {
            newValidation = update(validation, { $splice: [[index, 1]] })
            dispatch(setValidation(newValidation))
          } else {
            dispatch(setValidation(newValidation))
          }
        }
      } else if (id && level1Field && level2Field && field) {
        const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
        if (index > -1) {
          if ((validation[index]?.errorMessage as Record<any, any>)[level2Field as any]) {
            let newValidation = update(validation, {
              [index]: { errorMessage: { [level2Field]: { $unset: [field] } } },
            })
            if (
              Object.keys((newValidation[index]?.errorMessage as Record<any, any>)[level2Field as any]).length === 0
            ) {
              newValidation = update(validation, { [index]: { errorMessage: { $unset: [level2Field] } } })
              if (Object.keys(newValidation[index]?.errorMessage as Record<any, any>).length === 0) {
                newValidation = update(validation, { $splice: [[index, 1]] })
              }
              dispatch(setValidation(newValidation))
            } else {
              dispatch(setValidation(newValidation))
            }
          }
        }
      } else if (id && field) {
        const index = validation.findIndex((item) => item.id === id && item.field === field)
        if (index > -1) {
          const newValidation = update(validation, { $splice: [[index, 1]] })
          dispatch(setValidation(newValidation))
        }
      }
    }
  }, [reset])

  return
}

export default useResetValidationMessage
