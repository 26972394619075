import { definitions } from 'generated/apiTypes'

export type IHandbookGPUNewUnit = definitions['HandbookGPUCreate'] & {
  created?: boolean
}
export const readonlyFields = [
  'fuel_technical_condition_factor',
  'temperature_environment__K',
  'electric_engine_efficiency',
  'multiplier_efficiency',
  'compressibility_per_volume_flow_rate_1',
  'compressibility_per_volume_flow_rate_2',
  'compressibility_per_volume_flow_rate_3',
  'polytropic_efficiency_2',
  'polytropic_efficiency_3',
  'polytropic_efficiency_4',
  'polytropic_efficiency_5',
  'inner_power_1',
  'inner_power_2',
  'inner_power_3',
  'inner_power_4',
  'air_temperature_influence_coefficient',
  'heat_utilization_coefficient',
  'turbine_speed_influence_coefficient',
]
