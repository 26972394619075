import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCompressorStations, selectCompressorStationsOrder, selectProject } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'

const useGetData = (): any[] => {
  const compressorStations = useSelector(selectCompressorStations) as definitions['CompressorStation'][]
  const compressorStationsOrder = useSelector(selectCompressorStationsOrder)
  const project = useSelector(selectProject)

  const getCompressorPlantProps = (compressorPlant: definitions['CompressorPlant']): Record<any, any> => {
    return {
      gpu: compressorPlant.gpu,
      n_gpu_first_stage: compressorPlant.n_gpu_first_stage,
      n_gpu_second_stage: compressorPlant.n_gpu_second_stage,
      n_gpu_total: compressorPlant.n_gpu_total,
      max_temperature: compressorPlant.max_temperature,
      temperature_gtf: compressorPlant.temperature_gtf,
      temperature_ambient: compressorPlant.temperature_ambient,
      min_allowable_relative_value: compressorPlant.min_allowable_relative_value,
      max_allowable_relative_value: compressorPlant.max_allowable_relative_value,
      gas_inflow: compressorPlant.gas_inflow,
      acu: compressorPlant.acu,
      n_aca_first_stage: compressorPlant.n_aca_first_stage,
      n_throws_aca_first_stage: compressorPlant.n_throws_aca_first_stage,
      n_aca_second_stage: compressorPlant.n_aca_second_stage,
      n_throws_aca_second_stage: compressorPlant.n_throws_aca_second_stage,
      inlet_pressure_drop: compressorPlant.inlet_pressure_drop,
      exit_pressure_drop: compressorPlant.exit_pressure_drop,
      aca_pressure_drop: compressorPlant.aca_pressure_drop,
      cooling_level: compressorPlant.cooling_level,
      id: compressorPlant.id,
    }
  }

  return useMemo(() => {
    const data: any = []

    if (
      project?.detail?.compressor_stations &&
      compressorStations.length === project?.detail?.compressor_stations.length
    ) {
      const arr = [...compressorStations]
      arr
        .sort(
          (a, b) =>
            compressorStationsOrder?.findIndex((item) => item.id === a.id)! -
            compressorStationsOrder?.findIndex((item) => item.id === b.id)!,
        )
        ?.map((cs) => {
          if (cs.compressor_plants.length > 1) {
            cs.compressor_plants.map((compressor_plant, ix) => {
              if (ix === 0) {
                return data.push({
                  name: cs.name,
                  pressure__MPa: cs.pressure__MPa,
                  cs_id: cs.id,
                  compressor_plants_number: cs.compressor_plants.length,
                  compressor_plant_index: ix + 1,
                  gas_share: compressor_plant.auto_gas_share ? null : compressor_plant.gas_share,
                  auto_gas_share: compressor_plant.auto_gas_share,
                  combined: true,
                  disabledFields: compressor_plant.cooling_level ? ['temperature_ambient'] : [],
                  ...getCompressorPlantProps(compressor_plant),
                } as any)
              } else {
                return data.push({
                  compressor_plant_index: ix + 1,
                  gas_share: null,
                  auto_gas_share: null,
                  ...getCompressorPlantProps(compressor_plant),
                  disabledFields: compressor_plant.cooling_level ? ['temperature_ambient'] : [],
                  disable: true,
                } as any)
              }
            })
          } else if (cs.compressor_plants.length > 0) {
            data.push({
              name: cs.name,
              pressure__MPa: cs.pressure__MPa,
              compressor_plants_number: cs.compressor_plants.length,
              cs_id: cs.id,
              compressor_plant_index: 1,
              gas_share: null,
              auto_gas_share: null,
              disabledFields: cs.compressor_plants[0].cooling_level ? ['temperature_ambient'] : [],
              ...getCompressorPlantProps(cs.compressor_plants[0]),
              disable: true,
            } as any)
          }
        })
    }
    return data
  }, [compressorStations, project?.detail?.compressor_stations])
}

export default useGetData
