import React from 'react'
import '../styleGuide.scss'
import Input from 'components/Input'
import Dropdown from '../../../components/Dropdown/Dropdown'

const Inputs: React.FC = () => {
  const [selectedValue, setSelectedValue] = React.useState<Record<any, any>>()
  return (
    <div className='style-guide__panel style-guide__columns'>
      <div className='style-guide__panel-title'>Inputs</div>
      <Input label={'label'} />
      <Input disabled label={'label'} />
      <div style={{ width: 200 }}>
        <Input label={'label'} infoText={'info message'} />
      </div>
      <div style={{ width: 200 }}>
        <Input label={'label'} error warningText={'warning message'} />
      </div>
      <div style={{ width: 200 }}>
        <Input label={'label'} errorText={['error message1', 'error message2']} />
      </div>
      <div style={{ width: 200 }}>
        <Dropdown
          selectedValue={selectedValue}
          options={[
            { id: 1, name: 'Option 1' },
            { id: 2, name: 'Option 2' },
          ]}
          displayedField={'name'}
          setSelectedValue={setSelectedValue}
        />
      </div>
      <div style={{ width: 200 }}>
        <Dropdown
          selectedValue={{ id: 1, name: 'Option 1' }}
          options={[
            { id: 1, name: 'Option 1' },
            { id: 2, name: 'Option 2' },
          ]}
          displayedField={'name'}
          disabled
        />
      </div>
    </div>
  )
}

export default Inputs
