import React from 'react'
import './tasks.scss'
import Task from './components/Task'
import useGetTasks from './hooks/useGetTasks'
import { useSelector } from 'react-redux'
import { selectTasks } from 'store/tasksSlice'
import { definitions } from '../../../generated/apiTypes'
import useStopTask from './hooks/useStopTask'

const Tasks: React.FC = () => {
  const tasks = useSelector(selectTasks) as definitions['TaskQueue'][]

  useGetTasks()
  useStopTask()

  return (
    <div className={'main-page__container'}>
      <div className={'main-page-content__container'}>
        <h1 className={'project-list__projects-title no-margin'}>Очередь задач</h1>
        <div className={'tasks__table'}>
          <span className={'tasks__table-header normal-text'}>
            Тип расчета/
            <br />
            ID расчета
          </span>
          <span className={'tasks__table-header normal-text'}>
            Проект/
            <br />
            Пользователь
          </span>
          <span className={'tasks__table-header normal-text'}>Время запуска</span>
          <span className={'tasks__table-header normal-text'}>Статус</span>
          <span className={'tasks__table-header normal-text'}>Отмена</span>
          {tasks?.length > 0 ? (
            tasks.map((item, index) => (
              <Task
                key={index}
                title={item.task_type}
                project={item.project}
                id={item.task_id}
                author={item.user}
                date={item.start_time}
                status={item.task_status}
              />
            ))
          ) : (
            <div className={'tasks__empty-message normal-text'}>Нет задач в очереди</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Tasks
