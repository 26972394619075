import React from 'react'

export const columns = (editMode: boolean) => [
  {
    title: 'Слой системы',
    type: 'Column',
    key: 'title',
  },
  {
    title: 'Коэффициент влияния',
    type: 'Number',
    editable: editMode,
    key: 'coefficient',
    noEmpty: true,
    minValue: 1,
  },
]
