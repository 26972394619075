import React from 'react'

export const columns = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
    allowSpan: true,
    sticky: true,
  },
  {
    title: (
      <>
        Макс. давление <br /> нагнетания, МПа
      </>
    ),
    type: 'Number',
    key: 'pressure__MPa',
    editable: true,
    allowSpan: true,
  },
  {
    title: (
      <>
        Кол-во <br />
        цехов
      </>
    ),
    type: 'Option',
    key: 'compressor_plants_number',
    editable: true,
    options: [1, 2],
    displayedField: '',
    allowSpan: true,
  },
  {
    title: 'Цех',
    type: 'Number',
    key: 'compressor_plant_index',
    editable: false,
  },
  {
    title: (
      <>
        Доля, переход. <br />
        на цех 2, %
      </>
    ),
    type: 'Number',
    key: 'gas_share',
    editable: true,
    allowMixedEditing: true,
  },
  {
    title: (
      <>
        Рассчитать долю <br />
        автоматически
      </>
    ),
    type: 'Boolean',
    key: 'auto_gas_share',
    editable: true,
    allowMixedEditing: true,
  },
  {
    title: 'Тип ГПА',
    type: 'OptionAsButton',
    key: 'gpu',
    editable: true,
    displayedField: 'name',
  },
  {
    title: (
      <>
        Кол-во ГПА <br /> на 1 ступени
      </>
    ),
    type: 'Number',
    key: 'n_gpu_first_stage',
    editable: true,
  },
  {
    title: (
      <>
        Кол-во ГПА <br /> на 2 ступени
      </>
    ),
    type: 'Number',
    key: 'n_gpu_second_stage',
    editable: true,
  },
  {
    title: (
      <>
        Общее кол-во <br /> ГПА
      </>
    ),
    type: 'Number',
    key: 'n_gpu_total',
    editable: true,
  },
  {
    title: (
      <>
        Макс. темп. газа <br /> на выходе КС, °C
      </>
    ),
    type: 'Number',
    key: 'max_temperature',
    editable: true,
  },
  {
    title: (
      <>
        Расч. темп. газа <br /> на входе в ГТУ, °C
      </>
    ),
    type: 'Number',
    key: 'temperature_gtf',
    editable: true,
  },
  {
    title: (
      <>
        Темпер. наружного <br /> воздуха, °C
      </>
    ),
    type: 'Number',
    key: 'temperature_ambient',
    editable: true,
  },
  {
    title: (
      <>
        Рассчитать на &#34;уровне
        <br />
        охлаждения&#34;
      </>
    ),
    type: 'Boolean',
    key: 'cooling_level',
    editable: true,
  },
  {
    title: (
      <>
        Мин. допустимое <br /> значение отн. <br /> оборотов нагнетателя
      </>
    ),
    type: 'Number',
    key: 'min_allowable_relative_value',
    editable: true,
  },
  {
    title: (
      <>
        Макс. допустимое <br /> значение отн. <br /> оборотов нагнетателя
      </>
    ),
    type: 'Number',
    key: 'max_allowable_relative_value',
    editable: true,
  },
  {
    title: (
      <>
        Приток/Отбор газа <br /> перед КС, <br /> млн.куб.м/сутки
      </>
    ),
    type: 'Number',
    key: 'gas_inflow',
    editable: true,
    noEmpty: true,
  },
  {
    title: 'Тип АВО',
    type: 'OptionAsButton',
    key: 'acu',
    editable: true,
    displayedField: 'name',
  },
  {
    title: (
      <>
        Кол-во паралл. <br /> АВО I
      </>
    ),
    type: 'Number',
    key: 'n_aca_first_stage',
    editable: true,
  },
  {
    title: (
      <>
        Кол-во ходов <br /> в АВО I
      </>
    ),
    type: 'Number',
    key: 'n_throws_aca_first_stage',
    editable: true,
  },
  {
    title: (
      <>
        Кол-во паралл. <br /> АВО II
      </>
    ),
    type: 'Number',
    key: 'n_aca_second_stage',
    editable: true,
  },
  {
    title: (
      <>
        Кол-во ходов <br /> в АВО II
      </>
    ),
    type: 'Number',
    key: 'n_throws_aca_second_stage',
    editable: true,
  },
  {
    title: (
      <>
        Потери давления
        <br />
        на входе цеха, МПа
      </>
    ),
    type: 'Number',
    key: 'inlet_pressure_drop',
    editable: true,
  },
  {
    title: (
      <>
        Потери давления
        <br />
        на нагнетании, МПа
      </>
    ),
    type: 'Number',
    key: 'exit_pressure_drop',
    editable: true,
  },
  {
    title: (
      <>
        Потери давления
        <br />в обвязке АВО, МПа
      </>
    ),
    type: 'Number',
    key: 'aca_pressure_drop',
    editable: true,
  },
]
