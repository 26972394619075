import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getRouteProfileAreaSections,
  getRouteProfileCategorySections,
  getRouteProfileHeights,
  getRouteProfileLayingMethodSections,
  getRouteProfileRegionSections,
} from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams, IMap } from 'store/types'
import { definitions } from 'generated/apiTypes'
import { RouteCategory, RouteLayerType, RouteLayingMethod } from '../components/config'
import { selectHighlightData, setHighlightData, updateLayingMethodHighlightData } from 'store/mapSlice'
import { chartType } from '../components/ProfileChart'
import { profileRound } from '../components/utils'
import { selectProfileHeights } from '../../../../../store/profileSlice'

export type AreaSection = {
  distance_range__km: [number, number]
  value: RouteLayerType | RouteCategory | RouteLayingMethod | undefined
  regionValue?: string
  regionValueIx?: number
  valueIx?: number
  sectionId?: string
}

const useGetRouteProfileAreaSections = (
  params:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
  chartType: chartType,
  layingTypeUpdated: boolean,
) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const highlightDataStore = useSelector(selectHighlightData)
  const profileHeights = useSelector(selectProfileHeights)
  const [routeAreaSections, setRouteAreaSections] = React.useState<AreaSection[]>()
  const [dataIsLoading, setDataIsLoading] = React.useState<boolean>(false)
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()
  const firstUpdate = React.useRef(true)

  const getRegionValue = (regions: AreaSection[], km: number) => {
    return regions.find((item) => item.distance_range__km[0] <= km && item.distance_range__km[1] >= km)?.regionValue
  }

  const generateIntervals = (
    start_km: number,
    end_km: number,
    value: RouteLayerType | RouteCategory | RouteLayingMethod | undefined,
    kmValues: number[],
    index: number,
    regionsData?: AreaSection[],
  ): {
    distance_range__km: [number, number]
    value: RouteLayerType | RouteCategory | RouteLayingMethod | undefined
    regionValue?: string
    valueIx?: number
  }[] => {
    const data: {
      distance_range__km: [number, number]
      value: RouteLayerType | RouteCategory | RouteLayingMethod | undefined
      regionValue?: string
      valueIx?: number
    }[] = []
    const filteredKmValues = kmValues?.filter(
      (item) =>
        (profileRound(item as number) as number) > (profileRound(start_km) as number) &&
        (profileRound(item as number) as number) < (profileRound(end_km) as number),
    )
    if (filteredKmValues && filteredKmValues.length > 1) {
      if (index === 0) {
        data.push({
          distance_range__km: [start_km, start_km],
          value,
          ...(regionsData && { regionValue: getRegionValue(regionsData, start_km) }),
        })
        data.push({
          distance_range__km: [start_km, filteredKmValues[0]],
          value,
          ...(regionsData && { regionValue: getRegionValue(regionsData, filteredKmValues[0]) }),
        })
        //data.push({ distance_range__km: [start_km, filteredKmValues[1]], layer_type, category, laying_method })
      } else {
        data.push({
          distance_range__km: [start_km, filteredKmValues[0]],
          value,
          ...(regionsData && { regionValue: getRegionValue(regionsData, filteredKmValues[0]) }),
        })
        //data.push({ distance_range__km: [start_km, filteredKmValues[1]], layer_type, category, laying_method })
      }
      filteredKmValues?.map((item, index, arr) => {
        if (index < arr.length - 1) {
          data.push({
            distance_range__km: [arr[index - 1] || arr[0], arr[index + 1]],
            value,
            ...(regionsData && { regionValue: getRegionValue(regionsData, arr[index + 1]) }),
          })
        } else
          data.push({
            distance_range__km: [arr[index - 1], end_km],
            value,
            ...(regionsData && { regionValue: getRegionValue(regionsData, end_km) }),
          })
      })
    } else {
      if (index === 0)
        data.push({
          distance_range__km: [start_km, start_km],
          value,
          ...(regionsData && { regionValue: getRegionValue(regionsData, start_km) }),
        })
      data.push({
        distance_range__km: [start_km, end_km],
        value,
        ...(regionsData && { regionValue: getRegionValue(regionsData, end_km) }),
      })
    }
    return data
  }

  const getLayerType = (type: definitions['RouteProfileAreaSectionAreaType']['type']): RouteLayerType | undefined => {
    switch (type) {
      case 'Дороги':
        return 'road'
      case 'Железные дороги':
        return 'railway'
      case 'Местности':
        return 'terrain'
      case 'ООПТ':
        return 'protectedAreas'
      case 'Озера, водохранилища, крупные реки':
        return 'waterway'
      case 'Реки':
        return 'rivers'
      default:
        return undefined
    }
  }

  const getCategory = (category: definitions['RouteProfileCategorySection']['category']): RouteCategory | undefined => {
    switch (category) {
      case 'I':
        return 'I'
      case 'II':
        return 'II'
      case 'III':
        return 'III'
      case 'IV':
        return 'IV'
      case 'B':
        return 'B'
      default:
        return undefined
    }
  }

  const getLayingMethod = (
    method: definitions['RouteProfileLayingTypeSection']['laying_type'],
  ): RouteLayingMethod | undefined => {
    switch (method) {
      case 'Наземная':
        return 'ground'
      case 'Надземная':
        return 'aboveGround'
      case 'Подземная':
        return 'underGround'
      default:
        return undefined
    }
  }

  const tryToGetRouteProfileHeights = async (): Promise<number[] | undefined> => {
    const data: number[] = []
    if (profileHeights && profileHeights.length > 0) {
      profileHeights.map((item) => data.push(item.distance__km))
      return data
    }
    if (trunkPipelineId && projectId) {
      await getRouteProfileHeights(projectId, trunkPipelineId, params).then((response) => {
        response.data.map((item) => data.push(item.distance__km))
      })
      return data
    }
    return
  }

  const tryToGetRouteProfileAreaSections = async (kmValues: number[] | undefined) => {
    if (trunkPipelineId && projectId) {
      const data: AreaSection[] = []
      await getRouteProfileRegionSections(projectId, trunkPipelineId, params).then((response) => {
        let currentStartKm = params?.start_distance__km || 0
        response.data.map((item, index, arr) => {
          currentStartKm = arr[index - 1]?.length__km ? currentStartKm + arr[index - 1]?.length__km : currentStartKm
          data.push({
            distance_range__km: [currentStartKm, currentStartKm + item.length__km],
            value: 'admDivision',
            regionValue: item.region_name || '',
            regionValueIx: index,
          })
        })
      })
      await getRouteProfileAreaSections(projectId, trunkPipelineId, params)
        .then((response) => {
          const highlightData: IMap['highlightData']['layerType'] = []
          let currentStartKm = params?.start_distance__km || 0
          response.data.map((item, index, arr) => {
            currentStartKm = arr[index - 1]?.length__km ? currentStartKm + arr[index - 1]?.length__km : currentStartKm
            const intervals = generateIntervals(
              currentStartKm,
              currentStartKm + item.length__km,
              getLayerType(item.area_type.type),
              kmValues || [],
              index,
              data,
            )
            data.push(...intervals)
            if (params?.start_distance__km === undefined) {
              highlightData.push({
                pipelineId: item.pipeline.id || '',
                value: getLayerType(item.area_type.type),
                line: item.line__deg,
              })
            }
          })
          setRouteAreaSections(data)
          if (params?.start_distance__km === undefined)
            dispatch(setHighlightData({ data: highlightData, type: 'layerType' }))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: {
                title: 'Ошибка получения высотного профиля трассы',
                message: 'Непредвиденная ошибка',
              } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
      setDataIsLoading(false)
    }
  }

  const tryToGetRouteProfileCategorySections = async (kmValues: number[] | undefined) => {
    if (trunkPipelineId && projectId) {
      await getRouteProfileCategorySections(projectId, trunkPipelineId, params)
        .then((response) => {
          const data: AreaSection[] = []
          const highlightData: IMap['highlightData']['category'] = []
          let currentStartKm = params?.start_distance__km || 0
          response.data.map((item, index, arr) => {
            currentStartKm = arr[index - 1]?.length__km ? currentStartKm + arr[index - 1]?.length__km : currentStartKm
            const intervals = generateIntervals(
              currentStartKm,
              currentStartKm + item.length__km,
              getCategory(item.category),
              kmValues || [],
              index,
            )
            data.push(...intervals)
            setRouteAreaSections(data)
            if (params?.start_distance__km === undefined) {
              highlightData.push({
                pipelineId: item.pipeline.id || '',
                value: getCategory(item.category),
                line: item.line__deg,
              })
            }
          })
          if (params?.start_distance__km === undefined)
            dispatch(setHighlightData({ data: highlightData, type: 'category' }))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: {
                title: 'Ошибка получения высотного профиля трассы',
                message: 'Непредвиденная ошибка',
              } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
      setDataIsLoading(false)
    }
  }

  const tryToGetRouteProfileLayingMethodSections = async () => {
    if (trunkPipelineId && projectId) {
      await getRouteProfileLayingMethodSections(projectId, trunkPipelineId, params)
        .then((response) => {
          const data: AreaSection[] = []
          const highlightData: IMap['highlightData']['layingMethod'] = []
          let currentStartKm = params?.start_distance__km || 0
          response.data.map((item, index, arr) => {
            if (params?.start_distance__km === undefined) {
              highlightData.push({
                pipelineId: item.pipeline.id || '',
                value: getLayingMethod(item.laying_type),
                line: item.line__deg,
                valueIx: index,
              })
            }
            currentStartKm = arr[index - 1]?.length__km ? currentStartKm + arr[index - 1]?.length__km : currentStartKm
            data.push({
              distance_range__km: [currentStartKm, currentStartKm + item.length__km],
              value: getLayingMethod(item.laying_type),
              sectionId: item.id,
              valueIx:
                highlightData.length > 0
                  ? highlightData.find((it) => it.pipelineId === item.pipeline.id)?.valueIx
                  : highlightDataStore?.layingMethod.find((it) => it.pipelineId === item.pipeline.id)?.valueIx || index,
            })
            dispatch(
              updateLayingMethodHighlightData({
                pipelineId: item.pipeline.id || '',
                value: getLayingMethod(item.laying_type),
              }),
            )
          })
          setRouteAreaSections(data)
          if (params?.start_distance__km === undefined)
            dispatch(setHighlightData({ data: highlightData, type: 'layingMethod' }))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: {
                title: 'Ошибка получения высотного профиля трассы',
                message: 'Непредвиденная ошибка',
              } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
      setDataIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    timerId.current = setInterval(async () => {
      setDataIsLoading(true)
    }, 2000)
    switch (chartType) {
      case 'LAYER_TYPES': {
        tryToGetRouteProfileHeights().then((response) =>
          tryToGetRouteProfileAreaSections(response).then(() => {
            if (timerId.current) {
              clearInterval(timerId.current)
            }
          }),
        )
        break
      }
      case 'LAYING_METHOD': {
        tryToGetRouteProfileHeights().then(() =>
          tryToGetRouteProfileLayingMethodSections().then(() => {
            if (timerId.current) {
              clearInterval(timerId.current)
            }
          }),
        )
        break
      }
      case 'CATEGORY_AREA': {
        tryToGetRouteProfileHeights().then((response) =>
          tryToGetRouteProfileCategorySections(response).then(() => {
            if (timerId.current) {
              clearInterval(timerId.current)
            }
          }),
        )
        break
      }
      default:
        break
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
      }
    }
  }, [params?.start_distance__km, params?.end_distance__km, layingTypeUpdated])

  React.useEffect(() => {
    setDataIsLoading(true)
    switch (chartType) {
      case 'LAYER_TYPES': {
        tryToGetRouteProfileHeights().then((response) => tryToGetRouteProfileAreaSections(response).then())
        break
      }
      case 'LAYING_METHOD': {
        tryToGetRouteProfileHeights().then(() => tryToGetRouteProfileLayingMethodSections().then())
        break
      }
      case 'CATEGORY_AREA': {
        tryToGetRouteProfileHeights().then((response) => tryToGetRouteProfileCategorySections(response).then())
        break
      }
      default:
        break
    }
  }, [projectId, chartType])

  return {
    areaSectionsData: routeAreaSections,
    sectionsIsLoading: dataIsLoading,
  }
}

export default useGetRouteProfileAreaSections
