import { api } from '../api'
import { definitions } from '../generated/apiTypes'
import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { IGroupGeometry } from 'store/types'

export const getPipeTypesRequest = (): Promise<AxiosResponse<definitions['PipeType'][]>> => {
  return api.get('/pipe-types/')
}

export const getProjectsRequest = (
  limit?: number,
  offset?: number,
  search?: string,
  author_id?: string,
): Promise<AxiosResponse<definitions['ProjectCalculations'][]>> => {
  return api.get('/projects/', { params: { limit, offset, search, author_id } })
}
export const createProjectRequest = (): Promise<AxiosResponse<definitions['Project']>> => {
  return api.post('/projects/')
}

export const getProjectByIDRequest = (id: string): Promise<AxiosResponse<definitions['ProjectDetail']>> => {
  return api.get(`/projects/${id}/`)
}
export const getNodesByProjectIDRequest = (id: string): Promise<AxiosResponse<definitions['ProjectNodes']>> => {
  return api.get(`/projects/${id}/nodes/`)
}

export const getPipelinesByProjectIDRequest = (id: string): Promise<AxiosResponse<definitions['Pipeline'][]>> => {
  return api.get(`/projects/${id}/pipelines/`)
}
export const updateProjectByIDRequest = (id: string, data: definitions['ProjectPatch']) => {
  return api.patch(`/projects/${id}/`, data)
}

export const deleteProjectByIDRequest = (id: string) => {
  return api.delete(`/projects/${id}/`)
}

export const getSinkByIDRequest = (id: string, sinkID: string): Promise<AxiosResponse<definitions['Sink']>> => {
  return api.get(`/projects/${id}/sinks/${sinkID}/`)
}

export const updateSinkByIDRequest = (id: string, sinkID: string, data: definitions['SinkUpdate']) => {
  return api.patch(`/projects/${id}/sinks/${sinkID}/`, data)
}

export const updateComponentsFlowByIDRequest = (
  id: string,
  componentsFlowID: string,
  data: definitions['ComponentsFlowUpdate'],
) => {
  return api.patch(`/projects/${id}/sources/components_flows/${componentsFlowID}/`, data)
}

export const getSourceByIDRequest = (id: string, sourceID: string): Promise<AxiosResponse<definitions['Source']>> => {
  return api.get(`/projects/${id}/sources/${sourceID}/`)
}

export const updateSourceByIDRequest = (id: string, sourceID: string, data: definitions['SourceUpdate']) => {
  return api.patch(`/projects/${id}/sources/${sourceID}/`, data)
}

export const updateSourceProfileByIDRequest = (id: string, profileID: string, data: definitions['ProfileUpdate']) => {
  return api.patch(`/projects/${id}/sources/profiles/${profileID}/`, data)
}

export const createSourceProfileRequest = (
  project_id: string,
  source_id: string,
  data: definitions['ProfileCreate'],
): Promise<AxiosResponse<definitions['Profile']>> => {
  return api.post(`/projects/${project_id}/sources/${source_id}/profiles/`, data)
}

export const deleteSourceProfileByIDRequest = (project_id: string, profile_id: string) => {
  return api.delete(`/projects/${project_id}/sources/profiles/${profile_id}/`)
}
export const createReferenceNodeRequest = (
  id: string,
  data: definitions['ReferenceNodeCreate'],
): Promise<AxiosResponse<definitions['ReferenceNode']>> => {
  return api.post(`/projects/${id}/reference_nodes/`, data)
}

export const createCompressorStationRequest = (
  id: string,
  data: definitions['CompressorStationCreate'],
): Promise<AxiosResponse<definitions['CompressorStation']>> => {
  return api.post(`/projects/${id}/compressor-stations/`, data)
}

export const updateCompressorStationByIDRequest = (
  id: string,
  node_id: string,
  data: definitions['CompressorStationUpdate'],
) => {
  return api.patch(`/projects/${id}/compressor-stations/${node_id}/`, data)
}
export const getCompressorStationByIDRequest = (
  id: string,
  node_id: string,
): Promise<AxiosResponse<definitions['CompressorStationRetrieve']>> => {
  return api.get(`/projects/${id}/compressor-stations/${node_id}/`)
}

export const deleteCompressorStationByIDRequest = (project_id: string, node_id: string) => {
  return api.delete(`/projects/${project_id}/compressor-stations/${node_id}/`)
}

export const copyCompressorStationRequest = (
  project_id: string,
  node_id: string,
  data: definitions['CompressorStationCopy'],
) => {
  return api.post(`/projects/${project_id}/compressor-stations/${node_id}/copy/`, data)
}
export const createCompressorPlantRequest = (
  id: string,
  compressor_station_id: string,
): Promise<AxiosResponse<definitions['CompressorPlant']>> => {
  return api.post(`/projects/${id}/compressor-stations/${compressor_station_id}/compressor-plants/`)
}

export const updateCompressorPlantByIDRequest = (
  id: string,
  plant_id: string,
  data: definitions['CompressorPlantUpdate'],
) => {
  return api.patch(`/projects/${id}/compressor-stations/compressor-plants/${plant_id}/`, data)
}

export const deleteCompressorPlantByIDRequest = (id: string, plant_id: string) => {
  return api.delete(`/projects/${id}/compressor-stations/compressor-plants/${plant_id}/`)
}

export const getReferenceNodeByIDRequest = (
  id: string,
  node_id: string,
): Promise<AxiosResponse<definitions['ReferenceNodeRetrieve']>> => {
  return api.get(`/projects/${id}/reference_nodes/${node_id}/`)
}

export const updateReferenceNodeByIDRequest = (
  id: string,
  node_id: string,
  data: definitions['ReferenceNodeUpdate'],
) => {
  return api.patch(`/projects/${id}/reference_nodes/${node_id}/`, data)
}

export const deleteReferenceNodeByIDRequest = (project_id: string, node_id: string) => {
  return api.delete(`/projects/${project_id}/reference_nodes/${node_id}/`)
}
export const getReductionNodeByIDRequest = (
  id: string,
  node_id: string,
): Promise<AxiosResponse<definitions['ReductionNodeRetrieve']>> => {
  return api.get(`/projects/${id}/reduction_nodes/${node_id}/`)
}
export const createReductionNodeRequest = (
  id: string,
  data: definitions['ReductionNodeCreate'],
): Promise<AxiosResponse<definitions['ReductionNode']>> => {
  return api.post(`/projects/${id}/reduction_nodes/`, data)
}
export const updateReductionNodeByIDRequest = (
  id: string,
  node_id: string,
  data: definitions['ReductionNodeUpdate'],
) => {
  return api.patch(`/projects/${id}/reduction_nodes/${node_id}/`, data)
}
export const deleteReductionNodeByIDRequest = (project_id: string, node_id: string) => {
  return api.delete(`/projects/${project_id}/reduction_nodes/${node_id}/`)
}
export const createHeatingStationsRequest = (
  project_id: string,
  data: definitions['HeatingStationCreate'],
): Promise<AxiosResponse<definitions['HeatingStation']>> => {
  return api.post(`/projects/${project_id}/heating-stations/`, data)
}
export const getHeatingStationByIDRequest = (
  project_id: string,
  node_id: string,
): Promise<AxiosResponse<definitions['HeatingStationRetrieve']>> => {
  return api.get(`/projects/${project_id}/heating-stations/${node_id}/`)
}
export const updateHeatingStationByIDRequest = (
  project_id: string,
  node_id: string,
  data: definitions['HeatingStationUpdate'],
) => {
  return api.patch(`/projects/${project_id}/heating-stations/${node_id}/`, data)
}
export const deleteHeatingStationByIDRequest = (project_id: string, node_id: string) => {
  return api.delete(`/projects/${project_id}/heating-stations/${node_id}/`)
}
export const getReferenceLinesRequest = (
  project_id: string,
): Promise<AxiosResponse<definitions['ReferenceLine'][]>> => {
  return api.get(`/projects/${project_id}/reference-lines/`)
}
export const createReferenceLineRequest = (project_id: string, data: definitions['ReferenceLineCreate']) => {
  return api.post(`/projects/${project_id}/reference-lines/`, data)
}
export const updateReferenceLineRequest = (
  project_id: string,
  reference_line_id: string,
  data: definitions['ReferenceLineUpdate'],
) => {
  return api.patch(`/projects/${project_id}/reference-lines/${reference_line_id}/`, data)
}
export const deleteReferenceLineRequest = (project_id: string, reference_line_id: string) => {
  return api.delete(`/projects/${project_id}/reference-lines/${reference_line_id}/`)
}
export const getPipelineByIDRequest = (
  project_id: string,
  pipeline_id: string,
): Promise<AxiosResponse<definitions['DetailedPipeline']>> => {
  return api.get(`/projects/${project_id}/pipelines/${pipeline_id}/`)
}
export const updatePipelineGeometryPropertiesByIDRequest = (
  project_id: string,
  pipeline_id: string,
  data: definitions['UpdateLineProperties'],
) => {
  return api.patch(`/projects/${project_id}/pipelines/${pipeline_id}/line/properties/`, data)
}
export const updatePipelineByIDRequest = (
  project_id: string,
  pipeline_id: string,
  data: definitions['PipelineUpdate'],
) => {
  return api.patch(`/projects/${project_id}/pipelines/${pipeline_id}/`, data)
}
export const getTrunkPipelineByIDRequest = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['TrunkPipeline']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/`)
}

export const updateTrunkPipelineByID = (
  project_id: string,
  trunk_pipeline_id: string,
  data: definitions['TrunkPipelineUpdate'],
) => {
  return api.patch(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/`, data)
}
export const updatePipeByIDRequest = (project_id: string, pipe_id: string, data: definitions['PipeUpdate']) => {
  return api.patch(`/projects/${project_id}/pipelines/pipes/${pipe_id}/`, data)
}
export const deletePipeByIDRequest = (project_id: string, pipe_id: string) => {
  return api.delete(`/projects/${project_id}/pipelines/pipes/${pipe_id}/`)
}
export const createPipeRequest = (
  project_id: string,
  pipeline_id: string,
  data: definitions['PipeCreate'],
): Promise<AxiosResponse<definitions['Pipe']>> => {
  return api.post(`/projects/${project_id}/pipelines/${pipeline_id}/pipes/`, data)
}
export const loginRequest = (data: definitions['Login']): Promise<AxiosResponse<definitions['LoginResponse']>> => {
  return api.post('/login/', data)
}
export const logoutRequest = () => {
  return api.get('/logout/')
}

export const createModel10Task = (files: FormData): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post('/model10/tasks/', files, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getModel10TaskStatus = (task_id: string): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/model10/tasks/${task_id}/status`)
}

export const getTask = (task_id: string): Promise<AxiosResponse> => {
  return api.get(`/model10/tasks/${task_id}`, { responseType: 'blob' })
}

export const getHydroCalculationProperties = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['HydroCalculationProperties']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/hydraulics/calculation-properties/`)
}

export const createHydraulicTask = (
  projectId: string,
  trunkPipelineId: string,
  data: definitions['HydroCalculationProperties'],
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/hydraulics/`, data)
}

export const getHydraulicTaskStatus = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/hydraulics/status/`)
}

export const getHydraulicResultCompressorStations = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['HydroResultRetrieveCompressorStation'][]>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/hydraulics/result/compressor-stations/`)
}
export const getHydraulicResultCompressorStationByID = (
  project_id: string,
  cs_id: string,
): Promise<AxiosResponse<definitions['HydroResultRetrieveCompressorStation']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/hydraulics/result/compressor-stations/${cs_id}/`)
}

export const getHydraulicResultPipelines = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['HydraulicResultRetrievePipeline'][]>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/hydraulics/result/pipelines/`)
}

export const getHydraulicResultReductionNodes = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['HydroResultRetrieveReductionNode'][]>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/hydraulics/result/reduction-nodes/`)
}
export const getHydraulicResultHeatingStations = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['HydroResultRetrieveHeatingStation'][]>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/hydraulics/result/heating-stations/`)
}
export const createEconomicTask = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/economics/`)
}

export const getEconomicTaskStatus = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/economics/status/`)
}

export const getEconomicResult = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['EconomicsResult']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/economics/result/`)
}

export const getEconomicsDumpsId = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['File']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/economics/dumps/`)
}

export const createOptimizationTask = (
  project_id: string,
  trunk_pipeline_id: string,
  data: definitions['OptimizationCreate'],
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/optimization/`, data)
}

export const getOptimizationTaskStatus = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/optimization/status/`)
}

export const getOptimizationProperties = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['OptimizationCalculationProperties']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/optimization/calculation-properties/`)
}

export const getAutoCSTaskStatus = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/compressor-stations-placement/status/`)
}

export const createAutoCSTask = (
  project_id: string,
  trunk_pipeline_id: string,
  data: definitions['CSPlacementCalculationCreate'],
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/compressor-stations-placement/`, data)
}

export const getAutoCSDumpsId = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['File']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/compressor-stations-placement/dumps/`)
}

export const getCurrentUser = (): Promise<AxiosResponse<definitions['User']>> => {
  return api.get('/users/current/')
}

export const getHandbooks = (): Promise<AxiosResponse<definitions['Handbooks']>> => {
  return api.get('/handbooks/')
}

export const getGPUHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleGPU']>> => {
  return api.get('/handbooks/gpu/')
}

export const getACUHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleACU']>> => {
  return api.get('/handbooks/acu/')
}

export const createGPUHandbookVersion = (
  data: definitions['HandbookGPUCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleGPU']>> => {
  return api.post('/handbooks/gpu/', data)
}

export const createACUHandbookVersion = (
  data: definitions['HandbookACUCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleACU']>> => {
  return api.post('/handbooks/acu/', data)
}

export const getPipeTypesHandbook = (): Promise<AxiosResponse<definitions['HandbookMultiplePipeType']>> => {
  return api.get('/handbooks/pipe_types/')
}

export const createPipeTypesHandbookVersion = (
  data: definitions['HandbookPipeTypeCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultiplePipeType']>> => {
  return api.post('/handbooks/pipe_types/', data)
}

export const getLayersHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleAreaType']>> => {
  return api.get('/handbooks/layers/')
}

export const createLayersHandbookVersion = (
  data: definitions['HandbookAreaTypeCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleAreaType']>> => {
  return api.post('/handbooks/layers/', data)
}

export const getNextGISLayersHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleNextgisAreaType']>> => {
  return api.get('/handbooks/nextgis_layers/')
}

export const createNextGISLayersHandbookVersion = (
  data: definitions['HandbookNextgisAreaTypeCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleNextgisAreaType']>> => {
  return api.post('/handbooks/nextgis_layers/', data)
}

export const getUnitCostsHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleAreaUnitCostRule']>> => {
  return api.get('/handbooks/unit_costs/')
}

export const createUnitCostsHandbookVersion = (
  data: definitions['HandbookAreaUnitCostRuleCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleAreaUnitCostRule']>> => {
  return api.post('/handbooks/unit_costs/', data)
}

export const getOffsetsHandbook = (): Promise<AxiosResponse<definitions['HandbookMultipleOffsetRule']>> => {
  return api.get('/handbooks/offsets/')
}

export const createOffsetHandbookVersion = (
  data: definitions['HandbookOffsetRuleCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleOffsetRule']>> => {
  return api.post('/handbooks/offsets/', data)
}

export const getRegionCoefficientsHandbook = (): Promise<
  AxiosResponse<definitions['VersionEconomicsRegionCoefficientsSet']>
> => {
  return api.get('/handbooks/economics/regions-coefficients/')
}

export const createRegionCoefficientsHandbookVersion = (
  data: definitions['RegionCoefficientsCreate'][],
): Promise<AxiosResponse<definitions['VersionEconomicsRegionCoefficientsSet']>> => {
  return api.post('/handbooks/economics/regions-coefficients/', data)
}

export const getIndicesPipesCalculationHandbook = (): Promise<
  AxiosResponse<definitions['DiameterToPipesCalculationIndices']>
> => {
  return api.get('/handbooks/economics/pipes-calculation-indices/')
}

export const createIndicesPipesCalculationHandbookVersion = (
  data: definitions['DiameterToPipesCalculationIndicesCreate'],
): Promise<AxiosResponse<definitions['DiameterToPipesCalculationIndices']>> => {
  return api.post('/handbooks/economics/pipes-calculation-indices/', data)
}

export const getStationCalculationIndicesHandbook = (): Promise<
  AxiosResponse<definitions['PressureToCompressorStationCalculationIndices']>
> => {
  return api.get('/handbooks/economics/compressor-station-calculation-indices/')
}

export const createStationCalculationIndicesHandbookVersion = (
  data: definitions['PressureToCompressorStationCalculationIndicesCreate'],
): Promise<AxiosResponse<definitions['PressureToCompressorStationCalculationIndices']>> => {
  return api.post('/handbooks/economics/compressor-station-calculation-indices/', data)
}

export const getCSUnitCostsHandbook = (): Promise<AxiosResponse<definitions['PowerToCompressorStationUnitCosts']>> => {
  return api.get('/handbooks/economics/compressor-station-unit-costs/')
}

export const createCSUnitCostsHandbookVersion = (
  data: definitions['PowerToCompressorStationUnitCostsCreate'],
): Promise<AxiosResponse<definitions['PowerToCompressorStationUnitCosts']>> => {
  return api.post('/handbooks/economics/compressor-station-unit-costs/', data)
}

export const getLinearUnitCostsHandbook = (): Promise<AxiosResponse<definitions['LinearUnitCosts']>> => {
  return api.get('/handbooks/economics/linear-unit-costs/')
}

export const createLinearUnitCostsHandbookVersion = (
  data: definitions['LinearUnitCosts'],
): Promise<AxiosResponse<definitions['LinearUnitCosts']>> => {
  return api.post('/handbooks/economics/linear-unit-costs/', data)
}

export const getConstructionConditionsHandbook = (): Promise<
  AxiosResponse<definitions['VersionEconomicsConstructionConditionsSet']>
> => {
  return api.get('/handbooks/economics/construction-conditions/')
}

export const createConstructionConditionsHandbookVersion = (
  data: definitions['VersionEconomicsConstructionConditionsSetCreate'],
): Promise<AxiosResponse<definitions['VersionEconomicsConstructionConditionsSet']>> => {
  return api.post('/handbooks/economics/construction-conditions/', data)
}

export const getBlackPipePricesHandbook = (): Promise<AxiosResponse<definitions['DiameterToBlackPipePrice']>> => {
  return api.get('/handbooks/economics/black-pipe-prices/')
}

export const createBlackPipePricesHandbookVersion = (
  data: definitions['DiameterToBlackPipePriceCreate'],
): Promise<AxiosResponse<definitions['DiameterToBlackPipePrice']>> => {
  return api.post('/handbooks/economics/black-pipe-prices/', data)
}
export const getAreaLayingCategoriesHandbook = (): Promise<
  AxiosResponse<definitions['HandbookMultipleAreaLayingCategories']>
> => {
  return api.get('/handbooks/area-laying-categories/')
}
export const createAreaLayingCategoriesHandbookVersion = (
  data: definitions['HandbookAreaLayingCategoriesCreate'][],
): Promise<AxiosResponse<definitions['HandbookMultipleAreaLayingCategories']>> => {
  return api.post('/handbooks/area-laying-categories/', data)
}
export const getTasksQueue = (): Promise<AxiosResponse<definitions['TaskQueue'][]>> => {
  return api.get('/tasks/queue/')
}

export const getGPUChoices = (): Promise<AxiosResponse<definitions['HandbookMultipleGPUCompressed']>> => {
  return api.get('/handbooks/gpu/choices/')
}
export const getACUChoices = (): Promise<AxiosResponse<definitions['HandbookMultipleACUCompressed']>> => {
  return api.get('/handbooks/acu/choices/')
}

export const getOptimizationDumpsId = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['File']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/optimization/dumps/`)
}

export const getHydraulicsDumpsId = (
  project_id: string,
  trunk_pipeline_id: string,
): Promise<AxiosResponse<definitions['File']>> => {
  return api.get(`/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/hydraulics/dumps/`)
}

export const getFileById = (file_id: string) => {
  return api.get(`/files/${file_id}/`, {
    responseType: 'blob',
    headers: { Accept: '*/*' },
  })
}

export const copyProjectRequest = (project_id: string) => {
  return api.post(`/projects/${project_id}/copy/`)
}

export const getGISConfiguration = (): Promise<AxiosResponse<definitions['GISConfiguration']>> => {
  return api.get('/configurations/gis/')
}

export const getRegions = (): Promise<AxiosResponse<definitions['NextgisRegionName'][]>> => {
  return api.get('/gis/regions/names')
}

export const cancelTask = (task_id: string) => {
  return api.post(`/tasks/${task_id}/cancel/`)
}

export const updateGISHeight = (project_id: string, node_id: string) => {
  return api.post(`/projects/${project_id}/nodes/${node_id}/update-gis-height/`)
}

export const getAutoCSCalculationProperties = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['CSPlacementProperties']>> => {
  return api.get(
    `/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/compressor-stations-placement/calculation-properties/`,
  )
}

export const getDefaultPlantProperties = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['CSPlacementDraftCompressorPlantProperties']>> => {
  return api.get(
    `/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/compressor-stations-placement/default-compressor-plant-properties/`,
  )
}

export const updateGroupGeometry = (project_id: string, data: IGroupGeometry) => {
  return api.patch(`/projects/${project_id}/groups/`, data)
}

export const deleteNodesRequest = (project_id: string, data: string[]) => {
  return api.delete(`/projects/${project_id}/delete-nodes/`, { data })
}

export const createDNLayersTask = (
  projectId: string,
  trunkPipelineId: string,
  data: definitions['XYZTilesRun'],
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/xyz-tiles/`, data)
}

export const getDNLayersTaskStatus = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/xyz-tiles/status/`)
}

export const getDNLayers = (projectId: string): Promise<AxiosResponse> => {
  return api.get(`/projects/${projectId}/xyz-tiles/`)
}

export const getProfileTaskStatus = (
  projectId: string,
  trunkPipelineId: string,
): Promise<AxiosResponse<definitions['TaskStatus']>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/status/`)
}

export const exportProjectToNextGISRequest = (
  data: definitions['ExportProject'],
): Promise<AxiosResponse<definitions['ExportedProject']>> => {
  return api.post(`/gis/project-export`, data)
}

export const exportHydroResultToDXF = (projectId: string, trunkPipelineId: string): Promise<AxiosResponse> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/hydraulics/result/transport-gas-scheme/`)
}

export const updatePipeTypeByIDRequest = (
  projectId: string,
  pipeId: string,
  data: definitions['PipeTypeUpdate'],
): Promise<AxiosResponse<definitions['PipeTypeUpdateResult']>> => {
  return api.patch(`/projects/${projectId}/pipelines/pipes/${pipeId}/pipe-type/`, data)
}

export const getRouteProfileHeights = (
  projectId: string,
  trunkPipelineId: string,
  params?:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
): Promise<AxiosResponse<definitions['RouteProfileRetrieveHeights'][]>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/heights`, { params })
  /*const data: definitions['RouteProfileRetrieveHeights'][] = []
  const range = (params?.end_distance__km || 1000) - (params?.start_distance__km || 0)
  Array.from({ length: 100 }, (_, i) =>
    data.push({ distance__km: (params?.start_distance__km || 0) + (range / 100) * i, height__m: 10 + i }),
  )
  return new Promise((resolve) => {
    const successObject: AxiosResponse<definitions['RouteProfileRetrieveHeights'][]> = {
      config: {
        headers: {
          'Content-Type': 'application/json',
        } as AxiosRequestHeaders,
      },
      data,
      status: 200,
      request: data,
      statusText: '',
      headers: { 'Content-Length': 32 },
    }
    resolve(successObject)
  })*/
}

export const getRouteProfileAreaSections = (
  projectId: string,
  trunkPipelineId: string,
  params?:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
): Promise<AxiosResponse<definitions['RouteProfileAreaSection'][]>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/area-sections`, { params })
}

export const getRouteProfileCategorySections = (
  projectId: string,
  trunkPipelineId: string,
  params?:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
): Promise<AxiosResponse<definitions['RouteProfileCategorySection'][]>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/category-sections`, {
    params,
  })
}

export const getRouteProfileLayingMethodSections = (
  projectId: string,
  trunkPipelineId: string,
  params?:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
): Promise<AxiosResponse<definitions['RouteProfileLayingTypeSection'][]>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/laying-type-sections`, {
    params,
  })
}

export const getRouteProfileRegionSections = (
  projectId: string,
  trunkPipelineId: string,
  params?:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
): Promise<AxiosResponse<definitions['RouteProfileRegionSection'][]>> => {
  return api.get(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/region-sections`, { params })
}

export const createProfileTask = (
  projectId: string,
  trunkPipelineId: string,
  data: definitions['RouteProfileRun'],
): Promise<AxiosResponse<definitions['Task']>> => {
  return api.post(`/projects/${projectId}/trunk_pipelines/${trunkPipelineId}/route-profile/`, data)
}

export const updateLayingMethodSection = (
  project_id: string,
  trunk_pipeline_id: string,
  laying_method_section_id: string,
  data: definitions['RouteProfileLayingTypeSectionUpdate'],
) => {
  return api.patch(
    `/projects/${project_id}/trunk_pipelines/${trunk_pipeline_id}/route-profile/laying-type-sections/${laying_method_section_id}/`,
    data,
  )
}

export const visitTask = (projectId: string, data: definitions['CalculationVisit']): Promise<AxiosResponse> => {
  return api.post(`/projects/${projectId}/visit/`, data)
}
