import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IScheme } from './types'

export const initialState: IScheme = {
  display: false,
}

export const schemeSlice = createSlice({
  name: 'scheme',
  initialState,
  reducers: {
    setDisplayScheme: (state, action: PayloadAction<IScheme['display']>) => {
      return {
        ...state,
        display: action.payload,
      }
    },
  },
})

export const { setDisplayScheme } = schemeSlice.actions

export const selectDisplayScheme = (state: RootState) => state.scheme.display

export default schemeSlice.reducer
