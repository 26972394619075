import React from 'react'
import './style.scss'

export const ExportProjectToNextGISWindow: React.FC = () => {
  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container'>
        <div className={'blocking-window__header'}>Идет экспорт в ИУС ГИС</div>
        <div>
          <div className='progress__loader-container'>
            <div className='progress__loader' />
          </div>
          <div className={'progress__hint normal-text'}>{'Выполняется экспорт трассы в систему ИУС ГИС'}</div>
        </div>
      </div>
    </div>
  )
}
