import React, { useMemo, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { options as barChartOptions } from './barChartOptions'
import {
  BarChartConfig,
  CategoryConfig,
  LayerTypeConfig,
  LayingMethodConfig,
  ProfileConfig,
  RouteCategory,
  RouteLayerType,
  RouteLayingMethod,
} from '../config'
import { editBarIcon, innerBarText, verticalLineAtCursor } from '../chartPlugins'
import useGetRouteProfileAreaSections from '../../hooks/useGetRouteProfileAreaSections'
import useGanttChartData from '../../hooks/useGanttChartData'
import { profileRound } from '../utils'
import { IChartProps, IChartsProps, IControlChartProps } from '../ProfileChart'
import withControl from '../withControl'
import Dropdown from 'components/Dropdown/Dropdown'
import LayingTypePopup from './LayingTypePopup'
import EyeIcon from 'images/EyeIcon'
import { useDispatch, useSelector } from 'react-redux'
import { selectHighlightCriterion, setHighlightCriterion } from 'store/mapSlice'
import { IMap } from 'store/types'
import classnames from 'classnames'

const layerTypeOptions = [
  { value: 'admDivision' as RouteLayerType, name: 'Административное деление' },
  { value: 'road' as RouteLayerType, name: 'Дороги' },
  { value: 'railway' as RouteLayerType, name: 'Железные дороги' },
  { value: 'waterway' as RouteLayerType, name: 'Озера, водохранилища, крупные реки' },
  { value: 'protectedAreas' as RouteLayerType, name: 'ООПТ' },
  { value: 'rivers' as RouteLayerType, name: 'Реки' },
  { value: 'terrain' as RouteLayerType, name: 'Местности' },
]

const categoryOptions = [
  { value: 'B' as RouteCategory, name: 'B' },
  { value: 'I' as RouteCategory, name: 'I' },
  { value: 'II' as RouteCategory, name: 'II' },
  { value: 'III' as RouteCategory, name: 'III' },
  { value: 'IV' as RouteCategory, name: 'IV' },
]

export const layingMethodOptions = [
  { value: 'ground' as RouteLayingMethod, name: 'Наземная' },
  { value: 'aboveGround' as RouteLayingMethod, name: 'Надземная' },
  { value: 'underGround' as RouteLayingMethod, name: 'Подземная' },
]

const BarChart: React.FC<IControlChartProps & IChartsProps & IChartProps> = ({
  requestRange,
  startPoint,
  setStartPoint,
  finishPoint,
  setFinishPoint,
  minPoint,
  setMinPoint,
  maxPoint,
  setMaxPoint,
  setCurrentMaxPoint,
  setCurrentMinPoint,
  chartRef,
  panelRef,
  chartTypeOptions,
  chartTypeOption,
  setChartTypeOption,
  setChartRenderingInProgress,
}) => {
  const dispatch = useDispatch()
  const highlightCriterion = useSelector(selectHighlightCriterion)
  const [minmaxInit, setMinmaxInit] = React.useState<boolean>(false)
  const [barInitOptions, setBarInitOptions] = React.useState<any[]>(/*layerTypeOptions*/ [])
  const [barOptions, setBarOptions] = React.useState<any[]>(/*layerTypeOptions*/ [])
  const [bars, setBars] = React.useState<string[]>()
  const [clEventPosition, setClEventPosition] = React.useState<{ left: number; top: number }>()
  const [popupPosition, setPopupPosition] = React.useState<{ left: number; top: number }>()
  const [layingType, setLayingType] = React.useState<{ type: RouteLayingMethod; sectionId: string }>()
  const [layingTypeUpdated, setLayingTypeUpdated] = React.useState<boolean>(false)
  const { areaSectionsData, sectionsIsLoading } = useGetRouteProfileAreaSections(
    requestRange,
    chartTypeOption.value,
    layingTypeUpdated,
  )
  const barChartData = useGanttChartData(areaSectionsData || [], chartTypeOption.value)
  const [filteredBarChartData, setFilteredBarChartData] = React.useState<typeof barChartData>()
  const initialBars = React.useRef<string[]>()
  const containerRef = useRef<any>()
  const popupRef = useRef<any>()

  React.useEffect(() => {
    switch (chartTypeOption.value) {
      case 'LAYING_METHOD':
        setBarInitOptions(layingMethodOptions)
        setBarOptions(layingMethodOptions)
        break
      case 'CATEGORY_AREA':
        setBarInitOptions(categoryOptions)
        setBarOptions(categoryOptions)
        break
      case 'LAYER_TYPES':
        setBarInitOptions(layerTypeOptions)
        setBarOptions(layerTypeOptions)
        break
      default:
        break
    }
    if (highlightCriterion) dispatch(setHighlightCriterion(undefined))
  }, [chartTypeOption.value])

  React.useEffect(() => {
    if (areaSectionsData && !minmaxInit) {
      setMinmaxInit(true)
      if (setMinPoint) setMinPoint(profileRound(areaSectionsData[0].distance_range__km[0]))
      if (setMaxPoint) setMaxPoint(profileRound(areaSectionsData[areaSectionsData.length - 1].distance_range__km[1]))
      if (startPoint === undefined) setStartPoint(profileRound(areaSectionsData[0].distance_range__km[0]))
      if (finishPoint === undefined)
        setFinishPoint(profileRound(areaSectionsData[areaSectionsData.length - 1].distance_range__km[1]))
    }
    if (areaSectionsData) {
      if (startPoint !== undefined && finishPoint) {
        setCurrentMaxPoint(finishPoint)
        setCurrentMinPoint(startPoint)
      } else {
        // Проверить!!!
        setCurrentMaxPoint(profileRound(areaSectionsData[areaSectionsData.length - 1].distance_range__km[1]))
        setCurrentMinPoint(profileRound(areaSectionsData[0].distance_range__km[0]))
      }
    }
  }, [areaSectionsData])

  React.useEffect(() => {
    if (barChartData?.datasets[0]?.data?.length > 0) {
      const filteredData = initialBars.current
        ? barChartData.datasets[0].data
            .filter((item) => barOptions?.some((el) => el.value === (item as any).y))
            .sort(
              (a, b) =>
                (initialBars.current as string[]).indexOf((a as any)?.y) -
                (initialBars.current as string[]).indexOf((b as any)?.y),
            )
        : barChartData.datasets[0].data.filter((item) => barOptions?.some((el) => el.value === (item as any).y))

      setFilteredBarChartData({
        ...barChartData,
        datasets: [
          {
            ...barChartData.datasets[0],
            data: filteredData,
          },
        ],
      })
    }
  }, [barOptions, initialBars.current])

  React.useEffect(() => {
    if (barChartData?.datasets[0] && barChartData?.datasets[0].data.length) setFilteredBarChartData(barChartData)
  }, [barChartData /*.datasets[0].data.length*/])

  React.useEffect(() => {
    if (/*!initialBars.current && */ bars && bars?.length > 0) {
      initialBars.current = [...bars]
      const filteredOptions = getOptionsByChartType(chartTypeOption?.value || 'LAYER_TYPES')
        .filter((item) => bars?.includes(item.value))
        .sort((a, b) => bars.indexOf((a as any)?.value) - bars.indexOf((b as any)?.value))
      setBarInitOptions(filteredOptions)
      setBarOptions(filteredOptions)
    }
  }, [bars])

  React.useEffect(() => {
    if (containerRef?.current && panelRef?.current) {
      const panelRect = panelRef?.current?.getBoundingClientRect()
      const containerRect = containerRef?.current?.getBoundingClientRect()
      if (
        panelRect?.left !== undefined &&
        panelRect?.top !== undefined &&
        containerRect?.left !== undefined &&
        containerRect?.top !== undefined &&
        clEventPosition?.top !== undefined &&
        clEventPosition?.left !== undefined
      ) {
        const leftPos = containerRect.left - panelRect.left + (clEventPosition?.left || 0) + 6
        setPopupPosition({
          left:
            leftPos + 235 > containerRect.left - panelRect.left + containerRect.width
              ? containerRect.left - panelRect.left + containerRect.width - 235
              : leftPos,
          top: containerRect?.top - panelRect?.top + (clEventPosition?.top || 0) + 12 - 24,
        })
      }
    }
  }, [clEventPosition, containerRef?.current, panelRef?.current])

  /*React.useEffect(() => {
    setBarOptions(barInitOptions)
  }, [chartTypeOption?.value])*/

  const getAxisYLabelByChartType = () => {
    switch (chartTypeOption?.value) {
      case 'LAYER_TYPES':
        return 'Тип слоя'
      case 'CATEGORY_AREA':
        return 'Класс'
      case 'LAYING_METHOD':
        return 'Способ'
      default:
        return ''
    }
  }

  const getConfigByChartType = (): { [key: string]: BarChartConfig } | undefined => {
    switch (chartTypeOption?.value) {
      case 'LAYER_TYPES':
        return LayerTypeConfig
      case 'CATEGORY_AREA':
        return CategoryConfig
      case 'LAYING_METHOD':
        return LayingMethodConfig
      default:
        return undefined
    }
  }

  const getOptionsByChartType = (type: typeof chartTypeOption.value): any[] => {
    switch (type) {
      case 'LAYING_METHOD':
        return layingMethodOptions
      case 'CATEGORY_AREA':
        return categoryOptions
      case 'LAYER_TYPES':
        return layerTypeOptions
      default:
        return []
    }
  }

  const toggleHighlightCriterion = () => {
    if (highlightCriterion !== chartTypeOption.value)
      dispatch(setHighlightCriterion(chartTypeOption.value as IMap['highlightCriterion']))
    else dispatch(setHighlightCriterion())
  }

  const barChart = useMemo(() => {
    const isDataLoaded = barChartData?.datasets[0] && barChartData?.datasets[0].data.length
    const config = getConfigByChartType()
    return filteredBarChartData?.datasets[0] &&
      isDataLoaded > 0 &&
      minPoint !== undefined &&
      maxPoint !== undefined &&
      config &&
      !sectionsIsLoading ? (
      <Bar
        ref={chartRef}
        data={filteredBarChartData}
        options={barChartOptions(
          getAxisYLabelByChartType(),
          'Точка трубопровода, км',
          ProfileConfig.minRange,
          minPoint!,
          maxPoint!,
          bars || [],
          setStartPoint,
          setFinishPoint,
          setBars,
          setClEventPosition,
          setLayingType,
          config,
          chartTypeOption.value !== 'CATEGORY_AREA',
          chartTypeOption.value !== 'LAYING_METHOD',
        )}
        plugins={[verticalLineAtCursor(), innerBarText(setChartRenderingInProgress), editBarIcon()]}
      />
    ) : (
      <div className={'height-profile-panel__loading-container'}>
        <div className='progress__loader' />
      </div>
    )
  }, [filteredBarChartData, barChartData, minPoint, maxPoint, sectionsIsLoading])

  return (
    <>
      <div className={'height-profile-panel__select-row'}>
        <Dropdown
          options={chartTypeOptions}
          selectedValue={chartTypeOption}
          displayedField={'name'}
          setSelectedValue={setChartTypeOption}
          containerClassNames={'pressed-dropdown'}
          panelRef={panelRef}
        />
        {initialBars.current && (
          <>
            <Dropdown
              options={barInitOptions}
              selectedValues={barOptions}
              setSelectedValues={setBarOptions}
              displayedField={'name'}
              containerClassNames={'pressed-dropdown'}
              panelRef={panelRef}
              multiselect
            />
            <button
              className={classnames(
                'height-profile-panel__highlight-btn',
                highlightCriterion !== chartTypeOption.value && 'non-active',
              )}
              onClick={toggleHighlightCriterion}
            >
              <EyeIcon />
            </button>
          </>
        )}
      </div>
      <div className={'height-profile-panel__chart-container'} ref={containerRef}>
        {barChart}
      </div>
      <LayingTypePopup
        position={popupPosition}
        setPosition={setPopupPosition}
        layingType={layingType}
        layingTypeUpdated={layingTypeUpdated}
        setLayingTypeUpdated={setLayingTypeUpdated}
        popupRef={popupRef}
        panelRef={panelRef}
      />
    </>
  )
}

export default withControl(BarChart)
