import React, { useEffect } from 'react'
import classnames from 'classnames'
import { TableHeader } from '../TableHeader'
import '../Table.scss'
import { IExpandTableProps } from '../types'
import { TableCell } from '../TableCell'
import { TableRow } from './TableRow'
import { sortByOrder } from '../../../pages/utils'
import classNames from 'classnames'

export const Table: React.FC<IExpandTableProps> = ({
  columns,
  dataSource,
  expandRow,
  expandColumn,
  className,
  setValue,
  expandCellContent,
}) => {
  const tableRef = React.useRef<HTMLDivElement>(null)
  const [scrollTop, setScrollTop] = React.useState<number>(0)
  const contentColumns = columns.filter((column) => column.type).sort((a, b) => sortByOrder(a.order, b.order))

  useEffect(() => {
    const scrollToTop = () => {
      tableRef.current?.scroll({ top: 0, behavior: 'auto' })
    }
    window.addEventListener('resize', scrollToTop)
    return () => {
      window.removeEventListener('resize', scrollToTop)
    }
  }, [tableRef.current])

  useEffect(() => {
    const setTop = () => {
      if (tableRef.current?.scrollTop) setScrollTop(tableRef.current.scrollTop)
    }
    tableRef.current?.addEventListener('scroll', setTop)
    return () => {
      tableRef.current?.removeEventListener('scroll', setTop)
    }
  }, [tableRef.current])

  return (
    <div className={classnames('table-container', className)} ref={tableRef}>
      <div
        className={'grid-table'}
        style={{
          gridTemplateColumns: `${expandColumn ? '55px' : ''} ${contentColumns
            ?.map((column) => {
              return column.type === 'Select' ? '50px' : 'minmax(max-content, 1fr)'
            })
            .join(' ')}`,
          gridTemplateRows: `auto repeat(${dataSource.length}, minmax(36px, auto))`,
        }}
      >
        <TableHeader columns={columns} expandColumn={expandColumn} scrollTop={scrollTop} />
        {dataSource.map((obj) => (
          <TableRow
            key={`${obj.id}-row`}
            id={obj.id}
            expandRow={() => expandRow && expandRow(obj.id)}
            expandColumn={expandColumn}
            expandable={obj.expandable}
            expandCellContent={expandCellContent}
          >
            {contentColumns.map((field, index) =>
              Object.prototype.hasOwnProperty.call(obj, field.key) ? (
                <TableCell
                  key={`${obj.id}-${field.key}`}
                  id={
                    ['name', 'pressure__MPa', 'compressor_plants_number'].includes(field.key) && obj['cs_id']
                      ? obj['cs_id']
                      : obj.id
                  }
                  type={field.type}
                  value={obj[field.key]}
                  fieldName={field.key}
                  editable={
                    !(obj['disable'] && field.allowMixedEditing) &&
                    field.editable &&
                    !(obj['disabledFields'] || []).includes(field.key)
                  }
                  signed={field.signed}
                  noEmpty={field.noEmpty}
                  minValue={field.minValue}
                  setValue={setValue}
                  options={field.options}
                  className={field.className}
                  displayedField={field.displayedField}
                  cellClassNames={classNames(
                    obj['combined'] && field.allowSpan && 'combined',
                    field.sticky && 'sticky',
                  )}
                  tableRef={tableRef}
                />
              ) : (
                <React.Fragment key={`${obj.id}-${index}`} />
              ),
            )}
          </TableRow>
        ))}
      </div>
    </div>
  )
}
