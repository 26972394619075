import React, { useContext } from 'react'
import NumberInput from 'components/Input/numberInput'
import { GeoJSONSource } from 'maplibre-gl'
import { getSourceData } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowIntermediateElementInfo, setUpdatedElement } from 'store/projectSlice'
import { MapContext } from '../map'
import RadioButton from '../../RadioButton'
import IntegerInput from '../../Input/integerInput'
import { selectMapPipelines } from '../../../store/mapSlice'

const SegmentElement: React.FC = () => {
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()
  const intermediateElementInfo = useSelector(selectShowIntermediateElementInfo)
  const [angle, setAngle] = React.useState<number>()
  const [referenceAngle, setReferenceAngle] = React.useState<number>()
  const [length, setLength] = React.useState<number>()
  const pipelines = useSelector(selectMapPipelines)
  const [angleType, setAngleType] = React.useState<string>('ANGLE')

  React.useEffect(() => {
    if (intermediateElementInfo?.objectId) {
      const line = pipelines.find((line) => line.id === intermediateElementInfo?.objectId)
      if (
        intermediateElementInfo?.index !== undefined &&
        ((line?.line as any)?.properties as Record<string, any>)?.angles[intermediateElementInfo?.index] !== null
      ) {
        setAngle(((line?.line as any)?.properties as Record<string, any>)?.angles[intermediateElementInfo.index])
      } else {
        setAngle(undefined)
        if (angleType === 'ANGLE') setAngleType('REF_LINE_ANGLE')
      }
      if (
        intermediateElementInfo?.index !== undefined &&
        ((line?.line as any)?.properties as Record<string, any>)?.reference_lines_angles[
          intermediateElementInfo?.index
        ] !== null
      ) {
        setReferenceAngle(
          ((line?.line as any)?.properties as Record<string, any>)?.reference_lines_angles[
            intermediateElementInfo.index
          ],
        )
      } else {
        setReferenceAngle(undefined)
        if (angleType === 'REF_LINE_ANGLE') setAngleType('ANGLE')
      }
      if (
        intermediateElementInfo?.index !== undefined &&
        ((line?.line as any)?.properties as Record<string, any>)?.lengths__m[intermediateElementInfo?.index] !== null
      ) {
        setLength(((line?.line as any)?.properties as Record<string, any>)?.lengths__m[intermediateElementInfo.index])
      } else setLength(undefined)
    }
  }, [intermediateElementInfo?.objectId, intermediateElementInfo?.index, intermediateElementInfo?.updateCount])

  const updateGeometryProperties = (param: string, value: number) => {
    const line = pipelines.find((line) => line.id === intermediateElementInfo?.objectId)
    if (intermediateElementInfo?.objectId && intermediateElementInfo?.index !== undefined) {
      const updatedPropertyArr = Array((line?.line as any)?.properties?.[param].length).fill(null)
      updatedPropertyArr[intermediateElementInfo?.index] = value
      dispatch(
        setUpdatedElement({
          elementID: intermediateElementInfo?.objectId,
          type: 'PIPELINE_GEOMETRY_PROPERTIES',
          params: { [param]: updatedPropertyArr as any },
        }),
      )
    }
  }

  return (
    <div className={'intermediate-element__content'}>
      <div className={'intermediate-element__subtitle header4'}>Длина</div>
      <div className={'intermediate-element__params-panel'}>
        <NumberInput
          value={length?.toFixed(0)}
          unit={'м'}
          setValue={(value) => updateGeometryProperties('lengths__m', value)}
        />
      </div>
      {(referenceAngle !== undefined || angle !== undefined) && (
        <>
          <div className={'intermediate-element__divider'}>
            <hr />
          </div>
          <div className={'intermediate-element__subtitle header4'}>Угол</div>
          {referenceAngle === undefined ? (
            <div className={'intermediate-element__params-panel'}>
              <IntegerInput
                value={angle}
                unit={'°'}
                label={'Угол поворота'}
                infoText={'Угол, образованный продолжением направления предыдущего сегмента и текущим сегментом'}
                disabled={angleType !== 'ANGLE'}
                setValue={(value) => {
                  setAngle(value)
                  updateGeometryProperties('angles', value)
                }}
                signed
              />
            </div>
          ) : angle === undefined ? (
            <div className={'intermediate-element__params-panel'}>
              <IntegerInput
                value={referenceAngle}
                unit={'°'}
                label={'Угол отн. опорного отрезка'}
                infoText={'Угол, образованный текущим сегментом и пересекающем его опорным отрезком'}
                disabled={angleType !== 'REF_LINE_ANGLE'}
                setValue={(value) => updateGeometryProperties('reference_lines_angles', value)}
              />
            </div>
          ) : (
            <div className={'intermediate-element__params-panel radio-params'}>
              <RadioButton value={angleType} setValue={setAngleType} id={'ANGLE'} label={''}>
                <IntegerInput
                  value={angle}
                  unit={'°'}
                  label={'Угол поворота'}
                  infoText={'Угол, образованный продолжением направления предыдущего сегмента и текущим сегментом'}
                  disabled={angleType !== 'ANGLE'}
                  setValue={(value) => {
                    setAngle(value)
                    updateGeometryProperties('angles', value)
                  }}
                  signed
                />
              </RadioButton>
              <RadioButton value={angleType} setValue={setAngleType} id={'REF_LINE_ANGLE'} label={''}>
                <IntegerInput
                  value={referenceAngle}
                  unit={'°'}
                  label={'Угол отн. опорного отрезка'}
                  infoText={'Угол, образованный текущим сегментом и пересекающем его опорным отрезком'}
                  disabled={angleType !== 'REF_LINE_ANGLE'}
                  setValue={(value) => updateGeometryProperties('reference_lines_angles', value)}
                />
              </RadioButton>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SegmentElement
