import * as React from 'react'

function Icon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 4.167v11.666M4.167 10h11.667'
        stroke='#fff'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Icon
