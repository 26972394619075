import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IModel10Slice } from './types'

export const initialState: IModel10Slice = {
  taskId: undefined,
  createTask: false,
  downloadResults: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  author: undefined,
}

export const model10Slice = createSlice({
  name: 'model10',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IModel10Slice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IModel10Slice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IModel10Slice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setDownloadResults: (state, action: PayloadAction<IModel10Slice['downloadResults']>) => {
      return { ...state, downloadResults: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IModel10Slice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IModel10Slice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    setAuthor: (state, action: PayloadAction<IModel10Slice['author']>) => {
      return { ...state, author: action.payload }
    },
    resetModel10SliceState: () => {
      return initialState
    },
  },
})

export const {
  setCreateTask,
  setTaskId,
  setTaskCalculationProgress,
  setDownloadResults,
  setTaskStatus,
  setErrorMessage,
  setAuthor,
  resetModel10SliceState,
} = model10Slice.actions

export const selectCreateTask = (state: RootState) => state.model10.createTask

export const selectTaskId = (state: RootState) => state.model10.taskId

export const selectTaskCalculationProgress = (state: RootState) => state.model10.taskCalculationProgress

export const selectDownloadResults = (state: RootState) => state.model10.downloadResults

export const selectTaskStatus = (state: RootState) => state.model10.taskStatus

export const selectErrorMessage = (state: RootState) => state.model10.errorMessage

export const selectAuthor = (state: RootState) => state.model10.author

export default model10Slice.reducer
