import * as React from 'react'

function CursorIcon(props) {
  return (
    <svg width={10} height={12} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1.98 12L0 0l10 6.716L4.948 7.73 1.98 12z' fill='#373737' />
    </svg>
  )
}

export default CursorIcon
