import React, { useEffect, useMemo, useRef, useState } from 'react'
import './scheme.scss'
import { Station } from './components/station'
import { TrunkPipeline } from './components/trunkPipeline'
import { EndPoint } from './components/endPoint'
import { Arrow } from './components/arrow'
import { Legend } from './components/legend'
import { line } from './utils'
import { ControlButtons } from 'components/Map/ControlButtons'
import classnames from 'classnames'
import { jsPDF } from 'jspdf'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { twoDigitsFormatter } from 'pages/utils'
import { ReductionNode } from './components/reductionNode'
import { HeatingStation } from './components/heatingStation'
import { useGetDataSource } from './hooks/useGetDataSource'
import { IHeatingStationProps, IReductionNodeProps, IStationProps } from './types'

const opt = {
  paddingX: 170,
  paddingY: 20,
  deltaX: 258,
  positionY: 111,
}
const zoomDelta = 0.1
interface ISchemeProps {
  download: boolean
  setDownload(value: boolean): void
}
export const Scheme: React.FC<ISchemeProps> = ({ download, setDownload }) => {
  const canvasRef = useRef<any>(null)
  const canvas2PDFRef = useRef<any>(null)
  const context2D = useRef<any>(null)
  const schemeSize = useRef<any>({ width: 0, height: 0 })
  const init = useRef({
    x: 0,
    y: 0,
    scale: 0,
  })
  const [scale, setScale] = useState<number>(0)
  const [reset, setReset] = useState(false)
  const start = useRef<[number, number]>([0, 0])
  const scaleRef = useRef<any>(scale)
  const minZoom = useRef(0)
  const [cursor, setCursor] = useState('default')

  const { Q, P, t, dataSource } = useGetDataSource()
  const handleMouseDown = () => {
    setCursor('grabbing')
    document.getElementById('scheme')!.addEventListener('mousemove', handleMouseMove)
  }
  const handleMouseUp = () => {
    setCursor('default')
    document.getElementById('scheme')!.removeEventListener('mousemove', handleMouseMove)
  }

  const handleMouseMove = (e: any) => {
    clearCanvas(context2D.current, canvasRef.current)
    start.current = [
      start.current[0] + e.movementX * (1.14 / scaleRef.current),
      start.current[1] + e.movementY * (1.14 / scaleRef.current),
    ]
    draw(context2D.current, ...start.current, scaleRef.current)
  }
  useEffect(() => {
    if (canvasRef.current && dataSource) {
      const { width, height } = canvasRef.current.parentElement.getBoundingClientRect()
      canvasRef.current.width = width
      canvasRef.current.height = height
      context2D.current = canvasRef.current.getContext('2d')
      schemeSize.current.width = 215 + opt.paddingX * 2 + (dataSource.length + 1) * opt.deltaX
      schemeSize.current.height = 435 + 232 + 139 // высота схемы + обозначения
      const scaleX = width / schemeSize.current.width
      const scaleY = (height - opt.paddingY * 2 - opt.positionY) / schemeSize.current.height
      const scale = Math.min(scaleX, scaleY)
      scale < 1 ? setScale(scale) : setScale(1)
      minZoom.current = scale
      start.current = [opt.paddingX, (opt.positionY + opt.paddingY) / scale] // начальное смещение
      init.current = {
        x: start.current[0],
        y: start.current[1],
        scale,
      }
    }
    document.getElementById('scheme')!.addEventListener('mousedown', handleMouseDown)
    document.getElementById('scheme')!.addEventListener('mouseup', handleMouseUp)
  }, [dataSource])
  useEffect(() => {
    if (download) {
      canvas2PDFRef.current.width = canvasRef.current.width * (1 / init.current.scale)
      canvas2PDFRef.current.height = canvasRef.current.height * (1 / init.current.scale)
      draw(canvas2PDFRef.current.getContext('2d'), init.current.x, init.current.y, 1)
      const data = canvas2PDFRef.current.toDataURL('image/png')
      const pdf = new jsPDF('landscape', 'px', [canvas2PDFRef.current.width, canvas2PDFRef.current.height])
      pdf.addImage(data, 'PNG', 0, 0, canvas2PDFRef.current.width, canvas2PDFRef.current.height)
      pdf.save('gas_transport_scheme.pdf')
      setDownload(false)
    }
  }, [download])
  useEffect(() => {
    if (scale || reset) {
      scaleRef.current = scale
      clearCanvas(context2D.current, canvasRef.current)
      draw(context2D.current, ...start.current, scale)
      reset && setReset(false)
    }
  }, [scale, reset])
  const draw = (context: CanvasRenderingContext2D, x: number, y: number, scale: number) => {
    context.scale(scale, scale)
    const trunkStart = x + 158 + 110 //отступ + длина стрелки с параметрами + исток
    const trunkEnd = trunkStart + (dataSource.length + 1) * opt.deltaX
    Arrow(context, x, y, Q, P, t)
    TrunkPipeline(context, [trunkStart, y], [trunkEnd, y])
    EndPoint(context, trunkStart, y, true, 'НАЧ. ТОЧКА')
    for (let i = 1; i <= dataSource.length; i++) {
      const x = trunkStart + opt.deltaX * i
      switch (dataSource[i - 1].type) {
        case 'КС':
          Station(
            context,
            x,
            y,
            dataSource[i - 1].name,
            dataSource[i - 1].distance,
            (dataSource[i - 1] as IStationProps).plants,
          )
          break

        case 'Узел редуцирования':
          ReductionNode(
            context,
            x,
            y,
            dataSource[i - 1].name,
            dataSource[i - 1].distance,
            (dataSource[i - 1] as IReductionNodeProps).props,
          )
          break
        case 'Узел нагрева':
          HeatingStation(
            context,
            x,
            y,
            dataSource[i - 1].name,
            dataSource[i - 1].distance,
            (dataSource[i - 1] as IHeatingStationProps).props,
          )
      }
    }
    EndPoint(context, trunkEnd, y, false, 'КОН. ТОЧКА')
    line(context, [x, y + 435 + 28], [x + schemeSize.current.width, y + 435 + 28], 1, '#E4E9EF')
    Legend(context, x, y + 435 + 28 + 41)
  }
  const clearCanvas = (context: CanvasRenderingContext2D, canvas: any) => {
    const width = canvas.width
    const height = canvas.height
    context.clearRect(0, 0, width, height)
    canvas.width = width
    canvas.height = height
  }
  const updateScale = (scale: number) => {
    if (scale < minZoom.current) setScale(minZoom.current)
    else {
      setScale(scale)
    }
  }
  const resetState = () => {
    start.current = [init.current.x, init.current.y]
    setScale(init.current.scale)
    setReset(true)
  }
  return (
    <div id={'scheme'} className={classnames('scheme-container', cursor)}>
      <ControlButtons
        onZoomIn={() => {
          updateScale(scale + zoomDelta)
        }}
        onZoomOut={() => {
          updateScale(scale - zoomDelta)
        }}
        onNavigate={() => {
          resetState()
        }}
      />
      <canvas className={'scheme-canvas__to-pdf'} ref={canvas2PDFRef} />
      <canvas className={'scheme-canvas'} ref={canvasRef} />
    </div>
  )
}
