import { twoDigitsFormatter } from '../../../../../../utils'
import React from 'react'
import { definitions } from 'generated/apiTypes'

interface IPlantTableProps {
  plant: definitions['HydroResultRetrieveCompressorPlant']
}
export const PlantTable: React.FC<IPlantTableProps> = ({ plant }) => {
  return (
    <div className={'pipeline-result__cs-expand'}>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Объем пост. газа:</div>
          {`${twoDigitsFormatter.format(plant?.inlet_flow__Mm3_per_day)} млн.м3/сут`}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Топл. газа:</div>
          {`${twoDigitsFormatter.format(plant?.heating_gas_offtake__Mm3_per_day)} млн.м3/сут`}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Объем компр. газа:</div>
          {`${twoDigitsFormatter.format(plant?.compressing_gas_flow__Mm3_per_day)} млн.м3/сут`}
        </div>
      </div>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Давление всас.:</div>
          {`${twoDigitsFormatter.format(plant?.suction_pressure__Mpa)} МПа`}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Степень сжатия:</div>
          {twoDigitsFormatter.format(plant?.compression_factor)}
        </div>
      </div>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Мощность расп.:</div>
          {`${twoDigitsFormatter.format(plant?.available_power__MW)} МВт`}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Мощность расх.:</div>
          {`${twoDigitsFormatter.format(plant?.consumed_power__MW)} МВт`}
        </div>
      </div>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Кол-во ГПА раб.:</div>
          {plant?.n_gpu_operating}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Кол-во ГПА рез.:</div>
          {plant?.n_gpu_reserved}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Кол-во ГПА общ.:</div>
          {plant?.n_gpu_total}
        </div>
      </div>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>К исп. мощности:</div>
          {plant?.power_usage_coefficient}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Отн. об ГПА:</div>
          {twoDigitsFormatter.format(plant?.relative_n_gpu_rotation_speed)}
        </div>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Температура нагн.:</div>
          {`${twoDigitsFormatter.format(plant?.discharge_temperature__K)} К`}
        </div>
      </div>
      <div className={'pipeline-result__cs-expand__column normal-text'}>
        <div className={'pipeline-result__cs-expand__row'}>
          <div className={'title'}>Перепуск газа:</div>
          {twoDigitsFormatter.format(plant?.gas_bypass__Mm3_per_day)}
        </div>
      </div>
    </div>
  )
}
