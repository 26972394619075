import React from 'react'

import { getAreaLayingCategoriesHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItems } from 'store/directorySlice'

export const useGetAreaLayingCategories = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetBlackPipePrices = async () => {
    await getAreaLayingCategoriesHandbook().then((res) => {
      dispatch(
        setDirectoryItems(
          res.data.units.map((unit) => {
            return {
              ...unit,
              modified: false,
            }
          }),
        ),
      )
    })
  }

  React.useEffect(() => {
    tryToGetBlackPipePrices().then()
  }, [requestData])

  return
}
