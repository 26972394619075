import { ProfileConfig } from './config'

export const profileRound = (value: number | undefined): number | undefined => {
  if (value === undefined) return value
  return Math.round(value * Math.pow(10, ProfileConfig.decimalPoints)) / Math.pow(10, ProfileConfig.decimalPoints)
}

export const getRoundingError = (): number => {
  return 1 / Math.pow(10, ProfileConfig.decimalPoints) / 2
}
