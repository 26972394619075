import React from 'react'

import { getCSUnitCostsHandbook, getLinearUnitCostsHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItem } from 'store/directorySlice'

const useGetLinearUnitCosts = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetLinearUnitCosts = async () => {
    await getLinearUnitCostsHandbook().then((res) => {
      dispatch(setDirectoryItem(res.data))
    })
  }

  React.useEffect(() => {
    tryToGetLinearUnitCosts().then()
  }, [requestData])

  return
}

export default useGetLinearUnitCosts
