import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReferenceLines } from 'store/mapSlice'
import { getReferenceLinesRequest } from 'services/apiRequests'
import { selectProjectID } from 'store/projectSlice'

const useGetReferenceLines = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID) as string

  const tryToGetReferenceLines = async () => {
    await getReferenceLinesRequest(projectId).then((response) => {
      dispatch(setReferenceLines(response.data))
    })
  }

  React.useEffect(() => {
    if (projectId) {
      tryToGetReferenceLines().then()
    }
  }, [projectId])

  return
}

export default useGetReferenceLines
