import DeleteIcon from '../../images/DeleteIcon'
import React from 'react'
import './DeleteButton.scss'
interface IDeleteButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const DeleteButton: React.FC<IDeleteButton> = (props) => {
  return (
    <button className={'delete-btn'} {...props}>
      <DeleteIcon />
    </button>
  )
}
