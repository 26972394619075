import React, { useEffect, useState } from 'react'
import Tabs, { ITab } from 'components/Tabs/tabs'
import CS from './CS/cs'
import CSPlacement from './CSPlacement/csPlacement'
import Hydraulic from './Hydraulic/hydraulic'
import { definitions } from 'generated/apiTypes'
import useCreateTask from './hooks/useCreateTask'
import useGetValidationMessage from 'components/hooks/useGetValidationMessage'
import useGetAutoCSSettings from './hooks/useGetAutoCSSettings'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskSettings, setTaskSettings } from 'store/autoCSSlice'
import { useOldLibrary } from '../../../hooks/useOldLibrary'

type tabKey = 'CS' | 'CS_PLACEMENT' | 'HYDRAULIC'

const Settings: React.FC = () => {
  const dispatch = useDispatch()
  const taskSettings = useSelector(selectTaskSettings)
  const [activeTab, setActiveTab] = React.useState<tabKey>('CS')
  const [tabsList, setTabsList] = React.useState<ITab<tabKey>[]>([
    { key: 'CS', tabContent: 'Компрессорная станция' },
    { key: 'CS_PLACEMENT', tabContent: 'Расстановка КС' },
    { key: 'HYDRAULIC', tabContent: 'Гидравлический расчет' },
  ])
  const CSStation = taskSettings?.draft_compressor_station
  const hydroSettings = taskSettings?.hydro_calculation_properties
  const step = taskSettings?.step__km
  const pressure = taskSettings?.desired_pressure_increase__MPa
  const CSStationFirstPlant = taskSettings?.draft_compressor_station.first_compressor_plant
  const CSStationSecondPlant = taskSettings?.draft_compressor_station.second_compressor_plant
  const [useNewLibrary, setUseNewLibrary] = useState(true)
  useGetAutoCSSettings()

  useOldLibrary({ setUseNewLibrary })
  useEffect(() => {
    if (hydroSettings) {
      setHydroSettings({
        ...hydroSettings,
        year: hydroSettings?.year as number,
        use_new_library: useNewLibrary,
      })
    }
  }, [useNewLibrary])
  // @ts-ignore
  useCreateTask({
    draft_compressor_station: {
      pressure__MPa: CSStation?.pressure__MPa,
      first_compressor_plant: {
        acu_id: CSStationFirstPlant?.acu.id as string,
        gpu_id: CSStationFirstPlant?.gpu.id as string,
        ...(CSStationFirstPlant as definitions['CSPlacementDraftCompressorPlantProperties']),
      },
      second_compressor_plant: CSStationSecondPlant
        ? {
            acu_id: CSStationSecondPlant?.acu.id as string,
            gpu_id: CSStationSecondPlant?.gpu.id as string,
            ...CSStationSecondPlant,
          }
        : undefined,
    },
    hydro_calculation_properties: { ...hydroSettings, year: hydroSettings?.year as number },
    step__km: step,
    desired_pressure_increase__MPa: pressure,
  })
  const isCSErrors = useGetValidationMessage('compressor-stations-placement', 'draft_compressor_station') || false
  const isPressureError = useGetValidationMessage(
    'compressor-stations-placement',
    undefined,
    undefined,
    'desired_pressure_increase__MPa',
  )
  const isStepError = useGetValidationMessage('compressor-stations-placement', undefined, undefined, 'step__km')
  const isHydraulicErrors =
    useGetValidationMessage('compressor-stations-placement', 'hydro_calculation_properties') || false

  React.useEffect(() => {
    setTabsList([
      { key: 'CS', tabContent: 'Компрессорная станция', error: isCSErrors as boolean },
      {
        key: 'CS_PLACEMENT',
        tabContent: 'Расстановка КС',
        error: isStepError !== undefined || isPressureError !== undefined,
      },
      { key: 'HYDRAULIC', tabContent: 'Гидравлический расчет', error: isHydraulicErrors as boolean },
    ])
  }, [isCSErrors, isHydraulicErrors, isPressureError, isStepError])

  const setCSStation = (draft_compressor_station: definitions['CSPlacementProperties']['draft_compressor_station']) => {
    dispatch(
      setTaskSettings({
        ...(taskSettings as definitions['CSPlacementProperties']),
        draft_compressor_station,
      }),
    )
  }

  const setStep = (step__km: definitions['CSPlacementCalculationCreate']['step__km']) => {
    dispatch(
      setTaskSettings({
        ...(taskSettings as definitions['CSPlacementProperties']),
        step__km,
      }),
    )
  }
  const setPressure = (
    desired_pressure_increase__MPa: definitions['CSPlacementProperties']['desired_pressure_increase__MPa'],
  ) => {
    dispatch(
      setTaskSettings({
        ...(taskSettings as definitions['CSPlacementProperties']),
        desired_pressure_increase__MPa,
      }),
    )
  }
  const setHydroSettings = (
    hydro_calculation_properties: definitions['CSPlacementCalculationCreate']['hydro_calculation_properties'],
  ) => {
    dispatch(
      setTaskSettings({
        ...(taskSettings as definitions['CSPlacementProperties']),
        hydro_calculation_properties: {
          ...hydro_calculation_properties,
          use_new_library: hydro_calculation_properties.use_new_library || false,
        },
      }),
    )
  }

  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <span className={'hydraulic-calc__title header2'}>Автоматическая расстановка компрессорных станций</span>
      </div>
      {CSStation && pressure && step && hydroSettings ? (
        <>
          <Tabs
            tabList={tabsList}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            containerClassName={'auto-cs-calc__tabs'}
            tabClassName={'auto-cs-calc__tab'}
          />
          {activeTab === 'CS' && <CS CSStation={CSStation} setCSStation={setCSStation} />}
          {activeTab === 'CS_PLACEMENT' && (
            <CSPlacement step={step} pressure={pressure} setStep={setStep} setPressure={setPressure} />
          )}
          {activeTab === 'HYDRAULIC' && (
            <Hydraulic
              hydroSettings={{ ...hydroSettings, year: hydroSettings?.year as number }}
              setHydroSettings={setHydroSettings}
            />
          )}
        </>
      ) : (
        <span className={'hydraulic-settings__loading-message normal-text'}>Подождите...</span>
      )}
    </>
  )
}

export default Settings
