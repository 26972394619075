import React from 'react'
import '../mainPage.scss'
import './model10.scss'
import Settings from './Settings'
import { useSelector } from 'react-redux'
import { selectTaskId, selectTaskStatus } from 'store/model10Slice'
import Progress from './Progress'
import useGetTask from './hooks/useGetTask'
import Results from './Results'

const Model10: React.FC = () => {
  const taskId = useSelector(selectTaskId)
  const taskStatus = useSelector(selectTaskStatus)
  const [settingsFile, setSettingsFile] = React.useState<File>()
  const [GPUFile, setGPUFile] = React.useState<File>()
  const [ACUFile, setACUFile] = React.useState<File>()

  useGetTask()

  return (
    <div className={'main-page__container'}>
      <div className={'main-page-content__container'}>
        {!taskId && (
          <Settings
            settingsFile={settingsFile}
            GPUFile={GPUFile}
            ACUFile={ACUFile}
            setSettingsFile={setSettingsFile}
            setGPUFile={setGPUFile}
            setACUFile={setACUFile}
          />
        )}
        {taskId && !['Рассчитан', 'Ошибка'].includes(taskStatus!) && <Progress />}
        {taskId && ['Рассчитан', 'Ошибка'].includes(taskStatus!) && (
          <Results
            settingsFile={settingsFile}
            GPUFile={GPUFile}
            ACUFile={ACUFile}
            setSettingsFile={setSettingsFile}
            setGPUFile={setGPUFile}
            setACUFile={setACUFile}
          />
        )}
      </div>
    </div>
  )
}

export default Model10
