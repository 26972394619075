import { useEffect, useState } from 'react'
import { getCoords, multiLineString, Position, segmentReduce } from '@turf/turf'
import { convertToGreatCircle } from '../utils/convertToGreatCircle'
import { Feature, LineString } from 'geojson'
import { IMap } from 'store/types'
import { useSelector } from 'react-redux'
import { selectHighlightData } from 'store/mapSlice'

export const useGenerateRealGeometry = (
  pipelines: any[],
  zoom: number,
  highlightCriterion: IMap['highlightCriterion'],
): Feature[] => {
  const [realGeometry, setRealGeometry] = useState<Feature[]>([])
  const [gap, setGap] = useState(100)
  const highlightData: IMap['highlightData'] = useSelector(selectHighlightData)
  const chunkToMultilineString = (line: LineString): Position[][] => {
    return segmentReduce(
      line,
      (prev: any, cur: any) => {
        prev.push(getCoords(cur))
        return prev
      },
      [],
    )
  }
  useEffect(() => {
    if (!highlightCriterion) {
      const _pipelines: Feature[] = []
      pipelines.forEach((pipeline) => {
        _pipelines.push(
          multiLineString(
            chunkToMultilineString(pipeline.line).map((line) =>
              getCoords(convertToGreatCircle([line[0], line[1]], gap, gap === 1 ? Infinity : 50)),
            ),
            {
              id: pipeline.id,
              type: 'PIPELINE',
              start_node: pipeline.start_node_id,
              end_node: pipeline.end_node_id,
              zIndex: 0,
            },
          ),
        )
      })
      setRealGeometry(_pipelines)
    }
  }, [pipelines, gap, highlightCriterion])

  useEffect(() => {
    if (highlightCriterion) {
      let data: any[] | undefined
      switch (highlightCriterion) {
        case 'LAYER_TYPES':
          data = highlightData?.layerType
          break
        case 'CATEGORY_AREA':
          data = highlightData?.category
          break
        case 'LAYING_METHOD':
          data = highlightData?.layingMethod
          break
        default:
          break
      }
      const _pipelines: Feature[] = []
      data?.forEach((item) => {
        _pipelines.push(
          multiLineString(
            chunkToMultilineString(item.line as any).map((line) =>
              getCoords(convertToGreatCircle([line[0], line[1]], gap, gap === 1 ? Infinity : 50)),
            ),
            {
              id: pipelines.find((it) => it.id === item.pipelineId)?.id,
              type: 'PIPELINE',
              start_node: pipelines.find((it) => it.id === item.pipelineId)?.start_node_id,
              end_node: pipelines.find((it) => it.id === item.pipelineId)?.end_node_id,
              zIndex: 0,
              category: item.value,
              subCategoryDisplay: item.valueIx % 2 === 0 ? 'odd' : 'even',
            },
          ),
        )
      })
      setRealGeometry(_pipelines)
    }
  }, [highlightCriterion, highlightData])

  useEffect(() => {
    if (zoom > 9) {
      setGap(1)
    } else setGap(100)
  }, [zoom])
  return realGeometry
}
