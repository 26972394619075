import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCreateTask, setCreateTask } from 'store/model10Slice'
import FileDropzone from './components/FileDropzone'
import Button from 'components/Button'
import useCreateTask from './hooks/useCreateTask'

interface ISettingsProps {
  settingsFile: File | undefined
  GPUFile: File | undefined
  ACUFile: File | undefined
  setSettingsFile(file: File): void
  setGPUFile(file: File): void
  setACUFile(file: File): void
}

const Settings: React.FC<ISettingsProps> = ({
  settingsFile,
  GPUFile,
  ACUFile,
  setSettingsFile,
  setGPUFile,
  setACUFile,
}) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)

  useCreateTask(settingsFile, GPUFile, ACUFile)

  const onCalcClick = () => {
    dispatch(setCreateTask(true))
  }

  return (
    <>
      <h1 className={'project-list__projects-title no-margin'}>Гидравлический расчет</h1>
      <div className={'model10__drop-container'}>
        <FileDropzone file={settingsFile} setFile={setSettingsFile} label={'Настройки расчета'} />
        <FileDropzone file={GPUFile} setFile={setGPUFile} label={'Справочник ГПА'} accept={{ 'text/html': ['.pol'] }} />
        <FileDropzone file={ACUFile} setFile={setACUFile} label={'Справочник АВО'} accept={{ 'text/html': ['.XX'] }} />
      </div>
      <Button
        className={'model10__calculation-btn'}
        disabled={
          !settingsFile || (GPUFile !== undefined && !ACUFile) || (!GPUFile && ACUFile !== undefined) || createTask
        }
        onClick={onCalcClick}
      >
        Рассчитать
      </Button>
    </>
  )
}

export default Settings
