import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setGetProjects } from 'store/projectsListSlice'
import { updateProjectByIDRequest } from 'services/apiRequests'
import { resetBlockingWindow, selectBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, IEditProjectNameParams } from 'store/types'
import { updateProjectDetail } from 'store/projectSlice'

const useChangeProjectName = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IEditProjectNameParams)

  const tryToChangeProjectName = async () => {
    if (blockingWindowParams?.objectID) {
      updateProjectByIDRequest(blockingWindowParams?.objectID, { name: blockingWindowParams?.objectName }).then(() => {
        dispatch(updateProjectDetail({ name: blockingWindowParams?.objectName } as any))
        dispatch(setGetProjects(true))
        dispatch(resetBlockingWindow())
      })
    }
  }

  React.useEffect(() => {
    blockingWindow?.type === 'EDIT_PROJECT_NAME' && blockingWindowParams?.isConfirmed && tryToChangeProjectName()
  }, [blockingWindowParams?.isConfirmed])

  return
}

export default useChangeProjectName
