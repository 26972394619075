import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapMode, selectMapSubmode, setMapMode, setMapSubmode } from 'store/mapSlice'
import Button from '../../Button'
import AddPointIcon from 'images/AddPointIcon'
import EditIcon from 'images/EditIcon'
import classnames from 'classnames'
import './modeControl.scss'
import RulerIcon from 'images/RulerIcon'
import SelectAreaIcon from 'images/SelectAreaIcon'
import AddSegmentIcon from 'images/AddSegmentIcon'
export const ModeControl: React.FC = () => {
  const mode = useSelector(selectMapMode)
  const submode = useSelector(selectMapSubmode)
  const dispatch = useDispatch()
  const switchMode = () => {
    ;['add_point', 'reference_line'].includes(submode) && dispatch(setMapSubmode('base'))
    mode === 'edit' ? dispatch(setMapMode('view')) : dispatch(setMapMode('edit'))
  }
  return (
    <div className={'mode-control'}>
      <Button id={'mode-control__btn'} className={classnames(mode === 'edit' && 'selected')} onClick={switchMode}>
        <EditIcon id={'edit_icn'} /> Редактирование геометрии
      </Button>
      <div className={classnames('mode-control__btns-container', mode)}>
        <Button
          className={classnames(submode === 'select_area' && 'selected')}
          onClick={() => dispatch(setMapSubmode(submode === 'select_area' ? 'base' : 'select_area'))}
        >
          <SelectAreaIcon />
        </Button>
        <Button
          id={'mode-control__btn'}
          className={classnames(submode === 'ruler' && 'selected')}
          onClick={() => dispatch(setMapSubmode(submode === 'ruler' ? 'base' : 'ruler'))}
        >
          <RulerIcon />
        </Button>
        {mode === 'edit' && (
          <>
            <Button
              id={'mode-control__btn'}
              className={classnames(submode === 'add_point' && 'selected')}
              onClick={() => dispatch(setMapSubmode(submode === 'add_point' ? 'base' : 'add_point'))}
            >
              <AddPointIcon />
            </Button>
            <Button
              id={'mode-control__btn'}
              className={classnames(submode === 'reference_line' && 'selected')}
              onClick={() => dispatch(setMapSubmode(submode === 'reference_line' ? 'base' : 'reference_line'))}
            >
              <AddSegmentIcon />
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
