import { definitions } from 'generated/apiTypes'
import Tabs, { ITab } from 'components/Tabs/tabs'
import React from 'react'
import { PlantTable } from './plantTable'

interface ICSTableProps {
  cs: definitions['HydroResultRetrieveCompressorStation']
}
type tabKey = 'PLANT1' | 'PLANT2'

const tabList: ITab<tabKey>[] = [
  { key: 'PLANT1', tabContent: 'Цех 1' },
  { key: 'PLANT2', tabContent: 'Цех 2' },
]
export const CSTable: React.FC<ICSTableProps> = ({ cs }) => {
  const [activeTab, setActiveTab] = React.useState<tabKey>('PLANT1')
  if (cs.second_plant)
    return (
      <div className={'hydraulic-results__stations_expand-row'}>
        <Tabs
          tabList={tabList}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          containerClassName={'hydraulic-results__stations-tabs'}
          tabClassName={'hydraulic-results__stations-tab'}
        />
        {activeTab === 'PLANT1' && <PlantTable plant={cs.first_plant} />}
        {activeTab === 'PLANT2' && <PlantTable plant={cs.second_plant} />}
      </div>
    )
  return <PlantTable plant={cs.first_plant} />
}
