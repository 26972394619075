import Button from 'components/Button'
import PlusIcon from 'images/PlusIcon'
import MinusIcon from 'images/MinusIcon'
import React from 'react'
interface IZoomButton {
  onZoomIn(): void
  onZoomOut(): void
}
export const ZoomButton: React.FC<IZoomButton> = ({ onZoomIn, onZoomOut }) => {
  return (
    <div className={'ctrl-btn__zoom'}>
      <Button onClick={onZoomIn}>
        <PlusIcon />
      </Button>
      <Button onClick={onZoomOut}>
        <MinusIcon />
      </Button>
    </div>
  )
}
