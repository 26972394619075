import React from 'react'
import './editElementPanel.scss'
import { useDispatch } from 'react-redux'
import { resetShowElementInfo } from 'store/projectSlice'
import { setSelectedGroup } from 'store/mapSlice'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import classnames from 'classnames'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import FullScreenIcon from 'images/FullScreenIcon'
import MinimizeIcon from 'images/MinimizeIcon'
interface IEditElementPanelProps {
  title: string | JSX.Element
  children?: any
  deleteAllow?: boolean
  fullScreenAllow?: boolean
  deleteObj?(): void
  className?: string
  changeFullScreenMode?(mode: boolean): void
}
export const EditElementPanel: React.FC<IEditElementPanelProps> = ({
  title,
  children,
  deleteAllow = false,
  fullScreenAllow = false,
  deleteObj,
  className,
  changeFullScreenMode,
}) => {
  const dispatch = useDispatch()
  const [fullScreenMode, setFullScreenMode] = React.useState<boolean>(false)
  return (
    <div className={classnames('edit-element-panel', className, fullScreenMode && 'full-screen')}>
      <div className={'edit-element-panel__header'}>
        {title}
        <div className={'edit-element-panel__header-right'}>
          {deleteAllow && <DeleteButton onClick={deleteObj} />}
          {fullScreenAllow &&
            (!fullScreenMode ? (
              <button
                className={'edit-element-panel__maxmin-btn'}
                onClick={() => {
                  setFullScreenMode(true)
                  changeFullScreenMode && changeFullScreenMode(true)
                }}
              >
                <FullScreenIcon />
              </button>
            ) : (
              <button
                className={classnames('edit-element-panel__maxmin-btn', fullScreenMode && 'full-screen')}
                onClick={() => {
                  setFullScreenMode(false)
                  changeFullScreenMode && changeFullScreenMode(false)
                }}
              >
                <MinimizeIcon />
              </button>
            ))}
          <CloseBtn
            close={() => {
              dispatch(resetShowElementInfo())
              dispatch(setSelectedGroup([]))
            }}
          />
        </div>
      </div>
      {children}
    </div>
  )
}
