import * as React from 'react'
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone'

import classNames from 'classnames'

import './style.scss'

export interface IDropzoneProps {
  onDrop?(files: File[]): void
  accept?: Accept
  multiple?: boolean
  error?: boolean
  disabled?: boolean
  label?: string
}

const Droparea: React.FC<IDropzoneProps> = ({ onDrop, accept, disabled, multiple, error, label }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    noClick: disabled,
    noKeyboard: disabled,
    multiple,
  } as DropzoneOptions)

  React.useEffect(() => {
    if (onDrop) onDrop(acceptedFiles)
  }, [acceptedFiles])

  return (
    <div className={'dropzone__container'}>
      {label && <div className={'field-label-text'}>{label}</div>}
      <div {...getRootProps()} className={classNames('dropzone', isDragActive && 'active', { error })}>
        <input {...getInputProps()} />
        <div className={'dropzone__text-wrapper'}>
          <span className='normal-text dropzone__text_bold'>Выберите&nbsp;</span>
          <span className='normal-text dropzone__text'>или перетащите файл&nbsp;{accept?.['text/html'][0]}</span>
        </div>
      </div>
    </div>
  )
}

export default Droparea
