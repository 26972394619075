import React, { useEffect, useState } from 'react'
import { EditElementPanel } from '../editElementPanel'
import TextInput from 'components/Input/textInput'
import NumberInput from 'components/Input/numberInput'
import Dropdown from 'components/Dropdown/Dropdown'
import { definitions } from 'generated/apiTypes'
import { getCoords, round } from '@turf/turf'
import './compressorStationEditPanel.scss'
import {
  createCompressorPlantRequest,
  deleteCompressorPlantByIDRequest,
  getCompressorStationByIDRequest,
  getNodesByProjectIDRequest,
  getPipelineByIDRequest,
  updateCompressorStationByIDRequest,
  updateGISHeight,
} from 'services/apiRequests'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectProjectID,
  updateCompressorStation,
  setUpdatedElement,
  selectGPUChoices,
  selectACUChoices,
  selectLoadingHeightNodeID,
  setLoadingHeightNodeID,
  selectProject,
  setPipelines,
} from 'store/projectSlice'
import update from 'immutability-helper'
import { selectBlockingWindow, setBlockingWindow, resetBlockingWindow } from 'store/commonSlice'
import Checkbox from 'components/Checkbox'
import IntegerInput from 'components/Input/integerInput'
import CoordinateInput from 'components/Input/coordinateInput'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import {
  resetDragNodeAlong,
  resetMapSubmode,
  selectDragNodeAlong,
  selectMapMode,
  selectMapSubmode,
  setDragNodeAlong,
  setMapNodes,
  setMapSubmode,
  setNodeCreation,
} from 'store/mapSlice'
import classNames from 'classnames'
import LockedIcon from 'images/LockedIcon'
import UnlockedIcon from 'images/UnlockedIcon'
import { IBlockingWindow, IDeleteConfirmParams, ISelectParams } from 'store/types'
// @ts-ignore
import { ReactComponent as ArrowResizeIcon } from 'images/icn-arrow-resize.svg'
import axios, { AxiosResponse } from 'axios'
import classnames from 'classnames'
import CopyIcon from 'images/CopyIcon'
interface ICompressorStationEditPanel {
  object?: definitions['CompressorStationRetrieve']
}

export const CompressorStationEditPanel: React.FC<ICompressorStationEditPanel> = ({ object }) => {
  const [plantSwitch, setPlantSwitch] = useState(0)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ISelectParams)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const dispatch = useDispatch()
  const projectID = useSelector(selectProjectID)
  const project = useSelector(selectProject)
  const gpuChoices = useSelector(selectGPUChoices)
  const acuChoices = useSelector(selectACUChoices)
  const mapMode = useSelector(selectMapMode)
  const active = useSelector(selectMapSubmode) === 'dragAlong'
  const dragNodeInfo = useSelector(selectDragNodeAlong)
  const currentPlant: definitions['CompressorPlant'] | undefined = object?.compressor_plants[plantSwitch]
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateCompressorStation({
        ...object,
        ...updatedField,
      } as definitions['CompressorStation']),
    )
  }
  const setValue = (value: any, label: string) => {
    let updatedValue
    if (label === 'central_point') {
      updatedValue = {
        ...object?.central_point,
        coordinates: value,
      }
    } else updatedValue = value
    updateStore({ [label]: updatedValue })
    dispatch(
      setUpdatedElement({
        elementID: object?.id as string,
        type: 'COMPRESSOR_STATIONS',
        params: { [label]: updatedValue },
      }),
    )
  }
  const setPlantValue = (value: number | string | boolean | null, label: string) => {
    if (label === 'aca_type') {
      value = (value as number) > 5 ? 5 : value
    }
    updateStore({
      compressor_plants: update(object?.compressor_plants, {
        [plantSwitch]: {
          $set: {
            ...object?.compressor_plants[plantSwitch],
            [label]: value,
          },
        } as any,
      }),
    })
    dispatch(
      setUpdatedElement({
        elementID: object?.compressor_plants[plantSwitch].id as string,
        type: 'COMPRESSOR_PLANT',
        params: { [label]: value },
      }),
    )
  }
  const setPlantNumber = (value: any) => {
    if (value.num !== object?.compressor_plants.length) {
      switch (value.num) {
        case 1:
          if (projectID)
            deleteCompressorPlantByIDRequest(projectID, object?.compressor_plants[1].id as string).then(() =>
              updateStore({ compressor_plants: [object?.compressor_plants[0]] }),
            )
          break
        case 2:
          if (projectID)
            createCompressorPlantRequest(projectID, object?.id as string).then((res) =>
              updateStore({ compressor_plants: [...(object?.compressor_plants || []), res.data] }),
            )
      }
    }
  }
  const deleteObj = () => {
    dispatch(
      setBlockingWindow({
        type: 'DELETE_CONFIRM',
        params: {
          objectType: 'COMPRESSOR_STATIONS',
          objectID: object?.id,
          objectName: object?.name,
        } as IDeleteConfirmParams,
      }),
    )
  }
  const openHandbook = (id: string | undefined, type: 'ACU' | 'GPU') => {
    dispatch(
      setBlockingWindow({
        type: 'SELECT',
        params: { objectID: id, objectType: type } as ISelectParams,
      }),
    )
  }
  useEffect(() => {
    if (
      blockingWindowParams?.objectType &&
      ['GPU', 'ACU'].includes(blockingWindowParams.objectType) &&
      blockingWindow.type === 'SELECT' &&
      blockingWindowParams?.isConfirmed
    ) {
      dispatch(
        setUpdatedElement({
          elementID: object?.compressor_plants[plantSwitch].id as string,
          type: 'COMPRESSOR_PLANT',
          params: { [`${blockingWindowParams.objectType.toLowerCase()}_id`]: blockingWindowParams.objectID as string },
        }),
      )
      const choices = blockingWindowParams.objectType === 'GPU' ? gpuChoices : acuChoices
      updateStore({
        compressor_plants: update(object?.compressor_plants, {
          [plantSwitch]: {
            $set: {
              ...object?.compressor_plants[plantSwitch],
              [blockingWindowParams.objectType.toLowerCase()]: choices.find(
                (item) => item.id === blockingWindowParams.objectID,
              ),
            },
          } as any,
        }),
      })
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow])
  useEffect(() => {
    return () => {
      active && dispatch(resetMapSubmode())
      dispatch(resetDragNodeAlong())
    }
  }, [])
  const setHeight = (value: number) => {
    updateStore({ height__m: round(value) })
    if (projectID && object?.id) {
      updateCompressorStationByIDRequest(projectID, object.id, {
        height__m: round(value),
      }).then(() => {
        getCompressorStationByIDRequest(projectID, object.id).then((res) => {
          dispatch(updateCompressorStation(res.data))
        })
        getNodesByProjectIDRequest(projectID).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
        // @ts-ignore
        const req: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] = project?.detail?.trunk_pipeline.pipelines
          .filter((item) => item.start_node.id === object.id || item.end_node.id === object.id)
          ?.map((pipeline) => getPipelineByIDRequest(projectID, pipeline.id))
        axios.all(req).then((resArr) => {
          dispatch(setPipelines(resArr.map((res) => res.data)))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectID && object?.id) {
      if (value) dispatch(setLoadingHeightNodeID(object.id))
      updateCompressorStationByIDRequest(projectID, object.id, {
        allow_to_request_gis_height: value,
      })
        .then(() => {
          getCompressorStationByIDRequest(projectID, object.id)
            .then((res) => {
              dispatch(updateCompressorStation(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectID).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectID && object?.id) {
      dispatch(setLoadingHeightNodeID(object.id))
      updateGISHeight(projectID, object.id)
        .then(() => {
          getCompressorStationByIDRequest(projectID, object.id)
            .then((res) => {
              dispatch(updateCompressorStation(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectID).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const dragNodeAlong = () => {
    if (active) {
      dispatch(resetMapSubmode())
      //dispatch(resetDragNodeAlong())
    } else {
      dispatch(setMapSubmode('dragAlong'))
      dispatch(setDragNodeAlong({ id: object?.id as string, type: 'COMPRESSOR_STATIONS' }))
    }
  }
  const switchToCreationMode = () => {
    dispatch(setMapSubmode('creation'))
    dispatch(
      setNodeCreation({
        editObject: 'COMPRESSOR_STATIONS',
        copyID: object?.id,
      }),
    )
  }
  return (
    <EditElementPanel
      title={
        <div className={'edit-element-panel__title-container'}>
          Компрессорная станция (КС)
          <button className={classNames('edit-element-panel__copy-btn')} onClick={() => switchToCreationMode()}>
            <CopyIcon />
          </button>
        </div>
      }
      deleteAllow
      deleteObj={deleteObj}
    >
      <div className={'edit-element-panel__table'}>
        <div className={'common-row'}>
          <TextInput value={object?.name || ''} fieldName={'name'} setValue={setValue} label={'Название'} />
        </div>
        <div className={'common-row'}>
          <hr />
        </div>
        <div className={'common-row edit-element-panel__lock-row'}>
          <div className={'header3 primary-text'}>Координаты</div>
          {mapMode !== 'edit' && (
            <button
              className={classNames('edit-element-panel__lock-btn square-btn-28', object?.lock && 'locked')}
              onClick={() => setValue(!object?.lock, 'lock')}
            >
              {object?.lock ? <LockedIcon /> : <UnlockedIcon />}
            </button>
          )}
        </div>
        <CoordinateInput
          id={object?.id}
          field={'latitude'}
          label={'lat'}
          disabled={mapMode !== 'edit' && object?.lock}
          value={
            dragNodeInfo?.lat
              ? parseFloat(dragNodeInfo?.lat.toFixed(6))
              : object?.central_point
              ? getCoords(object?.central_point as any)[1]
              : ''
          }
          setValue={(value) => setValue([getCoords(object?.central_point as any)[0], value], 'central_point')}
        />
        <CoordinateInput
          id={object?.id}
          field={'longitude'}
          label={'lon'}
          disabled={mapMode !== 'edit' && object?.lock}
          value={
            dragNodeInfo?.lng
              ? parseFloat(dragNodeInfo?.lng.toFixed(6))
              : object?.central_point
              ? getCoords(object?.central_point as any)[0]
              : ''
          }
          setValue={(value) => setValue([value, getCoords(object?.central_point as any)[1]], 'central_point')}
        />
        <NumberInput
          value={object?.height__m || 0}
          unit={'м'}
          label={'Высотная отметка'}
          fieldName={'height__m'}
          setValue={setHeight}
          loading={loadingHeightNodeID === object?.id}
          signed
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={object?.gis_height_error}
          disabled={object?.allow_to_request_gis_height || (mapMode !== 'edit' && object?.lock)}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={object?.allow_to_request_gis_height || false}
            disabled={mapMode !== 'edit' && object?.lock}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {object?.allow_to_request_gis_height && (
              <Button
                className={'edit-element-panel__redo-btn'}
                mode={'secondary'}
                onClick={updateHeight}
                disabled={mapMode !== 'edit' && object?.lock}
              >
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
        <div className={'common-row display-flex space-between center'}>
          <div className={'header3 primary-text'}>Положение относительно трубы</div>
          <Button className={classnames('square-btn-36', { active })} mode={'secondary'} onClick={dragNodeAlong}>
            <ArrowResizeIcon />
          </Button>
        </div>
        <NumberInput
          label={'Расстояние до начальной точки'}
          value={
            dragNodeInfo?.distance_to_source ||
            (object?.distance_to_source__m && (parseFloat(object.distance_to_source__m!) / 1000).toFixed(2))
          }
          unit={'км'}
          disabled
        />
        <NumberInput
          label={'Расстояние до ближ. КС (1)'}
          value={dragNodeInfo?.distance_to_cs1}
          unit={'км'}
          infoText={dragNodeInfo?.cs1_name && `Расстояние до ${dragNodeInfo?.cs1_name || '-'}`}
          disabled
        />
        <NumberInput
          label={'Расстояние до ближ. КС (2)'}
          value={dragNodeInfo?.distance_to_cs2}
          unit={'км'}
          infoText={dragNodeInfo?.cs2_name && `Расстояние до ${dragNodeInfo?.cs2_name || '-'}`}
          disabled
        />
        <div />
        <div className={'common-row'}>
          <hr />
        </div>
        <div className={'common-row'}>
          <div className={'header3 primary-text'}>Параметры</div>
        </div>
        <NumberInput
          unit={'МПа'}
          id={object?.id}
          key={`${object?.id}_pressure__MPa`}
          label={'Макс. давление нагнетания'}
          value={object?.pressure__MPa}
          fieldName={'pressure__MPa'}
          setValue={setValue}
          signed
        />
        <Dropdown
          options={[{ num: 1 }, { num: 2 }]}
          displayedField={'num'}
          selectedValue={{ num: object?.compressor_plants.length }}
          setSelectedValue={setPlantNumber}
          label={'Кол-во цехов'}
        />
      </div>
      {object?.compressor_plants.length !== undefined && object?.compressor_plants.length > 1 ? (
        <div
          className={'plant-switcher'}
          style={{
            gridTemplateColumns: `repeat(${object?.compressor_plants.length - 1}, 88px) auto`,
          }}
        >
          {object?.compressor_plants.map((plant: definitions['CompressorPlant'], index) => {
            return (
              <div
                key={`switcher_${index}`}
                className={plantSwitch === index ? 'selected' : ''}
                onClick={() => setPlantSwitch(index)}
              >{`Цех ${index + 1}`}</div>
            )
          })}
        </div>
      ) : (
        <div className={'edit-element-panel__subtitle'}>Цех КС (КЦ)</div>
      )}
      <div className={'edit-element-panel__table'}>
        {object?.compressor_plants?.length! > 1 && !plantSwitch && (
          <>
            <NumberInput
              unit={'%'}
              id={currentPlant?.id}
              key={`${object?.id}_gas_share`}
              value={currentPlant?.auto_gas_share ? undefined : currentPlant?.gas_share}
              fieldName={'gas_share'}
              setValue={setPlantValue}
              disabled={currentPlant?.auto_gas_share}
              label={'Доля газа переходящая на Цех 2'}
            />
            <div className={'checkbox_field normal-text'}>
              <Checkbox
                checkedValue={currentPlant?.auto_gas_share!}
                setCheckedValue={(value) => setPlantValue(value, 'auto_gas_share')}
              />
              <div>Рассчитать автоматически</div>
            </div>
            <div className={'common-row'}>
              <hr />
            </div>
          </>
        )}
        <div
          className={'choices_dropdown'}
          onClick={() => openHandbook(currentPlant?.gpu && currentPlant?.gpu.id, 'GPU')}
        >
          <Dropdown options={[]} selectedValue={currentPlant?.gpu} displayedField={'name'} label={'Наименование ГПА'} />
        </div>
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_gpu_first_stage`}
          value={currentPlant?.n_gpu_first_stage}
          fieldName={'n_gpu_first_stage'}
          setValue={setPlantValue}
          label={'Кол-во ГПА на 1 ступени'}
        />
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_gpu_second_stage`}
          value={currentPlant?.n_gpu_second_stage}
          fieldName={'n_gpu_second_stage'}
          setValue={setPlantValue}
          label={'Кол-во ГПА на 2 ступени'}
        />
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_gpu_total`}
          value={currentPlant?.n_gpu_total}
          fieldName={'n_gpu_total'}
          setValue={setPlantValue}
          label={'Общее кол-во ГПА'}
        />
        <div className={'common-row'}>
          {' '}
          <hr />
        </div>
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_max_temperature`}
          value={currentPlant?.max_temperature}
          fieldName={'max_temperature'}
          unit={'°C'}
          setValue={setPlantValue}
          label={'Макс. темп. газа на выходе КС'}
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_temperature_gtf`}
          value={currentPlant?.temperature_gtf}
          fieldName={'temperature_gtf'}
          unit={'°C'}
          setValue={setPlantValue}
          label={'Расчетная температура атмосферного воздуха на входе ГТУ'}
          infoText={
            <>
              Расчетная температура атмосферного воздуха на входе ГТУ (t)
              <br />
              <br />
              Рассчитывается следующим образом:
              <br />t = Tср + 5, где Tср - средняя температура атм. воздуха расчетного календарного периода, опр. СНиП
              23-01
            </>
          }
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_temperature_ambient`}
          value={`${currentPlant?.temperature_ambient}`}
          fieldName={'temperature_ambient'}
          unit={'°C'}
          setValue={setPlantValue}
          label={'Темпер. наружного воздуха'}
          disabled={currentPlant?.cooling_level}
          signed
        />
        <div className={'checkbox_field normal-text'}>
          <Checkbox
            checkedValue={currentPlant?.cooling_level as boolean}
            setCheckedValue={(value) => setPlantValue(value, 'cooling_level')}
          />
          <div>Рассчитать на &#34;уровне охлаждения&#34;</div>
        </div>
        <div className={'common-row'}>
          <hr />
        </div>
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_min_allowable_relative_value`}
          value={currentPlant?.min_allowable_relative_value}
          fieldName={'min_allowable_relative_value'}
          setValue={setPlantValue}
          infoText={'Минимально допустимое значение относительных оборотов нагнетателя'}
          label={
            <>
              n<sub>min</sub>
            </>
          }
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_max_allowable_relative_value`}
          value={currentPlant?.max_allowable_relative_value}
          fieldName={'max_allowable_relative_value'}
          setValue={setPlantValue}
          infoText={'Максимально допустимое значение относительных оборотов нагнетателя'}
          label={
            <>
              n<sub>max</sub>
            </>
          }
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_gas_inflow`}
          unit={'млн.куб.м/сутки'}
          value={currentPlant?.gas_inflow}
          fieldName={'gas_inflow'}
          setValue={setPlantValue}
          signed
          label={'Приток/Отбор газа перед КС'}
          infoText={'Приток/отбор газа перед КС (отбор со знаком минус)'}
          noEmpty
        />
        <div />
        <div className={'common-row'}>
          <hr />
        </div>
        <div
          className={'choices_dropdown'}
          onClick={() => openHandbook(currentPlant?.acu ? (currentPlant?.acu.id as string) : '', 'ACU')}
        >
          <Dropdown options={[]} selectedValue={currentPlant?.acu} displayedField={'name'} label={'Наименование АВО'} />
        </div>
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_aca_first_stage`}
          value={currentPlant?.n_aca_first_stage}
          fieldName={'n_aca_first_stage'}
          setValue={setPlantValue}
          label={'Кол-во паралл. АВО I '}
          infoText={'Количество параллельно включенных АВО на первой ступени охлаждения'}
        />
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_throws_aca_first_stage`}
          value={currentPlant?.n_throws_aca_first_stage}
          fieldName={'n_throws_aca_first_stage'}
          setValue={setPlantValue}
          label={'Кол-во ходов в АВО I '}
        />
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_aca_second_stage`}
          value={currentPlant?.n_aca_second_stage}
          fieldName={'n_aca_second_stage'}
          setValue={setPlantValue}
          label={'Кол-во паралл. АВО II '}
          infoText={'Количество параллельно включенных АВО на второй ступени охлаждения'}
        />
        <IntegerInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_n_throws_aca_second_stage`}
          value={currentPlant?.n_throws_aca_second_stage}
          fieldName={'n_throws_aca_second_stage'}
          label={'Кол-во ходов в АВО II'}
          setValue={setPlantValue}
        />
        <div />
        <div className={'common-row'}>
          <hr />
        </div>
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_inlet_pressure_drop`}
          value={currentPlant?.inlet_pressure_drop}
          fieldName={'inlet_pressure_drop'}
          setValue={setPlantValue}
          label={'Потери давления на входе цеха'}
          unit={'МПа'}
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_exit_pressure_drop`}
          value={currentPlant?.exit_pressure_drop}
          fieldName={'exit_pressure_drop'}
          setValue={setPlantValue}
          label={'Потери давления на нагнетании'}
          unit={'МПа'}
          signed
        />
        <NumberInput
          id={currentPlant?.id}
          key={`${currentPlant?.id}_aca_pressure_drop`}
          value={currentPlant?.aca_pressure_drop}
          fieldName={'aca_pressure_drop'}
          setValue={setPlantValue}
          label={'Потери давления в обвязке АВО'}
          unit={'МПа'}
          signed
        />
        <div />
      </div>
    </EditElementPanel>
  )
}
