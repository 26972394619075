import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTasksQueue } from 'services/apiRequests'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { resetTasksSliceState, setTasks } from 'store/tasksSlice'
import { IBlockingWindow, IErrorParams } from 'store/types'

const useGetTasks = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow

  const tryToGetTasks = async () => {
    await getTasksQueue()
      .then((response) => {
        dispatch(setTasks(response.data))
      })
      .catch(() => {
        dispatch(
          setBlockingWindow({
            type: 'ERROR',
            params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
          }),
        )
      })
  }

  React.useEffect(() => {
    if (blockingWindow.type === 'CALC_PROGRESS') dispatch(resetBlockingWindow())
    tryToGetTasks().then()
    return () => {
      dispatch(resetTasksSliceState())
    }
  }, [])

  return
}

export default useGetTasks
