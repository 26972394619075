import React from 'react'
import CalcStatus from 'components/CalcStatus'
import { useDispatch, useSelector } from 'react-redux'
import { selectErrorMessage, selectTaskStatus } from 'store/hydraulicSlice'
import Button from 'components/Button'
import Tabs, { ITab } from 'components/Tabs/tabs'
import { ResultsTabKey } from './types'
import './results.scss'
import useGetResults from './hooks/useGetResults'
import Pressure from './Pressure/pressure'
import Temperature from './Temperature/temperature'
import Velocity from './Velocity/velocity'
import { Chart, registerables } from 'chart.js'
import { Pipeline } from './Pipeline/pipeline'
import { Stations } from './Stations/stations'
import EyeIcon from 'images/EyeIcon'
import { setDisplayScheme } from 'store/canvasSchemeSlice'
import { MessageBox } from 'components/MessageBox/messageBox'
import FullScreenIcon from '../../../../../../images/FullScreenIcon'
import classnames from 'classnames'
import MinimizeIcon from '../../../../../../images/MinimizeIcon'
import useCheckTaskStatus from './hooks/useCheckTaskStatus'
Chart.register(...registerables)

const resultsTabList: ITab<ResultsTabKey>[] = [
  { key: 'PIPELINE', tabContent: 'Трубопровод' },
  { key: 'STATIONS', tabContent: 'Компрессорные станции' },
  { key: 'PRESSURE', tabContent: 'Давление' },
  { key: 'TEMPERATURE', tabContent: 'Температура' },
  { key: 'VELOCITY', tabContent: 'Скорость газа' },
]

interface IResultsProps {
  changeFullScreenMode?(mode: boolean): void
}

const Results: React.FC<IResultsProps> = ({ changeFullScreenMode }) => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const errorMessage = useSelector(selectErrorMessage)
  const [activeTab, setActiveTab] = React.useState<ResultsTabKey>('PIPELINE')
  const [fullScreenMode, setFullScreenMode] = React.useState<boolean>(false)

  useCheckTaskStatus()
  useGetResults()

  const displayScheme = () => {
    dispatch(setDisplayScheme(true))
  }
  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <div className={'hydraulic-calc__status-wrapper'}>
          <span className={'hydraulic-calc__title header2'}>Гидравлический расчет</span>
          <CalcStatus status={taskStatus} classNames={'economic-results__status'} />
          {taskStatus && ['Рассчитан', 'Рассчитано частично'].includes(taskStatus) && (
            <Button
              className={'hydraulic-calc__scheme-btn'}
              mode={'secondary'}
              onClick={displayScheme}
              disabled={taskStatus === 'Рассчитано частично'}
            >
              <EyeIcon />
              Схема транспорта газа
            </Button>
          )}
        </div>
        {!fullScreenMode ? (
          <button
            className={'edit-element-panel__maxmin-btn single-btn'}
            onClick={() => {
              setFullScreenMode(true)
              changeFullScreenMode && changeFullScreenMode(true)
            }}
          >
            <FullScreenIcon />
          </button>
        ) : (
          <button
            className={classnames('edit-element-panel__maxmin-btn single-btn', fullScreenMode && 'full-screen')}
            onClick={() => {
              setFullScreenMode(false)
              changeFullScreenMode && changeFullScreenMode(false)
            }}
          >
            <MinimizeIcon />
          </button>
        )}
      </div>
      {taskStatus === 'Рассчитано частично' && errorMessage && <MessageBox message={errorMessage as string} />}
      {taskStatus && ['Рассчитан', 'Рассчитано частично'].includes(taskStatus) && (
        <>
          <Tabs
            tabList={resultsTabList}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            containerClassName={'hydraulic-results__tabs'}
            tabClassName={'hydraulic-results__tab'}
          />
          {activeTab === 'PIPELINE' && <Pipeline />}
          {activeTab === 'STATIONS' && <Stations />}
          {activeTab === 'PRESSURE' && <Pressure />}
          {activeTab === 'TEMPERATURE' && <Temperature />}
          {activeTab === 'VELOCITY' && <Velocity />}
        </>
      )}
      {taskStatus === 'Ошибка' && <div className={'results__error normal-text'}>{errorMessage}</div>}
    </>
  )
}

export default Results
