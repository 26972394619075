import * as React from 'react'

function CopyIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        clipPath='url(#prefix__clip0_132_7606)'
        stroke='#2C3E50'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M15 6.667H8.333c-.92 0-1.666.746-1.666 1.666V15c0 .92.746 1.667 1.666 1.667H15c.92 0 1.667-.747 1.667-1.667V8.333c0-.92-.747-1.666-1.667-1.666z' />
        <path d='M13.333 6.667V5a1.667 1.667 0 00-1.666-1.667H5A1.667 1.667 0 003.333 5v6.667A1.667 1.667 0 005 13.333h1.667' />
      </g>
      <defs>
        <clipPath id='prefix__clip0_132_7606'>
          <path fill='#fff' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CopyIcon
