import React from 'react'
import { ChartData, ChartDataset } from 'chart.js'
import { ResultsTabKey } from '../types'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { seriesColor } from '../Chart/options'

export type UseChartData = (targetKey: ResultsTabKey) => ChartData<'line'>

const useChartData: UseChartData = (targetKey) => {
  const taskResults = useSelector(selectTaskResults)
  const { axisXData } = React.useMemo(() => {
    const pipelines = taskResults?.pipelines
    if (pipelines) {
      return {
        axisXData: pipelines.flatMap((item) => {
          if (targetKey !== 'VELOCITY') {
            return [item.start_point__km, item.end_point__km]
          } else return item.start_point__km
        }),
      }
    }
    return {}
  }, [taskResults?.pipelines])

  return React.useMemo(() => {
    if (axisXData) {
      const chartData: number[] = []
      taskResults?.pipelines?.map((pipeline) => {
        switch (targetKey) {
          case 'PRESSURE':
            return chartData.push(pipeline.start_point_pressure__MPa, pipeline.finish_point_pressure__MPa)
          case 'TEMPERATURE':
            return chartData.push(pipeline.start_point_temperature__K, pipeline.finish_point_temperature__k)
          case 'VELOCITY':
            return chartData.push(pipeline.gas_velocity__m_per_s)
        }
      })

      return {
        labels: axisXData,
        datasets: [
          {
            label: '',
            data: chartData,
            fill: false,
            backgroundColor: seriesColor,
            borderColor: seriesColor,
            color: seriesColor,
            borderWidth: 1,
            tension: 0,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
          } as ChartDataset<'line'>,
        ],
      }
    }
    return { labels: [], datasets: [] }
  }, [taskResults?.pipelines, targetKey])
}

export default useChartData
