import React from 'react'
import classnames from 'classnames'
import '../Table.scss'
import { TableCell } from '../TableCell'
import { TableHeaderCell } from '../TableHeaderCell'
import { ITableProps } from '../types'
export const RowsTable: React.FC<ITableProps> = ({ columns, dataSource, className, setValue }) => {
  return (
    <div className={classnames('table-container', className)}>
      <div
        className={'grid-table'}
        style={{
          minWidth: '100%',
          gridTemplateColumns: `minmax(146px, auto) repeat(${dataSource.length}, auto)`,
          gridTemplateRows: `repeat(${columns.length}, minmax(36px, auto))`,
        }}
      >
        {columns.map((obj, index) => (
          <React.Fragment key={columns[index].key}>
            <TableHeaderCell key={columns[index].key} title={columns[index].title} />
            {dataSource.map((field, fieldIx) => (
              <TableCell
                key={`${columns[index].key}-${fieldIx}`}
                id={field.id}
                type={columns[index].type}
                value={field[columns[index].key]}
                fieldName={columns[index].key}
                noEmpty={columns[index].noEmpty}
                editable={columns[index].editable}
                options={field.options}
                displayedField={field.displayedField}
                setValue={setValue}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
