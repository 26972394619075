import { useDispatch, useSelector } from 'react-redux'
import { selectNodeCreation, setNodeCreation } from 'store/mapSlice'
import React, { useContext, useEffect } from 'react'
import { point } from '@turf/turf'
import { MapContext } from './map'
import { Layer } from './Layer'
import { layerProps } from './utils'
interface IAddNodeProps {
  handleCreatePoint: (e: any) => void
}
export const AddNode: React.FC<IAddNodeProps> = ({ handleCreatePoint }) => {
  const sourceID = 'addNode'
  const baseLayer = 'addNode'
  const dispatch = useDispatch()
  const mapContext = useContext(MapContext)
  const nodeCreation = useSelector(selectNodeCreation)
  const createNode = () => {
    dispatch(setNodeCreation({ create: true }))
    mapContext?.off('click', baseLayer, createNode)
  }
  const getIcon = () => {
    switch (nodeCreation.editObject) {
      case 'REFERENCE_NODES':
        return 'junction-icn'
      case 'COMPRESSOR_STATIONS':
        return 'cs-icn'
      case 'POINTS':
        return 'split-icn'
      case 'NODES_REDUCTIONS':
        return 'reduction-icn'
      case 'HEATING_STATIONS':
        return 'heating-station-icn'
      default:
        return ''
    }
  }
  useEffect(() => {
    if (nodeCreation.editObject) {
      mapContext?.setLayoutProperty(baseLayer, 'icon-image', getIcon())
      if (nodeCreation.editObject === 'POINTS') {
        mapContext?.moveLayer(baseLayer, 'points__layer')
        mapContext?.on('mousedown', baseLayer, handleCreatePoint)
      } else {
        mapContext?.on('click', baseLayer, createNode)
      }
    }
    return () => {
      mapContext?.setLayoutProperty(baseLayer, 'icon-image', '')
      mapContext?.off('mousedown', baseLayer, handleCreatePoint)
      mapContext?.off('click', baseLayer, createNode)
    }
  }, [nodeCreation.editObject])
  useEffect(() => {
    const source: any = mapContext?.getSource(sourceID)
    if (source && !nodeCreation.create) {
      const features = nodeCreation.coordinates ? [point(nodeCreation.coordinates)] : []
      source.setData({
        type: 'FeatureCollection',
        features,
      })
    }
  }, [nodeCreation.coordinates])
  return (
    <Layer sourceID={sourceID} baseLayer={baseLayer} features={[]} layerType={'symbol'} layout={{ ...layerProps }} />
  )
}
