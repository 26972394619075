import React from 'react'

import { getIndicesPipesCalculationHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItem } from 'store/directorySlice'

const useGetIndicesPipesCalculation = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetIndicesPipesCalculation = async () => {
    await getIndicesPipesCalculationHandbook().then((res) => {
      dispatch(setDirectoryItem(res.data))
    })
  }

  React.useEffect(() => {
    tryToGetIndicesPipesCalculation().then()
  }, [requestData])

  return
}

export default useGetIndicesPipesCalculation
