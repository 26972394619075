import * as React from 'react'

function RulerIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.43746 7.49998C3.39259 7.49998 3.33329 7.54106 3.33329 7.61915V12.5H16.5625C16.6073 12.5 16.6666 12.4589 16.6666 12.3808V7.61915C16.6666 7.54106 16.6073 7.49998 16.5625 7.49998H3.43746ZM1.66663 7.61915C1.66663 6.64556 2.44733 5.83331 3.43746 5.83331H16.5625C17.5526 5.83331 18.3333 6.64556 18.3333 7.61915V12.3808C18.3333 13.3544 17.5526 14.1666 16.5625 14.1666H3.33329C2.89126 14.1666 2.46734 13.9911 2.15478 13.6785C1.84222 13.3659 1.66663 12.942 1.66663 12.5V7.61915Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.49996 5.83331C7.9602 5.83331 8.33329 6.20641 8.33329 6.66665V8.33331C8.33329 8.79355 7.9602 9.16665 7.49996 9.16665C7.03972 9.16665 6.66663 8.79355 6.66663 8.33331V6.66665C6.66663 6.20641 7.03972 5.83331 7.49996 5.83331Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99996 5.83331C5.4602 5.83331 5.83329 6.20641 5.83329 6.66665V9.16665C5.83329 9.62688 5.4602 9.99998 4.99996 9.99998C4.53972 9.99998 4.16663 9.62688 4.16663 9.16665V6.66665C4.16663 6.20641 4.53972 5.83331 4.99996 5.83331Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99996 5.83331C10.4602 5.83331 10.8333 6.20641 10.8333 6.66665V9.16665C10.8333 9.62688 10.4602 9.99998 9.99996 9.99998C9.53972 9.99998 9.16663 9.62688 9.16663 9.16665V6.66665C9.16663 6.20641 9.53972 5.83331 9.99996 5.83331Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 5.83331C15.4602 5.83331 15.8333 6.20641 15.8333 6.66665V9.16665C15.8333 9.62688 15.4602 9.99998 15 9.99998C14.5397 9.99998 14.1666 9.62688 14.1666 9.16665V6.66665C14.1666 6.20641 14.5397 5.83331 15 5.83331Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 5.83331C12.9602 5.83331 13.3333 6.20641 13.3333 6.66665V8.33331C13.3333 8.79355 12.9602 9.16665 12.5 9.16665C12.0397 9.16665 11.6666 8.79355 11.6666 8.33331V6.66665C11.6666 6.20641 12.0397 5.83331 12.5 5.83331Z'
        fill='white'
      />
    </svg>
  )
}

export default RulerIcon
