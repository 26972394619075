import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskSettings, setCreateTask, setTaskSettings } from 'store/DNLayersSlice'
import Checkbox from '../Checkbox'
import update from 'immutability-helper'

interface IDNLayersTaskWindow {
  closeWindow(): void
}
export const DNLayersTaskWindow: React.FC<IDNLayersTaskWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const [singleClick, setSingleClick] = React.useState<boolean>(false)
  const DNLayersTaskSettings = useSelector(selectTaskSettings)

  const onCalculateHandler = () => {
    setSingleClick(true)
    dispatch(setCreateTask(true))
    closeWindow()
  }

  const setDNLayersTaskSettings = (val: boolean, id: string) => {
    const updateIndex = DNLayersTaskSettings.layers?.findIndex((layer) => layer.id === id)
    if (updateIndex > -1) {
      const updatedSettings = update(DNLayersTaskSettings, {
        layers: {
          [updateIndex]: { isIncluded: { $set: val } },
        },
      })
      dispatch(setTaskSettings(updatedSettings))
    }
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container stop-task-confirm'>
        <div className={'blocking-window__header'}>
          {'Расчет слоев'}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body blocking-window__body-list'}>
          {'Рассчитать слой МДР для:'}
          {DNLayersTaskSettings.layers?.map((layer, index) => (
            <Checkbox
              key={index}
              checkedValue={layer.isIncluded}
              setCheckedValue={(val) => setDNLayersTaskSettings(val, layer.id)}
            >
              {layer?.name}
            </Checkbox>
          ))}
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={onCalculateHandler} disabled={singleClick}>
            Рассчитать
          </Button>
        </div>
      </div>
    </div>
  )
}
