import { definitions } from 'generated/apiTypes'
import { generateUID, twoDigitsFormatter } from 'pages/utils'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { Table } from 'components/Table/Table/Table'
import { ITableColumn } from 'components/Table/types'
import React from 'react'
const columns = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: false,
  },
  {
    title: (
      <>
        Кол-во поступ.
        <br />
        газа, млн.м3/сут
      </>
    ),
    type: 'Text',
    key: 'inlet_flow',
    editable: false,
  },
  {
    title: (
      <>
        Давление на
        <br />
        входе, МПа
      </>
    ),
    type: 'Text',
    key: 'inlet_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Потери давления
        <br />
        на всас., МПа
      </>
    ),
    type: 'Text',
    key: 'suction_pressure_drop',
    editable: false,
  },
  {
    title: (
      <>
        Давление
        <br />
        всас., МПа
      </>
    ),
    type: 'Text',
    key: 'suction_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Темп.
        <br />
        всас., К
      </>
    ),
    type: 'Text',
    key: 'suction_temperature',
    editable: false,
  },
  {
    title: (
      <>
        Тип уст.
        <br />
        ЦБН
      </>
    ),
    type: 'Text',
    key: 'cfb_name',
    editable: false,
  },
  {
    title: (
      <>
        Кол-во
        <br />
        ГПА раб.
      </>
    ),
    type: 'Number',
    key: 'n_gpu_operating',
    editable: false,
  },
  {
    title: (
      <>
        Кол-во
        <br />
        ГПА рез.
      </>
    ),
    type: 'Number',
    key: 'n_gpu_reserved',
    editable: false,
  },
  {
    title: (
      <>
        Мощность КС
        <br />
        расп., МВт
      </>
    ),
    type: 'Text',
    key: 'available_power',
    editable: false,
  },
  {
    title: (
      <>
        Мощность КС
        <br />
        расх., МВт
      </>
    ),
    type: 'Text',
    key: 'consumed_power',
    editable: false,
  },
  {
    title: (
      <>
        Потери давл.
        <br />
        на нагн., МПа
      </>
    ),
    type: 'Text',
    key: 'discharge_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Потери давл.
        <br />
        на АВО, МПа
      </>
    ),
    type: 'Text',
    key: 'acu_pressure_loss',
    editable: false,
  },
  {
    title: (
      <>
        Давл. на выходе
        <br />
        КС, МПа
      </>
    ),
    type: 'Text',
    key: 'outlet_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Темп. на выходе
        <br />
        КС, К
      </>
    ),
    type: 'Text',
    key: 'outlet_temperature',
    editable: false,
  },
  {
    title: (
      <>
        Расход топл. газа
        <br />
        на КС, млн.м3/сут
      </>
    ),
    type: 'Text',
    key: 'heating_gas_offtake',
    editable: false,
  },
]
export const ResultTable1: React.FC = () => {
  const taskResults = useSelector(selectTaskResults)
  const getDataSource = (stationList: definitions['HydroResultRetrieveCompressorStation'][]) => {
    const data: any = []
    stationList.forEach((station) => {
      data.push({
        id: generateUID(),
        name: station.name,
        inlet_flow: twoDigitsFormatter.format(station.first_plant.inlet_flow__Mm3_per_day),
        inlet_pressure: twoDigitsFormatter.format(station.first_plant.inlet_pressure__MPa),
        suction_pressure_drop: twoDigitsFormatter.format(station.first_plant.suction_pressure_drop__MPa),
        suction_pressure: twoDigitsFormatter.format(station.first_plant.suction_pressure__Mpa),
        suction_temperature: twoDigitsFormatter.format(station.first_plant.suction_temperature__C),
        cfb_name: station.first_plant.cfb_name,
        n_gpu_operating: station.first_plant.n_gpu_operating,
        n_gpu_reserved: station.first_plant.n_gpu_reserved,
        available_power: twoDigitsFormatter.format(station.first_plant.available_power__MW),
        consumed_power: twoDigitsFormatter.format(station.first_plant.consumed_power__MW),
        discharge_pressure: twoDigitsFormatter.format(station.first_plant.discharge_pressure__MPa),
        acu_pressure_loss: twoDigitsFormatter.format(station.first_plant.acu_pressure_loss__MPa),
        outlet_pressure: twoDigitsFormatter.format(station.first_plant.outlet_pressure__MPa),
        outlet_temperature: twoDigitsFormatter.format(station.first_plant.outlet_temperature__C),
        heating_gas_offtake: twoDigitsFormatter.format(station.first_plant.heating_gas_offtake__Mm3_per_day),
      })
      if (station.second_plant)
        data.push({
          id: generateUID(),
          name: station.name,
          inlet_flow: twoDigitsFormatter.format(station.second_plant.inlet_flow__Mm3_per_day),
          inlet_pressure: twoDigitsFormatter.format(station.second_plant.inlet_pressure__MPa),
          suction_pressure_drop: twoDigitsFormatter.format(station.second_plant.suction_pressure_drop__MPa),
          suction_pressure: twoDigitsFormatter.format(station.second_plant.suction_pressure__Mpa),
          suction_temperature: twoDigitsFormatter.format(station.second_plant.suction_temperature__C),
          cfb_name: station.second_plant.cfb_name,
          n_gpu_operating: station.second_plant.n_gpu_operating,
          n_gpu_reserved: station.second_plant.n_gpu_reserved,
          available_power: twoDigitsFormatter.format(station.second_plant.available_power__MW),
          consumed_power: twoDigitsFormatter.format(station.second_plant.consumed_power__MW),
          discharge_pressure: twoDigitsFormatter.format(station.second_plant.discharge_pressure__MPa),
          acu_pressure_loss: twoDigitsFormatter.format(station.second_plant.acu_pressure_loss__MPa),
          outlet_pressure: twoDigitsFormatter.format(station.second_plant.outlet_pressure__MPa),
          outlet_temperature: twoDigitsFormatter.format(station.second_plant.outlet_temperature__C),
          heating_gas_offtake: twoDigitsFormatter.format(station.second_plant.heating_gas_offtake__Mm3_per_day),
        })
    })
    return data
  }
  return (
    <Table
      className={'result-table'}
      columns={columns as ITableColumn[]}
      dataSource={getDataSource(taskResults.compressorStations)}
    />
  )
}
