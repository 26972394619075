import React from 'react'
import CalcStatus from 'components/CalcStatus'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskResults, selectTaskStatus, setDownloadResults } from 'store/economicSlice'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import useGetResults from './hooks/useGetResults'
import DownloadIcon from '../../../../images/DownloadIcon'
import { selectDownloadResults } from 'store/economicSlice'
import './economicCalc.scss'
import { definitions } from 'generated/apiTypes'
import { selectErrorMessage } from 'store/economicSlice'
import useCheckTaskStatus from './hooks/useCheckTaskStatus'

const Results: React.FC = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const downloadResults = useSelector(selectDownloadResults)
  const errorMessage = useSelector(selectErrorMessage)
  const taskResults = useSelector(selectTaskResults) as definitions['EconomicsResult']

  useCheckTaskStatus()
  useGetResults()

  const getResults = () => {
    dispatch(setDownloadResults(true))
  }

  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <div className={'hydraulic-calc__status-wrapper'}>
          <span className={'hydraulic-calc__title header2'}>Экономический расчет</span>
          <CalcStatus classNames={'economic-results__status'} status={taskStatus} />
          {taskStatus === 'Рассчитан' && (
            <Button className={'results__btn economic-results__btn'} onClick={getResults} disabled={downloadResults}>
              <DownloadIcon />
              Скачать расчетную таблицу
            </Button>
          )}
        </div>
      </div>
      {taskStatus === 'Рассчитан' && (
        <>
          <div className={'economic-results__container'}>
            <div className={'economic-results__item'}>
              <div className={'economic-results__records-total'}>
                <div className={'economic-results__item-header header4'}>
                  Капитальные затраты на строительство линейной части
                </div>
                <div>
                  <div className={'economic-results__item-value header1'}>
                    {taskResults?.economics_linears?.total__k_rub?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  </div>
                  <div className={'economic-results__item-header label'}>тыс.руб.</div>
                </div>
              </div>
              <div className={'economic-results__record-divider'} />
              <div className={'economic-results__records'}>
                <div className={'economic-results__item-label label'}>Поставка труб</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.pipe_supply__k_rub.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Оборудование</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.equipment__k_rub.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Отвод земли, компенсации</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.linear_part_earth_grounding__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Строительно-монтажные работы</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.construction_assembly_work__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Управление, проектирование</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.management_and_design__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Резерв</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.reserve__k_rub.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Прочие затраты</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_linears?.other_economic_costs__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
              </div>
            </div>
            <div className={'economic-results__item'}>
              <div className={'economic-results__records-total'}>
                <div className={'economic-results__item-header header4'}>
                  Капитальные затраты на строительство компрессорных станций (КС)
                </div>
                <div>
                  <div className={'economic-results__item-value header1'}>
                    {taskResults?.economics_compressor_stations?.total__k_rub
                      ?.toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                  </div>
                  <div className={'economic-results__item-header label'}>тыс.руб.</div>
                </div>
              </div>
              <div className={'economic-results__record-divider'} />
              <div className={'economic-results__records'}>
                <div className={'economic-results__item-label label'}>Оборудование</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.equipment__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Подготовка территории, компенсации</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.territory_preparation_and_compensation__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>
                  Строительно-монтажные работы (Основные объекты)
                </div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.construction_assembly_work__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>
                  Строительно-монтажные работы (Прочие объекты)
                </div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.other_construction_assembly_work__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Временные сооружения</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.temporary_constructions__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Управление, проектирование</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.management_and_design__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Резерв</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.reserve__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Затраты, не зависящие от мощности КС</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.costs_for_power_independent_cs__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
                <div className={'economic-results__item-label label'}>Прочие затраты</div>
                <div className={'economic-results__item-header economic-results__detailed-value header4'}>
                  {taskResults?.economics_compressor_stations?.other_economic_costs__k_rub
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                </div>
                <div className={'economic-results__item-unit label'}>тыс.руб.</div>
              </div>
            </div>
          </div>
        </>
      )}
      {taskStatus === 'Ошибка' && errorMessage && <div className={'results__error normal-text'}>{errorMessage}</div>}
    </>
  )
}

export default Results
