import { Text } from './text'
import { roundedRect } from '../utils'
export interface Style {
  fontWeight: number
  fontSize: number
  color: string
  textBaseLine?: CanvasTextBaseline
  textAlign?: CanvasTextAlign
  padding: number
  borderColor: string
}
export const TextGroup = (ctx: CanvasRenderingContext2D, x: number, y: number, textList: any[], style: Style) => {
  let position = y + style.padding
  let maxWidth = 0
  const maxHeight = textList.length * 15 + (textList.length - 1) * 4 + style.padding * 2
  for (const text of textList) {
    Text(ctx, Array.isArray(text) ? text[0] : text, x + style.padding, position, {
      fontWeight: Array.isArray(text) ? 800 : style.fontWeight,
      fontSize: style.fontSize,
      color: style.color,
      textBaseLine: style.textBaseLine,
      textAlign: style.textAlign,
    })
    const measureWidth = ctx.measureText(text).width
    maxWidth = maxWidth < measureWidth ? measureWidth : maxWidth
    position += 19
  }
  maxWidth += style.padding * 2
  roundedRect(ctx, x + maxWidth * 0.5, y + maxHeight * 0.5, maxWidth, maxHeight, 4, 1, style.borderColor, 'transparent')
  return [maxWidth, maxHeight]
}
