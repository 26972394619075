import * as React from 'react'

function PencilIcon(props) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prefix__clip0_2613_28530)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.944 1.963zm.408 1.07l3.37 8.833a1 1 0 001.132.624l2.752-.555 1.75 1.78 2.182-2.146-1.75-1.78.6-2.743a1 1 0 00-.605-1.142L3.006 2.39l5.567 5.66-.654.643-5.567-5.66z'
          fill='#fff'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0_2613_28530'>
          <path fill='#fff' transform='translate(2 2)' d='M0 0h12v12H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PencilIcon
