import React, { useContext } from 'react'
import { MapContext } from '../map'
import { Popup } from './popup'
import { useSelector } from 'react-redux'
import { GeoJSONSource, LngLat } from 'maplibre-gl'
import { getCoords, length, Position } from '@turf/turf'
import { feature } from '@turf/helpers'
import { MapEvent } from '../types'
import './style.scss'
import { selectMapSubmode } from 'store/mapSlice'
import { convertToGreatCircle } from '../utils/convertToGreatCircle'

const Ruler: React.FC = () => {
  const mapContext = useContext(MapContext)
  const submode = useSelector(selectMapSubmode)
  const [lastPointCoordinate, setLastPointCoordinate] = React.useState<LngLat>()
  const [rulerCoords, setRulerCoords] = React.useState<Position[]>([])
  const [distance, setDistance] = React.useState<string>('0')

  React.useEffect(() => {
    if (submode === 'ruler') {
      mapContext?.on('click', onClickHandler)
    } else {
      setLastPointCoordinate(undefined)
      setRulerCoords([])
      setDistance('0')
    }
    return () => {
      mapContext?.off('click', onClickHandler)
    }
  }, [submode, rulerCoords.length])

  React.useEffect(() => {
    handleRulerCoords()
  }, [rulerCoords.length])

  const onClickHandler = (event: MapEvent) => {
    setLastPointCoordinate(event.lngLat)
    setRulerCoords([...rulerCoords, [event.lngLat.lng, event.lngLat.lat]])
  }

  const handleRulerCoords = () => {
    const rulerLinesData = feature({
      type: 'MultiLineString' as any,
      coordinates: rulerCoords.slice(0, -1).map((coord, index) => {
        return getCoords(convertToGreatCircle([coord, rulerCoords[index + 1]]))
      }),
    })
    const rulerPointsData = rulerCoords?.map((item) =>
      feature({ type: 'Point' as any, coordinates: item }, { type: 'Point' }),
    )
    const rulerSource = mapContext?.getSource('ruler-source') as GeoJSONSource
    rulerSource.setData({
      type: 'FeatureCollection',
      features: [...rulerPointsData, rulerLinesData],
    })
    const distanceM = length(rulerLinesData, { units: 'meters' })
    setDistance(
      `${Math.floor(distanceM / 1000) >= 1 ? `${Math.floor(distanceM / 1000).toFixed(0)} км ` : ''}${(
        distanceM % 1000
      ).toFixed(0)} м`,
    )
  }

  return submode === 'ruler' && lastPointCoordinate?.lat && lastPointCoordinate?.lng ? (
    <>
      {
        <Popup
          offset={[0, 36]}
          className='ruler-popup'
          coordinates={[lastPointCoordinate?.lng, lastPointCoordinate?.lat]}
          text={distance}
        />
      }
    </>
  ) : (
    <></>
  )
}

export default Ruler
