import CloseIcon from 'images/CloseIcon'
import React from 'react'
import './closeBtn.scss'
import classNames from 'classnames'
interface ICloseBtn {
  close(): void
  iconClassNames?: string
  btnClassNames?: string
}
export const CloseBtn: React.FC<ICloseBtn> = ({ close, btnClassNames, iconClassNames }) => {
  return (
    <button className={classNames('close-btn', btnClassNames)} onClick={close}>
      <CloseIcon className={iconClassNames} />
    </button>
  )
}
