import { definitions } from 'generated/apiTypes'
import { AnyLayer } from './types'
import { SourceSpecification } from 'maplibre-gl'
import { arabicToRoman } from './utils'

export const DNLayersToMapData = (
  serverLayers: definitions['XYZTiles'],
): { source: { [p: string]: SourceSpecification }; layer: AnyLayer }[] => {
  const returnValue: { source: { [p: string]: SourceSpecification }; layer: AnyLayer }[] = []
  let existingSource: { [p: string]: SourceSpecification } = {}
  if (serverLayers?.existing_xyz_tiles.length > 0) {
    serverLayers.existing_xyz_tiles.forEach((item, index) => {
      existingSource[`DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}-source`] = {
        type: 'raster',
        tiles: [item.url],
        tileSize: 256,
      }
      returnValue.push({
        source: existingSource,
        layer: {
          id: `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`,
          metadata: {
            name: `DN ${item.offset_type.diameter__mm} (${arabicToRoman(parseInt(item.offset_type.class_))})`,
          },
          type: 'raster',
          source: `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}-source`,
          minzoom: 0,
          maxzoom: 20,
          layout: {
            visibility: index === 0 ? 'visible' : 'none',
          },
        },
      })
      existingSource = {}
    })
  }
  return returnValue
}
