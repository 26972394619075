import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow, ICreateTaskConfirmParams } from 'store/types'
import Checkbox from '../Checkbox'
import { handleStorage } from 'services/handleStorage'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
interface IConfirmWindow {
  closeWindow(): void
}
export const CreateTaskConfirmWindow: React.FC<IConfirmWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICreateTaskConfirmParams)
  const [singleClick, setSingleClick] = React.useState<boolean>(false)
  const [checkboxValue, setCheckboxValue] = React.useState<boolean>(false)
  const titleByObjectType = () => {
    switch (blockingWindowParams?.taskType) {
      case 'OPTIMIZATION_TASK':
        return 'Автоматическое построение трубопровода'
      case 'AUTO_CS_TASK':
        return 'Автоматическая расстановка КС'
      case 'PROFILE_TASK':
        return 'Расчет профиля трубопровода'
      default:
        return ''
    }
  }

  const buttonTitleByObjectType = () => {
    switch (blockingWindowParams?.taskType) {
      case 'OPTIMIZATION_TASK':
        return 'Построить трубопровод'
      case 'AUTO_CS_TASK':
        return 'Расставить КС'
      case 'PROFILE_TASK':
        return 'Рассчитать'
      default:
        return ''
    }
  }

  const onConfirm = () => {
    setSingleClick(true)
    if (checkboxValue) {
      const userId = handleStorage.getUserId()
      if (userId) {
        switch (blockingWindowParams?.taskType) {
          case 'OPTIMIZATION_TASK':
            handleStorage.setOptNotificationShown(userId)
            break
          case 'AUTO_CS_TASK':
            handleStorage.setCSPlacementNotificationShown(userId)
            break
          case 'PROFILE_TASK':
            handleStorage.setProfileNotificationShown(userId)
            break
          default:
            break
        }
      }
    }
    dispatch(
      setBlockingWindow({
        params: { ...blockingWindowParams, isConfirmed: true },
      }),
    )
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container confirm-window'>
        <div className={'blocking-window__header'}>
          {titleByObjectType()}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body blocking-window__confirm-body'}>{blockingWindowParams?.message}</div>
        {['OPTIMIZATION_TASK', 'AUTO_CS_TASK', 'PROFILE_TASK'].includes(blockingWindowParams?.taskType as string) && (
          <Checkbox checkedValue={checkboxValue} setCheckedValue={setCheckboxValue}>
            Больше не показывать
          </Checkbox>
        )}
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={onConfirm} disabled={singleClick}>
            {buttonTitleByObjectType()}
          </Button>
        </div>
      </div>
    </div>
  )
}
