import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loginRequest as apiLoginRequest } from 'services/apiRequests'
import { selectLoginRequest, setLoginRequest } from 'store/loginSlice'
import { handleStorage } from 'services/handleStorage'
import updateAuthToken from 'services/updateAuthToken'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useLogin = (user: { username: string; password: string }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const loginRequest = useSelector(selectLoginRequest)

  const tryToLogin = async () => {
    apiLoginRequest(user).then((response) => {
      if (response.data.token) {
        handleStorage.setToken(response.data.token)
        handleStorage.setUser(response.data.first_name, response.data.last_name)
        updateAuthToken(response.data.token)
        const next = params.get('next')
        if (next) window.location.replace(next)
        else navigate('/')
      }
    })
  }

  React.useEffect(() => {
    if (loginRequest)
      tryToLogin().then(() => {
        dispatch(setLoginRequest(false))
      })
  }, [loginRequest])

  return
}

export default useLogin
