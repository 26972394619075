import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getProfileTaskStatus } from 'services/apiRequests'
import {
  resetProfileSliceState,
  selectTaskId,
  selectCreateTask,
  selectTaskStatus,
  setTaskCalculationProgress,
  setTaskStatus,
  setCreateTask,
  setErrorMessage,
  setTaskId,
  selectErrorMessage,
} from 'store/profileSlice'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import {
  resetBlockingWindow,
  selectBlockingWindow,
  selectTaskIsCanceling,
  setBlockingWindow,
  setTaskIsCanceling,
} from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { IBlockingWindow, ICalcProgressParams, IErrorParams } from 'store/types'
import { selectProfileDisplayed } from '../../../store/mapSlice'

const useGetProfileTaskStatus = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const taskId = useSelector(selectTaskId)
  const createTask = useSelector(selectCreateTask)
  const errorMessage = useSelector(selectErrorMessage)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICalcProgressParams)
  const taskIsCanceling = useSelector(selectTaskIsCanceling)
  const profileDisplayed = useSelector(selectProfileDisplayed)
  const [taskAuthor, setTaskAuthor] = React.useState<definitions['TaskStatus']['author']>()
  const [delay, setDelay] = React.useState<definitions['TaskStatus']['delay__s']>()
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  const tryToGetTaskStatus = async (): Promise<definitions['TaskStatus'] | void> => {
    if (trunkPipelineId && projectId)
      return await getProfileTaskStatus(projectId, trunkPipelineId)
        .then((response) => {
          setTaskAuthor(response.data.author)
          setDelay(response.data.delay__s)
          if (!taskId) dispatch(setTaskId(response.data.id))
          dispatch(setTaskCalculationProgress(response.data.progress))
          dispatch(setErrorMessage(response.data.error_message))
          dispatch(setTaskStatus(response.data.status))
          return response.data
        })
        .catch(() => {
          if (timerId.current) clearInterval(timerId.current)
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (taskStatus) {
      if (['Рассчитан', 'Ошибка', 'Рассчитано частично', 'Отменен', 'Неактуальный'].includes(taskStatus)) {
        dispatch(setCreateTask(false))
        dispatch(setTaskIsCanceling(false))
        if (timerId.current) {
          clearInterval(timerId.current)
          timerId.current = undefined
          if (taskStatus === 'Ошибка') {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Ошибка расчета',
                  message: errorMessage ? errorMessage : 'Пожалуйста, попробуйте рассчитать профиль позднее',
                } as IErrorParams,
              }),
            )
          } else dispatch(resetBlockingWindow())
        }
      } else if (!timerId.current) {
        tryToGetTaskStatus().then((returnValue) => {
          if (
            (returnValue as definitions['TaskStatus'])?.status &&
            !['Рассчитан', 'Ошибка', 'Рассчитано частично', 'Отменен', 'Неактуальный'].includes(
              (returnValue as definitions['TaskStatus']).status,
            )
          ) {
            clearInterval(timerId.current)
            timerId.current = undefined
            dispatch(
              setBlockingWindow({
                type: 'CALC_PROGRESS',
                params: {
                  taskType: 'PROFILE_TASK',
                  taskUserID: taskAuthor?.id?.toString(),
                  taskID: taskId,
                } as ICalcProgressParams,
              }),
            )
            timerId.current = setInterval(async () => {
              tryToGetTaskStatus().then()
            }, ((returnValue as definitions['TaskStatus'])?.delay__s || 3) * 1000)
          } else if ((returnValue as definitions['TaskStatus'])?.status === 'Ошибка') {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Ошибка расчета',
                  message: (returnValue as definitions['TaskStatus'])?.error_message
                    ? (returnValue as definitions['TaskStatus']).error_message
                    : 'Пожалуйста, попробуйте рассчитать профиль позднее',
                } as IErrorParams,
              }),
            )
          }
        })
      } else if (timerId.current) {
        clearInterval(timerId.current)
        timerId.current = setInterval(async () => {
          tryToGetTaskStatus().then()
        }, (delay || 3) * 1000)
      }
    }
  }, [taskStatus, delay, errorMessage])

  React.useEffect(() => {
    if (taskIsCanceling && trunkPipelineId && createTask) {
      tryToGetTaskStatus().then()
      if (blockingWindow?.type) dispatch(resetBlockingWindow())
    }
  }, [taskIsCanceling])

  React.useEffect(() => {
    if (taskAuthor?.id && blockingWindow?.type === 'CALC_PROGRESS') {
      dispatch(
        setBlockingWindow({
          params: { ...blockingWindowParams, taskUserID: taskAuthor?.id?.toString() } as ICalcProgressParams,
        }),
      )
    }
  }, [taskAuthor])

  React.useEffect(() => {
    if (trunkPipelineId) {
      tryToGetTaskStatus().then()
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
        dispatch(resetProfileSliceState())
      }
    }
  }, [trunkPipelineId])

  React.useEffect(() => {
    if (profileDisplayed) {
      tryToGetTaskStatus().then()
    }
  }, [profileDisplayed])

  React.useEffect(() => {
    if (!blockingWindow.type && taskStatus && !taskIsCanceling) {
      if (['Запущен', 'Ожидает очереди'].includes(taskStatus)) {
        dispatch(
          setBlockingWindow({
            type: 'CALC_PROGRESS',
            params: {
              taskType: 'PROFILE_TASK',
              taskUserID: taskAuthor?.id?.toString(),
              taskID: taskId,
            } as ICalcProgressParams,
          }),
        )
      }
    }
  }, [blockingWindow.type])

  return
}

export default useGetProfileTaskStatus
