import React, { PropsWithChildren } from 'react'
import './directoryGroup.scss'

interface IDirectoryGroupProps {
  title: string
}

const DirectoryGroup: React.FC<PropsWithChildren<IDirectoryGroupProps>> = ({ title, children }) => {
  return (
    <div className={'directory-card__container'}>
      <div className={'project-list__projects-title header2'}>{title}</div>
      <div className={'directories__content'}>{children}</div>
    </div>
  )
}

export default DirectoryGroup
