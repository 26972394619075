import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IAutoCSSlice } from './types'

export const initialState: IAutoCSSlice = {
  taskId: undefined,
  createTask: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  taskSettings: undefined,
}

export const autoCS = createSlice({
  name: 'autoCS',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IAutoCSSlice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IAutoCSSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IAutoCSSlice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IAutoCSSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IAutoCSSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    setTaskSettings: (state, action: PayloadAction<IAutoCSSlice['taskSettings']>) => {
      return { ...state, taskSettings: action.payload }
    },
    resetAutoCSSliceState: () => {
      return initialState
    },
  },
})

export const {
  setTaskId,
  setCreateTask,
  setTaskStatus,
  setTaskCalculationProgress,
  setErrorMessage,
  setTaskSettings,
  resetAutoCSSliceState,
} = autoCS.actions

export const selectTaskId = (state: RootState) => state.autoCS.taskId

export const selectCreateTask = (state: RootState) => state.autoCS.createTask

export const selectTaskStatus = (state: RootState) => state.autoCS.taskStatus

export const selectTaskCalculationProgress = (state: RootState) => state.autoCS.taskCalculationProgress

export const selectErrorMessage = (state: RootState) => state.autoCS.errorMessage

export const selectTaskSettings = (state: RootState) => state.autoCS.taskSettings
export default autoCS.reducer
