import React from 'react'
import useCreateTask from './hooks/useCreateTask'

const Settings: React.FC = () => {
  useCreateTask()

  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <span className={'hydraulic-calc__title header2'}>Экономический расчет</span>
      </div>
    </>
  )
}

export default Settings
