import * as React from 'react'

function CloseIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15 5L5 15M5 5l10 10'
        stroke='#2C3E50'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CloseIcon
