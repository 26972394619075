import { AnyLayer } from './types'

export const tilesLayers: AnyLayer[] = [
  {
    id: 'base-map-layer',
    metadata: {
      name: 'OSM',
    },
    type: 'raster',
    source: 'base-map-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'visible',
    },
  },
  {
    id: 'base-map-sat-layer',
    metadata: {
      name: 'Спутник',
    },
    type: 'raster',
    source: 'base-map-sat-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'none',
    },
  },
  {
    id: 'stripped-down-layer',
    metadata: {
      name: 'ИУС ГИС (Урез.)',
    },
    type: 'raster',
    source: 'stripped-down-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'visible',
    },
  },
  {
    id: 'full-layer',
    metadata: {
      name: 'ИУС ГИС (Полн.)',
    },
    type: 'raster',
    source: 'full-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'none',
    },
  },
]

const rulerLayers: AnyLayer[] = [
  {
    id: 'dashed-ruler-line',
    type: 'line',
    source: 'ruler-source',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#FF33C6',
      'line-width': ['interpolate', ['linear'], ['zoom'], 6, 1, 13, 3],
      'line-dasharray': [2, 2],
    },
  },
  {
    id: 'points-ruler-line',
    type: 'circle',
    source: 'ruler-source',
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 6, 2, 13, 6],
      'circle-color': '#FF33C6',
    },
    filter: ['==', 'type', 'Point'],
  },
]

export const kilometerMarksLayer: AnyLayer = {
  id: 'kilometer-marks__layer',
  metadata: {
    name: 'Километры',
  },
  type: 'symbol',
  source: 'kilometer-marks__source',
  minzoom: 0,
  maxzoom: 20,
  layout: {
    'icon-image': 'mark-icn',
    'icon-offset': ['get', 'icon-offset'],
    'text-font': ['Mulish Black'],
    'text-size': 14,
    'text-rotation-alignment': 'viewport',
    'text-field': ['get', 'text'],
    'text-allow-overlap': true,
    'text-offset': ['get', 'text-offset'],
    'text-rotate': ['get', 'text-rotation'],
    'text-ignore-placement': true,
    visibility: 'visible',
    'icon-rotate': ['get', 'rotation'],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
  },
  paint: {
    'text-halo-width': 2,
    'text-halo-color': 'white',
    'text-color': '#FF33C6',
  },
}
export const Layers = [...tilesLayers, ...rulerLayers, kilometerMarksLayer]
