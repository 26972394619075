import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow, IStopTaskConfirmParams } from 'store/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
interface IStopTaskWindow {
  closeWindow(): void
}
export const StopTaskWindow: React.FC<IStopTaskWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IStopTaskConfirmParams)
  const [singleClick, setSingleClick] = React.useState<boolean>(false)

  const stopTaskHandler = () => {
    setSingleClick(true)
    dispatch(setBlockingWindow({ params: { ...blockingWindowParams, isConfirmed: true } as IStopTaskConfirmParams }))
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container stop-task-confirm'>
        <div className={'blocking-window__header'}>
          {`Отмена расчета`}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          {`Вы действительно хотите отменить расчет`}
          {blockingWindowParams?.taskName !== undefined && ` “${blockingWindowParams?.taskName}“`}?
          <br />
          <br />
          {`Весь достигнутый прогресс будет потерян.`}
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Не отменять расчет
          </Button>
          <Button mode={'warn'} onClick={stopTaskHandler} disabled={singleClick}>
            Отменить расчет
          </Button>
        </div>
      </div>
    </div>
  )
}
