import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { Table } from 'components/Table/Table/Table'
import { DiameterToBlackPipePrice, SteelGradeToBlackPipePrice } from 'store/types'
import useGetBlackPipePrices from './hooks/useGetBlackPipePrices'
import { columns } from './tableConfig'
import './directoryBlackPipePrices.scss'
import useCreateBlackPipePricesVersion from './hooks/useCreateBlackPipePricesVersion'

interface IDirectoryPipeTypesProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryBlackPipePrices: React.FC<IDirectoryPipeTypesProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as DiameterToBlackPipePrice
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)

  const saveResult = useCreateBlackPipePricesVersion(saveData)
  useGetBlackPipePrices(requestData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, updatedValue: number, fieldName: string) => {
    const [diameterField, pressureField] = fieldName.split('/') as [
      keyof definitions['DiameterToBlackPipePrice'],
      keyof definitions['PressureToBlackPipePrice'],
    ]
    let value: { [key in keyof definitions['PressureToBlackPipePrice']]?: SteelGradeToBlackPipePrice } = {}
    // @ts-ignore
    value = {
      ...directoryItem[diameterField],
      [pressureField]: {
        ...directoryItem[diameterField][pressureField],
        [id]: {
          price: updatedValue,
          modified: true,
        },
      },
    }
    dispatch(
      updateItemField({
        value,
        fieldName: diameterField,
      }),
    )
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: { [key: string]: any }[] = [
      {
        id: 'steel_grade_k_60',
        title: 'K 60',
      },
      {
        id: 'steel_grade_k_65',
        title: 'K 65',
      },
      {
        id: 'steel_grade_x_70',
        title: 'X 70',
      },
      {
        id: 'steel_grade_x_80',
        title: 'X 80',
      },
    ]
    if (directoryItem) {
      Object.entries(directoryItem).map(([key, value], _ix) => {
        Object.entries(value).map(([key2, value2], ix) => {
          data[0][`${key}/${key2}`] = value2.steel_grade_k_60?.price
          data[1][`${key}/${key2}`] = value2.steel_grade_k_65?.price
          data[2][`${key}/${key2}`] = value2.steel_grade_x_70?.price
          data[3][`${key}/${key2}`] = value2.steel_grade_x_80?.price
        })
      })
    }
    return data
  }, [directoryItem])
  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
    >
      {directoryItem ? (
        <div className={'directory__content'}>
          <Table
            className={'directory-table__container black-pipe-prices-table__container'}
            columns={columns(editMode) as any}
            dataSource={dataSource}
            setValue={onSaveItemField}
          />
        </div>
      ) : (
        <div className={'normal-text directory-table__container-error'}>Не удалось загрузить справочник...</div>
      )}
    </DirectoryWrapper>
  )
}

export default DirectoryBlackPipePrices
