import React from 'react'
import './style.scss'
import Button from '../Button'
import CopyIcon from '../../images/CopyIcon'
import { useSelector } from 'react-redux'
import { selectBlockingWindow } from '../../store/commonSlice'
import { IBlockingWindow, IProjectExportDoneParams } from '../../store/types'

interface IExportProjectToNextGISSuccessWindowProps {
  closeWindow(): void
}

export const ExportProjectToNextGISSuccessWindow: React.FC<IExportProjectToNextGISSuccessWindowProps> = ({
  closeWindow,
}) => {
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IProjectExportDoneParams)
  const onCopyToClipboard = () => {
    if (blockingWindowParams?.projectUrl) navigator.clipboard.writeText(blockingWindowParams.projectUrl)
  }
  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container export-window'>
        <div className={'blocking-window__header'}>Успешный экспорт</div>
        <div className={'blocking-window__body blocking-window__body-list gap-8'}>
          <div className={'blocking-window__header font-size-12'}>Путь к файлу в системе ИУС ГИС</div>
          <div className={'blocking-window__url'}>
            <div className={'normal-text blocking-window__url-text'}>{blockingWindowParams?.projectUrl}</div>
            <Button className={'blocking-window__copy'} mode={'drop-down'} onClick={onCopyToClipboard}>
              <CopyIcon />
            </Button>
          </div>
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            ОК
          </Button>
        </div>
      </div>
    </div>
  )
}
