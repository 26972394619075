import React, { useEffect } from 'react'
import Settings from './Settings/settings'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskStatus } from 'store/autoCSSlice'
import Results from './Results/results'
import Button from 'components/Button'
import DownloadIcon from 'images/DownloadIcon'
import RedoIcon from 'images/RedoIcon'
import './autoCSCalc.scss'
import { resetAutoCSSliceState, setCreateTask, selectCreateTask } from 'store/autoCSSlice'
import useGetDumps from './Results/hooks/useGetDumps'
import { handleStorage } from 'services/handleStorage'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { ToolTabKey } from '../../types'
import { IBlockingWindow, ICreateTaskConfirmParams } from '../../../../store/types'

interface IAutoCSCalcProps {
  setToolTab(tab: ToolTabKey): void
}

const AutoCSCalc: React.FC<IAutoCSCalcProps> = ({ setToolTab }) => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const createTask = useSelector(selectCreateTask)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICreateTaskConfirmParams)
  const [showSettings, setShowSettings] = React.useState<boolean>()
  const [requestData, setRequestData] = React.useState<boolean>(false)
  useGetDumps({ requestData, setRequestData })

  React.useEffect(() => {
    if (['Рассчитан', 'Рассчитано частично', 'Ошибка', 'Отменен'].includes(taskStatus!)) {
      setShowSettings(false)
    }
  }, [taskStatus])

  useEffect(() => {
    if (blockingWindowParams?.taskType === 'AUTO_CS_TASK' && blockingWindowParams?.isConfirmed) {
      dispatch(setCreateTask(true))
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindowParams?.isConfirmed])

  const calculate = () => {
    const userId = handleStorage.getUserId()
    if (userId && handleStorage.getCSPlacementNotificationShown()?.includes(userId)) {
      dispatch(resetAutoCSSliceState())
      dispatch(setCreateTask(true))
    } else {
      dispatch(
        setBlockingWindow({
          type: 'CREATE_TASK_CONFIRM',
          params: {
            taskType: 'AUTO_CS_TASK',
            message: (
              <ul className='confirm-window__list'>
                <li>Результат автоматической расстановки компрессорных станций будет применен автоматически.</li>
                <li>Все ранее созданные компрессорные станции и узлы редуцирования будут удалены.</li>
                <li>В рамках расстановки КС будет выполнен гидравлический расчет.</li>
              </ul>
            ),
          } as ICreateTaskConfirmParams,
        }),
      )
    }
  }

  return (
    <div className={'auto-cs-calc__container'}>
      {(!['Рассчитан', 'Рассчитано частично', 'Ошибка'].includes(taskStatus!) || showSettings) && (
        <>
          <div className={'hydraulic-calc__content auto-cs-calc__settings-content'}>
            <Settings />
          </div>
          <div className={'calc__bottom-panel'}>
            {showSettings && (
              <Button
                className={'hydraulic-settings__button'}
                mode={'secondary'}
                onClick={() => setShowSettings(false)}
              >
                Отменить
              </Button>
            )}
            <Button className={'hydraulic-settings__button'} onClick={calculate} disabled={createTask}>
              Расставить КС
            </Button>
          </div>
        </>
      )}
      {['Рассчитан', 'Рассчитано частично', 'Ошибка'].includes(taskStatus!) && !showSettings && (
        <>
          <div className={'hydraulic-calc__content auto-cs-calc__results-content'}>
            <Results setToolTab={setToolTab} />
          </div>
          <div className={'calc__bottom-panel'}>
            <Button mode={'secondary'} onClick={() => setRequestData(true)}>
              <DownloadIcon />
              Лог-файл
            </Button>
            <Button onClick={() => setShowSettings(true)}>
              <RedoIcon className={'redo-icn'} />
              Пересчитать с новыми параметрами
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default AutoCSCalc
