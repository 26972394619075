import React, { useEffect, useState } from 'react'
import './gasTransportScheme.scss'
import Button from 'components/Button'
import { Scheme } from 'components/Scheme/scheme'
import { useDispatch, useSelector } from 'react-redux'
import { selectDisplayScheme, setDisplayScheme } from 'store/canvasSchemeSlice'
import classnames from 'classnames'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import DownloadIcon from 'images/DownloadIcon'
import { exportHydroResultToDXF, getEconomicsDumpsId, getFileById } from 'services/apiRequests'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'

export const GasTransportScheme: React.FC = () => {
  const dispatch = useDispatch()
  const display = useSelector(selectDisplayScheme)
  const projectId = useSelector(selectProjectID) as string
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const [download, setDownload] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(setDisplayScheme(false))
    }
  }, [])

  const onExportHydroResultToDXF = async () => {
    if (trunkPipelineId && projectId)
      exportHydroResultToDXF(projectId, trunkPipelineId)
        .then((fileIdResponse) => {
          if (fileIdResponse.data.file_id) {
            getFileById(fileIdResponse.data.file_id).then((response) => {
              const myURL = (window as any)?.URL || (window as any)?.webkitURL
              const url = myURL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'transport-gas-scheme.dxf')
              document.body.appendChild(link)
              link.click()
            })
          }
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              type: 'ERROR',
              params: { title: 'Ошибка экспорта', message: 'Ошибка экспорта' } as IErrorParams,
            }),
          )
        })
  }

  if (display)
    return (
      <div className={classnames('gas-transport-scheme__frame')}>
        <div className={'gas-transport-scheme__header'}>
          <div>
            <span className={'header2'}>Схема транспорта газа</span>
            <Button mode={'secondary'} onClick={() => setDownload(true)}>
              <DownloadIcon /> Скачать .pdf
            </Button>
            <Button mode={'secondary'} onClick={onExportHydroResultToDXF}>
              <DownloadIcon /> Скачать .dxf
            </Button>
          </div>
          <CloseBtn close={() => dispatch(setDisplayScheme(false))} />
        </div>
        <Scheme download={download} setDownload={setDownload} />
      </div>
    )
  return <></>
}
