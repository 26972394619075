import { useDispatch, useSelector } from 'react-redux'
import { selectDownloadResults, selectTaskId, setDownloadResults } from 'store/model10Slice'
import { getTask } from 'services/apiRequests'
import React from 'react'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'

const useGetResults = () => {
  const dispatch = useDispatch()
  const taskId = useSelector(selectTaskId)
  const downloadResults = useSelector(selectDownloadResults)

  const tryToGetResults = async () => {
    if (taskId)
      await getTask(taskId)
        .then((response) => {
          const myURL = (window as any)?.URL || (window as any)?.webkitURL
          const url = myURL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'results.txt')
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (downloadResults)
      tryToGetResults().then(() => {
        dispatch(setDownloadResults(false))
      })
  }, [downloadResults])

  return
}

export default useGetResults
