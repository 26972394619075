import React from 'react'

export const ClassType = ['B', 'I', 'II', 'III', 'IV', 'Запрещено']
export const columns = (editMode: boolean): any[] => [
  {
    title: <>Слой системы</>,
    type: 'Text',
    key: 'area_type',
    editable: false,
    className: 'area-laying-categories-table__header',
    sticky: true,
  },
  {
    title: 'Категория трубопровода',
  },
  {
    type: 'Option',
    title: 'Подземная прокладка',
    key: 'underground_laying',
    options: ClassType,
    displayedField: '',
    editable: editMode,
  },
  {
    type: 'Option',
    title: 'Наземная прокладка',
    key: 'ground_laying',
    editable: editMode,
    options: ClassType,
    displayedField: '',
  },
  {
    type: 'Option',
    title: 'Надземная прокладка',
    key: 'upground_laying',
    editable: editMode,
    options: ClassType,
    displayedField: '',
  },
]
