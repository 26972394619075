import * as React from 'react'

function FocusIcon() {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 9.99996C9.10457 9.99996 10 9.10453 10 7.99996C10 6.89539 9.10457 5.99996 8 5.99996C6.89543 5.99996 6 6.89539 6 7.99996C6 9.10453 6.89543 9.99996 8 9.99996Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 7.99996C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 7.99996C2 4.68625 4.68629 1.99996 8 1.99996C11.3137 1.99996 14 4.68625 14 7.99996ZM12 7.99996C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 7.99996C4 5.79082 5.79086 3.99996 8 3.99996C10.2091 3.99996 12 5.79082 12 7.99996Z'
        fill='white'
      />
    </svg>
  )
}

export default FocusIcon
