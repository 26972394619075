import React, { useEffect } from 'react'
import { handleStorage } from 'services/handleStorage'
import { useNavigate, useSearchParams } from 'react-router-dom'

const OAuth: React.FC = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  useEffect(() => {
    const token = params.get('token')
    const next = params.get('next')
    if (token) {
      handleStorage.setToken(token)
      if (next) window.location.replace(next)
      else navigate('/')
    }
  }, [])
  return <></>
}

export default OAuth
