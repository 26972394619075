import React from 'react'
import BarIcon from '../../../../../images/BarIcon'
import '../../../Projects/components/ProjectCard/projectCard.scss'
import './directoryCard.scss'

interface IDirectoryCard extends React.HTMLAttributes<HTMLElement> {
  id: string
  title: string
  date: string
}
export const DirectoryCard: React.FC<IDirectoryCard> = ({ id, title, date, ...props }) => {
  return (
    <div className={'project-card'} {...props}>
      <div className={'project-card__top'}>
        <div className={'project-card__flex-start directory-card__flex-start'}>
          <BarIcon /> <div className={'project-title directory-title button-text'}>{title}</div>
        </div>
        <div className={'project-card__flex-end directory-card__flex-end'}>
          <div className={'project-owner'}>{''}</div>
          <div className={'project-card__left'}>
            <div className={'project-date'}>{date}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
