import React, { useContext } from 'react'
import CoordinateInput from 'components/Input/coordinateInput'
import { getCoords, MultiLineString, multiLineString, point } from '@turf/turf'
import { MapContext } from '../map'
import { GeoJSONSource } from 'maplibre-gl'
import { getSourceData, last } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowIntermediateElementInfo, selectUpdatedElement, setUpdatedElement } from 'store/projectSlice'
import { Feature } from 'geojson'
import { IUpdateCoordsProps } from '../hooks/useUpdateCoords'
import { selectMap } from '../../../store/mapSlice'
import { convertToStraightLine } from '../utils/convertToStraightLine'
import { convertToGreatCircle } from '../utils/convertToGreatCircle'

interface IPointElementProps {
  updatedCoords: IUpdateCoordsProps[]
}

const PointElement: React.FC<IPointElementProps> = ({ updatedCoords }) => {
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()
  const intermediateElementInfo = useSelector(selectShowIntermediateElementInfo)
  const updatedElement = useSelector(selectUpdatedElement)
  const map = useSelector(selectMap)
  const [coords, setCoords] = React.useState<[number, number]>()

  React.useEffect(() => {
    if (intermediateElementInfo?.objectId) {
      const node = map.pipelines.find((feat) => feat.id === intermediateElementInfo?.objectId)
      if (intermediateElementInfo?.index !== undefined && getCoords(node?.line as any)[intermediateElementInfo.index])
        setCoords(getCoords(node?.line as any)[intermediateElementInfo.index])
    }
  }, [intermediateElementInfo?.objectId, intermediateElementInfo?.index, updatedElement?.params, updatedCoords])

  const updateCoordinate = (value: [number, number]) => {
    const majorSource = mapContext?.getSource('major__source') as GeoJSONSource
    if (intermediateElementInfo?.index !== undefined && intermediateElementInfo?.objectId) {
      getSourceData(majorSource).features.find((feat: Feature) => {
        if (feat.properties?.id === intermediateElementInfo?.objectId) {
          feat.geometry = multiLineString([
            ...getCoords(feat.geometry as any).slice(0, intermediateElementInfo.index! - 1),
            getCoords(
              convertToGreatCircle([getCoords(feat.geometry as any)[intermediateElementInfo.index! - 1][0], value]),
            ),
            getCoords(
              convertToGreatCircle([value, last(getCoords(feat.geometry as any)[intermediateElementInfo.index!])]),
            ),
            ...getCoords(feat.geometry as any).slice(intermediateElementInfo.index! + 1),
          ]).geometry
        }
      })
      getSourceData(majorSource).features.find((feat: Feature) => {
        if (
          feat.properties?.lineId === intermediateElementInfo?.objectId &&
          feat.properties?.index == intermediateElementInfo.index
        ) {
          feat.geometry = point(value).geometry
        }
      })
      majorSource?.setData(getSourceData(majorSource))
      dispatch(
        setUpdatedElement({
          elementID: intermediateElementInfo?.objectId,
          type: 'PIPELINE',
          params: {
            line: convertToStraightLine(
              getSourceData(majorSource).features.find(
                (feat: Feature) => feat.properties?.id === intermediateElementInfo?.objectId,
              )?.geometry as MultiLineString,
            ).geometry,
          } as { [key: string]: unknown },
        }),
      )
    }
  }

  return (
    <div className={'intermediate-element__content'}>
      <div className={'intermediate-element__subtitle header4'}>Координаты</div>
      <div className={'intermediate-element__params-panel'}>
        <CoordinateInput
          id={'1'}
          field={'latitude'}
          label={'lat'}
          value={coords ? coords[1].toFixed(6) : ''}
          setValue={(value) => updateCoordinate([coords ? coords[0] : 0, value])}
        />
        <CoordinateInput
          id={'2'}
          field={'longitude'}
          label={'lon'}
          value={coords ? coords[0].toFixed(6) : ''}
          setValue={(value) => updateCoordinate([value, coords ? coords[1] : 0])}
        />
      </div>
    </div>
  )
}

export default PointElement
