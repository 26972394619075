import React from 'react'
import classnames from 'classnames'
import './panelButton.scss'
import WarningIcon from 'images/WarningIcon'
interface IPanelButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: any
  isWarning?: boolean
}

export const PanelButton: React.FC<IPanelButton> = ({ className, value, isWarning, ...props }) => {
  return (
    <button {...props} className={classnames('elements-panel__btn', className)}>
      <div className={'select-marker'} />
      <div className={'normal-text'}>{value}</div>
      {isWarning && <WarningIcon className={'elements-panel__btn-warning-icon'} />}
    </button>
  )
}
