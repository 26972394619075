import React from 'react'

export const columns = (editMode: boolean): any[] => [
  {
    title: '',
    key: 'title',
    type: 'Column',
    editable: false,
  },
  {
    title: (
      <>
        Номин. мощность
        <br />
        ГПА 16 МВт
      </>
    ),
    type: 'Number',
    editable: editMode,
    key: `power_16_props`,
    noEmpty: true,
  },
  {
    title: (
      <>
        Номин. мощность
        <br />
        ГПА 25 МВт
      </>
    ),
    type: 'Number',
    editable: editMode,
    key: `power_25_props`,
    noEmpty: true,
  },
  {
    title: (
      <>
        Номин. мощность
        <br />
        ГПА 32 МВт
      </>
    ),
    type: 'Number',
    editable: editMode,
    key: `power_32_props`,
    noEmpty: true,
  },
  {
    title: (
      <>
        Номин. мощность
        <br />
        ГПА 50 МВт
      </>
    ),
    type: 'Number',
    editable: editMode,
    key: `power_50_props`,
    noEmpty: true,
  },
]
