import React, { useState } from 'react'
import { MainPageHeader } from 'components/Header/mainPageHeader'
import './mainPage.scss'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { handleStorage } from 'services/handleStorage'
import updateAuthToken from 'services/updateAuthToken'
import Projects from './Projects/projects'
import { MenuTabKey } from './types'
import Model10 from './Model10/model10'
import Directories from './Directories/directories'
import { getCurrentUser, logoutRequest } from 'services/apiRequests'
import { BlockingWindow } from 'components/BlockWindow'
import Tasks from './Tasks/tasks'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/commonSlice'
import { BaseURL } from '../../api'

const MainPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [tabKey, setTabKey] = useState<MenuTabKey>('PROJECTS')

  const logout = () => {
    handleStorage.removeToken()
    handleStorage.removeUser()
    handleStorage.removeAuthorities()
    handleStorage.removeUserId()
    handleStorage.removeIsStaff()
    updateAuthToken(false)
    let baseHref = window.location.href.split('project/')[0].split('directory/')[0]
    if (baseHref.slice(-1) !== '/') baseHref = baseHref.concat('/')
    window.location.replace(`${BaseURL}/logout?next=${baseHref}login?next=${baseHref}`)
  }

  React.useEffect(() => {
    if (location.state?.tab === 'directories') {
      setTabKey('DIRECTORIES ')
      navigate(location.pathname, { replace: true })
    }
    if (location.state?.tab === 'tasks') {
      setTabKey('TASKS')
      navigate(location.pathname, { replace: true })
    }
  }, [location.state])

  React.useEffect(() => {
    getCurrentUser().then((res) => {
      handleStorage.setUser(res.data.first_name, res.data.last_name)
      if (res.data.id) handleStorage.setUserId(res.data.id.toString())
      if (res.data.is_staff !== undefined) handleStorage.setIsStaff(res.data.is_staff)
      handleStorage.setAuthorities(res.data.groups)
      dispatch(setUser(res.data))
    })
  }, [])
  return (
    <div>
      <BlockingWindow />
      <MainPageHeader tabKey={tabKey} setTabKey={setTabKey} logout={logout} />
      {tabKey === 'PROJECTS' && <Projects />}
      {tabKey === 'MODEL10' && <Model10 />}
      {tabKey === 'DIRECTORIES ' && <Directories />}
      {tabKey === 'TASKS' && <Tasks />}
    </div>
  )
}

export default MainPage
