import React, { PropsWithChildren } from 'react'
import '../styleGuide.scss'

interface IIconProps {
  name: string
  iconContainerClassName?: string
}

const Icon: React.FC<PropsWithChildren<IIconProps>> = ({ iconContainerClassName, name, children }) => {
  return (
    <div className={'style-guide__icon-container'}>
      <div className={iconContainerClassName} style={{ fontSize: 0 }}>
        {children}
      </div>
      <p>{name}</p>
    </div>
  )
}

export default Icon
