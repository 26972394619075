import * as React from 'react'

function MinusIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M4.167 10h11.667' stroke='#fff' strokeWidth={1.667} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default MinusIcon
