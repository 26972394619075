import * as React from 'react'

function EyeIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 11.667a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334z'
        stroke='#373737'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.334 10c-2.223 3.89-5 5.833-8.334 5.833-3.333 0-6.11-1.944-8.333-5.833 2.222-3.89 5-5.833 8.333-5.833 3.334 0 6.111 1.944 8.334 5.833z'
        stroke='#2C3E50'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default EyeIcon
