import React, { useRef } from 'react'
import { IChartProps, IChartsProps, IControlChartProps } from './ProfileChart'
import { ProfileConfig } from './config'
import { Chart } from 'chart.js'

const withControl = (WrappedComponent: React.FC<IControlChartProps & IChartsProps & IChartProps>) => {
  return (props: IControlChartProps & IChartsProps) => {
    const chartRef = useRef<Chart<any>>()
    const timerId = React.useRef<ReturnType<typeof setTimeout>>()
    const [currentMinPoint, setCurrentMinPoint] = React.useState<number>()
    const [currentMaxPoint, setCurrentMaxPoint] = React.useState<number>()

    React.useEffect(() => {
      timerId.current = setInterval(() => {
        if (
          currentMaxPoint !== undefined &&
          currentMinPoint !== undefined &&
          props?.finishPoint !== undefined &&
          props?.startPoint !== undefined
        ) {
          const isZoomInThreshold =
            (currentMaxPoint - currentMinPoint) / (props?.finishPoint - props?.startPoint) >
            1 + ProfileConfig.requestThreshold
          const isZoomOutThreshold =
            (currentMaxPoint - currentMinPoint) / (props?.finishPoint - props?.startPoint) <
            1 / (1 + ProfileConfig.requestThreshold)
          const isPanPlusThreshold = props?.finishPoint > currentMaxPoint
          const isPanMinusThreshold = props?.startPoint < currentMinPoint
          if (isZoomInThreshold || isZoomOutThreshold || isPanPlusThreshold || isPanMinusThreshold) {
            props?.setRequestRange({
              start_distance__km: props?.requestRange?.start_distance__km !== undefined ? props?.startPoint : undefined,
              end_distance__km: props?.requestRange?.end_distance__km !== undefined ? props?.finishPoint : undefined,
            })
          }
        }
        timerId.current && clearInterval(timerId.current)
      }, 500)
      return () => {
        timerId.current && clearInterval(timerId.current)
      }
    }, [props?.finishPoint, props?.startPoint])

    React.useEffect(() => {
      if (props?.panPlus !== undefined && props?.panPlus > 0) chartRef?.current?.pan({ x: 100 })
    }, [props?.panPlus])

    React.useEffect(() => {
      if (props?.panMinus !== undefined && props?.panMinus > 0) chartRef?.current?.pan({ x: -100 })
    }, [props?.panMinus])

    React.useEffect(() => {
      if (props?.zoomPlus !== undefined && props?.zoomPlus > 0) chartRef?.current?.zoom(1.1)
    }, [props?.zoomPlus])

    React.useEffect(() => {
      if (props?.zoomMinus !== undefined && props?.zoomMinus > 0) chartRef?.current?.zoom(0.9)
    }, [props?.zoomMinus])

    React.useEffect(() => {
      if (props?.startPointToZoom !== undefined && props?.startPoint !== undefined) {
        const range =
          ((chartRef?.current?.scales?.x as any)?.end || props?.maxPoint!) -
          ((chartRef?.current?.scales?.x as any)?.start || props?.minPoint!)
        const scale = (chartRef?.current?.chartArea.width || 0) / range
        const startAdjustment =
          (chartRef?.current?.scales?.x as any)?.start - (chartRef?.current?.scales.x as any)?._range.min
        const endAdjustment =
          (chartRef?.current?.scales?.x as any)?.end - (chartRef?.current?.scales.x as any)?._range.max
        chartRef?.current?.zoomRect(
          {
            x:
              chartRef?.current?.chartArea.left +
              (props?.startPointToZoom - props?.startPoint) * scale -
              startAdjustment * scale,
            y: 0,
          },
          {
            x: (chartRef?.current?.chartArea.right || 0) - endAdjustment * scale,
            y: 0,
          },
        )
      }
    }, [props?.startPointToZoom])

    React.useEffect(() => {
      if (props?.finishPointToZoom !== undefined && props?.startPoint !== undefined) {
        const range =
          ((chartRef?.current?.scales?.x as any)?.end || props?.maxPoint!) -
          ((chartRef?.current?.scales?.x as any)?.start || props?.minPoint!)
        const scale = (chartRef?.current?.chartArea.width || 0) / range
        const startAdjustment =
          (chartRef?.current?.scales?.x as any)?.start - (chartRef?.current?.scales.x as any)?._range.min
        chartRef?.current?.zoomRect(
          { x: (chartRef?.current?.chartArea.left || 0) - startAdjustment * scale, y: 0 },
          {
            x:
              chartRef?.current?.chartArea.left +
              (props?.finishPointToZoom - props?.startPoint) * scale -
              startAdjustment * scale,
            y: 0,
          },
        )
      }
    }, [props?.finishPointToZoom])

    return (
      <WrappedComponent
        {...props}
        chartRef={chartRef}
        setCurrentMaxPoint={setCurrentMaxPoint}
        setCurrentMinPoint={setCurrentMinPoint}
      />
    )
  }
}

export default withControl
