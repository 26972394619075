import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapLayers, setMapLayers } from 'store/mapSlice'
import { getGISConfiguration } from 'services/apiRequests'
import { selectProjectID } from 'store/projectSlice'

const useGetGISConfiguration = () => {
  const dispatch = useDispatch()
  const layers = useSelector(selectMapLayers)
  const projectId = useSelector(selectProjectID) as string

  const tryToGetGISConfiguration = async () => {
    await getGISConfiguration().then((response) => {
      dispatch(setMapLayers(response.data))
    })
  }

  React.useEffect(() => {
    if (!layers && projectId) {
      tryToGetGISConfiguration().then()
    }
  }, [projectId])

  return
}

export default useGetGISConfiguration
