import { configureStore } from '@reduxjs/toolkit'

import projectsListSlice from './projectsListSlice'
import projectSlice from './projectSlice'
import commonSlice from './commonSlice'
import mapSlice from './mapSlice'
import loginSlice from './loginSlice'
import model10Slice from './model10Slice'
import hydraulicSlice from './hydraulicSlice'
import economicSlice from './economicSlice'
import optimizationSlice from './optimizationSlice'
import autoCSSlice from './autoCSSlice'
import DNLayersSlice from './DNLayersSlice'
import profileSlice from './profileSlice'
import directorySlice from './directorySlice'
import schemeSlice from './canvasSchemeSlice'
import taskSlice from './tasksSlice'
import validationSlice from './validationSlice'

const store = configureStore({
  reducer: {
    projectsList: projectsListSlice,
    common: commonSlice,
    project: projectSlice,
    map: mapSlice,
    login: loginSlice,
    model10: model10Slice,
    hydraulic: hydraulicSlice,
    economic: economicSlice,
    optimization: optimizationSlice,
    autoCS: autoCSSlice,
    DNLayers: DNLayersSlice,
    profile: profileSlice,
    directory: directorySlice,
    scheme: schemeSlice,
    tasks: taskSlice,
    validation: validationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>

export default store
