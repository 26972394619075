import React from 'react'

import { createIndicesPipesCalculationHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, setDirectoryItem } from 'store/directorySlice'
import { setBlockingWindow } from 'store/commonSlice'
import {
  DiameterToPipesCalculationIndices,
  IErrorParams,
  PipesCalculationIndices,
  PressureToPipesCalculationIndices,
} from 'store/types'

const useCreateIndicesPipeCalculationVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem)
  const [result, setResult] = React.useState(false)

  const tryToCreateIndicesPipeCalculationVersion = async () => {
    if (directoryItem) {
      const items: DiameterToPipesCalculationIndices = {}
      Object.entries(directoryItem).forEach((value) => {
        const [diameter, item] = value as [keyof DiameterToPipesCalculationIndices, PressureToPipesCalculationIndices]
        Object.entries(item).forEach((value2) => {
          const [pressure, attrs] = value2 as [keyof PressureToPipesCalculationIndices, PipesCalculationIndices]
          const { modified, ...newItem } = attrs
          if (modified) {
            if (items[diameter])
              //@ts-ignore
              items[diameter][pressure] = newItem
            else
              items[diameter] = {
                [pressure]: newItem,
              }
          }
        })
      })
      await createIndicesPipesCalculationHandbookVersion(items)
        .then((res) => {
          dispatch(setDirectoryItem(res.data))
          setResult(true)
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Ошибка сохранения',
                  message: (
                    <div className='error-window__errors-list-container'>
                      {err.response.data.map((item: any, index: number) => {
                        if (Object.keys(item).length > 0) {
                          return (
                            <div key={index}>
                              {'При сохранении возникли следующие ошибки:'}
                              <ul className='error-window__errors-list' key={index}>
                                {Object.entries(item).map(([key, value], ix) => {
                                  return <li key={ix}>{`${key}: ${value}`}</li>
                                })}
                              </ul>
                            </div>
                          )
                        }
                      })}
                    </div>
                  ),
                } as IErrorParams,
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              }),
            )
        })
    }
  }
  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateIndicesPipeCalculationVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateIndicesPipeCalculationVersion
