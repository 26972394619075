import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTaskSettings } from 'store/optimizationSlice'
import { getOptimizationProperties } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'

const useGetCalcProps = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToGetCalcProps = async () => {
    if (trunkPipelineId && projectId) {
      await getOptimizationProperties(projectId, trunkPipelineId)
        .then((response) => {
          dispatch(setTaskSettings(response.data))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
    }
  }

  React.useEffect(() => {
    if (projectId && trunkPipelineId) tryToGetCalcProps().then()
  }, [projectId, trunkPipelineId])

  return
}

export default useGetCalcProps
