import * as React from 'react'

function AutoCSIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_4009_6975)' strokeOpacity={0}>
        <path
          d='M-0.0195312 20L3.28047 12.95H4.78047L8.10047 20H6.25047L5.40047 18.06L6.11047 18.57H1.97047L2.67047 18.06L1.83047 20H-0.0195312ZM4.02047 14.85L2.85047 17.64L2.57047 17.17H5.50047L5.25047 17.64L4.07047 14.85H4.02047Z'
          fill='#0074BC'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 10C16 13.3137 13.3137 16 10 16C9.44261 16 8.90298 15.924 8.39089 15.7818L9.49207 17.9841C9.66003 17.9947 9.82939 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 10.3387 2.02104 10.6724 2.06189 11H4.08296C4.0284 10.6748 4 10.3407 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z'
          fill='#0074BC'
        />
        <path
          d='M6 12.6243V7.7756H7.12792V9.72196H7.69497L7.52239 9.84576L8.67497 7.7756H9.90767L8.5332 10.1002L8.08327 9.7701C8.38733 9.82971 8.63593 9.94663 8.82905 10.1209C9.02629 10.2905 9.19475 10.5335 9.33446 10.8499L10.1296 12.6243H8.90301L8.33597 11.2625C8.25379 11.0745 8.15312 10.9416 8.03396 10.8636C7.91891 10.7811 7.76482 10.7398 7.5717 10.7398H7.12792V12.6243H6Z'
          fill='#0074BC'
        />
        <path
          d='M12.5577 12.7C12.0852 12.7 11.6764 12.5968 11.3312 12.3905C10.9902 12.1841 10.7251 11.893 10.5361 11.517C10.3512 11.141 10.2587 10.7009 10.2587 10.1965C10.2587 9.69216 10.3512 9.25428 10.5361 8.88289C10.7251 8.50692 10.9902 8.21577 11.3312 8.00944C11.6764 7.80311 12.0852 7.69995 12.5577 7.69995C12.8577 7.69995 13.1309 7.7458 13.3775 7.8375C13.624 7.92462 13.8315 8.05071 14 8.21577L13.661 9.21302C13.472 9.06171 13.2974 8.95626 13.1371 8.89665C12.981 8.83246 12.8022 8.80036 12.6009 8.80036C12.2311 8.80036 11.9455 8.92187 11.7442 9.16488C11.5469 9.4033 11.4483 9.74718 11.4483 10.1965C11.4483 10.6504 11.5469 10.9989 11.7442 11.2419C11.9455 11.4803 12.2311 11.5995 12.6009 11.5995C12.8022 11.5995 12.981 11.5697 13.1371 11.5101C13.2974 11.4459 13.472 11.3382 13.661 11.1869L14 12.1841C13.8315 12.3492 13.624 12.4776 13.3775 12.5693C13.1309 12.6564 12.8577 12.7 12.5577 12.7Z'
          fill='#0074BC'
        />
        <circle cx='17' cy='3' r='3' fill='#00BC84' />
      </g>
      <defs>
        <clipPath id='clip0_4009_6975'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AutoCSIcon
