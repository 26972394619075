import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import './KebabButton.scss'
import DotsIcon from '../../images/DotsIcon'

interface IKebabButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'light' | 'dark' | 'light-btn'
  text?: string
  children?: any
}
export const KebabButton: React.FC<IKebabButton> = ({ mode = 'light', text, children, ...props }) => {
  const [menuVisibility, setMenuVisibility] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const onClickDotsButton = (e: any) => {
    e.stopPropagation()
    setMenuVisibility(true)
  }
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef?.current?.contains(event.target)) {
        event.target.click()
      }
      setMenuVisibility(false)
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className={'kebab-button__container'}>
      <button
        className={classnames('kebab-button', menuVisibility ? 'visible' : 'hidden', mode)}
        onClick={onClickDotsButton}
        {...props}
      >
        {text}
        <DotsIcon className={classnames('kebab-img', mode)} />
      </button>
      {menuVisibility && (
        <div className={classnames('popup-menu__container', menuVisibility, mode)} ref={menuRef}>
          {children}
        </div>
      )}
    </div>
  )
}
