import { definitions } from 'generated/apiTypes'

interface IPipeTypesColumn {
  title: string | JSX.Element
  type: 'Text' | 'Number' | 'Option'
  key: keyof definitions['HandbookPipeType']
  editable: boolean
  options?: any[]
  displayedField?: string
  sticky?: boolean
}

export const columns: IPipeTypesColumn[] = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
    sticky: true,
  },
  {
    title: 'Внешний диаметр, мм',
    type: 'Option',
    key: 'diameter__mm',
    editable: true,
    options: [
      '1420',
      '1220',
      '1020',
      '920',
      '820',
      '720',
      '630',
      '530',
      '426',
      '375',
      '325',
      '275',
      '225',
      '159',
      '114',
      '89',
      '57',
    ],
    displayedField: '',
  },
  {
    title: 'Толщина стенки, мм',
    type: 'Number',
    key: 'thickness__mm',
    editable: true,
  },
  {
    title: 'Шероховатость, мм',
    type: 'Number',
    key: 'roughness__mm',
    editable: true,
  },
  {
    title: 'Макс. раб. давление, МПа',
    type: 'Number',
    key: 'pressure__MPa',
    editable: true,
  },
  {
    title: 'Марка стали',
    type: 'Option',
    key: 'steel_name',
    editable: true,
    options: ['K 60', 'K 65', 'X 70', 'X 80'],
  },
]

export const readOnlyColumns = structuredClone(columns)
  .filter((item) => item.key !== 'name')
  .map((column) => {
    column.editable = false
    if (column.type === 'Option') column.type = 'Text'
    return column
  })
