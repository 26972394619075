import React from 'react'

export const columns = (editMode: boolean): any[] => [
  {
    title: 'Статья затрат',
    key: 'title',
    type: 'Column',
    editable: false,
  },
  {
    title: <>Базовая стоимость</>,
    type: 'Number',
    editable: editMode,
    key: `base_cost`,
    noEmpty: true,
  },
]
