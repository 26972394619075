import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAutoCSCalculationProperties } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { selectTaskSettings, setTaskSettings } from 'store/autoCSSlice'
import { IErrorParams } from 'store/types'

const useGetAutoCSSettings = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const taskSettings = useSelector(selectTaskSettings)

  const tryToGetAutoCSCalculationProperties = async () => {
    if (trunkPipelineId && projectId) {
      await getAutoCSCalculationProperties(projectId, trunkPipelineId)
        .then((response) => {
          dispatch(
            setTaskSettings({
              ...response.data,
              hydro_calculation_properties: {
                ...response.data.hydro_calculation_properties,
                use_new_library: true,
              },
            }),
          )
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
    }
  }

  React.useEffect(() => {
    !taskSettings && tryToGetAutoCSCalculationProperties().then()
  }, [])
}

export default useGetAutoCSSettings
