import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow } from 'store/commonSlice'
import { PipeParamsWindow } from './PipeParamsWindow/PipeParamsWindow'
import { resetBlockingWindow } from 'store/commonSlice'
import { DeleteWindow } from './DeleteWindow'
import { EditProjectNameWindow } from './EditProjectNameWindow'
import { ErrorWindow } from './ErrorWindow/ErrorWindow'
import { DiscardChangesWindow } from './DiscardChangesWindow'
import { StopTaskWindow } from './StopTaskWindow'
import { CreateTaskConfirmWindow } from './CreateTaskConfirmWindow'
import { DNLayersTaskWindow } from './DNLayersTaskWindow'
import SelectWindow from './SelectWindow/SelectWindow'
import CalcProgressWindow from './CalcProgressWindow/CalcProgressWindow'
import { ExportProjectToNextGISWindow } from './ExportProjectToNextGISWindow'
import { ExportProjectToNextGISSuccessWindow } from './ExportProjectToNextGISSuccessWindow'

export const BlockingWindow: React.FC = () => {
  const blockingWindow = useSelector(selectBlockingWindow)
  const dispatch = useDispatch()
  const closeWindow = () => {
    dispatch(resetBlockingWindow())
  }

  if (blockingWindow.type === 'SELECT') return <SelectWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'EDIT_PIPE_TYPE') return <PipeParamsWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'DELETE_CONFIRM') return <DeleteWindow closeWindow={closeWindow} />
  if (blockingWindow.type == 'EDIT_PROJECT_NAME') return <EditProjectNameWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'CALC_PROGRESS') return <CalcProgressWindow />
  if (blockingWindow.type === 'ERROR') return <ErrorWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'DISCARD_CHANGES') return <DiscardChangesWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'STOP_TASK_CONFIRM') return <StopTaskWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'DN_LAYERS_TASK') return <DNLayersTaskWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'CREATE_TASK_CONFIRM') return <CreateTaskConfirmWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'EXPORT_PROGRESS') return <ExportProjectToNextGISWindow />
  if (blockingWindow.type === 'EXPORT_DONE') return <ExportProjectToNextGISSuccessWindow closeWindow={closeWindow} />
  return <></>
}
