import React, { useMemo } from 'react'
import { definitions } from 'generated/apiTypes'
import { useGetAreaLayingCategories } from './hooks/useGetAreaLayingCategories'
import DirectoryWrapper from '../DirectoryWrapper'
import { getHandbookName } from '../../../MainPage/Directories/directories'
import { Table } from '../../../../components/Table/Table/Table'
import { columns } from './tableConfig'
import './directoryAreaLayingCategories.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, setModified, updateItemFieldById } from '../../../../store/directorySlice'
import useCreateOffsetsVersion from '../Offsets/hooks/useCreateOffsetsVersion'
import useCreateAreaLayingCategoriesVersion from './hooks/useCreateAreaLayingCategoriesVersion'

interface IDirectoryAreaLayingCategoriesProps {
  directoryKey: keyof definitions['Handbooks']
}

export const DirectoryAreaLayingCategories: React.FC<IDirectoryAreaLayingCategoriesProps> = ({ directoryKey }) => {
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookAreaLayingCategories'][]
  useGetAreaLayingCategories(requestData)
  const saveResult = useCreateAreaLayingCategoriesVersion(saveData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])
  const dataSource = useMemo(() => {
    return directoryItems.map((item) => {
      return {
        id: item.id,
        area_type: item.area_type.name,
        underground_laying: item.underground_laying,
        ground_laying: item.ground_laying,
        upground_laying: item.upground_laying,
      }
    })
  }, [directoryItems])

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }
  const onSaveChanges = () => {
    setSaveData(!saveData)
  }
  const onSaveItemField = async (id: string, value: number, fieldName: string) => {
    dispatch(
      updateItemFieldById({
        id,
        value,
        fieldName,
      }),
    )
    dispatch(setModified(id))
    setIsModified(true)
  }
  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      isModified={isModified}
      title={getHandbookName(directoryKey)}
    >
      <div className={'directory__content'}>
        <Table
          className={'directory-table__container area-laying-categories-table__container'}
          columns={columns(editMode)}
          dataSource={dataSource}
          setValue={onSaveItemField}
        />
      </div>
    </DirectoryWrapper>
  )
}
