import { EditElementPanel } from '../editElementPanel'
import NumberInput from 'components/Input/numberInput'
import React, { useEffect } from 'react'
import TextInput from 'components/Input/textInput'
import { definitions } from 'generated/apiTypes'
import { getCoords, round } from '@turf/turf'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLoadingHeightNodeID,
  selectProject,
  selectProjectID,
  setLoadingHeightNodeID,
  setPipelines,
  setUpdatedElement,
  updateHeatingStation,
} from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import {
  getHeatingStationByIDRequest,
  getNodesByProjectIDRequest,
  getPipelineByIDRequest,
  updateGISHeight,
  updateHeatingStationByIDRequest,
} from 'services/apiRequests'
import {
  resetDragNodeAlong,
  resetMapSubmode,
  selectDragNodeAlong,
  selectMapMode,
  selectMapSubmode,
  setDragNodeAlong,
  setMapNodes,
  setMapSubmode,
} from 'store/mapSlice'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import CoordinateInput from 'components/Input/coordinateInput'
import classNames from 'classnames'
import LockedIcon from 'images/LockedIcon'
import UnlockedIcon from 'images/UnlockedIcon'
import { IDeleteConfirmParams } from '../../../../../store/types'
import axios, { AxiosResponse } from 'axios'
// @ts-ignore
import { ReactComponent as ArrowResizeIcon } from 'images/icn-arrow-resize.svg'
import classnames from 'classnames'

interface IHeatingStationEditPanel {
  object?: definitions['HeatingStationRetrieve']
}
export const HeatingStationEditPanel: React.FC<IHeatingStationEditPanel> = ({ object }) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const project = useSelector(selectProject)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const mapMode = useSelector(selectMapMode)
  const active = useSelector(selectMapSubmode) === 'dragAlong'
  const dragNodeInfo = useSelector(selectDragNodeAlong)
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateHeatingStation({
        ...object,
        ...updatedField,
      } as definitions['HeatingStation']),
    )
  }
  const setValue = (value: any, label: string) => {
    let updatedValue
    if (label === 'central_point') {
      updatedValue = {
        ...object?.central_point,
        coordinates: value,
      }
    } else updatedValue = value
    updateStore({ [label]: updatedValue })
    dispatch(
      setUpdatedElement({
        elementID: object?.id as string,
        type: 'HEATING_STATIONS',
        params: { [label]: updatedValue },
      }),
    )
  }
  const deleteObj = () => {
    dispatch(
      setBlockingWindow({
        type: 'DELETE_CONFIRM',
        params: {
          objectType: 'HEATING_STATIONS',
          objectID: object?.id,
          objectName: object?.name,
        } as IDeleteConfirmParams,
      }),
    )
  }
  const setHeight = (value: number) => {
    updateStore({ height__m: round(value) })
    if (projectId && object?.id) {
      updateHeatingStationByIDRequest(projectId, object.id, {
        height__m: round(value),
      } as definitions['HeatingStationUpdate']).then(() => {
        getHeatingStationByIDRequest(projectId, object.id).then((res) => {
          dispatch(updateHeatingStation(res.data))
        })
        getNodesByProjectIDRequest(projectId).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
        // @ts-ignore
        const req: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] = project?.detail?.trunk_pipeline.pipelines
          .filter((item) => item.start_node.id === object.id || item.end_node.id === object.id)
          ?.map((pipeline) => getPipelineByIDRequest(projectId, pipeline.id))
        axios.all(req).then((resArr) => {
          dispatch(setPipelines(resArr.map((res) => res.data)))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectId && object?.id) {
      if (value) dispatch(setLoadingHeightNodeID(object.id))
      updateHeatingStationByIDRequest(projectId, object.id, {
        allow_to_request_gis_height: value,
      } as definitions['ReductionNodeUpdate'])
        .then(() => {
          getHeatingStationByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateHeatingStation(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectId && object?.id) {
      dispatch(setLoadingHeightNodeID(object.id))
      updateGISHeight(projectId, object.id)
        .then(() => {
          getHeatingStationByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateHeatingStation(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const dragNodeAlong = () => {
    if (active) {
      dispatch(resetMapSubmode())
      dispatch(resetDragNodeAlong())
    } else {
      dispatch(setMapSubmode('dragAlong'))
      dispatch(setDragNodeAlong({ id: object?.id as string, type: 'HEATING_STATIONS' }))
    }
  }
  useEffect(() => {
    return () => {
      active && dispatch(resetMapSubmode())
      dispatch(resetDragNodeAlong())
    }
  }, [])
  return (
    <EditElementPanel title={'Узел нагрева газа'} deleteAllow deleteObj={deleteObj}>
      <div className={'edit-element-panel__table'}>
        <div className={'common-row'}>
          <TextInput value={object?.name || ''} fieldName={'name'} label={'Название'} setValue={setValue} />
        </div>
        <div className={'common-row'}>
          <hr />
        </div>
        <div className={'common-row edit-element-panel__lock-row'}>
          <div className={'header3 primary-text'}>Координаты</div>
          {mapMode !== 'edit' && (
            <button
              className={classNames('edit-element-panel__lock-btn square-btn-28', object?.lock && 'locked')}
              onClick={() => setValue(!object?.lock, 'lock')}
            >
              {object?.lock ? <LockedIcon /> : <UnlockedIcon />}
            </button>
          )}
        </div>
        <CoordinateInput
          id={object?.id}
          field={'latitude'}
          label={'lat'}
          disabled={mapMode !== 'edit' && object?.lock}
          value={
            dragNodeInfo?.lat
              ? parseFloat(dragNodeInfo?.lat.toFixed(6))
              : object?.central_point
              ? getCoords(object?.central_point as any)[1]
              : ''
          }
          setValue={(value) => setValue([getCoords(object?.central_point as any)[0], value], 'central_point')}
        />
        <CoordinateInput
          id={object?.id}
          field={'longitude'}
          label={'lon'}
          disabled={mapMode !== 'edit' && object?.lock}
          value={
            dragNodeInfo?.lng
              ? parseFloat(dragNodeInfo?.lng.toFixed(6))
              : object?.central_point
              ? getCoords(object?.central_point as any)[0]
              : ''
          }
          setValue={(value) => setValue([value, getCoords(object?.central_point as any)[1]], 'central_point')}
        />
        <NumberInput
          unit={'м'}
          value={object?.height__m || 0}
          label={'Высотная отметка'}
          fieldName={'height__m'}
          setValue={setHeight}
          loading={loadingHeightNodeID === object?.id}
          signed
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={object?.gis_height_error}
          disabled={object?.allow_to_request_gis_height || (mapMode !== 'edit' && object?.lock)}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={object?.allow_to_request_gis_height || false}
            disabled={mapMode !== 'edit' && object?.lock}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {object?.allow_to_request_gis_height && (
              <Button
                className={'edit-element-panel__redo-btn'}
                mode={'secondary'}
                onClick={updateHeight}
                disabled={mapMode !== 'edit' && object?.lock}
              >
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
        <div className={'common-row display-flex space-between center'}>
          <div className={'header3 primary-text'}>Положение относительно трубы</div>
          <Button className={classnames('square-btn-36', { active })} mode={'secondary'} onClick={dragNodeAlong}>
            <ArrowResizeIcon />
          </Button>
        </div>
        <NumberInput
          label={'Расстояние до начальной точки'}
          value={
            dragNodeInfo?.distance_to_source ||
            (object?.distance_to_source__m && (parseFloat(object.distance_to_source__m!) / 1000).toFixed(2))
          }
          unit={'км'}
          disabled
        />
        <div />
        <div className={'common-row'}>
          <hr />
        </div>
        <div className={'common-row'}>
          <div className={'header3 primary-text'}>Параметры</div>
        </div>
        <NumberInput
          id={object?.id}
          key={`${object?.id}_pressure__MPa`}
          unit={'МПа'}
          fieldName={'pressure__MPa'}
          value={object?.pressure__MPa}
          label={'Давление на выходе'}
          setValue={setValue}
          signed
        />
        <NumberInput
          id={object?.id}
          key={`${object?.id}_temperature__C`}
          fieldName={'temperature__C'}
          unit={'°C/МПа'}
          value={object?.temperature__C}
          label={'Температура на выходе'}
          setValue={setValue}
          signed
        />
      </div>
    </EditElementPanel>
  )
}
