import * as React from 'react'

function FullScreenIcon(props) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.6665 5.33317V3.99984C2.6665 3.64622 2.80698 3.30708 3.05703 3.05703C3.30708 2.80698 3.64622 2.6665 3.99984 2.6665H5.33317'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.6665 10.6665V11.9998C2.6665 12.3535 2.80698 12.6926 3.05703 12.9426C3.30708 13.1927 3.64622 13.3332 3.99984 13.3332H5.33317'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6665 2.6665H11.9998C12.3535 2.6665 12.6926 2.80698 12.9426 3.05703C13.1927 3.30708 13.3332 3.64622 13.3332 3.99984V5.33317'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6665 13.3332H11.9998C12.3535 13.3332 12.6926 13.1927 12.9426 12.9426C13.1927 12.6926 13.3332 12.3535 13.3332 11.9998V10.6665'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FullScreenIcon
