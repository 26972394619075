import { ChartOptions } from 'chart.js'
import { profileRound } from '../utils'
import { BarChartConfig, RouteLayingMethod } from '../config'

export const seriesColor = '#0074BC'

export const options = (
  axisYLabel: string,
  axisXLabel: string,
  minRange: number,
  min: number,
  max: number,
  bars: string[],
  setStartPoint: (startPoint: number) => void,
  setFinishPoint: (finishPoint: number) => void,
  setBars: (bars: string[]) => void,
  setPopupPosition: (position: { left: number; top: number }) => void,
  setLayingType: (value: { type: RouteLayingMethod; sectionId: string }) => void,
  config: { [key: string]: BarChartConfig },
  isLegendVisible: boolean,
  isTooltipEnabled: boolean,
): ChartOptions<'bar'> => {
  return {
    //aspectRatio: datasetLength > 80 ? 1.2 : 3,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    onClick: !isTooltipEnabled
      ? function (e, items, chart) {
          const points = chart.getElementsAtEventForMode(e as any, 'nearest', { intersect: true }, true)
          if (points?.length > 0) {
            setPopupPosition({ left: e.x || 0, top: e.y || 0 })
            setLayingType({
              type: (points[0].element as any)?.$context?.raw.y,
              sectionId: (points[0].element as any)?.$context?.raw.sectionId,
            })
          }
        }
      : () => null,
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          onPan: function ({ chart }) {
            setStartPoint(profileRound((chart.scales?.x as any)?.start as number)!)
            setFinishPoint(profileRound((chart.scales?.x as any)?.end as number)!)
          },
        },
        zoom: {
          mode: 'x',
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          onZoom: function ({ chart }) {
            setStartPoint(profileRound((chart.scales?.x as any)?.start as number)!)
            setFinishPoint(profileRound((chart.scales?.x as any)?.end as number)!)
          },
        },
        limits: {
          x: { min, max, minRange },
          y: { min: 'original', max: 'original' },
        },
      },
      tooltip: {
        enabled: isTooltipEnabled,
        displayColors: false,
        cornerRadius: 4,
        padding: 8,
        caretSize: 8,
        yAlign: 'center',
        callbacks: {
          title: function () {
            return ''
          },
          label: function (context) {
            const x = context.parsed.x
            const y = (config as any)[(context.raw as any).y]?.label
            const ad = (context.raw as any).value
            return ad && bars.includes('admDivision')
              ? [`Тип слоя: ${y}`, `Точка трубопровода: ${x} км`, `Ад: ${ad}`]
              : [`Тип слоя: ${y}`, `Точка трубопровода: ${x} км`]
          },
        },
        backgroundColor: '#E4E9EF',
        bodyColor: '#373737',
        bodyFont: { size: 12, weight: '600', family: 'Mulish', lineHeight: '170%' },
        filter: function (tooltipItem) {
          return (tooltipItem.raw as any)?.y !== 'admDivision'
        },
      },
      legend: {
        display: isLegendVisible,
        align: 'start',
        position: 'bottom',
        labels: {
          padding: 20,
          boxWidth: 20,
          font: { size: 12 },
          generateLabels: (chart: any) => {
            setBars(chart.data.labels)
            return chart.data.labels
              .filter((item: string) => item !== 'admDivision')
              .map((label: string) => ({
                text: `${(config as any)[label]?.label} (${(config as any)[label]?.shortLabel})`,
                fillStyle: chart.data.datasets[0].backgroundColor(undefined, label),
                pointStyle: 'rect',
                fontColor: '#8896A4',
                lineWidth: 0,
              }))
          },
        },
        onClick: null,
      } as any,
    },
    indexAxis: 'y',
    scales: {
      y: {
        border: { display: false, dash: [4, 4] },
        grid: {
          color: '#E4E9EF',
          display: true,
        },
        ticks: {
          padding: 4,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
          callback: function (value) {
            return (config as any)[this.getLabelForValue(value as number)]?.shortLabel
          },
        },
        title: {
          display: true,
          text: axisYLabel,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
        },
      },
      x: {
        type: 'linear',
        grid: {
          color: () => {
            return '#E4E9EF'
          },
        },
        beginAtZero: false,
        border: { display: true },
        ticks: {
          padding: 4,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
          autoSkip: true,
          minRotation: 0,
          stepSize: 0.01,
          autoSkipPadding: 5,
          callback: function (value, i, arr) {
            return profileRound(arr[i].value)
          },
        },
        title: {
          display: true,
          text: axisXLabel,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
        },
      },
    },
  }
}
