import { distance, Feature, getCoords, greatCircle, LineString, lineString, Position } from '@turf/turf'
import { last } from '../utils'

const pointDelta = 100
const MaxNPoints = 50
export const convertToGreatCircle = (
  line: ([number, number] | Position)[],
  gap: number = pointDelta,
  maxNPoints: number = MaxNPoints,
) => {
  /**
   * Преобразует прямой путь в дугу большого круга
   * @param line Прямой путь
   * @param gap Расстояние между точками в км (по умолчанию 100)
   * @param maxNPoints Максимальное кол-во точек в дуге
   */
  const greatCircleRoute = greatCircle(line[0], line[1], {
    npoints: Math.min(Math.round(distance(line[0], line[1]) / gap), maxNPoints),
  })
  if (greatCircleRoute.geometry.type === 'MultiLineString') {
    const part1 = getCoords(greatCircleRoute)[0]
    let part2 = getCoords(greatCircleRoute)[1]
    const startLng = last(part1)[0]
    const endLng = part2[0][0]
    if (endLng - startLng >= 180) {
      part2 = part2.map((coord: [number, number]) => [coord[0] - 360, coord[1]])
    } else if (endLng - startLng < 180) {
      part2 = part2.map((coord: [number, number]) => [coord[0] + 360, coord[1]])
    }
    return lineString([...part1, ...part2])
  }
  return greatCircleRoute as Feature<LineString>
}
