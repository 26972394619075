import React from 'react'
import { MultiSelectTable } from 'components/Table/MultiSelectTable/MultiSelectTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedItem,
  deselectSelectedItemById,
  selectDirectoryItems,
  selectSelectedItems,
  updateItemFieldById,
  setModified,
} from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { generateUID } from 'pages/utils'
import { setBlockingWindow } from 'store/commonSlice'
import './directoryLayers.scss'
import useGetLayers from './hooks/useGetLayers'
import useCreateLayersVersion from './hooks/useCreateLayersVersion'
import { HandbookAreaType, IErrorParams } from 'store/types'

interface IDirectoryLayersProps {
  directoryKey: keyof definitions['Handbooks']
}

const newItemProperties = {
  created: true,
  modified: false,
  type: 'Дороги' as definitions['HandbookAreaType']['type'],
  is_restricted: false,
}

const DirectoryLayers: React.FC<IDirectoryLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectSelectedItems)
  const directoryItems = useSelector(selectDirectoryItems)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const [newItem, setNewItem] = React.useState<HandbookAreaType>({
    id: '',
    name: '',
    ...newItemProperties,
  })
  useGetLayers(requestData)
  const saveResult = useCreateLayersVersion(saveData)

  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  React.useEffect(() => {
    setNewItem({ id: generateUID(), name: `Слой ${directoryItems.length + 1}`, ...newItemProperties })
  }, [directoryItems.length])

  const onItemSelect = (id: string) => {
    const itemToAdd = directoryItems?.find((item) => item.id === id)
    if (itemToAdd) dispatch(addSelectedItem(itemToAdd))
  }

  const onItemDeselect = (id: string) => {
    dispatch(deselectSelectedItemById(id))
  }

  const onSaveItemField = async (id: string, value: string | number, fieldName: string) => {
    const uniqueFiled = 'name'
    if (
      fieldName === uniqueFiled &&
      (directoryItems as HandbookAreaType[])?.some((item) => item[uniqueFiled] === value)
    ) {
      dispatch(
        setBlockingWindow({
          type: 'ERROR',
          params: {
            title: 'Ошибка заполнения',
            message: 'Значение поля “Название” должно быть уникальным',
          } as IErrorParams,
        }),
      )
      const oldItem = (directoryItems as HandbookAreaType[]).find((item) => item.id === id)
      if (oldItem) {
        await dispatch(updateItemFieldById({ id, value, fieldName }))
        dispatch(updateItemFieldById({ id, value: oldItem[uniqueFiled], fieldName }))
      }
    } else {
      dispatch(updateItemFieldById({ id, value, fieldName }))
      dispatch(setModified(id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
      setIsModified={setIsModified}
      newItemToAdd={newItem as definitions['HandbookAreaType']}
    >
      <div className={'directory__content'}>
        <MultiSelectTable
          selectedItems={selectedItems}
          columns={columns}
          editMode={editMode}
          dataSource={directoryItems}
          onItemSelect={onItemSelect}
          onItemDeselect={onItemDeselect}
          setValue={onSaveItemField}
          className={'directory-table__container layers-table__container'}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryLayers
