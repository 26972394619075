import React from 'react'
import Dropzone, { IDropzoneProps } from 'components/Dropzone'
import FileUploadedIcon from 'images/FileUploadedIcon'
import CloseIcon from 'images/CloseIcon'

interface IFileDropzoneProps {
  label: string
  file: File | undefined
  setFile(file: File | undefined): void
}

const FileDropzone: React.FC<IFileDropzoneProps & IDropzoneProps> = ({ label, file, setFile, ...props }) => {
  const onDropFile = (files: File[]) => {
    setFile(files[0])
  }

  const deleteFile = () => {
    setFile(undefined)
  }

  return !file ? (
    <Dropzone onDrop={onDropFile} label={label} {...props} />
  ) : (
    <div className={'dropzone__container'}>
      <div className={'field-label-text'}>{label}</div>
      <div className={'dropzone disabled'}>
        <FileUploadedIcon />
        <div className='file-dropzone__filename normal-text'>{file.name}</div>
        <button className={'file-dropzone__close-btn'} onClick={deleteFile}>
          <CloseIcon className={'file-dropzone__close-icn'} />
        </button>
      </div>
    </div>
  )
}

export default FileDropzone
