import { elementType } from '../store/types'
import { v4 as uuid4 } from 'uuid'
import { getSinkByIDRequest, getSourceByIDRequest } from '../services/apiRequests'

export const getElementByType = (projectID: string, elementID: string, type: elementType) => {
  switch (type) {
    case 'SINK':
      return getSinkByIDRequest(projectID, elementID)
    case 'SOURCE':
      return getSourceByIDRequest(projectID, elementID)
    default:
      return
  }
}

export const classOptions = [
  { id: '1', name: 'Класс 1' },
  { id: '2', name: 'Класс 2' },
]

export const oneDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 1,
})
export const twoDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 2,
})
export const fourDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 4,
})
export const sixDigitsFormatter = new Intl.NumberFormat('ru', {
  maximumFractionDigits: 6,
})

export const generateUID = () => {
  return uuid4()
}

export const sortByDate = (date1: string, date2: string) => {
  return (new Date(date2) as any) - (new Date(date1) as any)
}

export const sortByOrder = (order1: number | undefined, order2: number | undefined) => {
  if (order1 && order1 > 0 && order2 && order2 > 0) {
    return order1 - order2
  }
  return 0
}

export const kelvin2Celsius = (value: number) => {
  return value - 273.15
}
