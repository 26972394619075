import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { Table } from 'components/Table/Table/Table'
import './directoryIndicesPipesCalculation.scss'
import useGetIndicesPipesCalculation from './hooks/useGetIndicesPipesCalculation'
import useCreateIndicesPipeCalculationVersion from './hooks/useCreateIndicesPipeCalculationVersion'
import { DiameterToPipesCalculationIndices } from 'store/types'

interface IDirectoryPipeTypesProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryIndicesPipesCalculation: React.FC<IDirectoryPipeTypesProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as definitions['DiameterToPipesCalculationIndices']
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)

  useGetIndicesPipesCalculation(requestData)
  const saveResult = useCreateIndicesPipeCalculationVersion(saveData)
  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, updatedValue: number, fieldName: string) => {
    const [diameterField, pressureField] = fieldName.split('/') as [
      keyof definitions['DiameterToPipesCalculationIndices'],
      keyof definitions['PressureToPipesCalculationIndices'],
    ]

    let value: DiameterToPipesCalculationIndices = {}
    if (id === 'linear_part_on_pressure') {
      // @ts-ignore
      value = {
        ...directoryItem[diameterField],
        [pressureField]: {
          ...directoryItem[diameterField][pressureField],
          [id]: isNaN(updatedValue) ? 0 : updatedValue,
          modified: true,
        },
      }
    } else {
      Object.entries(directoryItem[diameterField]).forEach(([key, fields]) => {
        // @ts-ignore
        value[key as keyof definitions['PressureToPipesCalculationIndices']] = {
          ...fields,
          [id]: isNaN(updatedValue) ? 0 : updatedValue,
          modified: true,
        }
      })
    }
    dispatch(
      updateItemField({
        value,
        fieldName: diameterField,
      }),
    )
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: { [key: string]: any }[] = [
      {
        id: 'linear_part_on_pressure',
        title: (
          <>
            ЛЧ: переход на давление
            <br />
            (от 7,4 МПа)
          </>
        ),
      },
      {
        id: 'linear_part_cir',
        title: (
          <>
            ЛЧ: соотношение СМР по
            <br /> диаметрам (укладка) к<br />
            предшествующему
            <br />
            диаметру
          </>
        ),
      },
      {
        id: 'linear_part_earth_grounding',
        title: (
          <>
            ЛЧ: отвод земли,
            <br /> компенсации
          </>
        ),
      },
    ]
    if (directoryItem) {
      Object.entries(directoryItem).map(([key, value], _ix) => {
        Object.entries(value).map(([key2, value2], ix) => {
          if (!ix) {
            data[1][`${key}/${key2}`] = value2.linear_part_cir
            data[2][`${key}/${key2}`] = value2.linear_part_earth_grounding
          }
          data[0][`${key}/${key2}`] = value2.linear_part_on_pressure
        })
      })
    }
    return data
  }, [directoryItem])
  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      isModified={isModified}
      title={getHandbookName(directoryKey)}
    >
      {directoryItem ? (
        <div className={'directory__content'}>
          <Table
            className={'directory-table__container indices-pipes-calculation-table__container'}
            columns={columns(editMode) as any}
            dataSource={dataSource}
            setValue={onSaveItemField}
          />
        </div>
      ) : (
        <div className={'normal-text directory-table__container-error'}>Не удалось загрузить справочник...</div>
      )}
    </DirectoryWrapper>
  )
}

export default DirectoryIndicesPipesCalculation
