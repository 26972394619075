import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import './directoryCSCalculationIndices.scss'
import { Table } from 'components/Table/Table/Table'
import useGetCSCalculationIndices from './hooks/useGetCSCalculationIndices'
import useCreateCSCalculationIndicesVersion from './hooks/useCSCalculationIndicesVersion'

interface IDirectoryPipeTypesProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryCSCalculationIndices: React.FC<IDirectoryPipeTypesProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)

  useGetCSCalculationIndices(requestData)
  const saveResult = useCreateCSCalculationIndicesVersion(saveData)

  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, cs_on_pressure: number, fieldName: string) => {
    dispatch(
      updateItemField({
        value: {
          cs_on_pressure,
        },
        fieldName,
      }),
    )
    setIsModified(true)
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: { [key in keyof definitions['PressureToCompressorStationCalculationIndices']]?: number } = {}
    if (directoryItem) {
      Object.entries(directoryItem).forEach(([key, value]) => {
        data[key as keyof definitions['PressureToCompressorStationCalculationIndices']] = value.cs_on_pressure
      })
    }
    return [data]
  }, [directoryItem])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
    >
      <div className={'directory__content'}>
        <Table
          className={'directory-table__container cs-calculation-indices-table__container'}
          columns={columns(editMode) as any}
          dataSource={dataSource}
          setValue={onSaveItemField}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryCSCalculationIndices
