import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setCreateTask, setTaskId, setTaskStatus } from 'store/autoCSSlice'
import { createAutoCSTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { resetValidation } from 'store/validationSlice'
import { IErrorParams } from 'store/types'

const useCreateTask = (settings: {
  draft_compressor_station: {
    pressure__MPa: definitions['CSPlacementCalculationCreate']['draft_compressor_station']['pressure__MPa'] | undefined
    first_compressor_plant:
      | definitions['CSPlacementCalculationCreate']['draft_compressor_station']['first_compressor_plant']
      | undefined
    second_compressor_plant:
      | definitions['CSPlacementCalculationCreate']['draft_compressor_station']['second_compressor_plant']
      | undefined
  }
  hydro_calculation_properties: definitions['CSPlacementCalculationCreate']['hydro_calculation_properties'] | undefined
  step__km: definitions['CSPlacementCalculationCreate']['step__km'] | undefined
  desired_pressure_increase__MPa:
    | definitions['CSPlacementCalculationCreate']['desired_pressure_increase__MPa']
    | undefined
}) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToCreateTask = async () => {
    if (
      trunkPipelineId &&
      projectId &&
      settings.draft_compressor_station &&
      settings.hydro_calculation_properties &&
      settings.step__km &&
      settings.desired_pressure_increase__MPa &&
      settings.draft_compressor_station.first_compressor_plant
    ) {
      dispatch(resetValidation())
      await createAutoCSTask(projectId, trunkPipelineId, {
        draft_compressor_station: {
          pressure__MPa: settings.draft_compressor_station.pressure__MPa as any,
          first_compressor_plant: settings.draft_compressor_station.first_compressor_plant,
          second_compressor_plant: settings.draft_compressor_station.second_compressor_plant,
        },
        hydro_calculation_properties: settings.hydro_calculation_properties,
        step__km: settings.step__km,
        desired_pressure_increase__MPa: settings.desired_pressure_increase__MPa,
      })
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data?.length > 0) {
              dispatch(
                setBlockingWindow({
                  type: 'ERROR',
                  params: {
                    title: 'Ошибка запуска расчета',
                    message: (
                      <div className='error-window__errors-list-container'>
                        <div>При запуске расчета возникли следующие ошибки:</div>
                        <ul className='error-window__errors-list'>
                          {(err.response.data as string[])?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ),
                  } as IErrorParams,
                }),
              )
            } else {
              let message = ''
              if (
                err.response.data.detail ===
                'Pipelines are too long. Total length of pipelines must be less than 10000km.'
              ) {
                message = 'Общая длина трубопровода не должна превышать 10000 км.'
              } else if (
                err.response.data.detail === 'Pipelines are too long. Length of each pipeline must be less than 3000km.'
              ) {
                message = 'Длина каждого участка трубопровода не должна превышать 3000 км.'
              }
              dispatch(
                setBlockingWindow({
                  params: {
                    title: 'Ошибка запуска расчета',
                    message: message || 'Непредвиденная ошибка',
                  } as IErrorParams,
                  type: 'ERROR',
                }),
              )
            }
          } else
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              }),
            )
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
