import * as React from 'react'

import classnames from 'classnames'

import './style.scss'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'primary-light' | 'secondary' | 'drop-down' | 'warn' | 'info'
  children?: any
}

const Button: React.FC<IButton> = ({ mode = 'primary', className, children, ...props }) => {
  return (
    <button {...props} className={classnames('main-button', className, mode)}>
      <div className='main-button__icon-wrapper'>{children}</div>
    </button>
  )
}

export default Button
