import React from 'react'
import './index.scss'
import { ZoomButton } from './zoomButton'
import { NavigateButton } from './navigateButton'
import classnames from 'classnames'
interface IControlButtons {
  onZoomIn(): void
  onZoomOut(): void
  onNavigate(): void
  className?: string
}
export const ControlButtons: React.FC<IControlButtons> = ({ onNavigate, onZoomOut, onZoomIn, className }) => {
  return (
    <div className={classnames('ctrl-btn', className)}>
      <ZoomButton onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
      <NavigateButton onNavigate={onNavigate} />
    </div>
  )
}
