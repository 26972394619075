import * as React from 'react'

function EditIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        clipPath='url(#prefix__clip0_910_19450)'
        stroke='#2C3E50'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path
          d='M5.833 5.833H5A1.667 1.667 0 003.333 7.5V15A1.667 1.667 0 005 16.667h7.5A1.667 1.667 0 0014.167 15v-.833'
          fillRule='evenodd'
          clipRule='evenodd'
          fill={'transparent'}
        />
        <path
          d='M16.988 5.487a1.75 1.75 0 00-2.476-2.475L7.5 10v2.5H10l6.988-7.013zM13.334 4.167l2.5 2.5'
          fillRule='evenodd'
          clipRule='evenodd'
          fill={'transparent'}
        />
      </g>
      {/*<defs>*/}
      {/*  <clipPath id='prefix__clip0_910_19450'>*/}
      {/*    <path fill='#fff' d='M0 0h20v20H0z' />*/}
      {/*  </clipPath>*/}
      {/*</defs>*/}
    </svg>
  )
}

export default EditIcon
