import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IDirectorySlice } from './types'
import update from 'immutability-helper'

export const initialState: IDirectorySlice = {
  id: undefined,
  versionId: undefined,
  selectedItems: [],
  directoryItem: undefined,
  directoryItems: [],
}

export const directorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<IDirectorySlice['id']>) => {
      state.id = action.payload
    },
    setVersionId: (state, action: PayloadAction<IDirectorySlice['versionId']>) => {
      state.versionId = action.payload
    },
    deselectSelectedItemById: (state, action: PayloadAction<string>) => {
      const index = state.selectedItems?.findIndex(({ id }) => action.payload === id)
      if (index !== undefined && index > -1) {
        state.selectedItems = update(state.selectedItems, { $splice: [[index, 1]] })
      }
    },
    addSelectedItem: (state, action: PayloadAction<any>) => {
      state.selectedItems = update(state.selectedItems, { $push: [action.payload] })
    },
    setDirectoryItems: (state, action: PayloadAction<IDirectorySlice['directoryItems']>) => {
      state.directoryItems = action.payload
    },
    setDirectoryItem: (state, action: PayloadAction<IDirectorySlice['directoryItem']>) => {
      state.directoryItem = action.payload
    },
    addDirectoryItem: (state, action: PayloadAction<any>) => {
      state.directoryItems = update(state.directoryItems, { $push: [action.payload] })
    },
    updateItemFieldById: (
      state,
      action: PayloadAction<{ id: string; value: string | number | object; fieldName: string }>,
    ) => {
      const index = state.directoryItems?.findIndex(({ id }) => id === action.payload.id)
      if (index !== undefined && index > -1) {
        state.directoryItems = update(state.directoryItems, {
          [index]: { $set: { ...state.directoryItems[index], [action.payload.fieldName]: action.payload.value } },
        })
      }
    },
    updateItemField: (state, action: PayloadAction<{ value: string | number | object; fieldName: string }>) => {
      if (state.directoryItem) {
        state.directoryItem = {
          ...state.directoryItem,
          [action.payload.fieldName]: action.payload.value,
        }
      }
    },
    removeSelectedItems: (state) => {
      let items = [...state.directoryItems]
      for (const item of state.selectedItems) {
        const index = items?.findIndex(({ id }) => id === item.id)
        if (index !== undefined && index > -1) {
          items = update(items, { $splice: [[index, 1]] })
        }
      }
      state.directoryItems = items
      state.selectedItems = []
    },
    clearSelectedItems: (state) => {
      state.selectedItems = initialState.selectedItems
    },
    resetDirectorySliceState: () => {
      return initialState
    },
    setModified: (state, action: PayloadAction<string>) => {
      const index = state.directoryItems?.findIndex(({ id }) => id === action.payload)
      if (index !== undefined && index > -1) {
        state.directoryItems = update(state.directoryItems, {
          [index]: { $set: { ...state.directoryItems[index], modified: true } },
        })
      }
    },
  },
})

export const {
  setId,
  setVersionId,
  addSelectedItem,
  deselectSelectedItemById,
  setDirectoryItems,
  addDirectoryItem,
  updateItemField,
  updateItemFieldById,
  removeSelectedItems,
  clearSelectedItems,
  resetDirectorySliceState,
  setModified,
  setDirectoryItem,
} = directorySlice.actions

export const selectSelectedItems = (state: RootState) => state.directory.selectedItems
export const selectDirectoryItems = (state: RootState) => state.directory.directoryItems
export const selectDirectoryItem = (state: RootState) => state.directory.directoryItem

export default directorySlice.reducer
