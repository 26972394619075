import React from 'react'

export const columns = (editMode: boolean) => [
  {
    title: 'Рабочее давление, МПа',
  },
  {
    title: '5.4',
    type: 'Number',
    editable: editMode,
    key: `pressure_5_4_props`,
    noEmpty: true,
  },
  {
    title: '7.4',
    type: 'Number',
    editable: false,
    key: `pressure_7_4_props`,
    noEmpty: true,
  },
  {
    title: '8.2',
    type: 'Number',
    editable: editMode,
    key: `pressure_8_2_props`,
    noEmpty: true,
  },
  {
    title: '9.8',
    type: 'Number',
    editable: editMode,
    key: `pressure_9_8_props`,
    noEmpty: true,
  },
  {
    title: '11.8',
    type: 'Number',
    editable: editMode,
    key: `pressure_11_8_props`,
    noEmpty: true,
  },
  {
    title: (
      <>
        КС: переход
        <br />
        на давление (от 7.4 МПа)
      </>
    ),
  },
]
