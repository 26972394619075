import { kelvin2Celsius, twoDigitsFormatter } from 'pages/utils'
import React from 'react'
import { definitions } from 'generated/apiTypes'

interface IHeatingStationTableProps {
  node: definitions['HydroResultRetrieveHeatingStation']
}
export const HeatingStationTable: React.FC<IHeatingStationTableProps> = ({ node }) => {
  return (
    <div className={'hydraulic-results__stations_expand-row'}>
      <div className={'pipeline-result__cs-expand'}>
        <div className={'pipeline-result__cs-expand__column normal-text'}>
          <div className={'pipeline-result__cs-expand__row'}>
            <div className={'title'}>Давление на выходе:</div>
            {`${twoDigitsFormatter.format(node?.out_pressure__MPa)} МПа`}
          </div>
        </div>
        <div className={'pipeline-result__cs-expand__column normal-text'}>
          <div className={'pipeline-result__cs-expand__row'}>
            <div className={'title'}>Температура на выходе:</div>
            {`${twoDigitsFormatter.format(kelvin2Celsius(node?.out_temperature__K))} °C`}
          </div>
        </div>
      </div>
    </div>
  )
}
