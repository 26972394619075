import React from 'react'
import { definitions } from 'generated/apiTypes'

export const columns = (editMode: boolean, regions: definitions['NextgisRegionName'][] | []): any[] => [
  {
    title: 'Экономический район',
    type: 'Option',
    key: 'name',
    editable: editMode,
    options: regions,
    displayedField: 'name',
    order: 1,
  },
  {
    title: 'Подрядные работы (СМР и прочие, относящиеся к деятельности подрядчика)',
  },
  {
    title: (
      <>
        Оборудование и прочие,
        <br />
        относящиеся к деятельности
        <br /> заказчика
      </>
    ),
    type: 'Number',
    key: 'customer_stuff_coeff',
    editable: editMode,
    order: 5,
    minValue: 0.001,
  },
  {
    type: 'Number',
    title: 'Линейная часть',
    key: 'contract_work_linear_coeff',
    editable: editMode,
    order: 2,
    minValue: 0.001,
  },
  {
    type: 'Number',
    title: 'КС',
    key: 'contract_work_cs_coeff',
    editable: editMode,
    order: 3,
    minValue: 0.001,
  },
  {
    type: 'Number',
    title: 'Газопровод',
    key: 'contract_work_gasline_coeff',
    editable: editMode,
    order: 4,
    minValue: 0.001,
  },
]
