import * as React from 'react'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

interface IPopupMenuLayout {
  closeMenu(): void
  controlRef?: React.RefObject<any>
  className?: string
  setMenuRef?(menuRef: React.RefObject<HTMLDivElement>): void
}

const PopupMenuLayout: React.FC<PropsWithChildren<IPopupMenuLayout>> = ({
  children,
  closeMenu,
  controlRef,
  className,
  setMenuRef,
  ...props
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (setMenuRef) {
      setMenuRef(menuRef)
    }
  }, [menuRef?.current])

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!menuRef?.current?.contains(event.target) && !controlRef?.current?.contains(event.target)) {
        closeMenu()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={menuRef} className={classNames(className)} {...props}>
      {children}
    </div>
  )
}

export default PopupMenuLayout
