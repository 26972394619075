import React from 'react'
import '../styleGuide.scss'
import Button from 'components/Button'
import CopyIcon from 'images/CopyIcon'
import InfoIcon from 'images/InfoIcon'

const ButtonsLinks: React.FC = () => {
  return (
    <div className='style-guide__panel style-guide__columns'>
      <div className='style-guide__panel-title'>Buttons & Links</div>
      <Button>primary button</Button>
      <Button mode={'primary-light'}>primary-light button</Button>
      <Button mode={'secondary'}>secondary button</Button>
      <Button mode={'warn'}>warn button</Button>
      <Button disabled>disabled button</Button>
      <Button style={{ maxWidth: 20, maxHeight: 20, padding: 0 }} mode={'drop-down'}>
        <CopyIcon />
      </Button>
      <Button style={{ maxWidth: 16, maxHeight: 16, padding: 0 }} mode={'info'}>
        <InfoIcon />
      </Button>
      <p className='link'>link</p>
    </div>
  )
}

export default ButtonsLinks
