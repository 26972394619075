import React from 'react'
import NumberInput from 'components/Input/numberInput'
import { definitions } from 'generated/apiTypes'
import './hydraulic.scss'

interface IHydraulicProps {
  hydroSettings: definitions['CSPlacementCalculationCreate']['hydro_calculation_properties'] | undefined
  setHydroSettings(station: definitions['CSPlacementCalculationCreate']['hydro_calculation_properties']): void
}

const Hydraulic: React.FC<IHydraulicProps> = ({ hydroSettings, setHydroSettings }) => {
  const setHydroSettingsValue = (value: number | boolean, fieldName?: string) => {
    setHydroSettings({ ...(hydroSettings as any), [fieldName as string]: value })
  }
  return (
    <div className={'cs-settings__container hydro-settings__container'}>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          label={'Год расчета'}
          value={hydroSettings?.year}
          fieldName={'year'}
          id={'compressor-stations-placement'}
          level1FieldName={'hydro_calculation_properties'}
          setValue={setHydroSettingsValue}
        />
      </div>
      <div className={'cs-settings__divider'} />
      <div className={'hydraulic-calc__sub-title header3'} style={{ marginBottom: 0 }}>
        Параметры расчета
      </div>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          label={'Точн. расчета мощности'}
          value={hydroSettings?.power_calculation_precision}
          unit={'MВт'}
          fieldName={'power_calculation_precision'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
        <NumberInput
          label={'Точн. pасчета мощности ГПА'}
          value={hydroSettings?.power_calculation_precision_GPU}
          unit={'Вт'}
          fieldName={'power_calculation_precision_GPU'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
        <NumberInput
          label={'Точн. pасчета давления (СТД)'}
          value={hydroSettings?.pressure_calculation_precision}
          unit={'MПа'}
          fieldName={'pressure_calculation_precision'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
        <NumberInput
          label={'Точн. pасчета вых. давления'}
          value={hydroSettings?.outlet_pressure_calculation_precision}
          unit={'MПа'}
          fieldName={'outlet_pressure_calculation_precision'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
      </div>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          label={'Диапазон давлений (СТД)'}
          unit={'MПа'}
          value={hydroSettings?.pressure_range_for_reduction_calculation__MPa}
          fieldName={'pressure_range_for_reduction_calculation__MPa'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
        <NumberInput
          label={'Шаг уточн. Q пpи поиске Qмах'}
          unit={
            <>
              <span className={'superscript-wrapper'}>
                млн м<span className={'superscript'}>3</span>
              </span>
            </>
          }
          value={hydroSettings?.increment_step_for_Qmax_calculation__Mm3_per_day}
          fieldName={'increment_step_for_Qmax_calculation__Mm3_per_day'}
          setValue={setHydroSettingsValue}
          level1FieldName={'hydro_calculation_properties'}
          id={'compressor-stations-placement'}
        />
      </div>
    </div>
  )
}

export default Hydraulic
