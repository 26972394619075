import * as React from 'react'

function AddSegmentIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 5V3.5C5 3.22386 5.22386 3 5.5 3H6.5C6.77614 3 7 3.22386 7 3.5V5H8.5C8.77614 5 9 5.22386 9 5.5V6.5C9 6.77614 8.77614 7 8.5 7H7V8.5C7 8.77614 6.77614 9 6.5 9H5.5C5.22386 9 5 8.77614 5 8.5V7H3.5C3.22386 7 3 6.77614 3 6.5V5.5C3 5.22386 3.22386 5 3.5 5H5Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.3125 5.39042C18.485 5.60605 18.4501 5.9207 18.2344 6.0932L4.01531 17.4685C3.79968 17.641 3.48504 17.606 3.31253 17.3904L2.68784 16.6096C2.51533 16.3939 2.55029 16.0793 2.76592 15.9068L16.9851 4.53146C17.2007 4.35896 17.5153 4.39392 17.6878 4.60955L18.3125 5.39042Z'
        fill='white'
      />
    </svg>
  )
}

export default AddSegmentIcon
