import React, { useState } from 'react'

import { getLayersHandbook } from 'services/apiRequests'

import { definitions } from 'generated/apiTypes'

const useGetAreaTypes = () => {
  const [areaTypes, setAreaTypes] = useState<definitions['HandbookAreaType'][]>([])

  const tryToGetNextGISLayers = async () => {
    await getLayersHandbook().then((res) => {
      setAreaTypes(res.data.units.map((unit) => unit as definitions['HandbookAreaType']))
    })
  }

  React.useEffect(() => {
    tryToGetNextGISLayers().then()
  }, [])

  return areaTypes
}

export default useGetAreaTypes
