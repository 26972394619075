import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, updateItemField } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { Table } from 'components/Table/Table/Table'
import useGetConstructionCondition from './hooks/useGetConstructionConditions'
import './directoryConstructionConditions.scss'
import { RowsTable } from 'components/Table/RowsTable/RowsTable'
import useCreateConstructionConditionsVersion from './hooks/useCreateConstructionConditionsVersion'
import { VersionEconomicsConstructionConditionsSet } from 'store/types'
interface IDirectoryPipeTypesProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryConstructionConditions: React.FC<IDirectoryPipeTypesProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as VersionEconomicsConstructionConditionsSet
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)

  useGetConstructionCondition(requestData)
  const saveResult = useCreateConstructionConditionsVersion(saveData)

  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onSaveItemField = async (id: string, value: number, fieldName: string) => {
    const index = directoryItem?.construction_conditions.findIndex((item) => item.id === id)
    if (index > -1) {
      dispatch(
        updateItemField({
          value: [
            ...directoryItem.construction_conditions.slice(0, index),
            {
              ...directoryItem.construction_conditions[index],
              is_modified: true,
              [fieldName]: value,
            },
            ...directoryItem.construction_conditions.slice(index + 1),
          ],
          fieldName: 'construction_conditions',
        }),
      )
      setIsModified(true)
    }
  }

  const onSaveItemFieldGround = async (id: string, value: number, fieldName: string) => {
    dispatch(
      updateItemField({
        value: {
          ...directoryItem.ground_construction_conditions,
          [fieldName]: value,
          is_modified: true,
        },
        fieldName: 'ground_construction_conditions',
      }),
    )
    setIsModified(true)
  }
  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const constructionConditionsData = React.useMemo(() => {
    const data: { id: string; title: string; coefficient: number }[] = []
    directoryItem?.construction_conditions.forEach((obj) => {
      data.push({
        id: obj.id,
        title: obj.area_type.name,
        coefficient: obj.coefficient,
      })
    })
    return data
  }, [directoryItem?.construction_conditions])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
    >
      <div className={'directory__content construction-conditions__content'}>
        <div className={'construction-conditions__container'}>
          <div className={'construction-conditions__title header3'}>Коэффициенты влияния слоев системы</div>
          <Table
            className={'directory-table__container'}
            columns={columns(editMode) as any}
            dataSource={constructionConditionsData}
            setValue={onSaveItemField}
          />
        </div>
        <div className={'construction-conditions__column'}>
          <div className={'ground-construction-conditions__container'}>
            <div className={'construction-conditions__title header3'}>Коэффициент влияния ММГ</div>
            <RowsTable
              columns={[
                {
                  title: 'Наличие ММГ',
                  type: 'Number',
                  editable: editMode,
                  key: 'permafrost',
                  noEmpty: true,
                },
              ]}
              dataSource={[directoryItem?.ground_construction_conditions || {}]}
              setValue={onSaveItemFieldGround}
            />
          </div>
          <hr />
          <div className={'height-diff-construction-conditions__container'}>
            <div className={'construction-conditions__title header3'}>Коэффициент влияния рельефа</div>
            <RowsTable
              columns={[
                {
                  title: 'Перепад высоты > 15 градусов',
                  type: 'Number',
                  editable: editMode,
                  key: 'height_difference_15_degrees',
                  noEmpty: true,
                },
              ]}
              dataSource={[directoryItem?.ground_construction_conditions || {}]}
              setValue={onSaveItemFieldGround}
            />
          </div>
        </div>
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryConstructionConditions
