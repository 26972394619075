import React from 'react'
import { definitions } from '../../generated/apiTypes'
import classnames from 'classnames'
import './style.scss'

interface ICalcStatus {
  status: definitions['TaskStatus']['status'] | undefined
  classNames?: string
}

const CalcStatus: React.FC<ICalcStatus> = ({ status, classNames }) => {
  const getLabelByStatus = () => {
    switch (status) {
      case 'Рассчитан':
        return 'РAССЧИТАНО'
      case 'Неактуальный':
        return 'НЕАКТУАЛЬНО'
      case 'Ошибка':
        return 'ОШИБКА'
      case 'Запущен':
        return 'ИДЕТ РАСЧЕТ'
      case 'Ожидает очереди':
        return 'В ОЧЕРЕДИ'
      case 'Рассчитано частично':
        return 'РАССЧИТАНО ЧАСТИЧНО'
      case 'Отменен':
        return 'ОТМЕНЕН'
      default:
        return ''
    }
  }

  const getClassNameByStatus = () => {
    switch (status) {
      case 'Рассчитан':
        return 'done'
      case 'Неактуальный':
        return 'non-actual'
      case 'Запущен':
        return 'progress'
      case 'Ошибка':
        return 'error'
      case 'Ожидает очереди':
        return 'new'
      case 'Рассчитано частично':
        return 'partly-calc'
      case 'Отменен':
        return 'canceled'
      default:
        return ''
    }
  }

  return (
    <div className={classnames('status__container label', getClassNameByStatus(), classNames)}>
      {getLabelByStatus()}
    </div>
  )
}

export default CalcStatus
