export interface FontStyle {
  fontWeight: number
  fontSize: number
  color: string
  textBaseLine?: CanvasTextBaseline
  textAlign?: CanvasTextAlign
}
export const Text = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number, style: FontStyle) => {
  ctx.fillStyle = style.color
  ctx.font = `${style.fontWeight} ${style.fontSize}px Mulish`
  ctx.textBaseline = style.textBaseLine || 'bottom'
  ctx.textAlign = style.textAlign || 'right'
  ctx.fillText(text, x, y)
}
