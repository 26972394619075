import * as React from 'react'

function PathIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={5} cy={5} r={2} fill='#0074BC' />
      <circle cx={15} cy={15} r={2} fill='#0074BC' />
      <path
        d='M5 5l.405 2.834a1 1 0 00.747.83l.59.147a1 1 0 01.758.97v1.352a1 1 0 00.859.99l2.387.34a1 1 0 01.459.19L13 14l2.5 1.5'
        stroke='#0074BC'
        strokeWidth={2}
      />
    </svg>
  )
}

export default PathIcon
