import React from 'react'
import BarChart from './BarChart/BarChart'
import LineChart from './LineChart/LineChart'

export type chartType = 'NONE' | 'HEIGHT_PROFILE' | 'LAYER_TYPES' | 'CATEGORY_AREA' | 'LAYING_METHOD'

export interface IControlChartProps {
  requestRange:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined
  setRequestRange(
    range:
      | {
          start_distance__km?: number
          end_distance__km?: number
        }
      | undefined,
  ): void
  startPoint: number | undefined
  setStartPoint(point: number | undefined): void
  finishPoint: number | undefined
  setFinishPoint(point: number | undefined): void
  minPoint: number | undefined
  setMinPoint?(point: number | undefined): void
  maxPoint: number | undefined
  setMaxPoint?(point: number | undefined): void
  panPlus?: number
  panMinus?: number
  zoomPlus?: number
  zoomMinus?: number
  startPointToZoom?: number
  finishPointToZoom?: number
}

export interface IChartProps {
  chartRef: React.RefObject<any>
  setCurrentMinPoint(point: number | undefined): void
  setCurrentMaxPoint(point: number | undefined): void
}

export interface IChartsProps {
  panelRef: React.RefObject<HTMLDivElement>
  chartTypeOptions: { value: chartType; name: string }[]
  chartTypeOption: {
    value: chartType
    name: string
  }
  setChartTypeOption(value: { value: chartType; name: string }): void
  setChartRenderingInProgress?(value: boolean): void
}

const ProfileChart: React.FC<IControlChartProps & IChartsProps> = (props) => {
  if (['LAYER_TYPES', 'CATEGORY_AREA', 'LAYING_METHOD'].includes(props.chartTypeOption.value)) {
    return <BarChart {...props} />
  }
  if (['HEIGHT_PROFILE', 'NONE'].includes(props.chartTypeOption.value)) return <LineChart {...props} />
  return <></>
}

export default ProfileChart
