import { api } from '../api'

export default (token: false | string) => {
  if (token) {
    api.defaults.headers.common = {
      ...api.defaults.headers.common,
      Authorization: `Token ${token}`,
    }
  } else {
    delete api.defaults.headers.common.Authorization
  }
}
