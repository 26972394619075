import React from 'react'

import { createPipeTypesHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, setDirectoryItems, setId, setVersionId } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { HandbookPipeType, IErrorParams } from 'store/types'

const useCreatePipeTypesVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems)
  const [result, setResult] = React.useState(false)

  const tryToCreatePipeTypesVersion = async () => {
    if (directoryItems) {
      const items: definitions['HandbookPipeTypeCreate'][] = []
      ;(directoryItems as HandbookPipeType[]).forEach((item) => {
        const { id, created, modified, ...newItem } = item
        if (created) {
          items.push({ modified: false, ...newItem })
        } else {
          items.push({ id, ...newItem, modified })
        }
      })
      await createPipeTypesHandbookVersion(items)
        .then((res) => {
          dispatch(setId(res.data.id))
          dispatch(setVersionId(res.data.id))
          dispatch(
            setDirectoryItems(
              res.data.units.map((unit) => {
                return {
                  ...unit,
                  modified: false,
                }
              }),
            ),
          )
          setResult(true)
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: {
                  title: 'Ошибка сохранения',
                  message: (
                    <div className='error-window__errors-list-container'>
                      {err.response.data.map((directoryItem: any, index: number) => {
                        if (Object.keys(directoryItem).length > 0) {
                          return (
                            <div key={index}>
                              {`При сохранении ${
                                (directoryItems[index] as definitions['HandbookPipeType'])?.name
                              } возникли следующие ошибки:`}
                              <ul className='error-window__errors-list' key={index}>
                                {Object.entries(directoryItem).map(([key, value], ix) => {
                                  return <li key={ix}>{`${key}: ${value}`}</li>
                                })}
                              </ul>
                            </div>
                          )
                        }
                      })}
                    </div>
                  ),
                } as IErrorParams,
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              }),
            )
        })
    }
  }

  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreatePipeTypesVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreatePipeTypesVersion
