import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react'

import './style.scss'
import IconArrayDown from './IconArrayDown'
import classNames from 'classnames'
import PopupMenuLayout from './PopupMenuLayout'
import Option from './Option'
import { InfoPopup } from 'components/InfoPopup/InfoPopup'
import InfoIcon from 'images/InfoIcon'
import Button from 'components/Button'

interface IDropdownProps<OptionType> {
  options: OptionType[]
  selectedValue?: OptionType
  selectedValues?: OptionType[]
  setSelectedValue?(value: OptionType): void
  setSelectedValues?(values: OptionType[]): void
  displayedField: string
  valuePlaceholder?: string
  panelRef?: React.RefObject<HTMLDivElement>
  disabled?: boolean
  label?: string
  infoText?: string
  appearanceOnly?: boolean
  containerClassNames?: string
  multiselect?: boolean
}

const Dropdown = <OptionType,>({
  options,
  selectedValue,
  selectedValues,
  displayedField,
  valuePlaceholder,
  setSelectedValue,
  setSelectedValues,
  panelRef,
  disabled,
  label,
  infoText,
  appearanceOnly,
  containerClassNames,
  multiselect,
}: PropsWithChildren<IDropdownProps<OptionType>>): ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const controlRef = React.useRef<HTMLDivElement>(null)
  const [menuBottom, setMenuBottom] = React.useState<number>()
  const [panelBottom, setPanelBottom] = React.useState<number>()
  const [menuRef, setMenuRef] = React.useState<React.RefObject<HTMLDivElement>>()
  const [focus, setFocus] = useState(false)
  const [visible, setVisibility] = useState(false)
  React.useEffect(() => {
    setMenuBottom(menuRef?.current?.getBoundingClientRect().bottom)
  }, [menuRef?.current, open])

  React.useEffect(() => {
    if (panelRef?.current)
      setPanelBottom(
        panelRef.current?.getBoundingClientRect().bottom -
          // scrollbar size
          (panelRef?.current?.offsetHeight - panelRef?.current?.clientHeight),
      )
    else setPanelBottom(window.innerHeight)
  }, [panelRef?.current])

  useEffect(() => {
    setVisibility(focus)
  }, [focus])

  React.useEffect(() => {
    if (selectedValues && selectedValues?.length > 0) {
      if (
        selectedValues?.filter((el) => (el as any).value !== 'ALL')?.length === options.length &&
        !selectedValues?.find((el) => (el as any).value === 'ALL')
      ) {
        setAllValues()
      } else if (
        selectedValues?.some((el) => (el as any).value === 'ALL') &&
        selectedValues?.filter((el) => (el as any).value !== 'ALL')?.length < options.length
      ) {
        if (setSelectedValues) setSelectedValues(selectedValues?.filter((el) => (el as any).value !== 'ALL') || [])
      }
    }
  }, [selectedValues])

  const getDropdownSingleValue = () => {
    return (selectedValue as any)[displayedField] || (displayedField === '' && selectedValue) || 'Отсутствует поле'
  }

  const getDropdownMultiValue = () => {
    if (selectedValues?.some((el) => (el as any)?.value === 'ALL')) {
      return 'Показывать все типы'
    } else {
      return `Показывать типы (${selectedValues?.length})`
    }
  }

  const setAllValues = () => {
    if (setSelectedValues) setSelectedValues([{ value: 'ALL', name: 'Показывать все типы' } as OptionType, ...options])
  }

  return (
    <>
      <div className={classNames('dropdown__container', containerClassNames)} ref={controlRef}>
        {label && (
          <div className={'dropdown__container__label field-label-text'}>
            {label}
            {infoText && (
              <Button mode={'info'} onClick={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <InfoIcon />
              </Button>
            )}
          </div>
        )}
        {appearanceOnly ? (
          <div className='dropdown__value-container'>
            <div className='dropdown__value-wrapper'>
              <span className={classNames('dropdown__value')}>
                {selectedValue
                  ? (selectedValue as any)[displayedField] ||
                    (displayedField === '' && selectedValue) ||
                    'Отсутствует поле'
                  : valuePlaceholder || 'Не задано'}{' '}
              </span>
            </div>
            <div className={classNames('dropdown__indicator', { open })}>
              <IconArrayDown />
            </div>
          </div>
        ) : (
          <button className='dropdown__value-container' onClick={() => setOpen(!open)} disabled={disabled}>
            <div className='dropdown__value-wrapper'>
              <span className={classNames('dropdown__value')}>
                {selectedValue
                  ? getDropdownSingleValue()
                  : multiselect
                  ? getDropdownMultiValue()
                  : valuePlaceholder || 'Не задано'}{' '}
              </span>
            </div>
            <div className={classNames('dropdown__indicator', { open })}>
              <IconArrayDown />
            </div>
          </button>
        )}
        {open && (
          <PopupMenuLayout
            controlRef={controlRef}
            closeMenu={() => setOpen(false)}
            className={classNames(
              menuBottom !== undefined && panelBottom !== undefined
                ? 'dropdown__options-list'
                : 'dropdown__options-list no-display',
              menuBottom !== undefined && panelBottom !== undefined && menuBottom > panelBottom && 'open-up',
            )}
            setMenuRef={setMenuRef}
          >
            {multiselect && (
              <Option
                key={'all'}
                item={{ value: 'ALL', name: 'Показывать все типы' } as OptionType}
                displayedField={'name'}
                setSelectedValue={setSelectedValue}
                closeOptionsList={() => setOpen(false)}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setAllValues={setAllValues}
                multiselect={multiselect}
              />
            )}
            {options.map((item, index) => (
              <Option
                key={index}
                item={item}
                displayedField={displayedField}
                setSelectedValue={setSelectedValue}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                closeOptionsList={() => setOpen(false)}
                multiselect={multiselect}
              />
            ))}
            {options.length === 0 && <div className='dropdown__empty-options'>Список опций пуст</div>}
          </PopupMenuLayout>
        )}
      </div>
      {/*infoText && <InfoPopup focused={focus} visible={visible} infoText={infoText} inputRef={controlRef} />*/}
    </>
  )
}

export default Dropdown
