import { line, station } from '../utils'
import { Text } from './text'
import { TextGroup } from './textGroup'

export const Station = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  name: string,
  distance: string,
  plants: any[],
) => {
  const width = 16
  const height = 28
  station(ctx, x, y)
  Text(ctx, name, x - width * 0.5, y - 29 - height * 0.5, {
    fontWeight: 800,
    fontSize: 16,
    color: '#0074BC',
    textAlign: 'left',
  })
  Text(ctx, distance.toString(), x - width * 0.5, y - 12 - height * 0.5, {
    fontWeight: 600,
    fontSize: 12,
    color: '#373737',
    textAlign: 'left',
  })
  if (plants.length > 1) {
    const [maxWidth, maxHeight] = TextGroup(
      ctx,
      x,
      y + height * 0.5 + 13,
      [
        ['КЦ-1'],
        plants[0].gpu_name,
        `Pкс = ${plants[0].p}`,
        `E = ${plants[0].e}`,
        `Tкс = ${plants[0].t}`,
        `Nn = ${plants[0].n}`,
        `K = ${plants[0].k}`,
        '',
        '',
        ['КЦ-2'],
        plants[1].gpu_name,
        `Pкс = ${plants[1].p}`,
        `E = ${plants[1].e}`,
        `Tкс = ${plants[1].t}`,
        `Nn = ${plants[1].n}`,
        `K = ${plants[1].k}`,
      ],
      {
        fontSize: 15,
        fontWeight: 600,
        color: '#373737',
        padding: 12,
        textAlign: 'left',
        textBaseLine: 'top',
        borderColor: '#E4E9EF',
      },
    )
    line(ctx, [x + 12, y + maxHeight * 0.5 + 26], [x + maxWidth - 12, y + maxHeight * 0.5 + 26], 1, '#E4E9EF')
  } else {
    TextGroup(
      ctx,
      x,
      y + height * 0.5 + 13,
      [
        plants[0].gpu_name,
        `Pкс = ${plants[0].p}`,
        `E = ${plants[0].e}`,
        `Tкс = ${plants[0].t}`,
        `Nn = ${plants[0].n}`,
        `K = ${plants[0].k}`,
      ],
      {
        fontSize: 15,
        fontWeight: 600,
        color: '#373737',
        padding: 12,
        textAlign: 'left',
        textBaseLine: 'top',
        borderColor: '#E4E9EF',
      },
    )
  }
}
