import React from 'react'
import { useSelector } from 'react-redux'
import { selectBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, ISelectParams } from 'store/types'
import { GPUChoicesWindow } from '../GPUChoicesWindow/gpuChoicesWindow'
import { ACUChoicesWindow } from './ACUChoicesWindow'

interface ISelectWindow {
  closeWindow(): void
}

export const SelectWindow: React.FC<ISelectWindow> = ({ closeWindow }) => {
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ISelectParams)

  return (
    <>
      {' '}
      {blockingWindowParams?.objectType === 'GPU' && <GPUChoicesWindow closeWindow={closeWindow} />}
      {blockingWindowParams?.objectType === 'ACU' && <ACUChoicesWindow closeWindow={closeWindow} />}
    </>
  )
}

export default SelectWindow
