import * as React from 'react'

function IconArrayDown(props: any) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.5 8.5l2.5 3 2.5-3h-5z'
        fill='#8896A4'
        stroke='#8896A4'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default IconArrayDown
