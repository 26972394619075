import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IDNLayersSlice } from './types'

export const initialState: IDNLayersSlice = {
  taskId: undefined,
  createTask: false,
  taskSettings: {
    layers: [],
  },
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  updateCount: 0,
}

export const DNLayersSlice = createSlice({
  name: 'DNLayers',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IDNLayersSlice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IDNLayersSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskSettings: (state, action: PayloadAction<IDNLayersSlice['taskSettings']>) => {
      return { ...state, taskSettings: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IDNLayersSlice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IDNLayersSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IDNLayersSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    setDNLayers: (state, action: PayloadAction<IDNLayersSlice['DNLayers']>) => {
      return { ...state, DNLayers: action.payload }
    },
    increaseUpdateCount: (state) => {
      return { ...state, updateCount: state.updateCount + 1 }
    },
    resetDNLayersSliceState: () => {
      return initialState
    },
  },
})

export const {
  setCreateTask,
  setTaskId,
  setTaskCalculationProgress,
  setTaskStatus,
  setErrorMessage,
  setDNLayers,
  setTaskSettings,
  increaseUpdateCount,
  resetDNLayersSliceState,
} = DNLayersSlice.actions

export const selectCreateTask = (state: RootState) => state.DNLayers.createTask

export const selectTaskSettings = (state: RootState) => state.DNLayers.taskSettings

export const selectTaskId = (state: RootState) => state.DNLayers.taskId

export const selectTaskCalculationProgress = (state: RootState) => state.DNLayers.taskCalculationProgress

export const selectTaskStatus = (state: RootState) => state.DNLayers.taskStatus

export const selectErrorMessage = (state: RootState) => state.DNLayers.errorMessage

export const selectDNLayers = (state: RootState) => state.DNLayers.DNLayers

export const selectUpdateCount = (state: RootState) => state.DNLayers.updateCount

export default DNLayersSlice.reducer
