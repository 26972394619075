import React, { useState } from 'react'
import classnames from 'classnames'
import Button from 'components/Button'
import './profileControl.scss'
import IconHeight from 'images/IconHeight'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetMapMode,
  selectHighlightCriterion,
  selectMapMode,
  selectProfileDisplayed,
  setHighlightCriterion,
  setProfileDisplayed,
} from 'store/mapSlice'
import ProfilePanel from './ProfilePanel/profilePanel'

const ProfileControl: React.FC = () => {
  const dispatch = useDispatch()
  const mode = useSelector(selectMapMode)
  const highlightCriterion = useSelector(selectHighlightCriterion)
  const profileDisplayed = useSelector(selectProfileDisplayed)

  React.useEffect(() => {
    if (!profileDisplayed && highlightCriterion) {
      dispatch(setHighlightCriterion(undefined))
    }
    if (profileDisplayed && mode === 'edit') {
      dispatch(resetMapMode())
    }
  }, [profileDisplayed])

  React.useEffect(() => {
    if (mode === 'edit') {
      dispatch(setProfileDisplayed(false))
    }
  }, [mode])

  return (
    <>
      <div className={classnames('height-profile-control', mode)}>
        <Button
          className={classnames('height-profile-control__btn', profileDisplayed && 'selected')}
          onClick={() => dispatch(setProfileDisplayed(!profileDisplayed))}
        >
          <IconHeight />
          Профиль
        </Button>
      </div>
      {profileDisplayed && <ProfilePanel closePanel={() => dispatch(setProfileDisplayed(false))} />}
    </>
  )
}

export const MemoizedHeightProfileControl = React.memo(ProfileControl)
