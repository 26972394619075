import * as React from 'react'

function SelectAreaIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.50008 11.6667C2.70419 11.6667 2.9012 11.7417 3.05372 11.8773C3.20625 12.0129 3.3037 12.1998 3.32758 12.4025L3.33341 12.5V13.3334C3.33344 13.5375 3.40838 13.7345 3.54401 13.887C3.67964 14.0395 3.86654 14.137 4.06925 14.1609L4.16675 14.1667H5.00008C5.21248 14.1669 5.41677 14.2483 5.57122 14.3941C5.72567 14.5399 5.81861 14.7391 5.83106 14.9512C5.84351 15.1632 5.77452 15.372 5.63819 15.5349C5.50187 15.6978 5.30849 15.8024 5.09758 15.8275L5.00008 15.8334H4.16675C3.52907 15.8334 2.91549 15.5897 2.45153 15.1523C1.98758 14.7148 1.70833 14.1166 1.67091 13.48L1.66675 13.3334V12.5C1.66675 12.279 1.75455 12.067 1.91083 11.9108C2.06711 11.7545 2.27907 11.6667 2.50008 11.6667Z'
        fill='white'
      />
      <path
        d='M2.50008 7.5C2.70419 7.50003 2.9012 7.57496 3.05372 7.7106C3.20625 7.84623 3.3037 8.03312 3.32758 8.23583L3.33341 8.33333V9.16667C3.33318 9.37907 3.25185 9.58336 3.10604 9.73781C2.96023 9.89225 2.76095 9.9852 2.54892 9.99764C2.33688 10.0101 2.1281 9.9411 1.96522 9.80478C1.80234 9.66845 1.69767 9.47508 1.67258 9.26417L1.66675 9.16667V8.33333C1.66675 8.11232 1.75455 7.90036 1.91083 7.74408C2.06711 7.5878 2.27907 7.5 2.50008 7.5Z'
        fill='white'
      />
      <path
        d='M5.00008 1.66669C5.21248 1.66692 5.41677 1.74825 5.57122 1.89406C5.72567 2.03987 5.81861 2.23915 5.83106 2.45118C5.84351 2.66322 5.77452 2.872 5.63819 3.03488C5.50187 3.19776 5.30849 3.30243 5.09758 3.32752L5.00008 3.33335H4.16675C3.96264 3.33338 3.76563 3.40832 3.6131 3.54395C3.46058 3.67958 3.36313 3.86648 3.33925 4.06919L3.33341 4.16669V5.00002C3.33318 5.21242 3.25185 5.41671 3.10604 5.57116C2.96023 5.72561 2.76095 5.81855 2.54892 5.831C2.33688 5.84344 2.1281 5.77446 1.96522 5.63813C1.80234 5.50181 1.69767 5.30843 1.67258 5.09752L1.66675 5.00002V4.16669C1.66671 3.52901 1.91035 2.91542 2.34782 2.45147C2.78528 1.98751 3.3835 1.70826 4.02008 1.67085L4.16675 1.66669H5.00008Z'
        fill='white'
      />
      <path
        d='M9.16672 1.66669C9.37912 1.66692 9.58341 1.74825 9.73786 1.89406C9.89231 2.03987 9.98525 2.23915 9.9977 2.45118C10.0101 2.66322 9.94116 2.872 9.80483 3.03488C9.66851 3.19776 9.47513 3.30243 9.26422 3.32752L9.16672 3.33335H8.33339C8.12099 3.33312 7.91669 3.25179 7.76225 3.10598C7.6078 2.96017 7.51486 2.76089 7.50241 2.54886C7.48996 2.33682 7.55895 2.12804 7.69527 1.96516C7.8316 1.80228 8.02497 1.69761 8.23589 1.67252L8.33339 1.66669H9.16672Z'
        fill='white'
      />
      <path
        d='M13.3332 1.66669C13.9709 1.66665 14.5845 1.91029 15.0484 2.34776C15.5124 2.78522 15.7916 3.38344 15.8291 4.02002L15.8332 4.16669V5.00002C15.833 5.21242 15.7517 5.41671 15.6059 5.57116C15.46 5.72561 15.2608 5.81855 15.0487 5.831C14.8367 5.84344 14.6279 5.77446 14.465 5.63813C14.3022 5.50181 14.1975 5.30843 14.1724 5.09752L14.1666 5.00002V4.16669C14.1665 3.96258 14.0916 3.76557 13.956 3.61304C13.8203 3.46051 13.6334 3.36307 13.4307 3.33919L13.3332 3.33335H12.4999C12.2875 3.33312 12.0832 3.25179 11.9288 3.10598C11.7743 2.96017 11.6814 2.76089 11.6689 2.54886C11.6565 2.33682 11.7255 2.12804 11.8618 1.96516C11.9981 1.80228 12.1915 1.69761 12.4024 1.67252L12.4999 1.66669H13.3332Z'
        fill='white'
      />
      <path
        d='M10 10L17.5 12.5L14.1667 14.1667L12.5 17.5L10 10Z'
        fill='white'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SelectAreaIcon
