import React, { PropsWithChildren, ReactElement } from 'react'
import Tab from './Tab'

export type ITab<tabKeyType> = {
  key: tabKeyType
  tabContent: string | JSX.Element
  disabled?: boolean
  error?: boolean
}

interface ITabsProps<tabKeyType> {
  tabList: ITab<tabKeyType>[]
  containerClassName?: string
  tabClassName?: string
  setActiveTab(key: tabKeyType): void
  activeTab: tabKeyType
}

const Tabs = <tabKeyType,>({
  tabList,
  containerClassName,
  tabClassName,
  setActiveTab,
  activeTab,
}: PropsWithChildren<ITabsProps<tabKeyType>>): ReactElement | null => {
  return (
    <div className={containerClassName}>
      {tabList.map((item, index) => (
        <Tab
          key={index}
          tab={item}
          className={tabClassName}
          active={activeTab === item.key}
          setActiveTab={setActiveTab}
        />
      ))}
    </div>
  )
}

export default Tabs
