import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { selectBlockingWindow, setBlockingWindow, resetBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, IDeleteConfirmParams } from 'store/types'
import { RootState } from 'store/configureStore'
import {
  setUpdatedElement,
  selectShowElementInfo,
  setPipelines,
  selectProjectID,
  resetShowIntermediateElementInfo,
} from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'
import {
  selectMapMode,
  selectSelectedGroup,
  setMapPipelines,
  setReferenceLines,
  setSelectedGroup,
} from 'store/mapSlice'
import { getUniqueItems } from '../utils'
import {
  deleteReferenceLineRequest,
  getPipelinesByProjectIDRequest,
  getReferenceLinesRequest,
  updatePipelineByIDRequest,
} from 'services/apiRequests'

const useDeleteGroup = () => {
  const selectedGroup = useSelector(selectSelectedGroup)
  const showElementInfo = useSelector(selectShowElementInfo)
  const projectId = useSelector(selectProjectID) as string
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IDeleteConfirmParams)
  const mapMode = useSelector(selectMapMode)
  const dispatch = useDispatch()
  const [deleteObj, setDeleteObj] = useState(false)

  const compressorStations = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.compressor_stations : [],
  )
  const referenceNodes = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.reference_nodes : [],
  )
  const reductionNodes = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.nodes_reductions : [],
  )
  const heatingStations = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.heating_stations : [],
  )
  const pipelines = useSelector((store: RootState) => store.map.pipelines)
  const getObjectName = () => {
    switch (selectedGroup[0].type.toUpperCase()) {
      case 'COMPRESSOR_STATIONS':
        return compressorStations.find((obj) => obj.id === selectedGroup[0].id)?.name
      case 'REFERENCE_NODES':
        return referenceNodes.find((obj) => obj.id === selectedGroup[0].id)?.name
      case 'NODES_REDUCTIONS':
        return reductionNodes.find((obj) => obj.id === selectedGroup[0].id)?.name
      case 'HEATING_STATIONS':
        return heatingStations.find((obj) => obj.id === selectedGroup[0].id)?.name
      default:
        return
    }
  }
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.code === 'Delete' || (event.code === 'Backspace' && !showElementInfo.isVisible)) {
      const group = selectedGroup.filter((i) => i.type === 'POINTS')
      mapMode === 'edit' &&
        selectedGroup.length > 1 &&
        group.length > 0 &&
        dispatch(
          setBlockingWindow({
            type: 'DELETE_CONFIRM',
            params: { objectType: 'GROUP' } as IDeleteConfirmParams,
          }),
        )

      selectedGroup.length > 1 &&
        mapMode === 'view' &&
        dispatch(
          setBlockingWindow({
            type: 'DELETE_CONFIRM',
            params: { objectType: 'GROUP_VIEW' } as IDeleteConfirmParams,
          }),
        )

      selectedGroup.length === 1 &&
        ['REFERENCE_NODES', 'COMPRESSOR_STATIONS', 'NODES_REDUCTIONS', 'HEATING_STATIONS'].includes(
          selectedGroup[0].type,
        ) &&
        dispatch(
          setBlockingWindow({
            type: 'DELETE_CONFIRM',
            params: {
              objectType: selectedGroup[0].type.toUpperCase(),
              objectID: selectedGroup[0].id,
              objectName: getObjectName(),
            } as IDeleteConfirmParams,
          }),
        )
      if (selectedGroup.length === 1 && selectedGroup[0].type === 'POINTS') {
        const lineIndex = pipelines.findIndex((pipeline) => pipeline.id === selectedGroup[0].lineId)
        if (lineIndex > -1 && selectedGroup[0].index) {
          const pipeline = structuredClone(pipelines[lineIndex]) as definitions['Pipeline']
          if (pipeline.line) {
            pipeline.line.coordinates = [
              ...(pipeline.line.coordinates as any).slice(0, selectedGroup[0].index),
              ...(pipeline.line.coordinates as any).slice(selectedGroup[0].index + 1),
            ]
          }
          dispatch(
            setUpdatedElement({
              elementID: selectedGroup[0].lineId as string,
              type: 'POINTS',
              params: { central_point: pipeline.line } as { [key: string]: unknown },
            }),
          )
          dispatch(setSelectedGroup([]))
          dispatch(resetShowIntermediateElementInfo())
        }
      }
      if (selectedGroup.length === 1 && ['REFERENCE_POINT', 'REFERENCE_LINE'].includes(selectedGroup[0].type)) {
        deleteReferenceLineRequest(
          projectId,
          selectedGroup[0].type === 'REFERENCE_POINT' ? selectedGroup[0].id.split('/p')[0] : selectedGroup[0].id,
        ).then(() => {
          getReferenceLinesRequest(projectId).then((response) => {
            dispatch(setReferenceLines(response.data))
          })
          getPipelinesByProjectIDRequest(projectId).then((response) => {
            dispatch(setMapPipelines(response.data))
          })
        })
      }
      setDeleteObj(true)
    }
  }
  useEffect(() => {
    if (selectedGroup.length) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      setDeleteObj(false)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedGroup, mapMode, showElementInfo.isVisible])

  const updatePolyline = () => {
    const points = selectedGroup.filter((i) => i.type === 'POINTS')
    const pipelinesCopy = structuredClone(pipelines)
    const ids = getUniqueItems(points, 'lineId').map((i) => i.lineId)
    const requests = []
    for (const lineId of ids) {
      const pipeline = pipelinesCopy.find((i) => i.id === lineId)
      const indexList = points
        .filter((p) => p.lineId === lineId)
        .map((p) => p.index as number)
        .sort((a, b) => {
          return b - a
        })
      for (const index of indexList) {
        ;(pipeline?.line?.coordinates as any).splice(index, 1)
      }
      requests.push(
        updatePipelineByIDRequest(projectId, lineId, { line: pipeline?.line } as definitions['PipelineUpdate']),
      )
    }
    dispatch(setSelectedGroup([]))
    dispatch(resetBlockingWindow())
    dispatch(setPipelines([]))
    dispatch(setMapPipelines(pipelinesCopy))
  }

  useEffect(() => {
    if (blockingWindow?.type === 'DELETE_CONFIRM' && blockingWindowParams?.isConfirmed) {
      blockingWindowParams?.objectType === 'GROUP' && updatePolyline()
    }
  }, [blockingWindowParams?.isConfirmed])

  return deleteObj
}

export default useDeleteGroup
