import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setTaskId, setCreateTask, setTaskStatus } from 'store/profileSlice'
import { createProfileTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'
import { definitions } from 'generated/apiTypes'

const useCreateTask = (laying_type: definitions['RouteProfileLayingTypeSection']['laying_type']) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToCreateTask = async () => {
    if (trunkPipelineId && projectId) {
      await createProfileTask(projectId, trunkPipelineId, { laying_type })
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch((err) => {
          if (err.response.status === 400) {
            let message = ''
            if (
              err.response.data.detail ===
              'Pipelines are too long. Total length of pipelines must be less than 10000km.'
            ) {
              message = 'Общая длина трубопровода не должна превышать 10000 км.'
            } else if (
              err.response.data.detail === 'Pipelines are too long. Length of each pipeline must be less than 3000km.'
            ) {
              message = 'Длина каждого участка трубопровода не должна превышать 3000 км.'
            }
            dispatch(
              setBlockingWindow({
                params: {
                  title: 'Ошибка запуска расчета',
                  message: message || 'Непредвиденная ошибка',
                } as IErrorParams,
                type: 'ERROR',
              }),
            )
          } else {
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              }),
            )
          }
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
