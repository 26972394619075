import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { ILoginSlice } from './types'

export const initialState: ILoginSlice = {
  loginRequest: false,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginRequest: (state, action: PayloadAction<ILoginSlice['loginRequest']>) => {
      return { ...state, loginRequest: action.payload }
    },
    resetLoginSliceState: (state: ILoginSlice) => {
      state.loginRequest = initialState.loginRequest
    },
  },
})

export const { setLoginRequest, resetLoginSliceState } = loginSlice.actions

export const selectLoginRequest = (state: RootState) => state.login.loginRequest

export default loginSlice.reducer
