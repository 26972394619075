import { useEffect, useState } from 'react'
import classnames from 'classnames'
import * as React from 'react'
import './infoPopup.scss'

interface IInfoPopupProps {
  focused: boolean
  message: JSX.Element
  inputRef: any
  classNames?: string
}
interface IStyleProps {
  width?: number
  left: number
  top: number
}
export const InfoPopup: React.FC<IInfoPopupProps> = ({ focused, message, classNames, inputRef }) => {
  const infoRef = React.useRef<HTMLInputElement | null>(null)
  const [opacity, setOpacity] = React.useState<boolean>(false)
  const [style, setStyle] = useState<IStyleProps>({
    width: 0,
    left: 0,
    top: 0,
  })
  useEffect(() => {
    if (focused && infoRef.current) {
      const blockHeight = inputRef.current.offsetParent.clientHeight
      const { offsetHeight, offsetTop } = inputRef.current
      const _top = inputRef.current.offsetTop - inputRef.current.offsetParent.scrollTop
      const dTop = _top + offsetHeight + infoRef.current.getBoundingClientRect().height + 3 - blockHeight
      const top =
        dTop > 0
          ? _top - infoRef.current.getBoundingClientRect().height + inputRef.current.offsetParent.scrollTop - 3
          : offsetTop + inputRef.current.clientHeight + 3
      const dLeft =
        inputRef.current.offsetLeft +
        infoRef.current.getBoundingClientRect().width -
        inputRef.current.offsetParent.scrollWidth
      const left = dLeft > 0 ? inputRef.current.offsetLeft - dLeft : inputRef.current.offsetLeft
      setStyle({
        width: inputRef.current.clientWidth,
        top,
        left,
      })
      setOpacity(true)
    } else {
      setStyle({
        left: 0,
        top: 0,
      })
    }
  }, [focused])

  return (
    <div
      ref={infoRef}
      id={'info'}
      className={classnames('info-block field-label-text', classNames, opacity && 'opacity')}
      style={{ ...style, width: inputRef.current && inputRef.current.clientWidth }}
    >
      <>{message}</>
    </div>
  )
}
