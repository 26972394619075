import React, { useContext } from 'react'
import RadioButton from 'components/RadioButton'
import { useSelector } from 'react-redux'
import { selectDNLayers } from 'store/DNLayersSlice'
import { MapContext } from '../map'
import { definitions } from 'generated/apiTypes'
import { AnyLayer } from '../types'
import WarningIcon from '../../../images/WarningIcon'
import PopupMenuLayout from '../../Dropdown/PopupMenuLayout'

export interface ILayerOptionProps {
  id: string
  name: string
  disabled: boolean
}

interface ILayerRadioButtonProps extends ILayerOptionProps {
  value: string
  setSelectedOptionId(id: string): void
}

const LayerRadioButton: React.FC<ILayerRadioButtonProps> = (props) => {
  const mapContext = useContext(MapContext)
  const DNLayers = useSelector(selectDNLayers) as definitions['XYZTiles']
  const [show, setShow] = React.useState<boolean>(false)

  const setVisibility = (visibleLayerId: string) => {
    if (visibleLayerId === 'none') {
      DNLayers.existing_xyz_tiles.map((item) =>
        mapContext?.setLayoutProperty(
          `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`,
          'visibility',
          'none',
        ),
      )
    } else {
      DNLayers?.existing_xyz_tiles.map((item) => {
        const layer = mapContext?.getLayer(`DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`) as AnyLayer
        if (layer) {
          if (mapContext.getLayoutProperty(layer.id, 'visibility') === 'visible') {
            mapContext?.setLayoutProperty(layer.id, 'visibility', 'none')
          }
        }
      })
      mapContext?.setLayoutProperty(visibleLayerId, 'visibility', 'visible')
    }
    props.setSelectedOptionId(visibleLayerId)
  }

  const isOutdated = (): boolean => {
    return (
      DNLayers?.existing_xyz_tiles.find(
        (item) => `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}` === props.id,
      )?.is_outdated || false
    )
  }

  const toggleShowMessage = () => {
    setShow(!show)
  }

  return (
    <div className={'layer-control__layer-btn'}>
      <RadioButton
        id={props.id}
        classNames={'layer-control__layer-btn-radio'}
        value={props.value}
        setValue={(value) => setVisibility(value)}
        label={props.name}
        disabled={props.disabled}
      />
      {isOutdated() && (
        <button className={'layer-control__dn-layers-warning-btn'} onClick={toggleShowMessage}>
          <WarningIcon className={'layer-control__dn-layers-warning-icn'} />
          {show && (
            <PopupMenuLayout closeMenu={() => setShow(false)}>
              <div
                className={'layer-control__dn-layers-warning-text outdated-text field-label-text'}
              >{`Рассчитанный слой устарел`}</div>
            </PopupMenuLayout>
          )}
        </button>
      )}
    </div>
  )
}

export default LayerRadioButton
