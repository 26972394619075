import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import TextInput from 'components/Input/textInput'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, IEditProjectNameParams } from 'store/types'
interface IEditProjectNameWindow {
  closeWindow(): void
}
export const EditProjectNameWindow: React.FC<IEditProjectNameWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IEditProjectNameParams)

  const changeProjectName = (name: string) => {
    dispatch(setBlockingWindow({ params: { ...blockingWindowParams, objectName: name } }))
  }

  const confirm = () => {
    dispatch(setBlockingWindow({ params: { ...blockingWindowParams, isConfirmed: true } }))
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container'>
        <div className={'blocking-window__header'}>
          Переименование проекта
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          <TextInput
            value={blockingWindowParams?.objectName!}
            setValue={changeProjectName}
            label={'Название проекта'}
          />
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={confirm}>Переименовать</Button>
        </div>
      </div>
    </div>
  )
}
