import React from 'react'

import { getCSUnitCostsHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItem } from 'store/directorySlice'

const useGetCSUnitCosts = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetCSUnitCosts = async () => {
    await getCSUnitCostsHandbook().then((res) => {
      dispatch(setDirectoryItem(res.data))
    })
  }

  React.useEffect(() => {
    tryToGetCSUnitCosts().then()
  }, [requestData])

  return
}

export default useGetCSUnitCosts
