import { useDispatch, useSelector } from 'react-redux'
import { selectTaskStatus } from 'store/autoCSSlice'
import { all, spread } from 'axios'
import React from 'react'
import { setBlockingWindow } from 'store/commonSlice'
import { selectProjectID, selectTrunkPipelineID, setProjectDetail } from 'store/projectSlice'
import {
  getHydraulicTaskStatus,
  getNodesByProjectIDRequest,
  getPipelinesByProjectIDRequest,
  getProjectByIDRequest,
} from 'services/apiRequests'
import { setMapNodes, setMapPipelines } from 'store/mapSlice'
import {
  setErrorMessage,
  setTaskCalculationProgress,
  setTaskStatus,
  resetHydraulicSliceState,
} from 'store/hydraulicSlice'
import { IErrorParams } from 'store/types'

const useGetResults = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus) as string
  const projectId = useSelector(selectProjectID) as string
  const trunkPipelineId = useSelector(selectTrunkPipelineID) as string
  const tryToGetResults = async () => {
    all([
      getPipelinesByProjectIDRequest(projectId),
      getNodesByProjectIDRequest(projectId) as any,
      getProjectByIDRequest(projectId),
      getHydraulicTaskStatus(projectId, trunkPipelineId),
    ])
      .then(
        spread((pipelinesRes, nodesRes, projectDetailRes, hydroRes) => {
          dispatch(setMapPipelines(pipelinesRes.data))
          dispatch(setMapNodes(nodesRes.data))
          dispatch(setProjectDetail(projectDetailRes.data))
          dispatch(resetHydraulicSliceState())
          dispatch(setTaskCalculationProgress(hydroRes.data.progress))
          dispatch(setTaskStatus(hydroRes.data.status))
          dispatch(setErrorMessage(hydroRes.data.error_message))
        }),
      )
      .catch(() => {
        dispatch(
          setBlockingWindow({
            type: 'ERROR',
            params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
          }),
        )
      })
  }

  React.useEffect(() => {
    if (
      taskStatus &&
      ['Рассчитан', 'Рассчитано частично'].includes(taskStatus) /*&& taskResults?.pipelines.length === 0*/
    )
      tryToGetResults().then()
  }, [taskStatus])

  return
}

export default useGetResults
