import { canvasPoint } from './types'

export const ComputedStyle = getComputedStyle(document.documentElement)

export const matrix = (x: number, y: number, scaleX: number, scaleY: number) => {
  return new DOMMatrix([scaleX, 0, 0, scaleY, x, y])
}
export const roundedRect = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  lineWidth: number,
  lineColor: string,
  fillColor: string,
) => {
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = lineColor
  ctx.fillStyle = fillColor
  ctx.beginPath()
  ctx.roundRect(x - width / 2, y - height / 2, width, height, radius)
  ctx.fill()
  ctx.stroke()
}

export const line = (
  ctx: CanvasRenderingContext2D,
  start: canvasPoint,
  end: canvasPoint,
  lineWidth: number,
  lineColor: string,
) => {
  ctx.beginPath()
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = lineColor
  ctx.moveTo(...start)
  ctx.lineTo(...end)
  ctx.stroke()
}

export const arrow = (ctx: CanvasRenderingContext2D, x: number, y: number, scaleX = 1, scaleY = 1) => {
  const p = new Path2D()
  const path = new Path2D(
    'M56.07 1.693A1 1 0 0157.243.71l37.35 6.633c1.095.195 1.101 1.764.008 1.968l-37.35 6.97a1 1 0 01-1.183-.984V11.15a1 1 0 00-1-1H1a1 1 0 01-1-1V7.457a1 1 0 011-1h54.07a1 1 0 001-1V1.693z',
  )
  ctx.fillStyle = 'black'
  ctx.strokeStyle = 'black'
  p.addPath(path, matrix(x, y, scaleX, scaleY))
  ctx.stroke(p)
  ctx.fill(p)
}

export const endpoint = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  const p = new Path2D()
  const path = new Path2D(
    'M.52 6.56c0-2.858 1.614-5.058 2.865-6.236.402-.378 1.028-.27 1.346.18l.577.817c.318.452.21 1.08-.166 1.484-.766.824-1.623 2.174-1.623 3.755 0 2.384 1.146 4.146 1.591 4.617.05.054.082.079.15.133.064.05.159.126.33.272.283.243.629.577.944 1.071.63.988.985 2.37.985 4.495 0 2.382-.951 4.455-2.113 6.001-.876 1.166-1.94 2.124-2.952 2.723-.475.281-1.056.023-1.255-.493l-.36-.933c-.198-.515.058-1.107.504-1.432a8.127 8.127 0 001.665-1.667c.88-1.171 1.511-2.627 1.511-4.199 0-1.827-.311-2.563-.514-2.881a1.769 1.769 0 00-.368-.407l-.083-.068a7.159 7.159 0 01-.625-.555C2.04 12.297.519 9.823.519 6.56z',
  )
  ctx.fillStyle = 'black'
  p.addPath(path, matrix(x, y, 1, 1))
  ctx.fill(p)
}
export const station = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  roundedRect(ctx, x, y, 16, 28, 12, 3, '#0074BC', 'white')
}
