import * as React from 'react'

function ListIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5112_21983)'>
        <path d='M7.5 5H16.6667' stroke='#2C3E50' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.5 10H16.6667' stroke='#2C3E50' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M7.5 15H16.6667' stroke='#2C3E50' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M4.16797 5V5.00833'
          stroke='#2C3E50'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.16797 10V10.0083'
          stroke='#2C3E50'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.16797 15V15.0083'
          stroke='#2C3E50'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5112_21983'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default ListIcon
