import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectValidation } from 'store/validationSlice'

const useGetValidationMessage = (id?: string, level1Field?: string, level2Field?: string, field?: string) => {
  const validation = useSelector(selectValidation)
  const [message, setMessage] = useState<string[] | boolean | undefined>()
  React.useEffect(() => {
    setMessage(undefined)
    if (id && level1Field && !level2Field && field) {
      const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
      if (index > -1) {
        setMessage((validation[index].errorMessage as Record<any, any>)[field as any])
      } else {
        setMessage(undefined)
      }
    } else if (id && level1Field && level2Field && field) {
      const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
      if (index > -1) {
        if ((validation[index]?.errorMessage as Record<any, any>)[level2Field as any])
          setMessage((validation[index]?.errorMessage as Record<any, any>)[level2Field as any][field as any])
      } else {
        setMessage(undefined)
      }
    } else if (id && field) {
      const index = validation.findIndex((item) => item.id === id && item.field === field)
      if (index > -1) {
        setMessage(validation[index].errorMessage as string[])
      } else {
        setMessage(undefined)
      }
    } else if (id && level1Field && !level2Field && !field) {
      const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
      if (index > -1) {
        setMessage(validation[index].errorMessage !== undefined)
      } else {
        setMessage(undefined)
      }
    } else if (id && level1Field && level2Field && !field) {
      const index = validation.findIndex((item) => item.id === id && item.field === level1Field)
      if (index > -1) {
        setMessage((validation[index].errorMessage as Record<any, any>)[level2Field as any] !== undefined)
      } else {
        setMessage(undefined)
      }
    }
  }, [validation, id, level1Field, level2Field, field])

  return message
}

export default useGetValidationMessage
