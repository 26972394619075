import { Layer } from './Layer'
import { getRotation, getSourceData, joinLines, layerProps, splitLineByPoint } from './utils'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetDragNodeAlong,
  selectDragNodeAlong,
  selectMapPipelines,
  selectMapSource,
  selectMapSubmode,
  setDragNodeAlong,
} from 'store/mapSlice'
import { MapContext } from './map'
import { GeoJSONSource } from 'maplibre-gl'
import { selectCompressorStationsOrder, selectShowElementInfo } from '../../store/projectSlice'
import { getCoords, length, lineString, Point } from '@turf/turf'
interface IDragAlongLayer {}
export const DragAlongLayer: React.FC<IDragAlongLayer> = () => {
  const mapContext = useContext(MapContext)
  const pipelines = useSelector(selectMapPipelines)
  const source = useSelector(selectMapSource)
  const isActiveMode = useSelector(selectMapSubmode) === 'dragAlong'
  const draggingNode = useSelector(selectDragNodeAlong)
  const elementInfo = useSelector(selectShowElementInfo)
  const csOrder = useSelector(selectCompressorStationsOrder)
  const [initRotation, setInitRotation] = useState<number>()
  const [initCoords, setInitCoords] = useState<[number, number]>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isActiveMode && draggingNode?.id) {
      mapContext?.setLayoutProperty(
        'drag-along__layer',
        'icon-image',
        draggingNode?.type === 'REFERENCE_NODES' ? 'drag-sm-icn' : 'drag-icn',
      )
      mapContext?.setFilter('drag-along__layer', ['==', 'id', draggingNode.id])
    } else {
      mapContext?.setFilter('drag-along__layer', ['==', 'id', ''])
    }
  }, [isActiveMode, draggingNode?.id])
  useEffect(() => {
    if (isActiveMode && draggingNode?.rotation !== undefined) {
      mapContext?.setLayoutProperty('drag-along__layer', 'icon-rotate', draggingNode.rotation)
    }
  }, [isActiveMode, draggingNode?.rotation])
  useEffect(() => {
    if (draggingNode?.reset) {
      calcDistanceInfo()
      dispatch(
        setDragNodeAlong({
          rotation: initRotation,
          lat: initCoords && initCoords[1],
          lng: initCoords && initCoords[0],
          reset: false,
        }),
      )
    }
  }, [draggingNode?.reset])
  useEffect(() => {
    if (isActiveMode && draggingNode?.id) {
      const data = getSourceData(mapContext?.getSource('major__source') as GeoJSONSource).features
      const dragNode = data.find((f) => f.properties!.id === draggingNode.id)
      const rotation =
        getRotation(
          draggingNode.id,
          data.filter((f) => f.properties!.type === 'PIPELINE'),
        ) - 90
      setInitRotation(rotation)
      setInitCoords(getCoords(dragNode?.geometry as any) as [number, number])
      dispatch(
        setDragNodeAlong({
          rotation,
          update: false,
        }),
      )
    }
  }, [isActiveMode, draggingNode?.update])
  useEffect(() => {
    if (draggingNode?.lat && draggingNode?.lng) {
      calcDistanceInfo()
    }
  }, [draggingNode?.lat, draggingNode?.lng])
  const distanceToNearestCS = (cs: any, line: any) => {
    const [part1, part2] = splitLineByPoint(line, cs.geometry)
    return parseFloat(length(lineString(part1)).toFixed(2))
  }
  const calcDistanceInfo = () => {
    const data = getSourceData(mapContext?.getSource('major__source') as GeoJSONSource).features
    const node = data.find((f) => f.properties!.id === elementInfo.objectId)
    const line = joinLines(source?.node_id as string, pipelines)
    const [part1, part2] = splitLineByPoint(line, node?.geometry as Point)
    const currentCSIndex = csOrder.findIndex((cs) => cs.id === elementInfo.objectId)
    const csFromStart = currentCSIndex > 0 ? csOrder[currentCSIndex - 1] : undefined
    const csFromEnd = currentCSIndex < csOrder.length - 1 ? csOrder[currentCSIndex + 1] : undefined
    const distance_to_cs1 = csFromStart
      ? distanceToNearestCS(
          data.find((f) => f.properties!.id === csFromStart!.id),
          part1.reverse(),
        )
      : undefined
    const distance_to_cs2 = csFromEnd
      ? distanceToNearestCS(
          data.find((f) => f.properties!.id === csFromEnd!.id),
          part2,
        )
      : undefined
    const cs1_name = csFromStart?.name
    const cs2_name = csFromEnd?.name
    dispatch(
      setDragNodeAlong({
        distance_to_source: parseFloat(length(lineString(part1)).toFixed(2)),
        distance_to_cs1,
        distance_to_cs2,
        cs1_name,
        cs2_name,
      }),
    )
  }
  useEffect(() => {
    if (
      elementInfo.objectId &&
      elementInfo.objectType &&
      ['COMPRESSOR_STATIONS', 'REFERENCE_NODES', 'NODES_REDUCTIONS', 'HEATING_STATIONS'].includes(
        elementInfo.objectType,
      )
    ) {
      dispatch(resetDragNodeAlong())
      calcDistanceInfo()
    }
  }, [elementInfo.objectId])
  return (
    <Layer
      sourceID={'major__source'}
      baseLayer={'drag-along__layer'}
      features={[]}
      layerType={'symbol'}
      filter={['==', 'id', '']}
      layout={{
        'icon-image': 'drag-icn',
        ...layerProps,
      }}
    />
  )
}
