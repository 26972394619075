import { ReductionNodeIcon } from '../icons/reductionNodeIcon'
import { TextGroup } from './textGroup'
import { Text } from './text'

export const ReductionNode = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  name: string,
  distance: string,
  props: any,
) => {
  const height = 26
  const width = 28
  Text(ctx, name, x - width * 0.5, y - 29 - height * 0.5, {
    fontWeight: 800,
    fontSize: 16,
    color: '#0074BC',
    textAlign: 'left',
  })
  Text(ctx, distance, x - width * 0.5, y - 12 - height * 0.5, {
    fontWeight: 600,
    fontSize: 12,
    color: '#373737',
    textAlign: 'left',
  })
  ReductionNodeIcon(ctx, x, y)
  TextGroup(ctx, x, y + height * 0.5 + 13, [`Pв = ${props.p}`, `Tв = ${props.t}`], {
    fontSize: 15,
    fontWeight: 600,
    color: '#373737',
    padding: 12,
    textAlign: 'left',
    textBaseLine: 'top',
    borderColor: '#E4E9EF',
  })
}
