import React, { useEffect, useMemo, useRef, useState } from 'react'
import { EditElementPanel } from '../editElementPanel'
import './trunkPipelineEditPanel.scss'
import { ITableColumn } from 'components/Table/types'
import Dropdown from 'components/Dropdown/Dropdown'
import PlusIcon from 'images/PlusIcon'
import Button from 'components/Button'
import NumberInput from 'components/Input/numberInput'
import { definitions } from 'generated/apiTypes'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { resetBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { FilterButton } from '../../filterButton'
import { connect, useDispatch } from 'react-redux'
import { IBlockingWindow, IDeleteConfirmParams, IEditPipeTypeParams, ISelectParams, IUpdatedElement } from 'store/types'
import { setTrunkPipeline, setUpdatedElement, updatePipeline, resetUpdatedElement } from 'store/projectSlice'
import { createPipeRequest, deletePipeByIDRequest, getPipelineByIDRequest } from 'services/apiRequests'
import update from 'immutability-helper'
import { classOptions, twoDigitsFormatter } from 'pages/utils'
import { Table } from 'components/Table/Table/Table'
import TextInput from 'components/Input/textInput'
import Resizable from 'components/Resizeable/resizable'
import Handle from 'components/Handle/handle'
import IntegerInput from 'components/Input/integerInput'
import classnames from 'classnames'

const columns = [
  {
    title: 'Участок',
    type: 'Text',
    key: 'name',
    editable: false,
  },
  {
    title: 'Длина, км',
    type: 'Text',
    key: 'length',
    editable: false,
  },
  {
    title: (
      <>
        Приток/отбор газа в начале <br /> участка, млн.куб.м/сутки
      </>
    ),
    type: 'Number',
    key: 'gas_inflow__mn_m3_per_day',
    editable: true,
    signed: true,
    noEmpty: true,
  },
  {
    title: 'К. гидравл. эффективности',
    type: 'Number',
    key: 'hydraulic_efficiency',
    editable: true,
  },
  {
    title: (
      <>
        К. теплопередачи от газа в<br />
        грунт, Вт/м2К
      </>
    ),
    type: 'Number',
    key: 'heat_transfer__w_per_m2_per_k',
    editable: true,
  },
  {
    title: (
      <>
        Темп. грунта на оси залож.,
        <br />
        °C
      </>
    ),
    type: 'Number',
    key: 'temperature__C',
    editable: true,
  },
  {
    title: 'Изменение высоты, м',
    type: 'Number',
    key: 'height__m',
    editable: false,
  },
]

interface ITrunkPipelineEditPanel {
  sourceID?: string
  trunkPipeline?: definitions['TrunkPipeline']
  pipelines?: definitions['DetailedPipeline'][]
  projectID: string
  pipeTypes: definitions['PipeType'][]
  updatedElement: IUpdatedElement
  blockingWindow: IBlockingWindow
}
const TrunkPipelineEditPanel: React.FC<ITrunkPipelineEditPanel> = ({
  sourceID,
  trunkPipeline,
  pipelines,
  projectID,
  pipeTypes,
  updatedElement,
  blockingWindow,
}) => {
  const dispatch = useDispatch()
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ISelectParams)
  const [editPipelineID, setEditPipelineID] = useState('')
  const [width, setWidth] = React.useState<number>()
  const tableRef = useRef<HTMLDivElement>(null)
  const [fullScreenMode, setFullScreenMode] = React.useState<boolean>(false)
  const [pipelineIDUpdated, setPipelineIDUpdated] = useState<string | undefined>()
  const trunkPipelineLength = useMemo(
    () => pipelines?.map((item) => item.length__m).reduce((a, b) => a + b, 0),
    [pipelines],
  )
  useEffect(() => {
    if (updatedElement.type === 'PIPE' && Object.keys(updatedElement.params!).includes('pipe_type_id')) {
      const editPipeline = pipelines?.find(
        (pipeline) => pipeline.id === editPipelineID,
      ) as definitions['DetailedPipeline']
      const pipeIndex = editPipeline?.pipes.findIndex((pipe) => pipe.id === updatedElement.elementID)
      const pipes = structuredClone(editPipeline?.pipes)
      pipes[pipeIndex] = {
        ...editPipeline?.pipes[pipeIndex],
        pipe_type: pipeTypes.find(
          (pipe) => pipe.id === (updatedElement.params! as definitions['PipeUpdate'])['pipe_type_id'],
        ) as definitions['PipeType'],
      }
      dispatch(
        updatePipeline({
          ...editPipeline,
          pipes,
        }),
      )
    }
  }, [updatedElement])
  useEffect(() => {
    if (
      (blockingWindowParams as any)?.objectType === 'PIPE' &&
      blockingWindow.type === 'DELETE_CONFIRM' &&
      blockingWindowParams?.isConfirmed
    ) {
      deletePipeByIDRequest(projectID, blockingWindowParams.objectID as string).then(() => {
        const editPipeline = pipelines?.find(
          (pipeline) => pipeline.id === editPipelineID,
        ) as definitions['DetailedPipeline']
        dispatch(
          updatePipeline({
            ...editPipeline,
            pipes: editPipeline.pipes.filter((pipe) => pipe.id !== blockingWindowParams.objectID),
          }),
        )
        dispatch(resetBlockingWindow())
      })
    }
  }, [blockingWindowParams?.isConfirmed])
  const getDataSource = (pipelineList: definitions['DetailedPipeline'][]) => {
    const data: any = []
    if (pipelineList.length > 0) {
      let currentID = sourceID
      const visited: string[] = []
      while (currentID) {
        const currentPipe = pipelineList.find(
          (obj) =>
            (obj.start_node.id === currentID && !visited.includes(obj.end_node.id)) ||
            (obj.end_node.id === currentID && !visited.includes(obj.start_node.id)),
        )
        if (currentPipe) {
          data.push({
            id: currentPipe.id,
            name: `${currentPipe.start_node.name} -> ${currentPipe.end_node.name}`,
            length: twoDigitsFormatter.format(currentPipe.length__m * 0.001),
            gas_inflow__mn_m3_per_day: currentPipe.gas_inflow__mn_m3_per_day,
            hydraulic_efficiency: currentPipe.hydraulic_efficiency,
            heat_transfer__w_per_m2_per_k: currentPipe.heat_transfer__w_per_m2_per_k,
            temperature__C: currentPipe.temperature__C,
            height__m: currentPipe.height__m,
            expandable: true,
          })
          visited.push(currentID)
          currentID = currentPipe.start_node.id === currentID ? currentPipe.end_node.id : currentPipe.start_node.id
        } else break
      }
    }
    return data
  }
  const dataSource = useMemo(() => getDataSource(pipelines as any), [pipelines])
  const createPipe = (pipelineID: string) => {
    createPipeRequest(projectID, pipelineID, {
      number_of_pipes: 1,
      pipe_type_id: pipeTypes[0].id,
    }).then((res) => {
      const pipeline = pipelines?.find((pipeline) => pipeline.id === pipelineID) as definitions['DetailedPipeline']
      dispatch(
        updatePipeline({
          ...pipeline,
          pipes: [res.data, ...pipeline.pipes],
        }),
      )
    })
  }
  const setPipeValue = (pipelineID: string, pipeID: string, value: number, fieldName: string) => {
    const pipeline = pipelines?.find((pipeline) => pipeline.id === pipelineID)
    const pipeIndex = pipeline?.pipes.findIndex((pipe) => pipe.id === pipeID) as number
    dispatch(
      updatePipeline({
        ...pipeline,
        pipes: update(pipeline?.pipes, {
          [pipeIndex]: {
            $set: {
              ...pipeline?.pipes[pipeIndex],
              [fieldName]: value,
            } as definitions['Pipe'],
          },
        }) as definitions['Pipe'][],
      } as definitions['DetailedPipeline']),
    )
    dispatch(
      setUpdatedElement({
        elementID: pipeID,
        type: 'PIPE',
        params: { [fieldName]: value },
      }),
    )
  }
  const setPipelineValue = (pipelineID: string, value: any, fieldName: string) => {
    const pipeline = pipelines?.find((pipeline) => pipeline.id === pipelineID) as definitions['DetailedPipeline']
    dispatch(
      updatePipeline({
        ...pipeline,
        [fieldName]: value,
      }),
    )
    dispatch(
      setUpdatedElement({
        elementID: pipelineID,
        type: 'PIPELINE',
        params: { [fieldName]: value },
      }),
    )
  }
  const setTrunkPipelineValue = (value: any, fieldName: string) => {
    dispatch(
      setTrunkPipeline({
        ...trunkPipeline,
        [fieldName]: value,
      } as definitions['TrunkPipeline']),
    )
    dispatch(
      setUpdatedElement({
        elementID: trunkPipeline?.id as string,
        type: 'TRUNK_PIPELINE',
        params: { [fieldName]: value },
      }),
    )
  }
  const updatePipeType = (pipeline_id: string, pipe_id: string, value: number | string, fieldName: string) => {
    setPipelineIDUpdated(pipeline_id)
    dispatch(
      setUpdatedElement({
        elementID: pipe_id,
        type: 'PIPE_TYPE',
        params: { [fieldName]: value },
      }),
    )
  }

  useEffect(() => {
    if (updatedElement.type === 'PIPE_TYPE' && updatedElement.isUpdated && pipelineIDUpdated) {
      getPipelineByIDRequest(projectID, pipelineIDUpdated).then((res) => dispatch(updatePipeline(res.data)))
      dispatch(resetUpdatedElement())
      setPipelineIDUpdated(undefined)
    }
  }, [updatedElement])
  const renderExpandRow = (key: string) => {
    const pipeline = pipelines?.find((pipeline) => pipeline.id === key)
    if (pipeline)
      return (
        <div key={key} className={'pipes-table'} style={{ width: `${width ? `${width - 2 * 20 - 2 * 12}px` : ''}` }}>
          <div className={'pipes-table__header'}>
            Нити
            <Button mode={'secondary'} onClick={() => createPipe(key)}>
              <PlusIcon />
            </Button>
          </div>
          <div className={'pipes-table__body'}>
            {pipeline.pipes?.map((pipe, index) => [
              <div key={pipe.id} className={'pipe-params__body-row'}>
                <div className={'marker-index'}>{index + 1}</div>
                <div className={'pipes-number'}>
                  <IntegerInput
                    label={'Кол-во нитей'}
                    value={pipe.number_of_pipes}
                    fieldName={'number_of_pipes'}
                    setValue={(value, fieldName) => setPipeValue(key, pipe.id, value, fieldName as string)}
                    noEmpty
                  />
                </div>
                <NumberInput
                  label={'Внешний диаметр'}
                  value={pipe.pipe_type.diameter__mm}
                  fieldName={'diameter__mm'}
                  unit={'мм'}
                  disabled
                />
                <NumberInput
                  label={'Толщина стенки'}
                  value={pipe.pipe_type.thickness__mm}
                  fieldName={'thickness__mm'}
                  unit={'мм'}
                  setValue={(value, label: string) => updatePipeType(pipeline.id, pipe.id, value, label)}
                />
                <NumberInput
                  label={'Шероховатость'}
                  unit={'мм'}
                  value={pipe.pipe_type.roughness__mm}
                  fieldName={'roughness__mm'}
                  setValue={(value, label: string) => updatePipeType(pipeline.id, pipe.id, value, label)}
                />
                <Dropdown
                  options={[{ name: 'K 60' }, { name: 'K 65' }, { name: 'X 70' }, { name: 'X 80' }]}
                  displayedField={'name'}
                  panelRef={tableRef}
                  selectedValue={{ name: pipe.pipe_type.steel_name }}
                  setSelectedValue={(value) => updatePipeType(pipeline.id, pipe.id, value.name, 'steel_name')}
                  label={'Марка стали'}
                />
                <div className={'pipe-params__type-btn'}>
                  <FilterButton
                    onClick={() => {
                      setEditPipelineID(key)
                      dispatch(
                        setBlockingWindow({
                          type: 'EDIT_PIPE_TYPE',
                          params: {
                            objectID: pipe.id,
                            objectType: 'PIPE',
                            currentPipeTypeID: pipe.pipe_type.id,
                          } as IEditPipeTypeParams,
                        }),
                      )
                    }}
                  />
                </div>
                {pipeline?.pipes?.length > 1 ? (
                  <div className={'pipe-params__delete-btn'}>
                    <DeleteButton
                      onClick={() => {
                        setEditPipelineID(key)
                        dispatch(
                          setBlockingWindow({
                            type: 'DELETE_CONFIRM',
                            params: { objectType: 'PIPE', objectID: pipe.id } as IDeleteConfirmParams,
                          }),
                        )
                      }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>,
              <hr key={`${pipe.id}-hr`} />,
            ])}
          </div>
        </div>
      )
  }
  return (
    <Resizable
      className={classnames('trunk-pipeline__resizable-container', fullScreenMode && 'full-screen')}
      handleElement={<Handle />}
      right
      setWidth={setWidth}
    >
      <EditElementPanel
        className={'trunk-pipeline__panel'}
        title={'Общие свойства магистрального трубопровода'}
        fullScreenAllow={true}
        changeFullScreenMode={setFullScreenMode}
      >
        <div className={'edit-element-panel__table'} ref={tableRef}>
          <Dropdown
            options={classOptions}
            displayedField={'name'}
            selectedValue={classOptions.find((option) => option.id === trunkPipeline?.class_)}
            label={'Класс'}
            setSelectedValue={(value) => setTrunkPipelineValue(value.id, 'class_')}
          />
          <NumberInput
            value={trunkPipeline?.pressure__MPa}
            unit={'МПа'}
            label={'Рабочее давление'}
            fieldName={'pressure__MPa'}
            setValue={setTrunkPipelineValue}
            signed
          />
          <TextInput
            value={trunkPipelineLength ? twoDigitsFormatter.format(trunkPipelineLength * 0.001) : ''}
            label={'Длина, км'}
            disabled
          />
        </div>
        {dataSource.length ? (
          <Table
            className={'trunk-pipeline-table__container'}
            columns={columns as ITableColumn[]}
            dataSource={dataSource}
            expandColumn
            expandRow={renderExpandRow}
            setValue={setPipelineValue}
          />
        ) : (
          <></>
        )}
      </EditElementPanel>
    </Resizable>
  )
}

const mapStateToProps = (state: any) => ({
  sourceID: state.project.detail ? state.project.detail.source.id : undefined,
  projectID: state.project.detail.id,
  pipeTypes: state.project.pipeTypes,
  updatedElement: state.project.updatedElement,
  blockingWindow: state.common.blockingWindow,
})
export default connect(mapStateToProps)(TrunkPipelineEditPanel)
