import * as React from 'react'

function DotsIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 10.833a.833.833 0 100-1.666.833.833 0 000 1.666zM10 16.667A.833.833 0 1010 15a.833.833 0 000 1.667zM10 5a.833.833 0 100-1.667A.833.833 0 0010 5z'
        stroke='#8896A4'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default DotsIcon
