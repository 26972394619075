import { Coord, getCoords, lineString, MultiLineString, nearestPointOnLine, point, Position } from '@turf/turf'
export const nearestPointOnMultiLine = (multiLines: MultiLineString, pt: Coord) => {
  /**
   * Находит ближайшую точку, лежащую MultiLineString
   * @param multiLines MultiLineString поиска ближайшей точки
   * @param pt точка от которой ищется ближайшая точка
   */
  let ptOnLine = pt
  let dist = Infinity // расстояние между точкой и точкой на линии
  let multiLineIndex = -1 // индекс линии
  let pointIndex = -1 // индекс точки
  getCoords(multiLines).forEach((line, lIndex) => {
    const nearestPoint = nearestPointOnLine(lineString(line), pt)
    if (nearestPoint?.properties && nearestPoint.properties.dist && nearestPoint.properties.dist < dist) {
      ptOnLine = getCoords(nearestPoint)
      dist = nearestPoint.properties.dist
      multiLineIndex = lIndex
      pointIndex = nearestPoint.properties.index as number
    }
  })
  return point(ptOnLine as Position, { dist, multiLineIndex, pointIndex })
}
