import Tabs, { ITab } from 'components/Tabs/tabs'
import React from 'react'
import './stations.scss'
import { ResultTable2 } from './resultTable2'
import { ResultTable1 } from './resultTable1'

type tabKey = 'TABLE1' | 'TABLE2'

const tabList: ITab<tabKey>[] = [
  { key: 'TABLE1', tabContent: '1' },
  { key: 'TABLE2', tabContent: '2' },
]
export const Stations: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<tabKey>('TABLE1')
  return (
    <>
      <Tabs
        tabList={tabList}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        containerClassName={'hydraulic-results__stations-tabs'}
        tabClassName={'hydraulic-results__stations-tab'}
      />
      {activeTab === 'TABLE1' && <ResultTable1 />}
      {activeTab === 'TABLE2' && <ResultTable2 />}
    </>
  )
}
