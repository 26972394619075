import * as React from 'react'

class IconHeight extends React.Component {
  render() {
    return (
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.572 4.5C13.572 4.22386 13.3481 4 13.072 4H7.21484C6.9387 4 6.71484 4.22386 6.71484 4.5V5.21429C6.71484 5.49043 6.9387 5.71429 7.21484 5.71429H8.78604C9.06219 5.71429 9.28604 5.93814 9.28604 6.21429V8.64282C9.28604 8.91896 9.06219 9.14282 8.78604 9.14282H8.46049C8.18435 9.14282 7.96049 9.36668 7.96049 9.64282V10.3571C7.96049 10.6333 8.18435 10.8571 8.46049 10.8571H8.78604C9.06219 10.8571 9.28604 11.081 9.28604 11.3571V13.7857C9.28604 14.0619 9.06219 14.2857 8.78604 14.2857H7.21484C6.9387 14.2857 6.71484 14.5096 6.71484 14.7857V15.5C6.71484 15.7762 6.9387 16 7.21484 16H13.072C13.3481 16 13.572 15.7762 13.572 15.5V14.7857C13.572 14.5096 13.3481 14.2857 13.072 14.2857H11.5003C11.2242 14.2857 11.0003 14.0619 11.0003 13.7857V11.3571C11.0003 11.081 11.2242 10.8571 11.5003 10.8571H11.8905C12.1666 10.8571 12.3905 10.6333 12.3905 10.3571V9.64282C12.3905 9.36668 12.1666 9.14282 11.8905 9.14282H11.5003C11.2242 9.14282 11.0003 8.91896 11.0003 8.64282V6.21429C11.0003 5.93814 11.2242 5.71429 11.5003 5.71429H13.072C13.3481 5.71429 13.572 5.49043 13.572 5.21429V4.5Z'
          fill='white'
        />
      </svg>
    )
  }
}

export default IconHeight
