import { ChartOptions } from 'chart.js'
import { profileRound } from '../utils'

export const seriesColor = '#0074BC'

export const options = (
  axisYLabel: string,
  axisXLabel: string,
  minRange: number,
  min: number,
  max: number,
  setStartPoint: (startPoint: number) => void,
  setFinishPoint: (finishPoint: number) => void,
): ChartOptions<'line'> => {
  return {
    //aspectRatio: datasetLength > 80 ? 1.2 : 3,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          onPan: function ({ chart }) {
            setStartPoint(profileRound((chart.scales?.x as any)?.start as number)!)
            setFinishPoint(profileRound((chart.scales?.x as any)?.end as number)!)
          },
        },
        zoom: {
          mode: 'x',
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          onZoom: function ({ chart }) {
            setStartPoint(profileRound((chart.scales?.x as any)?.start as number)!)
            setFinishPoint(profileRound((chart.scales?.x as any)?.end as number)!)
          },
        },
        limits: {
          x: { min, max, minRange },
          y: { min: 'original', max: 'original' },
        },
      },
      tooltip: {
        displayColors: false,
        cornerRadius: 4,
        padding: 8,
        caretSize: 8,
        callbacks: {
          title: function () {
            return ''
          },
          label: function (context) {
            const x = context.parsed.x
            const y = context.parsed.y
            return [`Высотная отметка: ${y} м`, `Точка трубопровода: ${x} км`]
          },
        },
        backgroundColor: '#E4E9EF',
        bodyColor: '#373737',
        bodyFont: { size: 12, weight: '600', family: 'Mulish', lineHeight: '170%' },
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
        },
        align: 'start',
      },
    },
    scales: {
      y: {
        border: { display: false },
        grid: {
          color: '#E4E9EF',
          display: false,
        },
        ticks: {
          padding: 4,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
          /*callback: (value) => {
            if (value === 0) return value
            if (Math.abs(value as number) < 0.01 || Math.abs(value as number) > 99999) {
              return numeral(value).format('0.0[00]e+0')
            }
            return numeral(value).format('0[.]0[00]')
          },*/
        },
        title: {
          display: true,
          text: axisYLabel,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
        },
      },
      x: {
        type: 'linear',
        grid: {
          color: () => {
            return '#E4E9EF'
          },
        },
        border: { display: true },
        ticks: {
          padding: 4,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
          autoSkip: true,
          minRotation: 0,
          stepSize: 0.01,
          autoSkipPadding: 5,
          callback: function (value, i, arr) {
            return profileRound(arr[i].value)
          },
        },
        title: {
          display: true,
          text: axisXLabel,
          color: '#8896A4',
          font: {
            size: 12,
            weight: '600',
            family: 'Mulish, sans-serif',
          },
        },
      },
    },
  }
}
