import { LineLayer } from './LineLayer'
import {
  bbox,
  booleanEqual,
  feature,
  getCoords,
  multiLineString,
  bboxPolygon,
  featureCollection,
  Position,
  bearing,
  Coord,
  Point,
} from '@turf/turf'
import { Layer } from './Layer'
import { AddNode } from './AddNode'
import { defaultZoomDelta, MapContext } from './map'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetMapZoom,
  selectMap,
  setMapZoom,
  setToMapCenter,
  selectNodeCreation,
  resetMapMode,
  resetMapSubmode,
  setNodeCreation,
  setSelectedGroup,
  setReferenceLineCreation,
  setDragNodeAlong,
  setMapCenter,
  setMapPipelines,
  selectHighlightCriterion,
} from 'store/mapSlice'
import { Feature } from 'geojson'
import {
  circleHoverLayerProps,
  circleWarnLayerProps,
  getIconName,
  getIconSize,
  getRotation,
  getSourceData,
  layerProps,
  lineHoverLayerProps,
  lineLayerProps,
  lineLayoutProps,
  lineSelectedLayerProps,
  lineTransparentLayerProps,
  selectAreaLayerProps,
  lineBaseLayerProps,
  pushOrReplace,
  getUniqueFeatures,
  getCurrentGeometry,
  getUniqueItems,
  concatLines,
  splitLineByPoint,
  removeCrossFeatures,
  last,
  addPointToMultiLineString,
  lineHighlightLayerProps,
} from './utils'
import { definitions } from 'generated/apiTypes'
import {
  selectShowElementInfo,
  setShowElementInfo,
  resetShowElementInfo,
  setShowIntermediateElementInfo,
  selectShowIntermediateElementInfo,
  resetShowIntermediateElementInfo,
} from 'store/projectSlice'
import { point, lineString } from '@turf/turf'
import { DragFeature, MapEvent } from './types'
import { elementType } from 'store/types'
import { GeoJSONSource, PointLike, LngLat, FilterSpecification } from 'maplibre-gl'
import useUpdateCoords, { IUpdateCoordsProps } from './hooks/useUpdateCoords'
import { ControlButtons } from './ControlButtons'
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson'
import { CoordsView } from './CoordsView/coordsView'
import { LayerControl } from './LayerControl/layerControl'
import { ModeControl } from './ModeControl/modeControl'
import useSwitchMode from './hooks/useSwitchMode'
import useEditLine from './hooks/useEditLine'
import useGetCurrentRef from './hooks/useGetCurrentRef'
import useDeleteGroup from './hooks/useDeleteGroup'
import { ReferenceLineLayers } from './ReferenceLineLayers'
import useGetReferenceLines from './hooks/useGetReferenceLines'
import Ruler from './Ruler/ruler'
import IntermediateElement from './IntemediateElement/intermediateElement'
import { selectBlockingWindow } from 'store/commonSlice'
import { selectDNLayers } from 'store/DNLayersSlice'
import { DNLayersToMapData } from './DNLayersToMapData'
import { DNLayer } from './DNLayer'
import { KilometerMarksLayer } from './KilometerMarksLayer/kilometerMarksLayer'
import { DragAlongLayer } from './DragAlongLayer'
import { useGenerateRealGeometry } from './hooks/useGenerateRealGeometry'
import { convertToGreatCircle } from './utils/convertToGreatCircle'
import { convertToStraightLine } from './utils/convertToStraightLine'
import { nearestPointOnMultiLine } from './utils/nearestPointOnMultiLine'
import { MemoizedHeightProfileControl } from './PipelineProfile/profileControl'

export const ProjectMap = () => {
  const currentMap = useSelector(selectMap)
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()
  const elementInfo = useSelector(selectShowElementInfo)
  const blockingWindow = useSelector(selectBlockingWindow)
  const DNLayers = useSelector(selectDNLayers)
  const [updatedCoords, setUpdatedCoords] = useState<IUpdateCoordsProps[]>([])
  const intermediateElementInfo = useSelector(selectShowIntermediateElementInfo)
  const [cursorCoords, setCursorCoords] = useState<LngLat>()
  const [zoom, setZoom] = useState<number>(0)
  const nodeCreation = useSelector(selectNodeCreation)
  const highlightCriterion = useSelector(selectHighlightCriterion)
  const [selectArea, setSelectArea] = useState<number[]>([])
  const dragSegment = useRef<DragFeature | undefined>(undefined)
  const dragStart = useRef([0, 0])
  const [resetReferenceLineDrawing, setResetReferenceLineDrawing] = useState(false)
  const [resetMode, setResetMode] = useState(false)
  const [showKilometerMarks, setShowKilometerMarks] = useState(true)
  const rules = useRef({
    select: false,
    selectLine: false,
    selectGroup: false,
    editLine: false,
    points: false,
    segment: false,
    referenceLine: false,
    referencePoint: false,
    dragAlong: false,
  })
  useGetReferenceLines()
  useEditLine()
  useUpdateCoords({ props: updatedCoords, setShowKilometerMarks })
  useSwitchMode({
    mode: currentMap.mode,
    submode: currentMap.submode,
    rules: rules,
    setResetReferenceLineDrawing,
  })
  const deleteGroup = useDeleteGroup()
  const getSource = () => {
    return mapContext?.getSource('major__source') as GeoJSONSource
  }
  useEffect(() => {
    dispatch(
      setNodeCreation({
        coordinates: undefined,
      }),
    )
    resetFilters(['points-hover__layer'])
  }, [deleteGroup])
  useEffect(() => {
    if (resetMode && currentMap.submode !== 'add_point') {
      dispatch(resetMapSubmode())
    }
    setResetMode(false)
  }, [resetMode])

  const referenceNodes = useMemo(
    () =>
      currentMap.nodes?.reference_nodes.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'REFERENCE_NODES', zIndex: 2, lock: node?.lock }),
      ) || [],
    [currentMap.nodes?.reference_nodes],
  )
  const realPipelineGeometry = useGenerateRealGeometry(currentMap.pipelines, zoom, highlightCriterion)
  const segments = useMemo(() => {
    const _segments: Feature[] = []
    realPipelineGeometry.forEach((pipeline) => {
      getCoords(pipeline.geometry as any).forEach((segment, index) => {
        _segments.push(
          lineString(segment, {
            id: `${pipeline.properties!.id}s${index}`,
            lineId: pipeline.properties!.id,
            index: index,
            type: 'SEGMENT',
            zIndex: 1,
          }),
        )
      })
    })
    return _segments
  }, [realPipelineGeometry])
  const points = useMemo(() => {
    const items: any[] = []
    currentMap.pipelines.forEach((pipeline: any) =>
      items.push(
        ...getCoords(pipeline.line)
          .slice(1, -1)
          .map((item, index) =>
            point(item, {
              id: `${pipeline.id}${index + 1}`,
              lineId: pipeline.id,
              type: 'POINTS',
              index: index + 1,
              zIndex: 2,
            }),
          ),
      ),
    )
    return items
  }, [currentMap.pipelines])
  const source = useMemo(
    () =>
      currentMap.nodes?.source
        ? [
            feature(currentMap.nodes?.source.central_point, {
              id: currentMap.nodes?.source.node_id,
              type: 'SOURCE',
              rotation: getRotation(currentMap.nodes?.source.node_id, realPipelineGeometry),
              zIndex: 2,
              lock: currentMap.nodes?.source?.lock,
            }),
          ]
        : [],
    [currentMap.nodes?.source, realPipelineGeometry],
  )
  const sink = useMemo(
    () =>
      currentMap.nodes?.sink
        ? [
            feature(currentMap.nodes?.sink.central_point, {
              id: currentMap.nodes?.sink.node_id,
              type: 'SINK',
              rotation: getRotation(currentMap.nodes?.sink.node_id, realPipelineGeometry),
              zIndex: 2,
              lock: currentMap.nodes?.sink?.lock,
            }),
          ]
        : [],
    [currentMap.nodes?.sink, realPipelineGeometry],
  )
  const compressorStations = useMemo(
    () =>
      currentMap.nodes?.compressor_stations.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'COMPRESSOR_STATIONS', zIndex: 2, lock: node?.lock }),
      ) || [],
    [currentMap.nodes?.compressor_stations],
  )

  const reductionNodes = useMemo(
    () =>
      currentMap.nodes?.nodes_reduction.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'NODES_REDUCTIONS', lock: node?.lock }),
      ) || [],
    [currentMap.nodes?.nodes_reduction],
  )
  const heatingStations = useMemo(
    () =>
      currentMap.nodes?.heating_stations.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'HEATING_STATIONS', zIndex: 2, lock: node?.lock }),
      ) || [],
    [currentMap.nodes?.heating_stations],
  )
  const nodesSource = useMemo(() => {
    return [
      ...source,
      ...sink,
      ...compressorStations,
      ...referenceNodes,
      ...reductionNodes,
      ...heatingStations,
      ...points,
      ...realPipelineGeometry,
      ...segments,
    ]
  }, [
    source,
    sink,
    compressorStations,
    referenceNodes,
    reductionNodes,
    heatingStations,
    points,
    realPipelineGeometry,
    segments,
  ])
  const ref = useGetCurrentRef(
    nodeCreation,
    realPipelineGeometry,
    currentMap.pipelines,
    currentMap.nodes as definitions['ProjectNodes'],
    currentMap.mode,
    currentMap.submode,
    currentMap.selectedGroup,
    selectArea,
    intermediateElementInfo,
    currentMap.dragNodeAlong,
  )

  useEffect(() => {
    for (const type of [
      'source',
      'sink',
      'reference_nodes',
      'compressor_stations',
      'nodes_reductions',
      'heating_stations',
      'points',
      'segment',
      'pipeline',
      'reference_line',
      'reference_point',
    ]) {
      if (mapContext?.getLayer(`${type}-selected__layer`)) {
        mapContext?.setFilter(`${type}-selected__layer`, [
          'in',
          'id',
          ...currentMap.selectedGroup.filter((i) => i.type.toLowerCase() === type).map((i) => i.id),
        ])
      }
    }
  }, [currentMap.selectedGroup])

  useEffect(() => {
    if (!elementInfo.objectId && !elementInfo.objectType && currentMap.submode === 'dragAlong') {
      dispatch(resetMapSubmode())
    }
  }, [elementInfo])
  useEffect(() => {
    document.onkeydown = (event) => {
      if (event.code === 'Escape') {
        if (intermediateElementInfo.objectType) {
          dispatch(resetShowIntermediateElementInfo())
        }
        if (blockingWindow?.type) {
          return
        }
        if (elementInfo.objectType) {
          currentMap.submode === 'dragAlong' && dispatch(resetMapSubmode())
          dispatch(resetShowElementInfo())
        } else if (currentMap.referenceLineCreation.draw) {
          setResetReferenceLineDrawing(true)
        } else if (ref.current.selectedGroup.length) {
          dispatch(setSelectedGroup([]))
          currentMap.submode === 'select_area' && dispatch(resetMapSubmode())
        } else {
          currentMap.submode !== 'base' ? dispatch(resetMapSubmode()) : dispatch(resetMapMode())
        }
      }
      if (event.key === 'Shift') {
        ref.current.onShift = true
      }
    }
    document.onkeyup = (event) => {
      if (event.key === 'Shift') {
        ref.current.onShift = false
        ref.current.selectArea && drawSelectAreaFinish()
      }
    }
    if (currentMap.submode === 'select_area') {
      mapContext?.on('mousedown', handleMouseDownOnMap)
    }
    return () => {
      mapContext?.off('mousedown', handleMouseDownOnMap)
    }
  }, [
    elementInfo,
    intermediateElementInfo,
    blockingWindow?.type,
    currentMap.mode,
    currentMap.submode,
    currentMap.referenceLineCreation,
  ])
  const resetFilters = (layers: string[]) => {
    setFilters(layers, ['==', 'id', ''])
  }
  const setFilters = (layers: string[], filter: FilterSpecification) => {
    layers.forEach((layerId: string) => {
      mapContext?.setFilter(layerId, filter)
    })
  }
  const project = (lnglat: LngLat) => {
    return mapContext?.project(lnglat)
  }
  const handleMouseMove = (event: MapEvent) => {
    setCursorCoords(event.lngLat)
    if ((ref.current.dragFeatures.length > 0 && ref.current.dragFeatures[0].drag) || rules.current.dragAlong) return
    const feature = mapContext
      ?.queryRenderedFeatures(event.point)
      .sort((a, b) => b.properties.zIndex - a.properties.zIndex)
      .find(
        (feat) =>
          !['addNode', 'select-area__source', 'ruler-source', 'kilometer-marks__source'].includes(feat.layer.source),
      )
    const layer = mapContext?.getLayer(`${ref.current.hoverType}-hover__layer`)
    ref.current.hoverType && layer && mapContext?.setFilter(`${ref.current.hoverType}-hover__layer`, ['==', 'id', ''])
    mapContext.getCanvas().style.cursor = ''
    if (feature) {
      ref.current.hoverType = feature.properties.type.toLowerCase()
      mapContext?.setFilter(`${ref.current.hoverType}-hover__layer`, ['==', 'id', feature.properties.id || ''])
    }
  }
  const updateNodeCoords = (nodes: { id: string; lngLat: LngLat; sourceId: string }[], updatePipeline = true) => {
    const source = getSource()
    const _segments: Feature[] = []
    for (const _node of nodes) {
      const node = getSourceData(source).features.find((feat) => feat.properties!.id === _node.id)
      if (node && (ref.current.mapMode === 'edit' || !node.properties!.lock || isMovingAlongLine())) {
        try {
          updatePipeline &&
            getSourceData(source)
              .features.filter((f) => f.properties!.type === 'PIPELINE')
              .forEach((feat) => {
                if (feat.properties!.start_node === _node.id) {
                  feat.geometry = multiLineString([
                    getCoords(convertToGreatCircle([_node.lngLat.toArray(), last(getCoords(feat.geometry as any)[0])])),
                    ...getCoords(feat.geometry as any).slice(1),
                  ]).geometry
                } else if (feat.properties!.end_node === _node.id) {
                  feat.geometry = multiLineString([
                    ...getCoords(feat.geometry as any).slice(0, -1),
                    getCoords(convertToGreatCircle([last(getCoords(feat.geometry as any))[0], _node.lngLat.toArray()])),
                  ]).geometry
                } else if (feat.properties!.id === node.properties!.lineId) {
                  feat.geometry = multiLineString([
                    ...getCoords(feat.geometry as any).slice(0, node.properties!.index - 1),
                    getCoords(
                      convertToGreatCircle([
                        getCoords(feat.geometry as any)[node.properties!.index - 1][0],
                        _node.lngLat.toArray(),
                      ]),
                    ),
                    getCoords(
                      convertToGreatCircle([
                        _node.lngLat.toArray(),
                        last(getCoords(feat.geometry as any)[node.properties!.index]),
                      ]),
                    ),
                    ...getCoords(feat.geometry as any).slice(node.properties!.index + 1),
                  ]).geometry
                }
              })
          node.geometry = point(_node.lngLat.toArray()).geometry
        } catch (error) {
          console.warn(error)
        }
      }
    }
    getSourceData(source)
      .features.filter((f) => f.properties!.type === 'PIPELINE')
      .forEach((feat) =>
        getCoords(feat.geometry as any).forEach((segment, index) => {
          _segments.push(
            lineString(segment, {
              id: `${feat.properties!.id}s${index}`,
              lineId: feat.properties!.id,
              index: index,
              type: 'SEGMENT',
              zIndex: 1,
            }),
          )
        }),
      )
    source?.setData(
      featureCollection([
        ...getSourceData(source).features.filter((f) => f.properties!.type !== 'SEGMENT'),
        ..._segments,
      ]),
    )
  }
  const dragAlongLine = (node_id: string, pt: Coord) => {
    const lMultiLine = ref.current.realPipelineGeometry.find((p) => p.properties.end_node === node_id)
    const rMultiLine = ref.current.realPipelineGeometry.find((p) => p.properties.start_node === node_id)
    const lNearestPoint = nearestPointOnMultiLine(lMultiLine, pt)
    const rNearestPoint = nearestPointOnMultiLine(rMultiLine, pt)
    let rotation = 0
    if (rNearestPoint.properties.dist < lNearestPoint.properties.dist) {
      rotation =
        (getCoords(rMultiLine)[rNearestPoint.properties.multiLineIndex].length - 1 ===
        rNearestPoint.properties.pointIndex
          ? bearing(
              getCoords(rMultiLine)[rNearestPoint.properties.multiLineIndex][rNearestPoint.properties.pointIndex - 1],
              getCoords(rMultiLine)[rNearestPoint.properties.multiLineIndex][rNearestPoint.properties.pointIndex],
            )
          : bearing(
              getCoords(rMultiLine)[rNearestPoint.properties.multiLineIndex][rNearestPoint.properties.pointIndex],
              getCoords(rMultiLine)[rNearestPoint.properties.multiLineIndex][rNearestPoint.properties.pointIndex + 1],
            )) - 90
    } else {
      rotation =
        (getCoords(lMultiLine)[lNearestPoint.properties.multiLineIndex].length - 1 ===
        lNearestPoint.properties.pointIndex
          ? bearing(
              getCoords(lMultiLine)[lNearestPoint.properties.multiLineIndex][lNearestPoint.properties.pointIndex - 1],
              getCoords(lMultiLine)[lNearestPoint.properties.multiLineIndex][lNearestPoint.properties.pointIndex],
            )
          : bearing(
              getCoords(lMultiLine)[lNearestPoint.properties.multiLineIndex][lNearestPoint.properties.pointIndex],
              getCoords(lMultiLine)[lNearestPoint.properties.multiLineIndex][lNearestPoint.properties.pointIndex + 1],
            )) - 90
    }
    return {
      point:
        rNearestPoint.properties.dist < lNearestPoint.properties.dist
          ? LngLat.convert(getCoords(rNearestPoint.geometry) as [number, number])
          : LngLat.convert(getCoords(lNearestPoint.geometry) as [number, number]),
      rotation,
    }
  }
  const dragNode = (e: MapEvent) => {
    if (dragSegment.current) dragSegment.current.drag = true
    mapContext.getCanvas().style.cursor = 'move'
    let deltaLat: number, deltaLng: number
    if (isMovingAlongLine()) {
      const { point, rotation } = dragAlongLine(ref.current.dragFeatures[0].properties.id, e.lngLat.toArray())
      if (!point.lng || !point.lat) return
      dispatch(
        setDragNodeAlong({
          rotation,
          lat: parseFloat(point.lat.toFixed(6)),
          lng: parseFloat(point.lng.toFixed(6)),
        }),
      )
      deltaLat = dragStart.current[1] - point.lat
      deltaLng = dragStart.current[0] - point.lng
    } else {
      setShowKilometerMarks(false)
      deltaLat = dragStart.current[1] - e.lngLat.lat
      deltaLng = dragStart.current[0] - e.lngLat.lng
    }
    if (ref.current.selectedGroup.length && !ref.current.dragFeatures[ref.current.dragFeatures.length - 1].group) {
      const type = (dragSegment.current || ref.current.dragFeatures[ref.current.dragFeatures.length - 1]).properties
        .type
      dispatch(
        setSelectedGroup([
          {
            ...(dragSegment.current?.properties ||
              ref.current.dragFeatures[ref.current.dragFeatures.length - 1].properties),
            type,
          },
        ] as any),
      )
    }
    const nodes = ref.current.dragFeatures.map((f) => {
      return {
        id: f.properties.id,
        lngLat: new LngLat(getCoords(f.geometry as any)[0] - deltaLng, getCoords(f.geometry as any)[1] - deltaLat),
        sourceId: f.layer.source,
      }
    })
    updateNodeCoords(nodes, !isMovingAlongLine())
    const crossFeatures = []
    const ids = ref.current.dragFeatures.map((f) => f.properties.id)
    for (const dragFeature of ref.current.dragFeatures) {
      const { properties, drag, geometry } = dragFeature as DragFeature
      if (!drag) dragFeature.drag = true
      dragFeature.dragAlong = isMovingAlongLine()
      if (properties.type === 'POINTS') continue
      const padding = getIconSize(properties.type) / 2
      const point = mapContext.project([
        getCoords(geometry as any)[0] - deltaLng,
        getCoords(geometry as any)[1] - deltaLat,
      ])
      const bbox = [
        [point.x - padding, point.y - padding],
        [point.x + padding, point.y + padding],
      ] as [PointLike, PointLike]
      const pointCrossFeatures = mapContext
        ?.queryRenderedFeatures(bbox)
        .filter(
          (feat) =>
            ['symbol', 'circle'].includes(feat.layer.type) &&
            feat.properties.id &&
            feat.properties.type !== 'POINTS' &&
            feat.properties.id !== properties.id &&
            point.dist(project(new LngLat(...(getCoords(feat.geometry as any) as [number, number])))) <=
              padding + getIconSize(feat.properties.type) / 2,
        )
        .filter((f) => !ids.includes(f.properties.id))
      if (pointCrossFeatures.length && dragFeature) {
        dragFeature.warn = true
        crossFeatures.push(...pointCrossFeatures)
      } else if (dragFeature) dragFeature.warn = false
    }
    for (const type of [
      'source',
      'sink',
      'reference_nodes',
      'compressor_stations',
      'nodes_reductions',
      'heating_stations',
    ]) {
      setFilters(
        [
          `${type}-warn__layer`,
          // 'points-warn__layer',
        ],
        [
          'in',
          'id',
          ...crossFeatures.filter((f) => f.properties.type === type.toUpperCase()).map((f) => f.properties.id),
        ],
      )
    }
  }
  const handleOnMouseDown = (e: MapEvent) => {
    e.preventDefault()
    const { type, id } = e.features[0].properties
    if (rules.current.dragAlong && (ref.current.dragAlong?.type !== type || ref.current.dragAlong?.id !== id)) return
    if (type === 'POINTS' && !rules.current.editLine) return
    if (!ref.current.onShift) {
      if (!ref.current.selectedGroup.find((item) => item.id === e.features[0].properties.id)) {
        dispatch(setSelectedGroup([]))
        ref.current.dragFeatures = []
        if (['POINTS', 'SEGMENT'].includes(e.features[0].properties?.type)) {
          dispatch(
            setShowIntermediateElementInfo({
              objectType: e.features[0].properties?.type,
              objectId: e.features[0].properties?.lineId,
              index: e.features[0].properties?.index,
            }),
          )
        } else {
          dispatch(resetShowIntermediateElementInfo())
        }
      }
    } else {
      if (ref.current.intermediateElementInfo?.objectId) {
        dispatch(setSelectedGroup([]))
        dispatch(resetShowIntermediateElementInfo())
      }
    }
    dragStart.current = e.lngLat.toArray()
    if (type === 'SEGMENT') {
      dragSegment.current = {
        ...e.features[0],
        geometry: e.features[0].geometry,
        warn: false,
        drag: false,
      } as DragFeature
      segmentToDragFeatures(e.features[0].properties.lineId, e.features[0].properties.index, false)
    } else {
      dragStart.current = getCoords(e.features[0].geometry as any)
      pushOrReplace(ref.current.dragFeatures, {
        ...e.features[0],
        geometry: e.features[0].geometry,
        warn: false,
        drag: false,
        group: false,
      } as DragFeature)
    }
    if (ref.current.mapMode === 'edit' || !e.features[0].properties.lock || isMovingAlongLine()) {
      mapContext?.on('mousemove', dragNode)
    }
    mapContext?.once('mouseup', handleOnMouseUp)
  }
  const handleCreatePoint = (e: MapEvent) => {
    // dispatch(resetShowElementInfo())
    e.preventDefault()
    const pointCoords = e.lngLat.toArray()
    const pipelineIndex = ref.current.realPipelineGeometry.findIndex(
      (pipe: Feature) => pipe.properties && pipe.properties.id === ref.current.nodeCreation.pipelineID,
    )
    if (pipelineIndex > -1) {
      const pipeline = ref.current.realPipelineGeometry[pipelineIndex]
      const multiLine = multiLineString(
        addPointToMultiLineString(getCoords(pipeline.geometry), pointCoords as number[]),
      ).geometry
      const simpleGeometry = convertToStraightLine(multiLine)
      const index = getCoords(simpleGeometry).findIndex((coord) =>
        booleanEqual(point(coord), point(pointCoords as number[])),
      )
      dragStart.current = pointCoords as number[]
      dispatch(
        setMapPipelines([
          ...ref.current.pipelines.slice(0, pipelineIndex),
          { ...ref.current.pipelines[pipelineIndex], line: simpleGeometry.geometry },
          ...ref.current.pipelines.slice(pipelineIndex + 1),
        ]),
      )
      dispatch(
        setSelectedGroup([
          {
            id: `${ref.current.nodeCreation.pipelineID}${index}`,
            index,
            lineId: ref.current.nodeCreation.pipelineID,
            type: 'POINTS',
          },
        ]),
      )
      ref.current.dragFeatures = [
        {
          properties: {
            id: `${ref.current.nodeCreation.pipelineID}${index}`,
            index,
            lineId: ref.current.nodeCreation.pipelineID,
            type: 'POINTS',
          },
          layer: mapContext?.getLayer('points__layer'),
          geometry: point(pointCoords as number[]).geometry,
          warn: false,
          drag: false,
        } as unknown as DragFeature,
      ]
      mapContext?.on('mousemove', dragNode)
      mapContext?.once('mouseup', handleOnMouseUp)
    }
  }
  const selectGroup = (id: string, type: elementType, index?: number, lineId?: string) => {
    if (
      ref.current.onShift &&
      ((ref.current.mapMode === 'view' &&
        ref.current.selectedGroup.length > 0 &&
        !['SOURCE', 'SINK', 'PIPELINE'].includes(type) &&
        !ref.current.selectedGroup.some((i) => ['SOURCE', 'SINK', 'PIPELINE'].includes(i.type))) ||
        (ref.current.mapMode === 'edit' &&
          !['REFERENCE_LINE', 'REFERENCE_POINT'].includes(type) &&
          !ref.current.selectedGroup.some((i) => ['REFERENCE_LINE', 'REFERENCE_POINT'].includes(i.type))))
    ) {
      ref.current.selectedGroup.find((i) => i.id === id)
        ? dispatch(setSelectedGroup(ref.current.selectedGroup.filter((i) => i.id !== id)))
        : dispatch(setSelectedGroup([...ref.current.selectedGroup, { id, type, index, lineId }]))
      dispatch(resetShowElementInfo())
    } else {
      setResetMode(true)
      index != undefined && lineId != undefined
        ? dispatch(setSelectedGroup([{ id, type, index, lineId }]))
        : dispatch(
            setShowElementInfo({
              objectType: type,
              objectId: id,
              isVisible: ref.current.mapMode === 'view',
            }),
          )
    }
  }
  const getNodeTypeByIndex = (id: string, group: boolean) => {
    const feature = getSourceData(getSource()).features.find((f) => f.properties!.id === id)
    if (feature)
      return {
        properties: feature.properties,
        layer: mapContext?.getLayer(`${feature.properties!.type.toLowerCase()}__layer`),
        geometry: feature.geometry,
        warn: false,
        drag: false,
        group,
      }
  }
  const finishDragAlongLine = () => {
    const { properties } = ref.current.dragFeatures[0]
    const node_id = properties.id
    const pipelines = ref.current.pipelines.filter((p) => p.start_node_id === node_id || p.end_node_id === node_id)
    const line = concatLines(...(pipelines.map((p) => getCoords(p.line)) as [Position[], Position[]]))
    let [lineA, lineB] = splitLineByPoint(line, getCurrentGeometry(node_id, getSource()) as Point)
    lineA = removeCrossFeatures(lineA, lineA.slice(-1)[0], 1)
    lineB = removeCrossFeatures(lineB, lineB[0], 1)
    const features = [
      { id: properties.id, type: properties.type, central_point: getCurrentGeometry(node_id, getSource()) },
    ]
    for (const pipeline of pipelines) {
      features.push({
        id: pipeline.id,
        type: 'PIPELINE',
        central_point: pipeline.end_node_id === node_id ? lineString(lineA).geometry : lineString(lineB).geometry,
      })
    }
    setUpdatedCoords(features as any)
    ref.current.dragFeatures[0].drag = false
  }
  const handleOnMouseUp = () => {
    mapContext.getCanvas().style.cursor = ''
    const { properties, drag, dragAlong } =
      dragSegment.current || (ref.current.dragFeatures[ref.current.dragFeatures.length - 1] as DragFeature)
    const type = properties.type.toUpperCase()
    if (!drag) {
      selectGroup(properties.id, type, properties.index, properties.lineId)
      if (ref.current.nodeCreation.editObject === 'POINTS') {
        setUpdatedCoords([
          {
            id: ref.current.nodeCreation.pipelineID as string,
            type: 'PIPELINE',
            central_point: getCurrentGeometry(ref.current.nodeCreation.pipelineID as string, getSource()) as any,
          },
        ])
      }
      if (ref.current.mapMode === 'edit') {
        if (properties?.type === 'POINTS' && !ref.current.onShift) {
          dispatch(
            setShowIntermediateElementInfo({
              objectType: properties?.type,
              objectId: properties?.lineId,
              index: properties?.index,
            }),
          )
        }
      }
    } else {
      const warn = ref.current.dragFeatures.map((f) => f.warn).some((i) => i)
      if (warn) {
        const nodes = ref.current.dragFeatures.map((f) => {
          f.drag = false
          return {
            id: f.properties.id,
            lngLat: new LngLat(...(getCoords(f.geometry as any) as [number, number])),
            sourceId: f.layer.source,
          }
        })
        updateNodeCoords(nodes)
        dragAlong && dispatch(setDragNodeAlong({ reset: true }))
        resetFilters([
          'source-warn__layer',
          'sink-warn__layer',
          'reference_nodes-warn__layer',
          'compressor_stations-warn__layer',
          'points-warn__layer',
          'nodes_reductions-warn__layer',
          'heating_stations-warn__layer',
        ])
      } else if (dragAlong) {
        finishDragAlongLine()
      } else {
        const uniqueFeatures = [
          ...getUniqueFeatures(
            ref.current.dragFeatures
              .filter((d) => {
                return d.properties.lineId
              })
              .map((d) => {
                d.geometry = getCurrentGeometry(d.properties.id, getSource())
                return d
              }),
            'lineId',
          ).map((d) => {
            d.drag = false
            return {
              id: d.properties.lineId,
              type: 'PIPELINE' as elementType,
              central_point: getCurrentGeometry(d.properties.lineId, getSource()),
            }
          }),
          ...getUniqueItems(
            ref.current.dragFeatures
              .filter((d) => !d.properties.lineId)
              .reduce((arr: any[], d) => {
                const type = d.properties.type as elementType
                d.geometry = getCurrentGeometry(d.properties.id, getSource())
                d.drag = false
                arr.push(
                  {
                    id: d.properties.id as string,
                    type: type.toUpperCase() as elementType,
                    central_point: d.geometry,
                  },
                  ...getLineByNodeId(d.properties.id),
                )
                return arr
              }, []),
            'id',
          ),
        ]
        setUpdatedCoords(uniqueFeatures as any)
        if (type === 'POINTS' && ref.current.selectedGroup.length < 1)
          selectGroup(properties.id, type, properties.index, properties.lineId)
        ref.current.dragFeatures = ref.current.dragFeatures.filter((d) => d.group)
      }
      !dragAlong && dispatch(resetShowElementInfo())
    }
    mapContext?.off('mousemove', dragNode)
    dragSegment.current = undefined
  }
  const handleMouseOut = (e: MapEvent) => {
    ref.current.dragFeatures.length && mapContext?.fire('mouseup', { lngLat: e.lngLat, point: e.point })
  }
  const handleZoom = () => {
    setZoom(mapContext?.getZoom())
  }
  const drawSelectArea = (e: MapEvent) => {
    ref.current.selectArea?.splice(2, 2, ...e.lngLat.toArray())
    mapContext.getCanvas().style.cursor = 'pointer'
    setSelectArea(ref.current.selectArea)
    const features = getUniqueFeatures(
      mapContext?.queryRenderedFeatures(
        [
          project(ref.current.selectArea.slice(0, 2) as unknown as LngLat),
          project(ref.current.selectArea.slice(2, 4) as unknown as LngLat),
        ],
        {
          layers: [
            'reference_nodes__layer',
            'compressor_stations__layer',
            'nodes_reductions__layer',
            'heating_stations__layer',
            ...(ref.current.mapMode === 'edit'
              ? ['source__layer', 'sink__layer', 'points__layer', 'segment__layer']
              : []),
          ],
        },
      ),
      'id',
    )
    dispatch(
      setSelectedGroup(
        features?.map((f) => {
          return {
            ...f.properties,
          }
        }),
      ),
    )
    dispatch(resetShowIntermediateElementInfo())
  }
  const drawSelectAreaFinish = () => {
    mapContext.getCanvas().style.cursor = ''
    setSelectArea([])
    mapContext?.setLayoutProperty('points__layer', 'icon-ignore-placement', false)
    mapContext?.off('mousemove', drawSelectArea)
  }
  const handleMouseDownOnMap = (e: MapEvent) => {
    const feature = mapContext
      ?.queryRenderedFeatures(e.point)
      .sort((a, b) => b.properties.zIndex - a.properties.zIndex)
      .find((feat) => !['addNode', 'select-area__source'].includes(feat.layer.source))
    if (!feature) {
      setSelectArea([...e.lngLat.toArray(), ...e.lngLat.toArray()])
      dispatch(resetShowElementInfo())
      e.preventDefault()
      mapContext?.setLayoutProperty('points__layer', 'icon-ignore-placement', true)
      mapContext.getCanvas().style.cursor = 'pointer'
      mapContext?.on('mousemove', drawSelectArea)
      mapContext?.once('mouseup', drawSelectAreaFinish)
    }
  }
  const onClick = (e: MapEvent) => {
    const feature = mapContext
      ?.queryRenderedFeatures(e.point)
      .sort((a, b) => b.properties.zIndex - a.properties.zIndex)
      .find((feat) => !['addNode', 'select-area__source'].includes(feat.layer.source))
    if (!feature) {
      rules.current.referencePoint &&
        dispatch(
          setReferenceLineCreation({
            coordinates: [e.lngLat.toArray() as number[], e.lngLat.toArray() as number[]],
            draw: true,
          }),
        )
      rules.current.dragAlong && dispatch(resetMapSubmode())
      dispatch(resetShowElementInfo())
      dispatch(setSelectedGroup([]))
      dispatch(resetShowIntermediateElementInfo())
      return
    }
  }
  useEffect(() => {
    mapContext?.on('mousemove', handleMouseMove)
    mapContext?.on('mouseout', handleMouseOut)
    mapContext?.on('zoom', handleZoom)
    mapContext?.on('click', onClick)
    return () => {
      mapContext?.off('mousemove', handleMouseMove)
      mapContext?.off('zoom', handleZoom)
    }
  }, [])

  const isMovingAlongLine = () => {
    return (
      rules.current.dragAlong &&
      ref.current.dragFeatures.length === 1 &&
      ['COMPRESSOR_STATIONS', 'REFERENCE_NODES', 'NODES_REDUCTIONS', 'HEATING_STATIONS'].includes(
        ref.current.dragFeatures[0].properties.type,
      )
    )
  }
  const segmentToDragFeatures = (lineId: string, index: number, group = true) => {
    const pipeline = ref.current.realPipelineGeometry.find((pipeline) => pipeline.properties.id === lineId)
    if (pipeline) {
      if (index === 0 && index === getCoords(pipeline.geometry).length - 1) {
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(pipeline.properties.start_node, group))
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(pipeline.properties.end_node, group))
      } else if (index === 0) {
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(pipeline.properties.start_node, group))
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(`${pipeline.properties.id}${1}`, group))
      } else if (index === getCoords(pipeline.geometry).length - 1) {
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(pipeline.properties.end_node, group))
        pushOrReplace(
          ref.current.dragFeatures,
          getNodeTypeByIndex(`${pipeline.properties.id}${getCoords(pipeline.geometry).length - 1}`, group),
        )
      } else {
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(`${pipeline.properties.id}${index}`, group))
        pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(`${pipeline.properties.id}${index + 1}`, group))
      }
    }
  }
  const getLineByNodeId = (nodeId: string) => {
    return getSourceData(getSource())
      .features.filter((f) => f.properties!.start_node === nodeId || f.properties!.end_node === nodeId)
      .map((f) => {
        return {
          id: f.properties!.id,
          type: 'PIPELINE' as elementType,
          central_point: f.geometry,
        }
      })
  }
  const getReferenceFeatureById = (id: string, type: string) => {
    const feature = getSourceData(
      mapContext?.getSource(`${type.toLowerCase()}__source`) as GeoJSONSource,
    ).features.find((f) => f.properties!.id === id)
    if (feature)
      return {
        properties: feature.properties,
        layer: mapContext?.getLayer(`${type.toLowerCase()}__layer`),
        geometry: feature.geometry,
        warn: false,
        drag: false,
      }
  }
  useEffect(() => {
    ref.current.dragFeatures = ref.current.dragFeatures.filter((d) => !d.group)
    for (const element of currentMap.selectedGroup) {
      if (element.type === 'SEGMENT') {
        segmentToDragFeatures(element.lineId as string, element.index as number, true)
      } else if (['REFERENCE_LINE', 'REFERENCE_POINT'].includes(element.type)) {
        pushOrReplace(ref.current.dragFeatures, getReferenceFeatureById(element.id, element.type))
      } else {
        if (getNodeTypeByIndex(element.id, true))
          pushOrReplace(ref.current.dragFeatures, getNodeTypeByIndex(element.id, true))
      }
    }
  }, [currentMap.selectedGroup])
  useEffect(() => {
    if (currentMap.zoom.in) {
      mapContext?.setZoom(mapContext?.getZoom() + defaultZoomDelta)
    }
    if (currentMap.zoom.out) {
      mapContext?.setZoom(mapContext?.getZoom() - defaultZoomDelta)
    }
    dispatch(resetMapZoom())
  }, [currentMap.zoom])

  useEffect(() => {
    if (currentMap.toCenter && !currentMap.center) {
      const collection = multiLineString(currentMap.pipelines.map((pipeline) => getCoords(pipeline.line as any)))
      const box = bbox(collection) as BBox2d
      try {
        mapContext?.fitBounds(box, { padding: { top: 80, right: 40, bottom: 40, left: 40 }, animate: false })
      } catch (error) {
        console.warn(error)
      }
      dispatch(setToMapCenter(false))
    } else if (currentMap.toCenter && currentMap.center) {
      mapContext?.jumpTo({ center: currentMap.center, padding: { top: 80, right: 40, bottom: 40, left: 420 } })
      dispatch(setToMapCenter(false))
      dispatch(setMapCenter(undefined))
    }
  }, [currentMap.toCenter])

  const updateSegmentInfo = () => {
    if (intermediateElementInfo?.objectId)
      dispatch(
        setShowIntermediateElementInfo({
          objectType: intermediateElementInfo?.objectType,
          objectId: intermediateElementInfo?.objectId,
          index: intermediateElementInfo?.index,
          updateCount: intermediateElementInfo.updateCount! + 1,
        }),
      )
    rules.current.dragAlong && dispatch(setDragNodeAlong({ update: true }))
  }

  return (
    <>
      <ModeControl />
      <LayerControl />
      <MemoizedHeightProfileControl />
      <ControlButtons
        onZoomIn={() => dispatch(setMapZoom({ in: true }))}
        onZoomOut={() => dispatch(setMapZoom({ out: true }))}
        onNavigate={() => dispatch(setToMapCenter(true))}
      />
      <CoordsView lngLat={cursorCoords || mapContext?.getCenter()} />
      <ReferenceLineLayers
        edit={rules.current.referencePoint}
        visible={rules.current.referenceLine}
        resetDrawing={resetReferenceLineDrawing}
        setResetDrawing={setResetReferenceLineDrawing}
      />
      <Ruler />
      <IntermediateElement updatedCoords={updatedCoords} />
      <KilometerMarksLayer zoom={zoom} visible={showKilometerMarks} />
      <Layer
        sourceID={'major__source'}
        baseLayer={'segment-hover__layer'}
        features={[]}
        layerType={'line'}
        handleMouseDown={handleOnMouseDown}
        paint={lineHoverLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'segment-selected__layer'}
        features={[]}
        layerType={'line'}
        paint={lineSelectedLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'segment__layer'}
        features={nodesSource}
        filter={['==', 'type', 'SEGMENT']}
        layout={lineLayoutProps}
        layerType={'line'}
        visible={rules.current.segment ? 'visible' : 'none'}
        paint={lineTransparentLayerProps}
        onFeaturesChange={updateSegmentInfo}
        source
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'pipeline-hover__layer'}
        features={[]}
        layerType={'line'}
        paint={rules.current.selectLine ? lineHoverLayerProps : lineTransparentLayerProps}
        layout={lineLayoutProps}
        // visible={currentMap.mode==='edit'?'none':'visible'}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'pipeline-selected__layer'}
        features={[]}
        layerType={'line'}
        paint={lineSelectedLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'pipeline-highlighted__layer'}
        features={[]}
        layerType={'line'}
        paint={lineHighlightLayerProps}
        layout={lineLayoutProps}
        visible={highlightCriterion !== undefined ? 'visible' : 'none'}
        //filter={['==', 'type', 'PIPELINE']}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'pipeline-init__layer'}
        features={realPipelineGeometry}
        layerType={'line'}
        paint={lineLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'type', 'PIPELINE']}
      />
      <LineLayer
        sourceID={'major__source'}
        baseLayer={'pipeline__layer'}
        actionLayer={'pipeline-hover__layer'}
        features={realPipelineGeometry}
        layout={lineLayoutProps}
        paint={lineBaseLayerProps}
        currentRef={ref}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'points__layer'}
        layerType={'symbol'}
        layout={{
          'icon-image': `point-icn`,
        }}
        features={[]}
        handleMouseDown={(e) => !rules.current.dragAlong && handleOnMouseDown(e)}
        filter={['==', 'type', 'POINTS']}
        visible={
          (ref.current.dragFeatures[0]?.drag && !rules.current.dragAlong) || !rules.current.points ? 'none' : 'visible'
        }
        // paint={zoom < PointsMinZoom ? circleTransparentLayerProps : circleLayerProps}
      />
      {!ref.current.dragFeatures[0]?.drag && <AddNode handleCreatePoint={handleCreatePoint} />}
      {
        ['source', 'sink', 'reference_nodes', 'compressor_stations', 'nodes_reductions', 'heating_stations'].map(
          (type, index) => {
            return (
              <React.Fragment key={index}>
                <Layer
                  sourceID={`major__source`}
                  baseLayer={`${type}-hover__layer`}
                  features={[]}
                  layerType={'symbol'}
                  layout={{
                    'icon-image': `${getIconName(type)}-hover-icn`,
                    ...layerProps,
                  }}
                  filter={['all', ['==', 'id', ''], ['==', 'type', type.toUpperCase()]]}
                />
                <Layer
                  sourceID={`major__source`}
                  baseLayer={`${type}-selected__layer`}
                  features={[]}
                  layerType={'symbol'}
                  layout={{
                    'icon-image': `${getIconName(type)}-selected-icn`,
                    ...layerProps,
                  }}
                  filter={['all', ['==', 'id', ''], ['==', 'type', type.toUpperCase()]]}
                />
                <Layer
                  sourceID={`major__source`}
                  baseLayer={`${type}-warn__layer`}
                  features={[]}
                  layerType={'symbol'}
                  layout={{
                    'icon-image': `${getIconName(type)}-warn-icn`,
                    ...layerProps,
                  }}
                  filter={['all', ['==', 'id', ''], ['==', 'type', type.toUpperCase()]]}
                />
              </React.Fragment>
            ) as any
          },
        ) as any
      }
      <DragAlongLayer />
      <Layer
        sourceID={'major__source'}
        baseLayer={'reference_nodes__layer'}
        features={[]}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        filter={['==', 'type', 'REFERENCE_NODES']}
        layout={{
          'icon-image': 'junction-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'heating_stations__layer'}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        features={[]}
        filter={['==', 'type', 'HEATING_STATIONS']}
        layout={{
          'icon-image': 'heating-station-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'nodes_reductions__layer'}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        features={[]}
        filter={['==', 'type', 'NODES_REDUCTIONS']}
        layout={{
          'icon-image': 'reduction-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={'major__source'}
        baseLayer={'compressor_stations__layer'}
        layerType={'symbol'}
        features={[]}
        filter={['==', 'type', 'COMPRESSOR_STATIONS']}
        handleMouseDown={handleOnMouseDown}
        layout={{
          'icon-image': 'cs-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={`major__source`}
        baseLayer={'source__layer'}
        features={[]}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        filter={['==', 'type', 'SOURCE']}
        layout={{
          'icon-image': 'source-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={`major__source`}
        baseLayer={'sink__layer'}
        layerType={'symbol'}
        features={[]}
        filter={['==', 'type', 'SINK']}
        handleMouseDown={handleOnMouseDown}
        layout={{
          'icon-image': 'sink-icn',
          ...layerProps,
        }}
      />
      <Layer
        features={[]}
        sourceID={'major__source'}
        baseLayer={'points-hover__layer'}
        layerType={'circle'}
        paint={circleHoverLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        features={[]}
        sourceID={'major__source'}
        baseLayer={'points-selected__layer'}
        layerType={'circle'}
        paint={circleHoverLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        features={[]}
        sourceID={'major__source'}
        baseLayer={'points-warn__layer'}
        layerType={'circle'}
        paint={circleWarnLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'select-area__source'}
        baseLayer={'select-area__layer'}
        features={selectArea.length > 0 ? [bboxPolygon(selectArea as BBox2d)] : selectArea}
        layerType={'fill'}
        paint={selectAreaLayerProps}
        source
      />
      {DNLayers &&
        DNLayersToMapData(DNLayers)?.map((item, index) => (
          <DNLayer key={index} layer={item.layer} source={item.source} />
        ))}
    </>
  )
}
