import React from 'react'
import Button from 'components/Button'
import FocusIcon from 'images/FocusIcon'
interface INavigateButton {
  onNavigate(): void
}
export const NavigateButton: React.FC<INavigateButton> = ({ onNavigate }) => {
  return (
    <Button onClick={onNavigate}>
      <FocusIcon />
    </Button>
  )
}
