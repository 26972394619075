import { useEffect, useRef } from 'react'
import { IMap, IProject, MapElementType } from 'store/types'
import { definitions } from 'generated/apiTypes'
import { DragFeature } from '../types'

interface ICurrentRefProps {
  nodeCreation: IMap['nodeCreation']
  mapMode: IMap['mode']
  mapSubMode: IMap['submode']
  realPipelineGeometry: any[]
  pipelines: any[]
  nodes: definitions['ProjectNodes']
  selectedType?: MapElementType
  hoverType?: MapElementType
  dragFeatures: DragFeature[]
  selectedGroup: IMap['selectedGroup']
  onShift: boolean
  onAlt: boolean
  selectArea: number[]
  intermediateElementInfo: IProject['showIntermediateElementInfo']
  dragAlong: IMap['dragNodeAlong']
}
const useGetCurrentRef = (
  nodeCreation: IMap['nodeCreation'],
  realPipelineGeometry: any[],
  pipelines: any[],
  nodes: definitions['ProjectNodes'],
  mapMode: IMap['mode'],
  mapSubMode: IMap['submode'],
  selectedGroup: IMap['selectedGroup'],
  selectArea: number[],
  intermediateElementInfo: IProject['showIntermediateElementInfo'],
  dragAlong: IMap['dragNodeAlong'],
) => {
  const currentRef = useRef<ICurrentRefProps>({
    nodeCreation,
    mapMode,
    mapSubMode,
    realPipelineGeometry,
    pipelines,
    nodes,
    selectedType: undefined,
    hoverType: undefined,
    dragFeatures: [],
    selectedGroup,
    selectArea,
    onShift: false,
    onAlt: false,
    intermediateElementInfo,
    dragAlong,
  })
  useEffect(() => {
    currentRef.current.nodeCreation = nodeCreation
  }, [nodeCreation])

  useEffect(() => {
    currentRef.current.pipelines = pipelines
  }, [pipelines])

  useEffect(() => {
    currentRef.current.realPipelineGeometry = realPipelineGeometry
  }, [realPipelineGeometry])

  useEffect(() => {
    currentRef.current.mapMode = mapMode
  }, [mapMode])

  useEffect(() => {
    currentRef.current.mapSubMode = mapSubMode
  }, [mapSubMode])

  useEffect(() => {
    currentRef.current.selectedGroup = selectedGroup
  }, [selectedGroup])

  useEffect(() => {
    currentRef.current.selectArea = selectArea
  }, [selectArea])

  useEffect(() => {
    currentRef.current.intermediateElementInfo = intermediateElementInfo
  }, [intermediateElementInfo])

  useEffect(() => {
    currentRef.current.nodes = nodes
  }, [nodes])

  useEffect(() => {
    currentRef.current.dragAlong = dragAlong
  }, [dragAlong])

  return currentRef
}

export default useGetCurrentRef
