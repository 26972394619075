import * as React from 'react'

function PersonIcon(props) {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.89062 8C11.1006 8 12.8906 6.21 12.8906 4C12.8906 1.79 11.1006 0 8.89062 0C6.68062 0 4.89062 1.79 4.89062 4C4.89062 6.21 6.68062 8 8.89062 8ZM8.89062 10C6.22062 10 0.890625 11.34 0.890625 14V15C0.890625 15.55 1.34062 16 1.89062 16H15.8906C16.4406 16 16.8906 15.55 16.8906 15V14C16.8906 11.34 11.5606 10 8.89062 10Z'
        fill='white'
      />
    </svg>
  )
}

export default PersonIcon
