import React, { useEffect } from 'react'
import { MapContext } from '../map'
import mapboxgl from 'maplibre-gl'
interface IPopupProps {
  coordinates: [number, number]
  offset: [number, number]
  text: string
  className?: string
}
export const Popup: React.FC<IPopupProps> = ({ coordinates, offset, text, className }) => {
  const mapContext = React.useContext(MapContext)
  useEffect(() => {
    const popup = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      anchor: 'bottom',
    })
      .setLngLat(coordinates)
      .setOffset(offset)
      .setText(text)
      .addTo(mapContext)
    className && popup.addClassName(className)
    return () => {
      popup.remove()
    }
  }, [coordinates])

  return <></>
}
