import React, { useContext } from 'react'
import { MapContext } from '../map'
import Checkbox from 'components/Checkbox'
interface ILayerProps {
  id: string
  name: string
  visible?: boolean
  setUpdate(value: boolean): void
}

const LayerCheckboxButton: React.FC<ILayerProps> = (props) => {
  const mapContext = useContext(MapContext)

  const setVisibility = (visible: boolean) => {
    mapContext?.setLayoutProperty(props.id, 'visibility', visible ? 'visible' : 'none')
    if (['base-map-layer', 'base-map-sat-layer'].includes(props.id)) {
      mapContext?.setLayoutProperty(
        props.id === 'base-map-layer' ? 'base-map-sat-layer' : 'base-map-layer',
        'visibility',
        'none',
      )
    } else if (['stripped-down-layer', 'full-layer'].includes(props.id)) {
      mapContext?.setLayoutProperty(
        props.id === 'full-layer' ? 'stripped-down-layer' : 'full-layer',
        'visibility',
        'none',
      )
    }
    props.setUpdate(true)
  }
  return (
    <div className={'layer-control__layer-btn'}>
      <Checkbox
        checkedValue={props.visible || false}
        setCheckedValue={(value) => setVisibility(value)}
        classNames={'layer-control__layer-btn-checkbox'}
      >
        {props.name}
      </Checkbox>
    </div>
  )
}

export default LayerCheckboxButton
