import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelectedItems } from 'store/directorySlice'
import { useNavigate } from 'react-router-dom'
import { IBlockingWindow, IDiscardChangesParams } from 'store/types'

interface IDiscardChangesProps {
  onDiscardChanges(): void
}

const useDiscardChanges = ({ onDiscardChanges }: IDiscardChangesProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IDiscardChangesParams)

  useEffect(() => {
    if (
      blockingWindow.type === 'DISCARD_CHANGES' &&
      blockingWindowParams?.discardType === 'EDIT_EXIT' &&
      blockingWindowParams.isConfirmed
    ) {
      onDiscardChanges()
      dispatch(clearSelectedItems())
      dispatch(resetBlockingWindow())
    } else if (
      blockingWindow.type === 'DISCARD_CHANGES' &&
      blockingWindowParams?.discardType === 'PAGE_LEAVE' &&
      blockingWindowParams.isConfirmed
    ) {
      navigate('/', { state: { tab: 'directories' } })
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindowParams?.isConfirmed])

  return
}

export default useDiscardChanges
