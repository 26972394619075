import * as React from 'react'

function FilterIcon(props: any) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.333 6.667h3.334V10H3.334V6.667zM5 3.333v3.334'
        stroke='#373737'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 10v6.667M8.334 11.667h3.333V15H8.334v-3.333zM10 3.333v8.334M10 15v1.667M13.334 4.167h3.333V7.5h-3.334V4.167zM15 3.333v.834M15 7.5v9.167'
        stroke='#2C3E50'
        strokeWidth={1.667}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FilterIcon
