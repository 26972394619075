import './layerControl.scss'
import React, { useContext, useEffect, useState } from 'react'
import { MapContext } from '../map'
import Button from 'components/Button'
import IconArrayDown from 'images/IconArrayDown'
import { kilometerMarksLayer, tilesLayers } from '../layers'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { selectMapMode } from 'store/mapSlice'
import LayerCheckboxButton from './layerCheckboxButton'
import DNLayers from './DNLayers'
import WarningIcon from 'images/WarningIcon'
import { selectTaskStatus } from 'store/DNLayersSlice'

export const LayerControl: React.FC = () => {
  const mapContext = useContext(MapContext)
  const status = useSelector(selectTaskStatus)
  const [open, setOpen] = useState(false)
  const [checkList, setCheckList] = useState<boolean[]>([])
  const [update, setUpdate] = useState<boolean>(true)

  useEffect(() => {
    setCheckList(
      [kilometerMarksLayer, ...tilesLayers].map(
        (layer) => mapContext?.getLayoutProperty(layer.id, 'visibility') === 'visible',
      ),
    )
    setUpdate(false)
  }, [update])

  return (
    <div className={'layer-control'}>
      <Button className={classnames('layer-control__btn', { open })} onClick={() => setOpen(!open)}>
        Слои
        {status === 'Ошибка' ? (
          <WarningIcon className={'layer-control__btn-warning-icn'} />
        ) : (
          <div className={'layer-control__btn-warning-icn'} />
        )}
        <IconArrayDown className={'layer-control__btn-arrow-icn'} />
      </Button>
      <div className={classnames('layer-control__container', { open })}>
        <div className={'layer-control__column'}>
          <div className={'layer-control__column-title header4'}>Общие</div>
          <div className={'layer-control__list-container reverse'}>
            {[kilometerMarksLayer, ...tilesLayers].map((layer, index) => (
              <LayerCheckboxButton
                id={layer.id}
                key={layer.id}
                name={(layer.metadata as any)?.name}
                visible={checkList.at(index)}
                setUpdate={setUpdate}
              />
            ))}
          </div>
        </div>
        <DNLayers />
      </div>
    </div>
  )
}
