import React from 'react'
import ResizeIcon from 'images/ResizeIcon'
import './handle.scss'

const Handle: React.FC = () => {
  return (
    <div className={'resize-handle'}>
      <ResizeIcon />
    </div>
  )
}

export default Handle
