import Button from '../../../components/Button'
import FilterIcon from '../../../images/FilterIcon'
import React from 'react'
interface IFilterButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const FilterButton: React.FC<IFilterButton> = ({ ...props }) => {
  return (
    <Button className={'filter-btn'} mode={'secondary'} onClick={props.onClick}>
      <FilterIcon />
      Выбрать
    </Button>
  )
}
