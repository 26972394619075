import { definitions } from 'generated/apiTypes'

interface ILayersColumn {
  title: string | JSX.Element
  type: 'Text' | 'Option'
  key: keyof definitions['HandbookNextgisAreaType']
  editable: boolean
  options?: any[]
  displayedField?: string
}

export const columns = (areaTypes: definitions['HandbookNextgisAreaTypeAreaType'][] | []): ILayersColumn[] => [
  {
    title: 'Идентификатор в NextGIS',
    type: 'Text',
    key: 'class_id',
    editable: true,
  },
  {
    title: 'Слой системы',
    type: 'Option',
    key: 'area_type',
    editable: true,
    options: areaTypes,
    displayedField: 'name',
  },
]
