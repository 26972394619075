import React from 'react'

import { getHandbooks } from 'services/apiRequests'

import { Handbooks } from 'store/types'

const useGetHandbooks = () => {
  const [handbooks, setHandbooks] = React.useState<Handbooks>(<Handbooks>{})

  const tryToGetHandbooks = async (): Promise<Handbooks> => {
    return await getHandbooks().then((res) => {
      const data: Handbooks = <Handbooks>{}
      Object.entries(res.data).forEach(([key, value]) => {
        /*if (
          ![
            'economics_pipes_calculation_indices',
            'economics_construction_conditions',
            'economics_compressor_station_unit_costs',
            'economics_linear_unit_costs',
            'economics_region_coefficients',
            'economics_compressor_station_calculation_indices',
            'economics_black_pipe_prices',
          ].includes(key)
        )*/
        if (!['unit_costs'].includes(key)) data[key as keyof Handbooks] = value
      })
      return data
    })
  }

  React.useEffect(() => {
    tryToGetHandbooks().then((res) => {
      setHandbooks(res)
    })
  }, [])

  return handbooks
}

export default useGetHandbooks
