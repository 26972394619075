import * as React from 'react'

function ListIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.5 4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667Z'
        stroke='#373737'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2.5 8.33337H17.5' stroke='#373737' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.33203 2.5V17.5' stroke='#373737' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
export default ListIcon
