export const ProfileConfig = {
  decimalPoints: 2,
  minRange: 0.2, // kilometers
  requestThreshold: 0.3, // 30%
}

export type BarChartConfig = {
  color: string
  color1?: string
  color2?: string
  shortLabel: string
  label: string
}

export type RouteLayerType = 'admDivision' | 'road' | 'railway' | 'waterway' | 'protectedAreas' | 'rivers' | 'terrain'

export type RouteLayingMethod = 'ground' | 'aboveGround' | 'underGround'

export type RouteCategory = 'B' | 'I' | 'II' | 'III' | 'IV'

export const LayerTypeConfig: { [key: string]: BarChartConfig } = {
  admDivision: {
    color: '#0074BC',
    color1: '#0074BC',
    color2: '#9D3B3B',
    shortLabel: 'Ад',
    label: 'Административное деление',
  },
  road: {
    color: '#DB62C8',
    shortLabel: 'Д',
    label: 'Дороги',
  },
  railway: {
    color: '#37B688',
    shortLabel: 'ЖД',
    label: 'Железные дороги',
  },
  waterway: {
    color: '#EC8D49',
    shortLabel: 'ОВ',
    label: 'Озера, водохранилища, крупные реки',
  },
  protectedAreas: {
    color: '#CFDD2D',
    shortLabel: 'ОТ',
    label: 'ООПТ',
  },
  rivers: {
    color: '#855BCA',
    shortLabel: 'Р',
    label: 'Реки',
  },
  terrain: {
    color: '#EC6767',
    shortLabel: 'М',
    label: 'Местность',
  },
}

export const LayingMethodConfig: { [key: string]: BarChartConfig } = {
  ground: {
    color: '#DB62C8',
    color1: '#DB62C8',
    color2: '#B33AA0',
    shortLabel: 'Нз',
    label: 'Наземная',
  },
  aboveGround: {
    color: '#37B688',
    color1: '#37B688',
    color2: '#1F9269',
    shortLabel: 'Нд',
    label: 'Надземная',
  },
  underGround: {
    color: '#006FB4',
    color1: '#006FB4',
    color2: '#01539B',
    shortLabel: 'Пд',
    label: 'Подземная',
  },
}

export const CategoryConfig: { [key: string]: BarChartConfig } = {
  I: {
    color: '#DB62C8',
    shortLabel: 'I',
    label: 'I',
  },
  II: {
    color: '#EC8D49',
    shortLabel: 'II',
    label: 'II',
  },
  III: {
    color: '#CFDD2D',
    shortLabel: 'III',
    label: 'III',
  },
  IV: {
    color: '#37B688',
    shortLabel: 'IV',
    label: 'IV',
  },
  B: {
    color: '#006FB4',
    shortLabel: 'B',
    label: 'B',
  },
}
