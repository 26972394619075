import * as React from 'react'

function TaskIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M5 7.5H10' stroke='#0074BC' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M3.33301 4.16699H6.66634'
        stroke='#0074BC'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 4.16699V13.3337C5 13.5547 5.0878 13.7666 5.24408 13.9229C5.40036 14.0792 5.61232 14.167 5.83333 14.167H10'
        stroke='#0074BC'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 6.66634C10 6.44533 10.0878 6.23337 10.2441 6.07709C10.4004 5.92081 10.6123 5.83301 10.8333 5.83301H15.8333C16.0543 5.83301 16.2663 5.92081 16.4226 6.07709C16.5789 6.23337 16.6667 6.44533 16.6667 6.66634V8.33301C16.6667 8.55402 16.5789 8.76598 16.4226 8.92226C16.2663 9.07854 16.0543 9.16634 15.8333 9.16634H10.8333C10.6123 9.16634 10.4004 9.07854 10.2441 8.92226C10.0878 8.76598 10 8.55402 10 8.33301V6.66634Z'
        stroke='#0074BC'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 13.3333C10 13.1123 10.0878 12.9004 10.2441 12.7441C10.4004 12.5878 10.6123 12.5 10.8333 12.5H15.8333C16.0543 12.5 16.2663 12.5878 16.4226 12.7441C16.5789 12.9004 16.6667 13.1123 16.6667 13.3333V15C16.6667 15.221 16.5789 15.433 16.4226 15.5893C16.2663 15.7455 16.0543 15.8333 15.8333 15.8333H10.8333C10.6123 15.8333 10.4004 15.7455 10.2441 15.5893C10.0878 15.433 10 15.221 10 15V13.3333Z'
        stroke='#0074BC'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default TaskIcon
