import * as React from 'react'

function RubIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_131_5910)'>
        <path
          d='M6.66667 15.8333V4.16663H11.6667C12.3297 4.16663 12.9656 4.43002 13.4344 4.89886C13.9033 5.3677 14.1667 6.00358 14.1667 6.66663C14.1667 7.32967 13.9033 7.96555 13.4344 8.43439C12.9656 8.90323 12.3297 9.16663 11.6667 9.16663H5'
          stroke='#2C3E50'
          strokeWidth='2.08333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M11.6667 12.5H5' stroke='#2C3E50' strokeWidth='2.08333' strokeLinecap='round' strokeLinejoin='round' />
        <circle cx='17' cy='3' r='3' fill='#00BC84' />
      </g>
      <defs>
        <clipPath id='clip0_131_5910'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RubIcon
