import React from 'react'
import Input from '../Input'
interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  classNames?: string
  label?: string
  value: string
  setValue?(value: string, label?: string): void
  fieldName?: string
  maxlength?: number
  placeholder?: string
  infoText?: string
}

const TextInput: React.FC<ITextInputProps> = ({
  value,
  classNames,
  disabled,
  setValue,
  fieldName,
  label,
  maxlength = 30,
  placeholder = '—',
  infoText,
  ...props
}) => {
  const [valueState, setValueState] = React.useState<string>('')

  React.useEffect(() => {
    if (value) setValueState(value.toString().trim())
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueState(e.target.value.slice(0, maxlength))
  }
  const onEnterHandler = () => {
    if (valueState.trim().length) {
      setValue && setValue(valueState, fieldName)
    } else setValueState(value)
  }

  return (
    <Input
      {...props}
      label={label}
      value={valueState}
      onChange={onChangeHandler}
      classNames={classNames}
      disabled={disabled}
      size={valueState.length | 1}
      onEnter={onEnterHandler}
      placeholder={placeholder}
      infoText={infoText}
    />
  )
}

export default TextInput
