import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDefaultPlantProperties } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { IErrorParams } from '../../../../../../store/types'

const useGetDefaultPlant = (
  plantsNumber: number | undefined,
): definitions['CSPlacementDraftCompressorPlantProperties'] | undefined => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const [properties, setProperties] = React.useState<definitions['CSPlacementDraftCompressorPlantProperties']>()

  const tryToGetDefaultPlantProperties = async () => {
    if (trunkPipelineId && projectId) {
      await getDefaultPlantProperties(projectId, trunkPipelineId)
        .then((response) => {
          setProperties(response.data)
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              type: 'ERROR',
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
            }),
          )
        })
    }
  }

  React.useEffect(() => {
    if (plantsNumber === 2) tryToGetDefaultPlantProperties().then()
  }, [plantsNumber])

  return properties
}

export default useGetDefaultPlant
