import * as React from 'react'

function ErrorIcon(props) {
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6 1.5C8.7615 1.5 11 3.7385 11 6.5C11.001 7.81274 10.4858 9.07322 9.56545 10.0093C8.64512 10.9454 7.39358 11.482 6.08101 11.5033C4.76845 11.5246 3.50017 11.0288 2.55 10.123C1.59983 9.21721 1.04399 7.97408 1.0025 6.662L1 6.5L1.002 6.36C1.076 3.6635 3.285 1.5 6 1.5ZM6.005 8L5.9415 8.0035C5.81998 8.01795 5.70797 8.07648 5.62671 8.16798C5.54544 8.25949 5.50056 8.37762 5.50056 8.5C5.50056 8.62238 5.54544 8.74051 5.62671 8.83202C5.70797 8.92352 5.81998 8.98205 5.9415 8.9965L6 9L6.0635 8.9965C6.18502 8.98205 6.29703 8.92352 6.37829 8.83202C6.45956 8.74051 6.50444 8.62238 6.50444 8.5C6.50444 8.37762 6.45956 8.25949 6.37829 8.16798C6.29703 8.07648 6.18502 8.01795 6.0635 8.0035L6.005 8ZM6 4C5.87753 4.00002 5.75933 4.04498 5.66781 4.12636C5.5763 4.20774 5.51783 4.31987 5.5035 4.4415L5.5 4.5V6.5L5.5035 6.5585C5.51795 6.68002 5.57648 6.79203 5.66798 6.87329C5.75949 6.95456 5.87762 6.99944 6 6.99944C6.12238 6.99944 6.24051 6.95456 6.33202 6.87329C6.42352 6.79203 6.48205 6.68002 6.4965 6.5585L6.5 6.5V4.5L6.4965 4.4415C6.48217 4.31987 6.4237 4.20774 6.33219 4.12636C6.24067 4.04498 6.12247 4.00002 6 4Z'
        fill='#E83232'
      />
    </svg>
  )
}

export default ErrorIcon
