import { twoDigitsFormatter } from 'pages/utils'
import React from 'react'
import { definitions } from 'generated/apiTypes'

interface IReductionNodeTableProps {
  node: definitions['HydroResultRetrieveReductionNode']
}
export const ReductionNodeTable: React.FC<IReductionNodeTableProps> = ({ node }) => {
  return (
    <div className={'hydraulic-results__stations_expand-row'}>
      <div className={'pipeline-result__cs-expand'}>
        <div className={'pipeline-result__cs-expand__column normal-text'}>
          <div className={'pipeline-result__cs-expand__row'}>
            <div className={'title'}>Перепад давления:</div>
            {`${twoDigitsFormatter.format(node?.pressure_drop__MPa)} МПа`}
          </div>
        </div>
        <div className={'pipeline-result__cs-expand__column normal-text'}>
          <div className={'pipeline-result__cs-expand__row'}>
            <div className={'title'}>Давление на выходе:</div>
            {`${twoDigitsFormatter.format(node?.out_pressure__MPa)} МПа`}
          </div>
        </div>
      </div>
    </div>
  )
}
