import * as React from 'react'

function BarIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.667 10H3.333a.833.833 0 00-.833.833v5c0 .46.373.834.833.834h3.334c.46 0 .833-.373.833-.834v-5A.833.833 0 006.667 10zM11.667 6.667H8.333A.833.833 0 007.5 7.5v8.333c0 .46.373.834.833.834h3.334c.46 0 .833-.373.833-.834V7.5a.833.833 0 00-.833-.833z'
        stroke='#0074BC'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.667 3.333h-3.334a.833.833 0 00-.833.834v11.666c0 .46.373.834.833.834h3.334c.46 0 .833-.373.833-.834V4.167a.833.833 0 00-.833-.834zM3.333 16.667H15'
        stroke='#0074BC'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='17' cy='3' r='3' fill='transparent' />
    </svg>
  )
}

export default BarIcon
