import React from 'react'
import NumberInput from 'components/Input/numberInput'
import { definitions } from 'generated/apiTypes'

interface ICSPlacementProps {
  step: definitions['CSPlacementCalculationCreate']['step__km'] | undefined
  setStep(step: definitions['CSPlacementCalculationCreate']['step__km']): void
  pressure: definitions['CSPlacementCalculationCreate']['desired_pressure_increase__MPa'] | undefined
  setPressure(pressure: definitions['CSPlacementCalculationCreate']['desired_pressure_increase__MPa']): void
}

const CSPlacement: React.FC<ICSPlacementProps> = ({ step, pressure, setStep, setPressure }) => {
  return (
    <div className={'cs-settings__container'}>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          id={'compressor-stations-placement'}
          key={`${1}_step`}
          unit={'км'}
          value={step}
          setValue={setStep}
          fieldName={'step__km'}
          label={'Шаг поиска КС'}
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`${2}_step`}
          value={pressure}
          setValue={setPressure}
          fieldName={'desired_pressure_increase__MPa'}
          infoText={'Желаемая степень повышения давления на КЦ (компрессорный цех)'}
          label={'Желаемая степень повыш. давления'}
        />
      </div>
    </div>
  )
}

export default CSPlacement
