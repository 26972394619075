import { IMap, INodeCreation } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore'
import { definitions } from '../generated/apiTypes'
import update from 'immutability-helper'
import { RouteLayingMethod } from '../components/Map/PipelineProfile/ProfilePanel/components/config'

export const initialState: IMap = {
  mode: 'view',
  submode: 'base',
  layers: undefined,
  zoom: {
    in: false,
    out: false,
  },
  toCenter: false,
  nodeCreation: {
    editObject: null,
    create: false,
    copyID: undefined,
    pipelineID: undefined,
  },
  referenceLineCreation: {
    draw: false,
  },
  profileDisplayed: false,
  highlightCriterion: undefined,
  highlightData: { layerType: [], category: [], layingMethod: [] },
  nodes: undefined,
  pipelines: [],
  selectedGroup: [],
  referenceLines: [],
  dragNodeAlong: undefined,
}
export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setMapNodes: (state, action: PayloadAction<definitions['ProjectNodes']>) => {
      state.nodes = { ...state.nodes, ...action.payload }
    },
    setMapPipelines: (state, action: PayloadAction<IMap['pipelines']>) => {
      state.pipelines = action.payload
    },
    setMapMode: (state, action: PayloadAction<IMap['mode']>) => {
      state.mode = action.payload
    },
    setMapSubmode: (state, action: PayloadAction<IMap['submode']>) => {
      state.submode = action.payload
    },
    resetMapMode: (state) => {
      state.mode = initialState.mode
    },
    resetMapSubmode: (state) => {
      state.submode = initialState.submode
    },
    setNodeCreation: (state, action: PayloadAction<INodeCreation>) => {
      state.nodeCreation = {
        ...state.nodeCreation,
        ...action.payload,
      }
    },
    resetNodeCreation: (state) => {
      state.nodeCreation = initialState.nodeCreation
    },
    setProfileDisplayed: (state, action: PayloadAction<IMap['profileDisplayed']>) => {
      state.profileDisplayed = action.payload
    },
    setHighlightCriterion: (state, action: PayloadAction<IMap['highlightCriterion']>) => {
      state.highlightCriterion = action.payload
    },
    setHighlightData: (
      state,
      action: PayloadAction<{
        data:
          | IMap['highlightData']['category']
          | IMap['highlightData']['layerType']
          | IMap['highlightData']['layingMethod']
        type: 'layerType' | 'category' | 'layingMethod'
      }>,
    ) => {
      state.highlightData[action.payload.type as 'layerType' | 'category' | 'layingMethod'] = action.payload.data as any
    },
    updateLayingMethodHighlightData: (
      state,
      action: PayloadAction<{ pipelineId: string; value: RouteLayingMethod | undefined }>,
    ) => {
      const index = state.highlightData.layingMethod?.findIndex(
        ({ pipelineId }) => pipelineId === action.payload.pipelineId,
      )
      if (index !== undefined && index > -1) {
        state.highlightData.layingMethod = update(state.highlightData.layingMethod, {
          [index]: {
            $set: {
              ...state.highlightData.layingMethod[index],
              value: action.payload.value as RouteLayingMethod,
            },
          },
        })
      }
    },
    resetMap: () => {
      return initialState
    },
    setMapZoom: (state, action: PayloadAction<IMap['zoom']>) => {
      state.zoom = { ...state.zoom, ...action.payload }
    },
    setMapCenter: (state, action: PayloadAction<IMap['center']>) => {
      state.center = action.payload
    },
    setToMapCenter: (state, action: PayloadAction<IMap['toCenter']>) => {
      state.toCenter = action.payload
    },
    resetMapZoom: (state) => {
      state.zoom = initialState.zoom
    },
    setMapLayers: (state, action: PayloadAction<IMap['layers']>) => {
      state.layers = action.payload
    },
    setSelectedGroup: (state, action: PayloadAction<IMap['selectedGroup']>) => {
      state.selectedGroup = action.payload
    },
    setReferenceLineCreation: (state, action: PayloadAction<IMap['referenceLineCreation']>) => {
      state.referenceLineCreation = {
        ...state.referenceLineCreation,
        ...action.payload,
      }
    },
    setReferenceLines: (state, action: PayloadAction<IMap['referenceLines']>) => {
      state.referenceLines = action.payload
    },
    resetReferenceLineCreation: (state) => {
      state.referenceLineCreation = initialState.referenceLineCreation
    },
    setDragNodeAlong: (state, action: PayloadAction<IMap['dragNodeAlong']>) => {
      state.dragNodeAlong = {
        ...state.dragNodeAlong,
        ...action.payload,
      }
    },
    resetDragNodeAlong: (state) => {
      state.dragNodeAlong = initialState.dragNodeAlong
    },
  },
})

export const {
  setMapNodes,
  setMapPipelines,
  setMapMode,
  resetMapMode,
  setMapSubmode,
  resetMapSubmode,
  setNodeCreation,
  resetNodeCreation,
  resetMap,
  setMapCenter,
  setToMapCenter,
  setMapZoom,
  resetMapZoom,
  setMapLayers,
  setSelectedGroup,
  setReferenceLineCreation,
  setReferenceLines,
  resetReferenceLineCreation,
  setDragNodeAlong,
  setProfileDisplayed,
  setHighlightCriterion,
  setHighlightData,
  updateLayingMethodHighlightData,
  resetDragNodeAlong,
} = mapSlice.actions

export const selectMap = (state: RootState) => state.map
export const selectMapMode = (state: RootState) => state.map.mode
export const selectMapSubmode = (state: RootState) => state.map.submode
export const selectNodeCreation = (state: RootState) => state.map.nodeCreation
export const selectReferenceLineCreation = (state: RootState) => state.map.referenceLineCreation
export const selectMapZoom = (state: RootState) => state.map.zoom
export const selectMapLayers = (state: RootState) => state.map.layers
export const selectSelectedGroup = (state: RootState) => state.map.selectedGroup
export const selectMapNodes = (state: RootState) => state.map.nodes
export const selectMapSource = (state: RootState) => state.map.nodes?.source
export const selectMapPipelines = (state: RootState) => state.map.pipelines
export const selectReferenceLines = (state: RootState) => state.map.referenceLines
export const selectDragNodeAlong = (state: RootState) => state.map.dragNodeAlong
export const selectProfileDisplayed = (state: RootState) => state.map.profileDisplayed
export const selectHighlightCriterion = (state: RootState) => state.map.highlightCriterion
export const selectHighlightData = (state: RootState) => state.map.highlightData
export default mapSlice.reducer
