import { IProjectsList } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore'
import { definitions } from '../generated/apiTypes'

export const initialState: IProjectsList = {
  items: [],
  getProjects: false,
}
export const projectsListSlice = createSlice({
  name: 'projectsListSlice',
  initialState: {
    items: initialState.items,
    getProjects: initialState.getProjects,
  },
  reducers: {
    setProjectsList: (state, action: PayloadAction<definitions['ProjectCalculations'][]>) => {
      state.items = action.payload
    },
    setProjectsListPage: (
      state,
      action: PayloadAction<{ pageIx: number; pageLimit: number; list: definitions['ProjectCalculations'][] }>,
    ) => {
      const restItems = state.items.filter(
        (item, index) =>
          index < action.payload.pageIx * action.payload.pageLimit ||
          index >= (action.payload.pageIx + 1) * action.payload.pageLimit,
      )
      const itemsToSet = action.payload.list
      state.items = [...restItems, ...itemsToSet]
    },
    addProjectsListPage: (state, action: PayloadAction<number>) => {
      const itemsToAdd = Array.from(Array(action.payload), (_, i) => {
        return {
          id: i.toString(),
          name: '',
          updated: '',
          author: {},
        } as definitions['ProjectCalculations']
      })
      state.items = [...state.items, ...itemsToAdd]
    },
    setGetProjects: (state, action: PayloadAction<IProjectsList['getProjects']>) => {
      state.getProjects = action.payload
    },
  },
})

export const { setProjectsList, setGetProjects, setProjectsListPage, addProjectsListPage } = projectsListSlice.actions

export const selectProjectsList = (state: RootState) => state.projectsList.items

export const selectGetProjects = (state: RootState) => state.projectsList.getProjects

export default projectsListSlice.reducer
