import React, { CSSProperties, PropsWithChildren } from 'react'
import classNames from 'classnames'
import './resizable.scss'

interface IResizableProps {
  className?: string
  handleElement?: JSX.Element
  setWidth?(width: number | undefined): void
  right: boolean
  left?: boolean
}

const Resizable: React.FC<PropsWithChildren<IResizableProps>> = ({
  className,
  children,
  setWidth,
  handleElement,
  right,
  left,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [containerStyles, setContainerStyles] = React.useState<CSSProperties>()
  const [dragMode, setDragMode] = React.useState<boolean>(false)
  const [resizeRight, setResizeRight] = React.useState<boolean>(true)

  const resize = (e: MouseEvent) => {
    if (containerRef?.current?.clientWidth) {
      const rect = containerRef?.current?.getBoundingClientRect()
      if (resizeRight) {
        setContainerStyles({
          width: `${containerRef?.current?.clientWidth + (e.pageX - (rect?.left + rect.width))}px`,
        })
      } else {
        setContainerStyles({
          width: `${containerRef?.current?.clientWidth + (rect?.right - rect.width - e.pageX)}px`,
          left: 'unset',
        })
      }
    }
  }

  React.useEffect(() => {
    const element = containerRef?.current
    if (!element) return
    const observer = new ResizeObserver(() => {
      if (setWidth) setWidth(containerRef?.current?.clientWidth)
    })
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [])

  React.useEffect(() => {
    if (dragMode) {
      document.addEventListener('mousemove', resize)
      document.addEventListener('mouseup', onMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', resize)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [dragMode, containerRef?.current, resizeRight])

  const onMouseDown = (right: boolean) => {
    document.body.style.cursor = 'ew-resize'
    if (right) setResizeRight(true)
    else setResizeRight(false)
    setDragMode(true)
  }

  const onMouseUp = () => {
    setDragMode(false)
    document.body.style.cursor = 'default'
  }

  return (
    <div
      ref={containerRef}
      style={containerStyles}
      className={classNames('resizable-container', className, dragMode && 'dragging')}
    >
      {children}
      {right && (
        <button
          onMouseDown={() => onMouseDown(true)}
          onMouseUp={onMouseUp}
          className={'resizable-container__handle-right'}
        >
          {handleElement}
        </button>
      )}
      {left && (
        <button
          onMouseDown={() => onMouseDown(false)}
          onMouseUp={onMouseUp}
          className={'resizable-container__handle-left'}
        >
          {handleElement}
        </button>
      )}
    </div>
  )
}

export default Resizable
