import React from 'react'
import { ChartData, ChartDataset } from 'chart.js'
import { seriesColor } from '../components/LineChart/lineChartOptions'
import { profileRound } from '../components/utils'

export type UseLineChartData = (data: { height__m: number; distance__km: number }[]) => ChartData<'line'>

const useChartData: UseLineChartData = (data) => {
  const { axisXData } = React.useMemo(() => {
    return {
      axisXData: data.map((item) => {
        return profileRound(item.distance__km)
      }),
    }
  }, [data])

  return React.useMemo(() => {
    if (axisXData) {
      const chartData: number[] = []
      data?.map((item) => {
        return chartData.push(item.height__m)
      })

      return {
        labels: axisXData,
        datasets: [
          {
            label: '',
            data: chartData,
            fill: false,
            backgroundColor: seriesColor,
            borderColor: seriesColor,
            color: seriesColor,
            borderWidth: 2,
            tension: 0.3,
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            pointBorderWidth: 0,
            pointHoverRadius: 0,
          } as ChartDataset<'line'>,
        ],
      }
    }
    return { labels: [], datasets: [] }
  }, [data])
}

export default useChartData
