import * as React from 'react'

import classnames from 'classnames'

import './style.scss'
import InfoIcon from 'images/InfoIcon'
import WarningIcon from 'images/WarningIcon'
import ErrorIcon from 'images/ErrorIcon'
import Button from 'components/Button'
import { useEffect, useState } from 'react'
import { InfoPopup } from 'components/InfoPopup/InfoPopup'
interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  unit?: string | JSX.Element
  error?: boolean
  onEnter?(): void
  classNames?: string
  placeholder?: string
  children?: any
  infoText?: string | JSX.Element
  warningText?: string | JSX.Element
  errorText?: string[]
  loading?: boolean
}

const Input: React.FC<IInput> = ({
  error,
  onEnter,
  infoText,
  warningText,
  errorText,
  classNames,
  loading,
  ...props
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [focused, setFocused] = React.useState<boolean>(false)
  const [showInfoMessage, setShowInfoMessage] = React.useState<boolean>(false)
  const [showWarningMessage, setShowWarningMessage] = React.useState<boolean>(false)
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false)
  const [visible, setVisibility] = useState(false)
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (['Enter', 'Escape'].includes(e.key)) {
      ref?.current?.blur()
    }
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    }
    return false
  }

  const onBlur = () => {
    if (typeof onEnter === 'function') {
      onEnter()
    }
    setFocused(false)
  }
  const onFocus = () => {
    setFocused(true)
  }
  const blur = () => {
    ref.current?.blur()
  }
  useEffect(() => {
    if (focused) {
      if (showInfoMessage) setShowInfoMessage(false)
      if (showWarningMessage) setShowWarningMessage(false)
      window.addEventListener('resize', blur)
    }
    setVisibility(focused)
    return () => {
      window.removeEventListener('resize', blur)
    }
  }, [focused])

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!inputRef?.current?.contains(event.target)) {
        setFocused(false)
        if (showInfoMessage) setShowInfoMessage(false)
        if (showWarningMessage) setShowWarningMessage(false)
        if (showErrorMessage) setShowErrorMessage(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showInfoMessage, showErrorMessage, showWarningMessage, focused])

  const getPopupMessage = (): JSX.Element => {
    if (showErrorMessage && errorText) {
      return (
        <>
          {errorText.map((text, idx) => (
            <div key={idx}>{text}</div>
          ))}
        </>
      )
    } else if (showWarningMessage && warningText) {
      return <>{warningText}</>
    } else if (showInfoMessage && infoText) {
      return <>{infoText}</>
    } else {
      if (errorText) {
        return (
          <>
            {errorText.map((text, idx) => (
              <div key={idx}>{text}</div>
            ))}
          </>
        )
      } else if (warningText) return <>{warningText}</>
      else if (infoText) return <>{infoText}</>
      return <></>
    }
  }

  return (
    <>
      <div className={classnames('input-wrapper', errorText && 'error', warningText && 'warning')} ref={inputRef}>
        {props.label && (
          <div className={'input-wrapper__label'}>
            <div className={'field-label-text'}>{props.label}</div>
            {infoText && (
              <Button
                mode={'info'}
                onClick={() => {
                  if (showWarningMessage) setShowWarningMessage(false)
                  if (showErrorMessage) setShowErrorMessage(false)
                  setShowInfoMessage(!showInfoMessage)
                }}
              >
                <InfoIcon />
              </Button>
            )}
            {warningText && (
              <Button
                mode={'info'}
                onClick={() => {
                  if (showInfoMessage) setShowInfoMessage(false)
                  if (showErrorMessage) setShowErrorMessage(false)
                  setShowWarningMessage(!showWarningMessage)
                }}
              >
                <WarningIcon />
              </Button>
            )}
            {errorText && (
              <Button
                mode={'info'}
                onClick={() => {
                  if (showInfoMessage) setShowInfoMessage(false)
                  if (showWarningMessage) setShowWarningMessage(false)
                  setShowErrorMessage(!showErrorMessage)
                }}
              >
                <ErrorIcon />
              </Button>
            )}
          </div>
        )}
        <div
          className={classnames('unit-wrapper input normal-text', props.disabled && 'disabled', classNames, {
            focused,
          })}
        >
          {!loading ? (
            <input
              ref={ref}
              className={classnames('', { error })}
              {...props}
              onKeyDown={onKeyDownHandler}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder={props.placeholder}
            />
          ) : (
            <div className={'input__loader'} />
          )}
          {errorText && (
            <Button
              className={'table-error__icon'}
              mode={'info'}
              onClick={() => setShowErrorMessage(!showErrorMessage)}
            >
              <ErrorIcon />
            </Button>
          )}
          {props.unit && <div className='unit'>{props.unit}</div>}
        </div>
      </div>
      {(focused || showInfoMessage || showWarningMessage || showErrorMessage) &&
        (infoText || warningText || errorText) && (
          <InfoPopup
            classNames={`${(visible || showInfoMessage || showWarningMessage || showErrorMessage) && 'visible'} ${
              showErrorMessage || (focused && errorText)
                ? 'error'
                : showWarningMessage || (focused && warningText)
                ? 'warning'
                : ''
            }`}
            focused={focused || showInfoMessage || showWarningMessage || showErrorMessage}
            message={getPopupMessage()}
            inputRef={inputRef}
          />
        )}
    </>
  )
}

export default Input
