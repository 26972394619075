import React, { useEffect, useState } from 'react'
import Settings from './Settings/settings'
import { useDispatch, useSelector } from 'react-redux'
import { resetOptimizationSliceState, selectTaskStatus, setCreateTask } from 'store/optimizationSlice'
import Results from './Results/results'
import useGetDumps from './Results/hooks/useGetDumps'
import Button from 'components/Button'
import DownloadIcon from 'images/DownloadIcon'
import RedoIcon from 'images/RedoIcon'
import './optimizationCalc.scss'
import { handleStorage } from 'services/handleStorage'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, ICreateTaskConfirmParams } from 'store/types'

const OptimizationCalc: React.FC = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICreateTaskConfirmParams)
  const [showSettings, setShowSettings] = React.useState<boolean>()
  const [requestData, setRequestData] = useState<boolean>(false)
  useGetDumps({ requestData, setRequestData })

  React.useEffect(() => {
    if (['Рассчитан', 'Ошибка', 'Отменен'].includes(taskStatus!)) {
      setShowSettings(false)
    }
  }, [taskStatus])

  useEffect(() => {
    if (blockingWindowParams?.taskType === 'OPTIMIZATION_TASK' && blockingWindowParams?.isConfirmed) {
      dispatch(resetOptimizationSliceState())
      dispatch(setCreateTask(true))
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindowParams?.isConfirmed])

  const calculate = () => {
    const userId = handleStorage.getUserId()
    if (userId && handleStorage.getOptNotificationShown()?.includes(userId)) {
      dispatch(resetOptimizationSliceState())
      dispatch(setCreateTask(true))
    } else {
      dispatch(
        setBlockingWindow({
          type: 'CREATE_TASK_CONFIRM',
          params: {
            taskType: 'OPTIMIZATION_TASK',
            message: (
              <ul className='confirm-window__list'>
                <li>Результат автоматического построения трубопровода будет применен автоматически.</li>
                <li>Указанные параметры будут применены ко всем участкам трубопровода.</li>
              </ul>
            ),
          } as ICreateTaskConfirmParams,
        }),
      )
    }
  }

  return (
    <div className={'optimization-calc__container'}>
      {(!['Рассчитан', 'Ошибка'].includes(taskStatus!) || showSettings) && (
        <>
          <Settings />
          <div className={'calc__bottom-panel'}>
            {showSettings && (
              <Button
                className={'hydraulic-settings__button'}
                mode={'secondary'}
                onClick={() => setShowSettings(false)}
              >
                Отменить
              </Button>
            )}
            <Button
              disabled={['Запущен', 'Ожидает очереди'].includes(taskStatus!)}
              className={'optimization-settings__calc-btn'}
              onClick={calculate}
            >
              Построить трубопровод
            </Button>
          </div>
        </>
      )}
      {['Рассчитан', 'Ошибка'].includes(taskStatus!) && !showSettings && (
        <>
          {' '}
          <Results />
          <div className={'calc__bottom-panel'}>
            <Button mode={'secondary'} onClick={() => setRequestData(true)}>
              <DownloadIcon />
              Лог-файл
            </Button>
            <Button onClick={() => setShowSettings(true)}>
              <RedoIcon className={'redo-icn'} />
              Пересчитать с новыми параметрами
            </Button>
          </div>{' '}
        </>
      )}
    </div>
  )
}

export default OptimizationCalc
