import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { apiError, ICommonSlice, IBlockingWindow } from './types'
import update from 'immutability-helper'
import { handleStorage } from '../services/handleStorage'
import { definitions } from '../generated/apiTypes'

const getGroups = (): definitions['Group'][] => {
  const groups: definitions['Group'][] = []
  const librarianAuthority = handleStorage.getLibrarianAuthority()
  if (librarianAuthority) groups.push({ name: librarianAuthority })
  return groups
}

export const initialState: ICommonSlice = {
  user: {
    first_name: handleStorage.getUser().first_name || '',
    last_name: handleStorage.getUser().last_name || '',
    groups: getGroups(),
  },
  apiErrors: [],
  blockingWindow: {
    type: null,
  },
  taskIsCanceling: false,
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    addApiErrors: (state, action: PayloadAction<apiError[]>) => {
      state.apiErrors = [...state.apiErrors, ...action.payload]
    },
    deleteApiError: (state, action: PayloadAction<apiError>) => {
      const index = state.apiErrors.findIndex((item) => action.payload.message === item.message)
      if (index !== undefined && index > -1) {
        state.apiErrors = update(state.apiErrors, { $splice: [[index, 1]] })
      }
    },
    setApiErrors: (state, action: PayloadAction<ICommonSlice['apiErrors']>) => {
      state.apiErrors = action.payload
    },
    setUser: (state, action: PayloadAction<ICommonSlice['user']>) => {
      state.user = action.payload
    },
    setTaskIsCanceling: (state, action: PayloadAction<ICommonSlice['taskIsCanceling']>) => {
      state.taskIsCanceling = action.payload
    },
    setBlockingWindow: (state, action: PayloadAction<IBlockingWindow>) => {
      state.blockingWindow = {
        ...state.blockingWindow,
        ...action.payload,
      }
    },
    resetBlockingWindow: (state) => {
      state.blockingWindow = initialState.blockingWindow
    },
    resetCommonSliceState: (state) => {
      state.apiErrors = initialState.apiErrors
      state.taskIsCanceling = initialState.taskIsCanceling
    },
  },
})

export const {
  addApiErrors,
  setApiErrors,
  setUser,
  deleteApiError,
  resetCommonSliceState,
  setBlockingWindow,
  setTaskIsCanceling,
  resetBlockingWindow,
} = commonSlice.actions

const selectSelf = (state: RootState) => state.common

export const selectApiErrors = createSelector(selectSelf, (common) => common.apiErrors)
export const selectBlockingWindow = createSelector(selectSelf, (common) => common.blockingWindow)
export const selectUser = createSelector(selectSelf, (common) => common.user)
export const selectTaskIsCanceling = createSelector(selectSelf, (common) => common.taskIsCanceling)

export default commonSlice.reducer
