import { useDispatch, useSelector } from 'react-redux'
import {
  selectProject,
  selectProjectID,
  setPipelines,
  resetSink,
  resetSource,
  setCompressorStations,
  setReferenceNodes,
  setReductionNodes,
  setHeatingStations,
} from 'store/projectSlice'
import React from 'react'
import { elementType } from 'store/types'
import { getNodesByProjectIDRequest, getPipelinesByProjectIDRequest, updateGroupGeometry } from 'services/apiRequests'
import axios from 'axios'
import { selectMapSubmode, setMapNodes, setMapPipelines } from 'store/mapSlice'
import { convertToStraightLine } from '../utils/convertToStraightLine'
import { convertToLng } from '../utils/convertToLng'
import { getCoords, point } from '@turf/turf'

export interface IUpdateCoordsProps {
  id: string
  type: elementType
  central_point: { [key: string]: unknown }
}
interface IUpdateCoords {
  props?: IUpdateCoordsProps[]
  setShowKilometerMarks(value: boolean): void
}
const useUpdateCoords = ({ props, setShowKilometerMarks }: IUpdateCoords) => {
  const dispatch = useDispatch()
  const project = useSelector(selectProject)
  const projectID = useSelector(selectProjectID) as string
  const isDragAlong = useSelector(selectMapSubmode) === 'dragAlong'
  const removeFromStore = (type: IUpdateCoordsProps['type'], ids: string[]) => {
    switch (type) {
      case 'SOURCE':
        project.source !== undefined && dispatch(resetSource())
        break
      case 'SINK':
        project.sink !== undefined && dispatch(resetSink())
        break
      case 'COMPRESSOR_STATIONS':
        dispatch(setCompressorStations(project.compressorStations.filter((i) => !ids.includes(i.id))))
        break
      case 'REFERENCE_NODES':
        dispatch(setReferenceNodes(project.referenceNodes.filter((i) => !ids.includes(i.id))))
        break
      case 'NODES_REDUCTIONS':
        dispatch(setReductionNodes(project.reductionNodes.filter((i) => !ids.includes(i.id))))
        break
      case 'HEATING_STATIONS':
        dispatch(setHeatingStations(project.heatingStations.filter((i) => !ids.includes(i.id))))
        break
      case 'PIPELINE':
        dispatch(setPipelines(project.pipelines.filter((i) => !ids.includes(i.id))))
        break
    }
  }
  const tryToUpdateCoords = () => {
    if (props) {
      const pipelines: any = []
      const nodes: any = []
      props.forEach((p) => {
        p.type === 'PIPELINE'
          ? pipelines.push({
              id: p.id,
              line:
                p.central_point.type === 'MultiLineString'
                  ? convertToStraightLine(p.central_point as any).geometry
                  : p.central_point,
            })
          : nodes.push({
              id: p.id,
              central_point: point([
                convertToLng(getCoords(p.central_point as any)[0]),
                getCoords(p.central_point as any)[1],
              ]).geometry,
            })
      })
      if (!isDragAlong) {
        for (const type of [
          'SINK',
          'SOURCE',
          'COMPRESSOR_STATIONS',
          'REFERENCE_NODES',
          'PIPELINE',
          'NODES_REDUCTIONS',
          'HEATING_STATIONS',
        ]) {
          removeFromStore(
            type as elementType,
            props.filter((p) => p.type === type).map((p) => p.id),
          )
        }
      }
      updateGroupGeometry(projectID, { nodes, pipelines }).then(() =>
        axios
          .all([getPipelinesByProjectIDRequest(projectID) as any, getNodesByProjectIDRequest(projectID) as any])
          .then(
            axios.spread((pipelinesRes, nodesRes) => {
              dispatch(setMapPipelines(pipelinesRes.data))
              dispatch(setMapNodes(nodesRes.data))
              setShowKilometerMarks(true)
            }),
          ),
      )
    }
  }

  React.useEffect(() => {
    if (props?.length) tryToUpdateCoords()
  }, [props])

  return
}

export default useUpdateCoords
