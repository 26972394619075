// @ts-ignore
import SvgImg from 'images/EditBarIcon.svg'
const imgSVG = new Image(12, 12)
let imgSVGLoaded = false
imgSVG.src = SvgImg
imgSVG.onload = function () {
  imgSVGLoaded = true
}

export const verticalLineAtCursor = () => {
  const width = 1
  const color = '#0074BC'

  return {
    id: 'verticalLineAtCursor',
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        chartArea: { top, bottom },
      } = chart
      ctx.lineWidth = width
      ctx.strokeStyle = color
      ctx.setLineDash([5, 3])
      ctx.beginPath()
      if (chart.tooltip.opacity && chart.tooltip.dataPoints.length > 0) {
        ctx.moveTo(chart.tooltip.caretX, top)
        ctx.lineTo(chart.tooltip.caretX, bottom)
      } else {
        ctx.moveTo(0, 0)
        ctx.lineTo(0, 0)
      }
      ctx.stroke()
    },
  }
}

export const innerBarText = (setChartRenderingInProgress?: (value: boolean) => void) => {
  const color = 'white'
  const maxTextLengthPx = 160

  return {
    id: 'innerBarText',
    beforeRender: () => {
      if (setChartRenderingInProgress) setChartRenderingInProgress(true)
    },
    afterRender: () => {
      if (setChartRenderingInProgress) setChartRenderingInProgress(false)
    },
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        data,
        scales: { x, y },
      } = chart
      ctx.save()
      let admDivisionIx: number | undefined = undefined
      data.datasets[0].data
        .filter((item: any) => item.y === 'admDivision')
        .forEach((dataPoint: any) => {
          if (admDivisionIx !== dataPoint.valueIx) {
            ctx.font = 'normal 10px Mulish'
            ctx.fillStyle = color
            ctx.fillText(
              `${
                x.getPixelForValue(dataPoint.x[0]) >= x.left &&
                x.getPixelForValue(dataPoint.x[0]) < x.right - maxTextLengthPx &&
                x.getPixelForValue(dataPoint.x[1]) - x.getPixelForValue(dataPoint.x[0]) > maxTextLengthPx
                  ? dataPoint.value
                  : ''
              }`,
              x.getPixelForValue(dataPoint.x[0] < x.min ? x.min : dataPoint.x[0]) + 6,
              y.getPixelForValue(0),
            )
          }
          admDivisionIx = dataPoint.valueIx
        })
    },
  }
}

export const editBarIcon = () => {
  return {
    id: 'editBarIcon',
    afterDatasetDraw: (chart: any) => {
      const {
        ctx,
        data,
        scales: { x, y },
      } = chart
      ctx.save()
      data.datasets[0].data
        .filter((item: any) => ['ground', 'underGround', 'aboveGround'].includes(item.y))
        .forEach((dataPoint: any) => {
          if (imgSVGLoaded) {
            ctx.drawImage(
              imgSVG,
              x.getPixelForValue(dataPoint.x[0]) + 6,
              y.getPixelForValue(dataPoint.y) - imgSVG.height / 2,
              12,
              12,
            )
          }
        })
    },
  }
}
