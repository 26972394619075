import React from 'react'
import { MultiSelectTable } from 'components/Table/MultiSelectTable/MultiSelectTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedItem,
  deselectSelectedItemById,
  selectDirectoryItems,
  selectSelectedItems,
  updateItemFieldById,
  setModified,
  setDirectoryItems,
} from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import useGetGPU from './hooks/useGetGPU'
import { columns, GPUTypes, strippedDownTableColumns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import useCreateGPUVersion from './hooks/useCreateGPUVersion'
import { generateUID } from '../../../utils'
import { setBlockingWindow } from 'store/commonSlice'
import '../../directory.scss'
import './DirectoryGPU.scss'
import { IHandbookGPUNewUnit, readonlyFields } from './types'
import { IErrorParams } from 'store/types'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import { ITableColumn } from '../../../../components/Table/types'
import classNames from 'classnames'

interface IDirectoryGPUProps {
  directoryKey: keyof definitions['Handbooks']
}

const newItemProperties = (type?: definitions['HandbookGPU']['type']) => {
  const isEmpty = type === 'По формуле'
  return {
    inner_power_4: isEmpty ? 0 : 1,
    inner_power_3: isEmpty ? 0 : 1,
    inner_power_1: isEmpty ? 0 : 1,
    nominal_efficiency: 1,
    technical_condition_coefficient: 1,
    polytropic_efficiency_1: 1,
    polytropic_efficiency_2: isEmpty ? 0 : 1,
    polytropic_efficiency_3: isEmpty ? 0 : 1,
    polytropic_efficiency_4: isEmpty ? 0 : 1,
    electric_engine_efficiency: isEmpty ? 0 : 1,
    fuel_technical_condition_factor: isEmpty ? 0 : 1,
    blower_mechanical_efficiency: 1,
    multiplier_efficiency: isEmpty ? 0 : 1,
    turbine_speed_influence_coefficient: isEmpty ? 0 : 1,
    heat_utilization_coefficient: isEmpty ? 0 : 1,
    air_temperature_influence_coefficient: isEmpty ? 0 : 1,
    compressibility_per_volume_flow_rate_3: isEmpty ? 0 : 1,
    compressibility_per_volume_flow_rate_2: isEmpty ? 0 : 1,
    max_multi_stage_compression_ratio: 1,
    max_single_stage_compression_ratio: 1,
    compressibility_factor: 1,
    R__kgs_m_per_kg_K: 1,
    temperature__K: 1,
    max_volume_flow_rate__m3_per_min: 1,
    min_volume_flow_rate__m3_per_min: 1,
    power__kW: 1,
    compressibility_per_volume_flow_rate_1: isEmpty ? 0 : 1,
    inner_power_2: isEmpty ? 0 : 1,
    polytropic_efficiency_5: isEmpty ? 0 : 1,
    temperature_environment__K: isEmpty ? 0 : 1,
    type: type || ('Полнонапорный' as definitions['HandbookGPU']['type']),
    created: true,
    modified: false,
  }
}

const DirectoryGPU: React.FC<IDirectoryGPUProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectSelectedItems)
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookGPUCreate'][]
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState<any>({ type: undefined })
  const [newItem, setNewItem] = React.useState<IHandbookGPUNewUnit>({
    id: '',
    name: '',
    ...newItemProperties(filter.type),
  })
  useGetGPU(requestData)
  const saveResult = useCreateGPUVersion(saveData)
  const currentItems = directoryItems.map((item) => {
    if (item.type === 'По формуле') {
      return {
        ...item,
        ...Object.fromEntries(readonlyFields.map((field) => [field, ''])),
      }
    }
    return item
  })
  React.useEffect(() => {
    setNewItem({ id: generateUID(), name: `ГПА ${directoryItems.length + 1}`, ...newItemProperties(filter.type) })
  }, [directoryItems.length, filter])

  React.useEffect(() => {
    if (saveResult) {
      setIsModified(false)
      setEditMode(false)
    }
  }, [saveResult])

  const onItemSelect = (id: string) => {
    const itemToAdd = directoryItems?.find((item) => item.id === id)
    if (itemToAdd) dispatch(addSelectedItem(itemToAdd))
  }

  const onItemDeselect = (id: string) => {
    dispatch(deselectSelectedItemById(id))
  }

  const onSaveItemField = async (id: string, value: definitions['HandbookGPU']['type'] | number, fieldName: string) => {
    const uniqueFiled = 'name'
    if (
      fieldName === uniqueFiled &&
      (directoryItems as definitions['HandbookGPU'][])?.some((item) => item[uniqueFiled] === value)
    ) {
      dispatch(
        setBlockingWindow({
          type: 'ERROR',
          params: {
            title: 'Ошибка заполнения',
            message: 'Значение поля “Название” должно быть уникальным',
          } as IErrorParams,
        }),
      )
      const oldItem = (directoryItems as definitions['HandbookGPU'][]).find((item) => item.id === id)
      if (oldItem) {
        await dispatch(updateItemFieldById({ id, value, fieldName }))
        dispatch(updateItemFieldById({ id, value: oldItem[uniqueFiled], fieldName }))
      }
    } else {
      if (fieldName === 'type' && value === 'По формуле') {
        const itemIndex = directoryItems.findIndex((i) => i.id === id)
        dispatch(
          setDirectoryItems([
            ...directoryItems.slice(0, itemIndex),
            {
              ...directoryItems[itemIndex],
              [fieldName]: value,
              ...Object.fromEntries(readonlyFields.map((field) => [field, 0])),
            },
            ...directoryItems.slice(itemIndex + 1),
          ]),
        )
      } else dispatch(updateItemFieldById({ id, value, fieldName }))

      dispatch(setModified(id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const classNameExpander = (field: ITableColumn, obj: any) => {
    if (obj.type === 'По формуле' && readonlyFields.includes(field.key)) {
      return 'readonly'
    }
    return ''
  }
  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
      setIsModified={setIsModified}
      newItemToAdd={newItem as definitions['HandbookGPU']}
    >
      <div className={'directory__content'}>
        <div className={'directory-filters__container'}>
          <Dropdown
            options={[
              { type: null },
              ...GPUTypes.map((opt) => {
                return { type: opt }
              }),
            ]}
            displayedField={'type'}
            setSelectedValue={setFilter}
            selectedValue={filter.type && filter}
            label={'Тип ГПА'}
          />
        </div>
        <MultiSelectTable
          selectedItems={selectedItems}
          columns={filter.type === 'По формуле' ? strippedDownTableColumns : columns}
          editMode={editMode}
          dataSource={filter.type ? currentItems.filter((item) => item.type === filter.type) : currentItems}
          onItemSelect={onItemSelect}
          onItemDeselect={onItemDeselect}
          setValue={onSaveItemField}
          className={classNames(
            'directory-table__container gpu-table__container',
            filter.type === 'По формуле' && 'stripped_down',
          )}
          classNameExpander={classNameExpander}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryGPU
