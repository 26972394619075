import * as React from 'react'

function AlertIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 7.5V9.16667M10 12.5V12.5083'
        stroke='#E83232'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.7223 1.98668C9.10699 1.75988 9.54541 1.64026 9.99199 1.64026C10.4386 1.64026 10.877 1.75988 11.2617 1.98668C11.6437 2.21194 11.959 2.53476 12.175 2.92196L18.088 13.1238C18.1076 13.1577 18.1248 13.1929 18.1395 13.2292C18.2925 13.6073 18.3506 14.0171 18.3088 14.4229C18.267 14.8286 18.1266 15.218 17.8998 15.557C17.6729 15.896 17.3666 16.1744 17.0075 16.3678C16.6484 16.5612 16.2474 16.6638 15.8395 16.6667L15.8337 16.6667V16.6667H4.16699C4.15175 16.6667 4.13662 16.6663 4.12158 16.6655C4.09221 16.6671 4.06246 16.6672 4.03242 16.6656C3.63416 16.6451 3.2466 16.5296 2.90207 16.3287C2.55755 16.1279 2.26608 15.8476 2.05198 15.5111C1.83788 15.1747 1.70737 14.7919 1.67135 14.3947C1.63533 13.9976 1.69484 13.5976 1.84492 13.2281C1.8595 13.1922 1.87657 13.1574 1.896 13.1238L7.80893 2.92196C8.02501 2.53476 8.34023 2.21194 8.7223 1.98668ZM4.12095 15.0013C4.13619 15.0005 4.15154 15.0001 4.16699 15.0001H15.8303C15.9654 14.9987 16.0982 14.9645 16.2171 14.9004C16.3368 14.836 16.439 14.7432 16.5146 14.6302C16.5902 14.5172 16.637 14.3874 16.6509 14.2521C16.6632 14.1331 16.6496 14.013 16.6114 13.9L10.7293 3.75127L10.7212 3.73682C10.6491 3.60657 10.5434 3.498 10.4152 3.4224C10.287 3.3468 10.1408 3.30693 9.99199 3.30693C9.84313 3.30693 9.69699 3.3468 9.56876 3.4224C9.44053 3.498 9.33489 3.60657 9.26282 3.73682L9.25464 3.75127L3.3722 13.9005C3.33469 14.0108 3.32066 14.1279 3.33121 14.2442C3.34321 14.3766 3.38672 14.5042 3.45808 14.6163C3.52945 14.7285 3.62661 14.8219 3.74145 14.8889C3.85628 14.9558 3.98547 14.9943 4.11823 15.0012C4.11914 15.0012 4.12004 15.0013 4.12095 15.0013Z'
        fill='#E83232'
      />
    </svg>
  )
}

export default AlertIcon
