import React, { useState } from 'react'
import BackIcon from 'images/BackIcon'
import Button from 'components/Button'
import './projectHeader.scss'
import { useNavigate } from 'react-router-dom'
import { KebabButton } from 'components/KebabButton/KebabButton'
import CopyIcon from 'images/CopyIcon'
import DeleteIcon from 'images/DeleteIcon'
import EditIcon from 'images/EditIcon'
import GISIcon from 'images/GISIcon'
import { setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import useCopyProject from 'components/hooks/useCopyProject'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { exportProjectToNextGISRequest } from '../../services/apiRequests'
import { IDeleteConfirmParams, IEditProjectNameParams, IErrorParams, IProjectExportDoneParams } from 'store/types'
import useChangeProjectName from 'components/hooks/useChangeProjectName'
interface IProjectHeaderProps {
  title: string
  id: string
}
export const ProjectHeader: React.FC<IProjectHeaderProps> = ({ title, id }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID) as string
  const [copyProjectID, setCopyProjectID] = useState<string | undefined>()

  useCopyProject(copyProjectID)
  useChangeProjectName()

  const onDelete = () => {
    dispatch(
      setBlockingWindow({
        type: 'DELETE_CONFIRM',
        params: { objectType: 'PROJECT', objectName: title, objectID: id } as IDeleteConfirmParams,
      }),
    )
  }
  const onChangeProjectName = () => {
    dispatch(
      setBlockingWindow({
        type: 'EDIT_PROJECT_NAME',
        params: { objectName: title, objectID: id } as IEditProjectNameParams,
      }),
    )
  }

  const onCopyProject = () => {
    setCopyProjectID(projectId)
  }

  const onExportProjectToNextGIS = () => {
    dispatch(
      setBlockingWindow({
        type: 'EXPORT_PROGRESS',
      }),
    )
    exportProjectToNextGISRequest({ project_id: projectId })
      .then((res) => {
        dispatch(
          setBlockingWindow({
            type: 'EXPORT_DONE',
            params: { projectUrl: res.data.nextgis_project_url } as IProjectExportDoneParams,
          }),
        )
      })
      .catch(() => {
        dispatch(
          setBlockingWindow({
            type: 'ERROR',
            params: { title: 'Ошибка экспорта', message: 'Ошибка экспорта' } as IErrorParams,
          }),
        )
      })
  }
  return (
    <div className={'project__header base-header'}>
      <div className={'project__header-start'}>
        <BackIcon className={'back-icon'} onClick={() => navigate('/')} />
        <h2 className={'project__header-title'}>{title}</h2>
      </div>
      <div className={'project__header-end'}>
        <KebabButton mode={'dark'} text={'Действия'}>
          <Button mode={'drop-down'} onClick={onExportProjectToNextGIS}>
            <GISIcon /> Экспортировать&nbsp;трассу&nbsp;в&nbsp;ИУС&nbsp;ГИС
          </Button>
          <Button mode={'drop-down'} onClick={onChangeProjectName}>
            <EditIcon /> Переименовать&nbsp;проект
          </Button>
          <Button mode={'drop-down'} onClick={onCopyProject}>
            {' '}
            <CopyIcon /> Копировать&nbsp;проект{' '}
          </Button>
          <Button mode={'drop-down'} onClick={onDelete}>
            {' '}
            <DeleteIcon /> Удалить&nbsp;проект{' '}
          </Button>
        </KebabButton>
        {/*<Button className={'action-btn'} mode={'primary-light'}>*/}
        {/*  Действия*/}
        {/*  <DotsIcon />*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}
