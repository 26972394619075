import React from 'react'
import Typography from './Typography'
import Inputs from './Inputs'
import ButtonsLinks from './ButtonsLinks'
import Icons from './Icons'
import Colors from './Colors'

export default function StyleGuide() {
  return (
    <>
      <div className={'style-guide__wrapper'}>
        <Typography />
        <Inputs />
        <ButtonsLinks />
        <Icons />
        <Colors />
      </div>
    </>
  )
}
