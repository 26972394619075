import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createModel10Task } from 'services/apiRequests'
import { selectCreateTask, setCreateTask, setTaskId } from 'store/model10Slice'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'

const useCreateTask = (settingsFile: File | undefined, GPUFile?: File, ACUFile?: File) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)

  const tryToCreateTask = async () => {
    if (settingsFile) {
      const filesToSend = new FormData()
      filesToSend.append('calc', settingsFile)
      if (GPUFile) filesToSend.append('GPU', GPUFile)
      if (ACUFile) filesToSend.append('ACU', ACUFile)
      await createModel10Task(filesToSend)
        .then((response) => {
          dispatch(setTaskId(response.data.id))
        })
        .catch(() => {
          dispatch(setCreateTask(false))
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then(() => dispatch(setCreateTask(false)))
  }, [createTask])

  return
}

export default useCreateTask
