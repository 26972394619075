import * as React from 'react'

function GISIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='5' cy='5' r='2' fill='#373737' />
      <circle cx='15' cy='15' r='2' fill='#373737' />
      <path
        d='M5 5L5.40491 7.83436C5.46241 8.23686 5.75788 8.56447 6.15232 8.66308L6.74254 8.81063C7.1877 8.92193 7.5 9.32191 7.5 9.78078V11.1327C7.5 11.6304 7.86593 12.0523 8.35858 12.1227L10.7464 12.4638C10.9128 12.4875 11.0705 12.5529 11.205 12.6537L13 14L15.5 15.5'
        stroke='#373737'
        strokeWidth='1.67'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 5.16671C11 4.70647 11.2985 4.33337 11.6667 4.33337H18.3333C18.7015 4.33337 19 4.70647 19 5.16671C19 5.62694 18.7015 6.00004 18.3333 6.00004H11.6667C11.2985 6.00004 11 5.62694 11 5.16671Z'
        fill='#373737'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7559 4.57745C20.0814 4.90289 20.0814 5.43053 19.7559 5.75596L16.4226 9.0893C16.0972 9.41473 15.5695 9.41473 15.2441 9.0893C14.9186 8.76386 14.9186 8.23622 15.2441 7.91078L18.5774 4.57745C18.9028 4.25201 19.4305 4.25201 19.7559 4.57745Z'
        fill='#373737'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2441 1.24408C15.5695 0.918641 16.0972 0.918641 16.4226 1.24408L19.7559 4.57741C20.0814 4.90285 20.0814 5.43049 19.7559 5.75592C19.4305 6.08136 18.9028 6.08136 18.5774 5.75592L15.2441 2.42259C14.9186 2.09715 14.9186 1.56951 15.2441 1.24408Z'
        fill='#373737'
      />
    </svg>
  )
}

export default GISIcon
