import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import '../style.scss'
import { IBlockingWindow, IErrorParams } from 'store/types'
import AlertIcon from 'images/AlertIcon'
import './errorWindow.scss'
import { useSelector } from 'react-redux'
import { selectBlockingWindow } from 'store/commonSlice'

interface IErrorWindow {
  closeWindow(): void
}

export const ErrorWindow: React.FC<IErrorWindow> = ({ closeWindow }) => {
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IErrorParams)
  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container delete-confirm'>
        <div className={'blocking-window__header'}>
          {blockingWindowParams?.title}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          <div className={'blocking-window__error-container'}>
            <AlertIcon className='blocking-window__error-icon' />
            <div className={'blocking-window__error-message'}>{blockingWindowParams?.message}</div>
          </div>
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Ок
          </Button>
        </div>
      </div>
    </div>
  )
}
