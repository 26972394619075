import React from 'react'
import { TableHeaderCell } from './TableHeaderCell'
import { ITableHeader } from './types'
export const TableHeader: React.FC<ITableHeader> = ({ columns, expandColumn = false, scrollTop }) => {
  return (
    <>
      {expandColumn && <div className={'grid-table__header'} />}
      {columns.map((header, index) => (
        <TableHeaderCell
          key={index}
          title={header.title}
          scrollTop={scrollTop}
          isSticky={header.sticky}
          className={header.sticky ? 'sticky' : ''}
        />
      ))}
    </>
  )
}
