import React, { PropsWithChildren } from 'react'
import Checkbox from '../../Checkbox'
interface IMultiSelectTableRow extends React.HTMLAttributes<HTMLElement> {
  id: string
  selectionMode?: boolean
  selected: boolean
  onItemSelect(id: string): void
  onItemDeselect(id: string): void
}
export const MultiSelectTableRow: React.FC<PropsWithChildren<IMultiSelectTableRow>> = ({
  id,
  children,
  selectionMode,
  selected,
  onItemSelect,
  onItemDeselect,
}) => {
  const setSelected = () => {
    if (selected) {
      onItemDeselect(id)
    } else onItemSelect(id)
  }

  return (
    <>
      {selectionMode && (
        <div className={'grid-table__cell selection-cell'}>
          <Checkbox checkedValue={selected} setCheckedValue={setSelected} />
        </div>
      )}
      {children}
    </>
  )
}
