import React from 'react'
import { IGPUChoicesProps } from './types'
import { PanelButton } from 'components/PanelButton/panelButton'
import { GPUCard } from './gpuCard'

export const GPUChoicesList: React.FC<IGPUChoicesProps> = ({ directoryItems, selectedID, setSelectedID }) => {
  return (
    <div className={'gpu-choices-list-view'}>
      <div className={'gpu-choices-list-view__btns'}>
        {directoryItems.map((item) => (
          <PanelButton
            key={item.id}
            className={selectedID === item.id ? 'selected' : ''}
            value={item.name}
            onClick={() => setSelectedID(item.id)}
          />
        ))}
      </div>
      <div className={'gpu-choices-list-view__card'}>
        <GPUCard item={directoryItems.find((i) => i.id === selectedID) || directoryItems[0]} />
      </div>
    </div>
  )
}
