import { getCoords, lineString, MultiLineString, Position } from '@turf/turf'
import { last } from '../utils'
import { convertToLng } from './convertToLng'

export const convertToStraightLine = (line: MultiLineString) => {
  /**
   * Преобразует дугу большого круга в прямую
   * @param line Дуга
   */
  return lineString(
    getCoords(line)
      .reduce(
        (prev, cur) => {
          prev.push(last(cur))
          return prev
        },
        [getCoords(line)[0][0]],
      )
      .map((coords: Position) => [convertToLng(coords[0]), coords[1]]),
  )
}
