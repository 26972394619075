import React, { useEffect, useMemo, useRef } from 'react'
import './profilePanel.scss'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import classnames from 'classnames'
import Handle from 'components/Handle/handle'
import Resizable from 'components/Resizeable/resizable'
import { Chart, Tooltip } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import NumberInput from 'components/Input/numberInput'
import Button from 'components/Button'
import MinusIcon from 'images/MinusIcon'
import BackIcon from 'images/BackIcon'
import PlusIcon from 'images/PlusIcon'
import { ProfileConfig } from './components/config'
import { profileRound } from './components/utils'
import DownloadIcon from 'images/DownloadIcon'
import { KebabButton } from 'components/KebabButton/KebabButton'
import ProfileChart, { chartType } from './components/ProfileChart'
import { setCreateTask, resetProfileSliceState, selectTaskStatus, setTaskStatus } from 'store/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import useCreateProfileTask from './hooks/useCreateProfileTask'
import { handleStorage } from 'services/handleStorage'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, ICreateTaskConfirmParams } from 'store/types'
import Dropdown from 'components/Dropdown/Dropdown'
import { layingTypeOptions } from './components/BarChart/LayingTypePopup'
import { definitions } from 'generated/apiTypes'
Chart.register([Tooltip, zoomPlugin])

interface IHeightProfilePanelProps {
  closePanel(): void
}

const chartTypeOptions = [
  { value: 'HEIGHT_PROFILE' as chartType, name: 'Высотный профиль' },
  { value: 'CATEGORY_AREA' as chartType, name: 'Категорийные участки' },
  { value: 'LAYING_METHOD' as chartType, name: 'Способ прокладки' },
  { value: 'LAYER_TYPES' as chartType, name: 'Тип слоя' },
]
const noChartOption = [{ value: 'NONE' as chartType, name: 'Не показывать график 2' }]

const ProfilePanel: React.FC<IHeightProfilePanelProps> = ({ closePanel }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ICreateTaskConfirmParams)
  const taskStatus = useSelector(selectTaskStatus)
  const panelRef = useRef<any>()
  const [startPoint, setStartPoint] = React.useState<number>()
  const [finishPoint, setFinishPoint] = React.useState<number>()
  const [minPoint, setMinPoint] = React.useState<number>()
  const [maxPoint, setMaxPoint] = React.useState<number>()
  const [requestRange, setRequestRange] = React.useState<
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined
  >()
  const [panPlus, setPanPlus] = React.useState<number>(0)
  const [panMinus, setPanMinus] = React.useState<number>(0)
  const [zoomPlus, setZoomPlus] = React.useState<number>(0)
  const [zoomMinus, setZoomMinus] = React.useState<number>(0)
  const [startPointToZoom, setStartPointToZoom] = React.useState<number>()
  const [finishPointToZoom, setFinishPointToZoom] = React.useState<number>()
  const [chart1TypeOption, setChart1TypeOption] = React.useState<{
    value: chartType
    name: string
  }>({ value: 'HEIGHT_PROFILE' as chartType, name: 'Высотный профиль' })
  const [chart2TypeOption, setChart2TypeOption] = React.useState<{
    value: chartType
    name: string
  }>({ value: 'LAYER_TYPES' as chartType, name: 'Тип слоя' })
  const [chartRenderingInProgress, setChartRenderingInProgress] = React.useState<boolean>(false)
  const [layingTypeOption, setLayingTypeOption] = React.useState<
    definitions['RouteProfileLayingTypeSection']['laying_type']
  >(layingTypeOptions[0])

  useCreateProfileTask(layingTypeOption)

  const resetRange = () => {
    setRequestRange({ start_distance__km: undefined, end_distance__km: undefined })
    setStartPoint(minPoint)
    setStartPointToZoom(minPoint)
    setFinishPoint(maxPoint)
    setFinishPointToZoom(maxPoint)
  }

  const recalculate = () => {
    dispatch(setTaskStatus('Неактуальный'))
  }

  const onPanPlus = () => {
    if (!chartRenderingInProgress) setPanPlus(panPlus + 1)
  }

  const onPanMinus = () => {
    if (!chartRenderingInProgress) setPanMinus(panMinus + 1)
  }

  const onZoomPlus = () => {
    if (!chartRenderingInProgress) setZoomPlus(zoomPlus + 1)
  }

  const onZoomMinus = () => {
    if (!chartRenderingInProgress) setZoomMinus(zoomMinus + 1)
  }

  const isPanLeftDisabled = useMemo(() => {
    if (minPoint === undefined || startPoint === undefined) return true
    return minPoint === startPoint
  }, [startPoint, minPoint])

  const isPanRightDisabled = useMemo(() => {
    if (maxPoint === undefined || finishPoint === undefined) return true
    return maxPoint === finishPoint
  }, [finishPoint, maxPoint])

  useEffect(() => {
    if (blockingWindowParams?.taskType === 'PROFILE_TASK' && blockingWindowParams?.isConfirmed) {
      dispatch(resetProfileSliceState())
      dispatch(setCreateTask(true))
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindowParams?.isConfirmed])

  const calculate = () => {
    const userId = handleStorage.getUserId()
    if (userId && handleStorage.getProfileNotificationShown()?.includes(userId)) {
      dispatch(resetProfileSliceState())
      dispatch(setCreateTask(true))
    } else {
      dispatch(
        setBlockingWindow({
          type: 'CREATE_TASK_CONFIRM',
          params: {
            taskType: 'PROFILE_TASK',
            message: (
              <>
                Способы прокладки трубопровода, выбранные в ручную, не будут сохранены. Каждому участку будет
                переназначен способ прокладки.
              </>
            ),
          } as ICreateTaskConfirmParams,
        }),
      )
    }
  }

  const changeChart1Type = (value: { value: chartType; name: string }) => {
    if (value.value !== chart1TypeOption.value) resetRange()
    setChart1TypeOption(value)
  }

  const changeChart2Type = (value: { value: chartType; name: string }) => {
    if (value.value !== chart2TypeOption.value) resetRange()
    setChart2TypeOption(value)
  }

  return (
    <Resizable
      className={classnames('height-profile-panel__resizable-container')}
      right={false}
      left
      handleElement={<Handle />}
    >
      <div className='height-profile-panel' ref={panelRef}>
        <div className={'height-profile-panel__top-row'}>
          <div className={'height-profile-panel__control-row-wrapper'}>
            <div className={'height-profile-panel__title-wrapper'}>
              <div className={'height-profile-panel__title primary-text'}>Профиль трубопровода</div>
              <Button mode={'secondary'} disabled>
                <DownloadIcon /> Скачать .pdf
              </Button>
            </div>
            <div className={'height-profile-panel__control-row'}>
              <div className={'height-profile-panel__control-btns'}>
                <div className={'height-profile-panel__zoom-btns'}>
                  <Button
                    className='height-profile-panel__control-btn left'
                    onClick={onZoomPlus}
                    disabled={
                      finishPoint === profileRound(startPoint! + ProfileConfig.minRange) || taskStatus !== 'Рассчитан'
                    }
                  >
                    <PlusIcon className={'height-profile-panel__zoom-icn'} />
                  </Button>
                  <Button
                    className='height-profile-panel__control-btn right'
                    onClick={onZoomMinus}
                    disabled={(minPoint === startPoint && maxPoint === finishPoint) || taskStatus !== 'Рассчитан'}
                  >
                    <MinusIcon className={'height-profile-panel__zoom-icn'} />
                  </Button>
                </div>
                <div className={'height-profile-panel__pan-btns'}>
                  <Button
                    className='height-profile-panel__control-btn left'
                    onClick={onPanPlus}
                    disabled={isPanLeftDisabled || taskStatus !== 'Рассчитан'}
                  >
                    <BackIcon className='height-profile-panel__back-icn' />
                  </Button>
                  <Button
                    className='height-profile-panel__control-btn right'
                    onClick={onPanMinus}
                    disabled={isPanRightDisabled || taskStatus !== 'Рассчитан'}
                  >
                    <BackIcon className='height-profile-panel__back-icn right' />
                  </Button>
                </div>
              </div>
              <div className={'height-profile-panel__range'}>
                <div className={'height-profile-panel__inputs'}>
                  <NumberInput
                    value={startPoint}
                    setValue={setStartPointToZoom}
                    classNames={'height-profile-panel__control-row-input'}
                    minValue={minPoint}
                    maxValue={profileRound(finishPoint! - ProfileConfig.minRange)}
                    unit={'км'}
                    accuracy={ProfileConfig.decimalPoints}
                    disabled={taskStatus !== 'Рассчитан'}
                  />
                  <MinusIcon className={'height-profile-panel__minus-icn'} />
                  <NumberInput
                    value={finishPoint}
                    setValue={setFinishPointToZoom}
                    classNames={'height-profile-panel__control-row-input'}
                    minValue={profileRound(startPoint! + ProfileConfig.minRange)}
                    maxValue={maxPoint}
                    unit={'км'}
                    accuracy={ProfileConfig.decimalPoints}
                    disabled={taskStatus !== 'Рассчитан'}
                  />
                </div>
                <KebabButton mode={'light-btn'} disabled={taskStatus !== 'Рассчитан'}>
                  <Button mode={'drop-down'} onClick={resetRange}>
                    Сбросить
                  </Button>
                  <Button mode={'drop-down'} onClick={recalculate}>
                    Пересчитать&nbsp;профиль
                  </Button>
                </KebabButton>
              </div>
            </div>
          </div>
          <CloseBtn close={closePanel} btnClassNames={'height-profile-panel__close-btn'} />
        </div>
        {taskStatus === 'Рассчитан' ? (
          <>
            <ProfileChart
              requestRange={requestRange}
              setRequestRange={setRequestRange}
              startPoint={startPoint}
              setStartPoint={setStartPoint}
              finishPoint={finishPoint}
              setFinishPoint={setFinishPoint}
              minPoint={minPoint}
              setMinPoint={setMinPoint}
              maxPoint={maxPoint}
              setMaxPoint={setMaxPoint}
              panPlus={panPlus}
              panMinus={panMinus}
              zoomPlus={zoomPlus}
              zoomMinus={zoomMinus}
              startPointToZoom={startPointToZoom}
              finishPointToZoom={finishPointToZoom}
              panelRef={panelRef}
              chartTypeOption={chart1TypeOption}
              chartTypeOptions={chartTypeOptions /*.filter((option) => option.value !== chart2TypeOption.value)*/}
              setChartTypeOption={changeChart1Type}
              setChartRenderingInProgress={setChartRenderingInProgress}
            />
            <ProfileChart
              requestRange={requestRange}
              setRequestRange={setRequestRange}
              startPoint={startPoint}
              setStartPoint={setStartPoint}
              finishPoint={finishPoint}
              setFinishPoint={setFinishPoint}
              minPoint={minPoint}
              setMinPoint={setMinPoint}
              maxPoint={maxPoint}
              setMaxPoint={setMaxPoint}
              panPlus={panPlus}
              panMinus={panMinus}
              zoomPlus={zoomPlus}
              zoomMinus={zoomMinus}
              startPointToZoom={startPointToZoom}
              finishPointToZoom={finishPointToZoom}
              panelRef={panelRef}
              chartTypeOption={chart2TypeOption}
              chartTypeOptions={[...noChartOption, ...chartTypeOptions].filter(
                (option) => option.value !== chart1TypeOption.value,
              )}
              setChartTypeOption={changeChart2Type}
              setChartRenderingInProgress={setChartRenderingInProgress}
            />
          </>
        ) : (
          <>
            <div className={'height-profile-panel__info-text color-primary-text'}>
              Профиль трубопровода устарел или еще не был рассчитан
            </div>
            <div className={'height-profile-panel__calc-container'}>
              <Dropdown
                label={'Способ прокладки (По умолчанию)'}
                displayedField={''}
                options={layingTypeOptions}
                panelRef={panelRef}
                selectedValue={layingTypeOption}
                setSelectedValue={setLayingTypeOption}
              />
              <Button onClick={calculate}>Рассчитать</Button>
            </div>
          </>
        )}
      </div>
    </Resizable>
  )
}

export default ProfilePanel
