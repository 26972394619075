import * as React from 'react'

function UnlockedIcon(props) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.33301 8.66671C3.33301 8.31309 3.47348 7.97395 3.72353 7.7239C3.97358 7.47385 4.31272 7.33337 4.66634 7.33337H11.333C11.6866 7.33337 12.0258 7.47385 12.2758 7.7239C12.5259 7.97395 12.6663 8.31309 12.6663 8.66671V12.6667C12.6663 13.0203 12.5259 13.3595 12.2758 13.6095C12.0258 13.8596 11.6866 14 11.333 14H4.66634C4.31272 14 3.97358 13.8596 3.72353 13.6095C3.47348 13.3595 3.33301 13.0203 3.33301 12.6667V8.66671Z'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.33301 10.6667C7.33301 10.8435 7.40325 11.013 7.52827 11.1381C7.65329 11.2631 7.82286 11.3333 7.99967 11.3333C8.17649 11.3333 8.34605 11.2631 8.47108 11.1381C8.5961 11.013 8.66634 10.8435 8.66634 10.6667C8.66634 10.4899 8.5961 10.3203 8.47108 10.1953C8.34605 10.0702 8.17649 10 7.99967 10C7.82286 10 7.65329 10.0702 7.52827 10.1953C7.40325 10.3203 7.33301 10.4899 7.33301 10.6667Z'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.33301 7.33337V4.00004C5.33301 3.2928 5.61396 2.61452 6.11406 2.11442C6.61415 1.61433 7.29243 1.33337 7.99967 1.33337C8.70692 1.33337 9.3852 1.61433 9.88529 2.11442C10.3854 2.61452 10.6663 3.2928 10.6663 4.00004'
        stroke='#373737'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UnlockedIcon
