import { ITableColumn } from '../../Table/types'

export const columnsPart1: ITableColumn[] = [
  {
    title: 'Тип ГПА',
    type: 'Text',
    editable: false,
    key: 'type',
  },
  {
    title: 'Номин. мощность, кВт',
    type: 'Number',
    editable: false,
    key: 'power__kW',
  },
  {
    title: 'Мин. привед. объемный расход, м3/мин',
    type: 'Number',
    editable: false,
    key: 'min_volume_flow_rate__m3_per_min',
  },
  {
    title: 'Макс. привед. объемный расход, м3/мин',
    type: 'Number',
    editable: false,
    key: 'max_volume_flow_rate__m3_per_min',
  },
  {
    title: 'Приведенная температура, К',
    type: 'Number',
    editable: false,
    key: 'temperature__K',
  },
  {
    title: 'Газовая постоянная, кгс.м/кг*К',
    type: 'Number',
    key: 'R__kgs_m_per_kg_K',
    editable: false,
  },
  {
    title: 'Коэф. сжимаемости',
    type: 'Number',
    key: 'compressibility_factor',
    editable: false,
  },
  {
    title: 'Макс. степень сжатия при одноступ. сжатии',
    type: 'Number',
    key: 'max_single_stage_compression_ratio',
    editable: false,
  },
  {
    title: 'Макс. степень сжатия при многоступ. сжатии',
    type: 'Number',
    key: 'max_multi_stage_compression_ratio',
    editable: false,
  },
  {
    title: 'Коэф. техн. состояния ГТУ по топливу',
    type: 'Number',
    key: 'fuel_technical_condition_factor',
    editable: false,
  },
  {
    title: 'Температура окружающей среды, К',
    type: 'Number',
    key: 'temperature_environment__K',
    editable: false,
  },
  {
    title: 'КПД электродвигателя',
    type: 'Number',
    key: 'electric_engine_efficiency',
    editable: false,
  },
  {
    title: 'КПД мультипликатора',
    type: 'Number',
    key: 'multiplier_efficiency',
    editable: false,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К1)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_1',
    editable: false,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К2)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_2',
    editable: false,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К3)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_3',
    editable: false,
  },
]

export const columnsPart2: ITableColumn[] = [
  {
    title: 'Политроп. КПД (К1)',
    type: 'Number',
    key: 'polytropic_efficiency_1',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К2)',
    type: 'Number',
    key: 'polytropic_efficiency_2',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К3)',
    type: 'Number',
    key: 'polytropic_efficiency_3',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К4)',
    type: 'Number',
    key: 'polytropic_efficiency_4',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К5)',
    type: 'Number',
    key: 'polytropic_efficiency_5',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К1)',
    type: 'Number',
    key: 'inner_power_1',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К2)',
    type: 'Number',
    key: 'inner_power_2',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К3)',
    type: 'Number',
    key: 'inner_power_3',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К4)',
    type: 'Number',
    key: 'inner_power_4',
    editable: true,
  },
  {
    title: 'Номинальный КПД ГТУ',
    type: 'Number',
    key: 'nominal_efficiency',
    editable: true,
  },
  {
    title: 'Коэф. техн. состояния ГТУ',
    type: 'Number',
    key: 'technical_condition_coefficient',
    editable: true,
  },
  {
    title: 'Коэф. расч. влияния температуры атм. воздуха',
    type: 'Number',
    key: 'air_temperature_influence_coefficient',
    editable: true,
  },
  {
    title: 'Коэф., утилизатора тепла',
    type: 'Number',
    key: 'heat_utilization_coefficient',
    editable: true,
  },
  {
    title: 'Коэф. влияния скор. вращ. ротора силовой турбины',
    type: 'Number',
    key: 'turbine_speed_influence_coefficient',
    editable: true,
  },
  {
    title: 'Механический КПД нагнетателя',
    type: 'Number',
    key: 'blower_mechanical_efficiency',
    editable: true,
  },
]

export const strippedDownTableColumnsPart1: ITableColumn[] = [
  {
    title: 'Тип ГПА',
    type: 'Text',
    editable: false,
    key: 'type',
  },
  {
    title: 'Номин. мощность, кВт',
    type: 'Number',
    editable: false,
    key: 'power__kW',
  },
  {
    title: 'Мин. привед. объемный расход, м3/мин',
    type: 'Number',
    editable: false,
    key: 'min_volume_flow_rate__m3_per_min',
  },
  {
    title: 'Макс. привед. объемный расход, м3/мин',
    type: 'Number',
    editable: false,
    key: 'max_volume_flow_rate__m3_per_min',
  },
  {
    title: 'Приведенная температура, К',
    type: 'Number',
    editable: false,
    key: 'temperature__K',
  },
  {
    title: 'Газовая постоянная, кгс.м/кг*К',
    type: 'Number',
    key: 'R__kgs_m_per_kg_K',
    editable: false,
  },
  {
    title: 'Коэф. сжимаемости',
    type: 'Number',
    key: 'compressibility_factor',
    editable: false,
  },
  {
    title: 'Макс. степень сжатия при одноступ. сжатии',
    type: 'Number',
    key: 'max_single_stage_compression_ratio',
    editable: false,
  },
  {
    title: 'Макс. степень сжатия при многоступ. сжатии',
    type: 'Number',
    key: 'max_multi_stage_compression_ratio',
    editable: false,
  },
]
export const strippedDownTableColumnsPart2: ITableColumn[] = [
  {
    title: 'Политроп. КПД (К1)',
    type: 'Number',
    key: 'polytropic_efficiency_1',
    editable: true,
  },
  {
    title: 'Номинальный КПД ГТУ',
    type: 'Number',
    key: 'nominal_efficiency',
    editable: true,
  },
  {
    title: 'Коэф. техн. состояния ГТУ',
    type: 'Number',
    key: 'technical_condition_coefficient',
    editable: true,
  },
  {
    title: 'Механический КПД нагнетателя',
    type: 'Number',
    key: 'blower_mechanical_efficiency',
    editable: true,
  },
]
