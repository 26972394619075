import React from 'react'
import BarIcon from 'images/BarIcon'
import './projectCard.scss'
import Button from 'components/Button'
import { KebabButton } from 'components/KebabButton/KebabButton'
import DeleteIcon from 'images/DeleteIcon'
import CopyIcon from 'images/CopyIcon'
import { definitions } from 'generated/apiTypes'

interface IProjectCard extends React.HTMLAttributes<HTMLElement> {
  id: string
  title: string
  owner: string
  date: string
  notVisited: definitions['ProjectNotVisitedTaskStates']
  deleteProject(projectID: string, projectName: string): void
  copyProject(projectID: string): void
}
export const ProjectCard: React.FC<IProjectCard> = ({
  id,
  title,
  owner,
  date,
  deleteProject,
  copyProject,
  notVisited,
  ...props
}) => {
  const onDelete = (e: any) => {
    e.stopPropagation()
    deleteProject(id, title)
  }
  const onCopy = (e: any) => {
    e.stopPropagation()
    copyProject(id)
  }

  const parsedDate = React.useMemo(() => {
    return new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).replace(' г.', '')
  }, [date])

  return (
    <div className={'project-card'} {...props}>
      <div className={'project-card__top'}>
        <div className={'project-card__flex-start'}>
          <BarIcon />
          {Object.entries(notVisited).some(([_key, value]) => value === 'Рассчитан') && (
            <div className={'project-card__indicator-icon'} />
          )}
          {Object.entries(notVisited).some(([_key, value]) => value === 'Ошибка') && (
            <div className={'project-card__indicator-icon error'} />
          )}
          {Object.entries(notVisited).some(([_key, value]) => value === 'Рассчитано частично') && (
            <div className={'project-card__indicator-icon warning'} />
          )}
          <div className={'project-title button-text'}>{title}</div>
        </div>
        <div className={'project-card__flex-end'}>
          <div className={'project-owner'}>{owner}</div>
          <div className={'project-card__left'}>
            <div className={'project-date'}>{parsedDate}</div>
            <KebabButton>
              <Button mode={'drop-down'} onClick={onCopy}>
                {' '}
                <CopyIcon /> Копировать&nbsp;проект{' '}
              </Button>
              <Button mode={'drop-down'} onClick={onDelete}>
                {' '}
                <DeleteIcon /> Удалить&nbsp;проект{' '}
              </Button>
            </KebabButton>
          </div>
        </div>
      </div>
      <div className={'project-card__bottom'}>
        <div className={'project-id'}>{id}</div>
      </div>
    </div>
  )
}
