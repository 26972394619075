import React from 'react'
import CalcStatus from 'components/CalcStatus'
import { useSelector } from 'react-redux'
import { selectTaskStatus } from 'store/autoCSSlice'
import { selectErrorMessage } from 'store/autoCSSlice'
import useGetResults from './hooks/useGetResults'
import Button from 'components/Button'
import { ToolTabKey } from '../../../types'
import { MessageBox } from 'components/MessageBox/messageBox'
import useCheckTaskStatus from './hooks/useCheckTaskStatus'

interface IResultsProps {
  setToolTab(tab: ToolTabKey): void
}

const Results: React.FC<IResultsProps> = ({ setToolTab }) => {
  const taskStatus = useSelector(selectTaskStatus)
  const errorMessage = useSelector(selectErrorMessage)

  useCheckTaskStatus()
  useGetResults()

  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <div className={'hydraulic-calc__status-wrapper'}>
          <span className={'hydraulic-calc__title optimization-results__title header2'}>
            Автоматическая расстановка компрессорных станций
          </span>
          <CalcStatus classNames={'optimization-results__status'} status={taskStatus} />
        </div>
      </div>
      {taskStatus === 'Ошибка' && errorMessage && (
        <div className={'optimization-info__error-message'}>{errorMessage}</div>
      )}
      {taskStatus === 'Рассчитано частично' && errorMessage && <MessageBox message={errorMessage as string} />}
      {['Рассчитан', 'Рассчитано частично'].includes(taskStatus!) && (
        <Button onClick={() => setToolTab('HYDRAULIC_CALC')}>Открыть гидравлический расчет</Button>
      )}
    </>
  )
}

export default Results
