import { useDispatch, useSelector } from 'react-redux'
import {
  resetMapSubmode,
  resetNodeCreation,
  selectMapMode,
  selectNodeCreation,
  setMapCenter,
  setMapNodes,
  setMapPipelines,
  setToMapCenter,
} from 'store/mapSlice'
import { useEffect } from 'react'
import {
  copyCompressorStationRequest,
  createCompressorStationRequest,
  createHeatingStationsRequest,
  createReductionNodeRequest,
  createReferenceNodeRequest,
  getNodesByProjectIDRequest,
  getPipelineByIDRequest,
  getPipelinesByProjectIDRequest,
  getProjectByIDRequest,
} from 'services/apiRequests'
import { point } from '@turf/turf'
import { definitions } from 'generated/apiTypes'
import {
  selectProjectID,
  setPipelines,
  setProjectDetail,
  setShowElementInfo,
  updateCompressorStation,
  updateHeatingStation,
  updateReductionNode,
  updateReferenceNode,
} from 'store/projectSlice'
import axios, { AxiosResponse } from 'axios'

export const useCreateNode = () => {
  const nodeCreation = useSelector(selectNodeCreation)
  const projectID = useSelector(selectProjectID) as string
  const mapMode = useSelector(selectMapMode)
  const dispatch = useDispatch()

  const toCenter = () => {
    if (mapMode === 'view') {
      dispatch(setMapCenter(nodeCreation.coordinates))
      dispatch(setToMapCenter(true))
    }
  }
  const fetchProject = async (project_id: string) => {
    await getProjectByIDRequest(project_id).then((res: any) => {
      dispatch(setProjectDetail(res.data))
    })
    await getPipelinesByProjectIDRequest(project_id).then((res: any) => {
      dispatch(setMapPipelines(res.data))
      // @ts-ignore
      const requests: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] = res.data.map((pipeline) =>
        getPipelineByIDRequest(projectID, pipeline.id),
      )
      axios.all(requests).then((resArr) => {
        dispatch(setPipelines(resArr.map((res) => res.data)))
      })
    })
    await getNodesByProjectIDRequest(project_id).then((res: any) => {
      dispatch(setMapNodes(res.data))
    })
  }
  useEffect(() => {
    if (nodeCreation.create && !nodeCreation.copyID) {
      switch (nodeCreation.editObject) {
        case 'REFERENCE_NODES':
          createReferenceNodeRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(updateReferenceNode(res.data))
            fetchProject(projectID).then(() => {
              dispatch(
                setShowElementInfo({
                  isVisible: mapMode === 'view',
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
            })
          })
          break
        case 'COMPRESSOR_STATIONS':
          createCompressorStationRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(updateCompressorStation(res.data))
            fetchProject(projectID).then(() => {
              dispatch(
                setShowElementInfo({
                  isVisible: mapMode === 'view',
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
            })
          })
          break
        case 'HEATING_STATIONS':
          createHeatingStationsRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(updateHeatingStation(res.data))
            fetchProject(projectID).then(() => {
              dispatch(
                setShowElementInfo({
                  isVisible: mapMode === 'view',
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
            })
          })
          break
        case 'NODES_REDUCTIONS':
          createReductionNodeRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(updateReductionNode(res.data))
            fetchProject(projectID).then(() => {
              dispatch(
                setShowElementInfo({
                  isVisible: mapMode === 'view',
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
            })
          })
          break
      }
    } else if (nodeCreation.create && nodeCreation.copyID) {
      switch (nodeCreation.editObject) {
        case 'COMPRESSOR_STATIONS':
          copyCompressorStationRequest(projectID, nodeCreation.copyID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(updateCompressorStation(res.data))
            fetchProject(projectID).then(() => {
              dispatch(
                setShowElementInfo({
                  isVisible: mapMode === 'view',
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                  objectName: res.data.name,
                }),
              )
              toCenter()
            })
          })
          break
      }
    }
    if (nodeCreation.create) {
      dispatch(resetMapSubmode())
      dispatch(resetNodeCreation())
    }
  }, [nodeCreation.create])
}
