import React from 'react'

import { getConstructionConditionsHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItem, setId, setVersionId } from 'store/directorySlice'

const useGetConstructionCondition = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetConstructionCondition = async () => {
    await getConstructionConditionsHandbook().then((res) => {
      const { id, ...data } = res.data
      dispatch(setId(id))
      dispatch(setVersionId(id))
      dispatch(
        setDirectoryItem({
          ...data,
          construction_conditions: data.construction_conditions.map((item) => {
            return {
              ...item,
              is_modified: false,
            }
          }),
        }),
      )
    })
  }

  React.useEffect(() => {
    tryToGetConstructionCondition().then()
  }, [requestData])

  return
}

export default useGetConstructionCondition
