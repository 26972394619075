import {
  getCoords,
  Position,
  rhumbBearing,
  point,
  distance,
  Geometries,
  transformRotate,
  lineString,
  booleanEqual,
  length,
  radians2degrees,
  Coord,
  segmentEach,
  booleanPointOnLine,
} from '@turf/turf'
import { GeoJSONSource } from 'maplibre-gl'
import { definitions } from 'generated/apiTypes'
import { AnyLayer } from './types'
import { Feature, FeatureCollection, LineString, Point } from 'geojson'
import { v4 as uuid4 } from 'uuid'
import { MapElementType } from 'store/types'
import { convertToGreatCircle } from './utils/convertToGreatCircle'
import { CategoryConfig, LayerTypeConfig, LayingMethodConfig } from './PipelineProfile/ProfilePanel/components/config'

export const PointsMinZoom = 10
export const layerProps = {
  // 'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.1, 18, 1],
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'icon-rotate': ['get', 'rotation'],
}
export const lineLayerProps = {
  'line-color': [
    'match',
    ['get', 'category'],
    'rivers',
    LayerTypeConfig['rivers'].color,
    'terrain',
    LayerTypeConfig['terrain'].color,
    'road',
    LayerTypeConfig['road'].color,
    'railway',
    LayerTypeConfig['railway'].color,
    'waterway',
    LayerTypeConfig['waterway'].color,
    'protectedAreas',
    LayerTypeConfig['protectedAreas'].color,
    'ground',
    [
      'match',
      ['get', 'subCategoryDisplay'],
      'odd',
      LayingMethodConfig['ground'].color1,
      'even',
      LayingMethodConfig['ground'].color2,
      LayingMethodConfig['ground'].color,
    ],
    'aboveGround',
    [
      'match',
      ['get', 'subCategoryDisplay'],
      'odd',
      LayingMethodConfig['aboveGround'].color1,
      'even',
      LayingMethodConfig['aboveGround'].color2,
      LayingMethodConfig['aboveGround'].color,
    ],
    'underGround',
    [
      'match',
      ['get', 'subCategoryDisplay'],
      'odd',
      LayingMethodConfig['underGround'].color1,
      'even',
      LayingMethodConfig['underGround'].color2,
      LayingMethodConfig['underGround'].color,
    ],
    'I',
    CategoryConfig['I'].color,
    'II',
    CategoryConfig['II'].color,
    'III',
    CategoryConfig['III'].color,
    'IV',
    CategoryConfig['IV'].color,
    'B',
    CategoryConfig['B'].color,
    '#0074BC',
  ],
  'line-width': 5,
}
export const lineBaseLayerProps = {
  'line-color': 'transparent',
  'line-width': 30,
}
export const lineHighlightLayerProps = {
  'line-color': '#ffffff',
  'line-width': 17,
}
export const lineLayoutProps = {
  'line-join': 'round',
  'line-cap': 'round',
}
export const lineHoverLayerProps = {
  'line-color': 'rgba(0, 116, 188, 0.3)',
  'line-width': 17,
}
export const lineTransparentLayerProps = {
  'line-color': 'transparent',
  'line-width': 17,
}
export const lineSelectedLayerProps = {
  'line-color': 'rgba(0, 116, 188, 0.5)',
  'line-width': 17,
}
export const lineReferenceLineLayerProps = {
  'line-width': 2,
  'line-color': '#FF0000',
}
export const lineReferenceLineHoverLayerProps = {
  'line-width': 17,
  'line-color': 'rgba(255, 0, 0, 0.3)',
}
export const lineReferenceLineSelectedLayerProps = {
  'line-width': 17,
  'line-color': 'rgba(255, 0, 0, 0.4)',
}
export const circleReferenceLineLayerProps = {
  'circle-radius': 5,
  'circle-color': '#FF0000',
}
export const circleReferenceLineHoverLayerProps = {
  'circle-radius': 5,
  'circle-color': '#FF0000',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#FF0000',
  'circle-stroke-opacity': 0.3,
}
export const circleReferenceLineSelectedLayerProps = {
  'circle-radius': 5,
  'circle-color': '#FF0000',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#FF0000',
  'circle-stroke-opacity': 0.4,
}
export const circleTransparentLayerProps = {
  'circle-radius': 5,
  'circle-color': 'transparent',
}
export const circleHoverLayerProps = {
  'circle-radius': 5,
  'circle-color': '#0074BC',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#0074BC',
  'circle-stroke-opacity': 0.5,
}
export const circleWarnLayerProps = {
  'circle-radius': 5,
  'circle-color': '#0074BC',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#FF3030',
  'circle-stroke-opacity': 0.3,
}
export const selectAreaLayerProps = {
  'fill-color': 'rgba(255, 51, 198, 0.12)',
  'fill-outline-color': '#FF33C6',
}
const normalizeVector = (vector: Position[]) => {
  const deltaX = vector[1][0] - vector[0][0]
  const deltaY = vector[1][1] - vector[0][1]
  const invLength = 1 / Math.sqrt(deltaX ** 2 + deltaY ** 2)
  return [deltaX * invLength, deltaY * invLength]
}
const calcCrossProduct = (vectorA: [number, number], vectorB: [number, number]) => {
  return vectorA[0] * vectorB[1] - vectorA[1] * vectorB[0]
}
const calcDotProduct = (vectorA: [number, number], vectorB: [number, number]) => {
  return vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1]
}

const isPointOnLine = (line: Position[], point: Position, epsilon: number) => {
  const vectorA = [line[0], point]
  const vectorB = [point, line[1]]
  const normalA = normalizeVector(vectorA as [number, number][])
  const normalB = normalizeVector(vectorB as [number, number][])
  return 1 - calcDotProduct(normalA as [number, number], normalB as [number, number]) <= epsilon
}
const isPointBetween = (point: Position, line: Position[]) => {
  return (
    ((point[0] > line[0][0] && point[0] < line[1][0]) || (point[0] < line[0][0] && point[0] > line[1][0])) &&
    ((point[1] > line[0][1] && point[1] < line[1][1]) || (point[1] < line[0][1] && point[1] > line[1][1]))
  )
}
export const calcAngleBetweenVectors = (vectorA: Position[], vectorB: Position[]) => {
  const ab = normalizeVector(vectorA) as [number, number]
  const cb = normalizeVector(vectorB) as [number, number]
  return Math.atan(calcCrossProduct(cb, ab) / calcDotProduct(cb, ab))
}
export const calcLength = (line: Position[]) => {
  return Math.sqrt((line[0][0] - line[1][0]) ** 2 + (line[0][1] - line[1][1]) ** 2)
}
export const calcLineAngle = (line: Position[]) => {
  const dx = line[1][0] - line[0][0]
  const dy = line[1][1] - line[0][1]
  return Math.PI / 2 - Math.atan2(dx, dy)
}
export const findProjectionOnLine = (line: Position[], pt: Position) => {
  const angle = -rhumbBearing(point(line[0]), point(pt)) + rhumbBearing(line[0], line[line.length - 1])
  return getCoords(
    transformRotate(lineString([line[0], pt]), angle, {
      pivot: line[0],
    }),
  ).at(-1)
}

export const pointOnLine = (
  line: Position[],
  pt: Position,
  calcRotation = false,
): { point: [number, number] | null; rotation: number } | [number, number] | null => {
  let point = null
  let distance = 30
  let rotation = 0
  line.reduce((prev, cur) => {
    const segment = [prev, cur]
    const lineAngle = Math.PI - calcLineAngle(segment)
    const lineLength = calcLength([segment[0], pt])
    const angle = calcAngleBetweenVectors(segment, [segment[0], pt])
    const dx = lineLength * Math.sin(angle) * Math.sin(lineAngle)
    const dy = lineLength * Math.sin(angle) * Math.cos(lineAngle)
    const _pt = [pt[0] - dx, pt[1] - dy]
    if (calcLength([pt, _pt]) < distance && isPointBetween(_pt, segment)) {
      distance = calcLength([pt, _pt])
      rotation = radians2degrees(calcLineAngle(segment))
      point = _pt
    }
    return cur
  })
  return calcRotation ? { point, rotation } : point
}
export const calcRotation = (isStart = true, coordinates: any) => {
  return isStart ? rhumbBearing(coordinates[0], coordinates[1]) : rhumbBearing(coordinates[1], coordinates[0])
}
export const getRotation = (node_id: string, edgesList: Feature[]) => {
  const edge = edgesList.find(
    (edge) => edge.properties!.start_node === node_id || edge.properties!.end_node === node_id,
  )
  if (edge) {
    const isStart = edge.properties!.start_node === node_id
    return calcRotation(
      isStart,
      isStart
        ? getCoords(edge.geometry as any)[0].slice(0, 2)
        : getCoords(edge.geometry as any)
            .slice(-1)[0]
            .slice(-2),
    )
  }
  return 0
}

export const getFeatureRotation = (node: any, edgesList: any[]) => {
  const edge = edgesList.find((edge) => edge.properties.start_node === node.properties.id)
  if (edge) {
    return rhumbBearing(...(getCoords(edge.geometry) as [Coord, Coord])) - 90
  }
  return 0
}
export const addPointToMultiLineString = (multiLine: Position[][], point: Position) => {
  let index = -1
  let lIndex = -1
  let distance = Infinity
  multiLine.forEach((line, lineIndex) =>
    line.reduce((prev, cur, curIndex) => {
      const segment = [prev, cur]
      const lineAngle = Math.PI - calcLineAngle(segment)
      const lineLength = calcLength([segment[0], point])
      const angle = calcAngleBetweenVectors(segment, [segment[0], point])
      const dx = lineLength * Math.sin(angle) * Math.sin(lineAngle)
      const dy = lineLength * Math.sin(angle) * Math.cos(lineAngle)
      const _pt = [point[0] - dx, point[1] - dy]
      if (isPointOnLine(segment, _pt, 10 ** -5) && calcLength([point, _pt]) < distance) {
        distance = calcLength([point, _pt])
        index = curIndex
        lIndex = lineIndex
      }
      return cur
    }),
  )
  if (index > -1) {
    return [
      ...multiLine.slice(0, lIndex),
      [...multiLine[lIndex].slice(0, index), point],
      [point, ...multiLine[lIndex].slice(index)],
      ...multiLine.slice(lIndex + 1),
    ]
  }
  return multiLine
}

export const splitLineByPoint = (line: Position[], pt: Point): Position[][] => {
  let splitIndex = undefined
  let index = 0
  segmentEach(lineString(line), (segment) => {
    index++
    if (booleanPointOnLine(pt, convertToGreatCircle(getCoords(segment as any)), { epsilon: 5e-3 })) {
      splitIndex = index
    }
  })
  return [
    [...line.slice(0, splitIndex), getCoords(pt)],
    [getCoords(pt), ...line.slice(splitIndex)],
  ]
}
export const booleanPointOnSegment = (segment: Feature<LineString, any>, pt: Position, epsilon: number) => {
  return (
    length(lineString([getCoords(segment)[0], pt])) +
      length(lineString([pt, getCoords(segment).slice(-1)[0]])) -
      length(segment) <=
    epsilon
  )
}
export const findPoints = (segment: Feature<LineString, any>, pointList: Position[]) => {
  const points: Position[] = []
  for (const ptIndex in pointList) {
    const currentCoord = pointList[ptIndex]
    if (booleanPointOnSegment(segment, currentCoord, 10 ** -10)) {
      !points.find((i) => booleanEqual(point(currentCoord), point(i))) && points.push(currentCoord)
    }
  }
  return points
}
export const getSourceData = (source: GeoJSONSource) => {
  return source._data as FeatureCollection
}
export const getSourceType = (layer: AnyLayer) => {
  return layer?.source.replace('__source', '') as MapElementType
}
export const getIconName = (type: string) => {
  switch (type) {
    case 'source':
      return 'source'
    case 'sink':
      return 'sink'
    case 'reference_nodes':
      return 'junction'
    case 'compressor_stations':
      return 'cs'
    case 'nodes_reductions':
      return 'reduction'
    case 'heating_stations':
      return 'heating-station'
  }
}
export const getIconSize = (type: string) => {
  switch (type?.toLowerCase()) {
    case 'source':
    case 'sink':
      return 48
    case 'reference_nodes':
      return 32
    case 'compressor_stations':
      return 39
    case 'nodes_reductions':
      return 39
    case 'heating_stations':
      return 39
    case 'points':
      return 20
    default:
      return 0
  }
  return 0
}

export const generateUID = () => {
  return uuid4()
}

export const getUniqueFeatures = (features: any[], comparatorProperty: string) => {
  const uniqueIds = new Set()
  const uniqueFeatures = []
  for (const feature of features) {
    const id = feature.properties[comparatorProperty]
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      uniqueFeatures.push(feature)
    }
  }
  return uniqueFeatures
}

export const getUniqueItems = (items: any[], comparatorProperty: string) => {
  const uniqueIds = new Set()
  const uniqueItems = []
  for (const item of items) {
    const id = item[comparatorProperty]
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      uniqueItems.push(item)
    }
  }
  return uniqueItems
}

export const pushOrReplace = (array: any[], item: any) => {
  const index = array.findIndex((i) => i.properties.id === item?.properties.id)
  if (index > -1) {
    array[index].gepmetry = item.geometry
    if (array[index].group) {
      array.splice(index, 1)
      array.push({ ...item, group: true })
    } else array[index] = item
  } else array.push(item)
}

export const getCurrentGeometry = (id: string, source: GeoJSONSource) => {
  return getSourceData(source).features.find((feat: Feature) => feat.properties && feat.properties.id === id)
    ?.geometry as Geometries
}

export const removeItem = (arr: any[], index: number) => {
  return index > -1 ? [...arr.slice(0, index), ...arr.slice(index + 1)] : arr
}

export const concatLines = (lineA: Position[], lineB: Position[]) => {
  if (distance(point(lineA.slice(-1)[0]), point(lineB[0])) <= 10 ** -4) {
    return [...lineA.slice(0, -1), ...lineB]
  } else if (distance(point(lineB.slice(-1)[0]), point(lineA[0])) <= 10 ** -4) {
    return [...lineB.slice(0, -1), ...lineA]
  } else return lineA
}
export const joinLines = (start_node_id: string, pipelines: definitions['Pipeline'][]) => {
  const pipes = []
  let currentNode = start_node_id
  const visited: string[] = []
  while (currentNode) {
    const pipeline = pipelines.find(
      (obj) =>
        (obj.start_node_id === currentNode && !visited.includes(obj.end_node_id as string)) ||
        (obj.end_node_id === currentNode && !visited.includes(obj.start_node_id as string)),
    )

    if (pipeline) {
      pipes.push(pipeline)
      visited.push(currentNode)
      currentNode = (pipeline.start_node_id === currentNode ? pipeline.end_node_id : pipeline.start_node_id) as string
    } else break
  }
  return pipes.reduce((prev: any, cur: any) => {
    if (prev.length) return concatLines(getCoords(cur.line), prev)
    else {
      return getCoords(cur.line)
    }
  }, [])
}

export const arabicToRoman = (number: number) => {
  let roman = ''
  const romanNumList: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XV: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  let a
  if (number < 1 || number > 3999) return ''
  else {
    const keys = Object.keys(romanNumList)
    for (const key of keys) {
      a = Math.floor(number / romanNumList[key])
      if (a >= 0) {
        for (let i = 0; i < a; i++) {
          roman += key
        }
      }
      number = number % romanNumList[key]
    }
  }
  return roman
}

export const removeCrossFeatures = (line: Position[], pt: Position, padding: number) => {
  line
    .slice(1, -1)
    .reverse()
    .forEach((p) => {
      if (distance(pt, p, { units: 'meters' }) <= padding) {
        line = removeItem(
          line,
          line.findIndex((i) => booleanEqual(point(i), point(p))),
        )
      }
    })
  return line
}

export const last = (array: any[]) => {
  return array.slice(-1)[0]
}
