import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDNLayers } from 'services/apiRequests'
import { resetUpdatedElement, selectProjectID, selectUpdatedElement } from 'store/projectSlice'
import { selectTaskStatus, setDNLayers, setTaskSettings } from 'store/DNLayersSlice'
import { definitions } from 'generated/apiTypes'
import { arabicToRoman } from '../utils'
import { IUpdatedElement } from 'store/types'

const useGetDNLayers = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID) as string
  const taskStatus = useSelector(selectTaskStatus)
  const updatedElement = useSelector(selectUpdatedElement) as IUpdatedElement

  const tryToGetDNLayers = async () => {
    await getDNLayers(projectId).then((response) => {
      dispatch(setDNLayers(response.data))
      const taskSettingsLayers: { layers: { id: string; isIncluded: boolean; name: string }[] } = { layers: [] }
      response.data.existing_xyz_tiles.map((item: definitions['XYZTilesExisting']) =>
        taskSettingsLayers.layers.push({
          id: `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`,
          isIncluded: true,
          name: `DN ${item.offset_type.diameter__mm} (${arabicToRoman(parseInt(item.offset_type.class_))})`,
        }),
      )
      response.data.missing_xyz_tiles.map((item: definitions['XYZTilesMissing']) =>
        taskSettingsLayers.layers.push({
          id: `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`,
          isIncluded: true,
          name: `DN ${item.offset_type.diameter__mm} (${arabicToRoman(parseInt(item.offset_type.class_))})`,
        }),
      )
      dispatch(setTaskSettings(taskSettingsLayers))
    })
  }

  React.useEffect(() => {
    if (projectId) {
      tryToGetDNLayers().then()
    }
  }, [projectId, taskStatus])

  React.useEffect(() => {
    if (updatedElement?.type === 'PIPE' && updatedElement?.isUpdated && projectId) {
      dispatch(resetUpdatedElement())
      tryToGetDNLayers().then()
    } else if (updatedElement?.type === 'TRUNK_PIPELINE' && updatedElement?.isUpdated && projectId) {
      dispatch(resetUpdatedElement())
      tryToGetDNLayers().then()
    }
  }, [updatedElement?.isUpdated])

  return
}

export default useGetDNLayers
