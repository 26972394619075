import React, { PropsWithChildren } from 'react'

import './style.scss'
import classnames from 'classnames'

interface IRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  setValue?(value: string): void
  label: string
  id: string
  classNames?: string
}

const RadioButton: React.FC<PropsWithChildren<IRadioButtonProps>> = ({
  value,
  setValue,
  disabled,
  label,
  id,
  classNames,
  children,
  ...inputProps
}) => {
  const onChangeValue = () => {
    if (setValue) {
      setValue(id)
    }
  }

  return (
    <div className={classnames('radio__input-row', classNames, { disabled }, children && 'with-children')}>
      <input
        type='radio'
        aria-checked={false}
        className='radio__input'
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
        id={id}
        checked={id === value}
        {...inputProps}
      />
      {children && <div className={'radio__children-content'}>{children}</div>}
      {label && (
        <label htmlFor={id} className='radio__label'>
          {label}
        </label>
      )}
    </div>
  )
}

export default RadioButton
