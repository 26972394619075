import { matrix } from '../utils'
export const HeatingStationIcon = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  const p = new Path2D()
  const path = new Path2D(
    'M27 13.5C27 20.9558 20.9558 27 13.5 27C6.04416 27 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5Z',
  )
  ctx.fillStyle = 'white'
  p.addPath(path, matrix(x - 13.5, y - 13.5, 1, 1))
  ctx.fill(p)
  const p2 = new Path2D()
  const path2 = new Path2D(
    'M15 7.5C15 7.22386 14.7761 7 14.5 7H12.5C12.2239 7 12 7.22386 12 7.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H12.5C12.2239 10 12 10.2239 12 10.5V11.5C12 11.7761 12.2239 12 12.5 12H14.5C14.7761 12 15 11.7761 15 11.5V7.5ZM11 7V12V13V14.6373C11 14.789 10.9298 14.9311 10.8173 15.0329C10.0083 15.7649 9.5 16.8231 9.5 18C9.5 20.2091 11.2909 22 13.5 22C15.7091 22 17.5 20.2091 17.5 18C17.5 16.8231 16.9917 15.7649 16.1827 15.0329C16.0702 14.9311 16 14.789 16 14.6373V13V12V7V6.5C16 6.22386 15.7761 6 15.5 6H15H12H11.5C11.2239 6 11 6.22386 11 6.5V7Z',
  )
  ctx.fillStyle = '#0074BC'
  p2.addPath(path2, matrix(x - 13.5, y - 13.5, 1, 1))
  ctx.fill(p2, 'evenodd')
  ctx.beginPath()
  ctx.lineWidth = 2.5
  ctx.strokeStyle = '#0074BC'
  ctx.arc(x, y, 12.25, 0, 2 * Math.PI, false)
  ctx.stroke()
}
