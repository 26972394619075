import React, { useEffect, useState } from 'react'
import useGetGPU from 'pages/Directory/components/GPU/hooks/useGetGPU'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import { useDispatch, useSelector } from 'react-redux'
import { resetDirectorySliceState, selectDirectoryItems } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import Button from 'components/Button'
import Tabs, { ITab } from 'components/Tabs/tabs'
import { ViewTypeKey } from './types'
import TableIcon from 'images/TableIcon'
import ListIcon from 'images/ListIcon'
import { GPUChoicesTable } from './gpuChoicesTable'
import { GPUChoicesList } from './gpuChoicesList'
import './gpuChoicesWindow.scss'
import Dropdown from 'components/Dropdown/Dropdown'
import { ISelectParams } from 'store/types'
import { GPUTypes } from '../../../pages/Directory/components/GPU/tableConfig'
interface IGPUChoicesWindow {
  closeWindow(): void
}

type OptionType = { value: number | null }
type Filter1OptionType = { value: string | null }
const viewTypeTabList: ITab<ViewTypeKey>[] = [
  { key: 'TABLE', tabContent: <TableIcon /> },
  { key: 'LIST', tabContent: <ListIcon /> },
]
export const GPUChoicesWindow: React.FC<IGPUChoicesWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookGPU'][]
  const blockingWindow = useSelector(selectBlockingWindow) as { type: 'SELECT'; params: ISelectParams }
  const [activeTab, setActiveTab] = useState('TABLE')
  const [selectedID, setSelectedID] = useState<ISelectParams['objectID']>(blockingWindow.params?.objectID)
  const [filter1, setFilter1] = useState<string | null>()
  const [filter2, setFilter2] = useState<number | null>()
  useGetGPU(false)

  const firstFilteredItems = filter1 ? directoryItems.filter((item) => item.type === filter1) : directoryItems
  const currentItems = filter2 ? firstFilteredItems.filter((item) => item.power__kW === filter2) : firstFilteredItems

  useEffect(() => {
    !selectedID && setSelectedID(directoryItems[0]?.id)
  }, [directoryItems])
  const selectGPU = () => {
    dispatch(
      setBlockingWindow({
        params: {
          ...blockingWindow?.params,
          objectID: selectedID,
          objectName: directoryItems?.find((item) => item.id === selectedID)?.name,
          isConfirmed: true,
        },
      }),
    )
  }
  React.useEffect(() => {
    return () => {
      dispatch(resetDirectorySliceState())
    }
  }, [])
  const getOptionsList = () => {
    return directoryItems
      .reduce(
        (list: OptionType[], item) => {
          if (!list.map((l: OptionType) => l.value).includes(item.power__kW)) {
            list.push({ value: item.power__kW })
          }
          return list
        },
        [{ value: null }],
      )
      .sort((a, b) => a.value! - b.value!)
  }
  if (directoryItems.length > 0)
    return (
      <div className='blocking-window-background'>
        <div className='blocking-window-container gpu-choices-window'>
          <div className={'blocking-window__header'}>
            Выбор ГПА
            <CloseBtn close={closeWindow} />
          </div>
          <div className={'gpu-choices-control'}>
            <Tabs
              tabList={viewTypeTabList}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              containerClassName={'view-type'}
              tabClassName={'view-type-btn'}
            />
            <div className={'gpu-choices-control__filters'}>
              <Dropdown
                options={[
                  { type: null },
                  ...GPUTypes.map((opt) => {
                    return { value: opt }
                  }),
                ]}
                displayedField={'value'}
                setSelectedValue={(value: Filter1OptionType) => setFilter1(value.value)}
                selectedValue={filter1 && ({ value: filter1 } as any)}
                label={'Тип ГПА'}
              />
              <Dropdown
                options={getOptionsList()}
                displayedField={'value'}
                label={'Номин. мощность, кВт'}
                selectedValue={filter2 && ({ value: filter2 } as any)}
                setSelectedValue={(value: OptionType) => setFilter2(value.value)}
              />
            </div>
          </div>
          {activeTab === 'TABLE' && (
            <GPUChoicesTable
              directoryItems={currentItems}
              selectedID={selectedID as string}
              setSelectedID={setSelectedID}
            />
          )}
          {activeTab === 'LIST' && (
            <GPUChoicesList
              directoryItems={currentItems}
              selectedID={selectedID as string}
              setSelectedID={setSelectedID}
            />
          )}
          <div className={'blocking-window__footer gap-16'}>
            <Button mode={'secondary'} onClick={closeWindow}>
              Отменить
            </Button>
            <Button onClick={selectGPU} disabled={!selectedID}>
              Выбрать
            </Button>
          </div>
        </div>
      </div>
    )
  return <></>
}
