import '../style.scss'
import './PipeParamsWindow.scss'
import { selectPipeTypes, setUpdatedElement } from 'store/projectSlice'
import React, { useEffect, useMemo, useState } from 'react'
import { CloseBtn } from '../../CloseBtn/closeBtn'
import Dropdown from '../../Dropdown/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { definitions } from 'generated/apiTypes'
import Button from '../../Button'
import { readOnlyColumns } from 'pages/Directory/components/PipeTypes/tableConfig'
import useGetPipeTypes from 'pages/Directory/components/PipeTypes/hooks/useGetPipeTypes'
import { resetDirectorySliceState, selectDirectoryItems } from 'store/directorySlice'
import { Table } from '../../Table/Table/Table'
import { ITableColumn } from '../../Table/types'
import { selectTaskSettings, setTaskSettings } from 'store/optimizationSlice'
import { selectBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, IEditPipeTypeParams } from 'store/types'
interface IPipeParamsWindow {
  closeWindow(): void
}
export const PipeParamsWindow: React.FC<IPipeParamsWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const pipeTypes = useSelector(selectPipeTypes)
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IEditPipeTypeParams)
  const optimizationTask = useSelector(selectTaskSettings)
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookPipeType'][]
  const [pipeTypeID, setPipeTypeID] = useState<string>('')
  const [filteredPipeTypes, setFilteredPipeTypes] = useState(pipeTypes)
  const [filters, setFilters] = useState<any>({
    diameter__mm: null,
    thickness__mm: null,
    roughness__mm: null,
    pressure__MPa: null,
    steel_name: null,
  })
  useGetPipeTypes(false)
  const getDataSource = (data: definitions['HandbookPipeType'][]) => {
    return data.map((item) => {
      return { selected: pipeTypeID, ...item }
    })
  }
  const dataSource = useMemo(() => getDataSource(filteredPipeTypes as any), [filteredPipeTypes, pipeTypeID])
  const currentColumns = [
    {
      title: '',
      type: 'Select',
      key: 'selected',
      editable: true,
      sticky: true,
    },
    ...readOnlyColumns,
  ]

  React.useEffect(() => {
    if (blockingWindowParams?.currentPipeTypeID) setPipeTypeID(blockingWindowParams.currentPipeTypeID)
  }, [blockingWindowParams?.currentPipeTypeID])

  useEffect(() => {
    let updatedPipeTypes = [...directoryItems]
    for (const filter in filters) {
      if (filters[filter]) {
        updatedPipeTypes = updatedPipeTypes.filter((pipe: any) => pipe[filter] === filters[filter])
      }
    }
    setFilteredPipeTypes(updatedPipeTypes)
  }, [directoryItems, filters])

  React.useEffect(() => {
    return () => {
      dispatch(resetDirectorySliceState())
    }
  }, [])

  const getOptionsList = (field: string) => {
    return [
      ...pipeTypes
        .map((pipe: any) => {
          return pipe[field]
        })
        .reduce((prev, cur) => {
          !prev.map((i: any) => i[field]).includes(cur) && prev.push({ [field]: cur })
          return prev
        }, []),
      { [field]: null },
    ]
  }
  const updateFilters = (value: any) => {
    setFilters({
      ...filters,
      ...value,
    })
  }

  const updatePipeType = (pipeTypeID: string) => {
    switch (blockingWindowParams?.objectType) {
      case 'TRUNK_PIPELINE':
        dispatch(
          setTaskSettings({
            ...optimizationTask,
            pipe_type: pipeTypes.find((value) => value.id === pipeTypeID),
          } as definitions['OptimizationCalculationProperties']),
        )
        break
      case 'PIPE':
        dispatch(
          setUpdatedElement({
            elementID: blockingWindowParams?.objectID as string,
            type: 'PIPE',
            params: {
              pipe_type_id: pipeTypeID,
            },
          }),
        )
    }
    closeWindow()
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container pipe-table'>
        <div className={'blocking-window__header'}>
          Параметры трубы
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'pipe-params__filters'}>
          <Dropdown
            options={getOptionsList('diameter__mm')}
            displayedField={'diameter__mm'}
            setSelectedValue={updateFilters}
            selectedValue={filters.diameter__mm && { diameter__mm: filters.diameter__mm }}
            label={'Внешний диаметр, мм'}
          />
          <Dropdown
            options={getOptionsList('thickness__mm')}
            displayedField={'thickness__mm'}
            setSelectedValue={updateFilters}
            selectedValue={filters.thickness__mm && { thickness__mm: filters.thickness__mm }}
            label={'Толщина стенки, мм'}
          />
          <Dropdown
            options={getOptionsList('roughness__mm')}
            displayedField={'roughness__mm'}
            setSelectedValue={updateFilters}
            selectedValue={filters.roughness__mm && { roughness__mm: filters.roughness__mm }}
            label={'Шероховатость, мм'}
          />
          <Dropdown
            options={getOptionsList('pressure__MPa')}
            displayedField={'pressure__MPa'}
            setSelectedValue={updateFilters}
            selectedValue={filters.pressure__MPa && { pressure__MPa: filters.pressure__MPa }}
            label={'Макс. раб. давление, Мпа'}
          />
          <Dropdown
            options={getOptionsList('steel_name')}
            displayedField={'steel_name'}
            setSelectedValue={updateFilters}
            selectedValue={filters.steel_name && { steel_name: filters.steel_name }}
            label={'Марка стали'}
          />
        </div>
        {filteredPipeTypes.length ? (
          filteredPipeTypes.length > 0 && (
            <Table columns={currentColumns as ITableColumn[]} dataSource={dataSource} setValue={setPipeTypeID} />
          )
        ) : (
          <div className={'empty-table'}>Не найдено доступных опций</div>
        )}
        <div className={'blocking-window__footer gap-16'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={() => updatePipeType(pipeTypeID)}>Выбрать</Button>
        </div>
      </div>
    </div>
  )
}
