import React from 'react'
import { ChartData } from 'chart.js'
import { BarChartConfig, CategoryConfig, LayerTypeConfig, LayingMethodConfig } from '../components/config'
import { profileRound } from '../components/utils'
import { chartType } from '../components/ProfileChart'
import { AreaSection } from './useGetRouteProfileAreaSections'

export type UseGanttChartData = (data: AreaSection[], chartType: chartType) => ChartData<'bar'>

const useGanttChartData: UseGanttChartData = (data, chartType) => {
  const getConfigByChartType = (): { [key: string]: BarChartConfig } | undefined => {
    switch (chartType) {
      case 'LAYER_TYPES':
        return LayerTypeConfig
      case 'CATEGORY_AREA':
        return CategoryConfig
      case 'LAYING_METHOD':
        return LayingMethodConfig
      default:
        return undefined
    }
  }

  return React.useMemo(() => {
    const chartData: { x: [number, number]; y: string; value?: string; valueIx?: number; sectionId?: string }[] = []
    data?.map((item) => {
      return chartData.push({
        x: [profileRound(item.distance_range__km[0]) || 0, profileRound(item.distance_range__km[1]) || 0],
        y: item.value || '',
        value: item.regionValue,
        valueIx: item.regionValueIx !== undefined ? item.regionValueIx : item.valueIx,
        sectionId: item.sectionId,
      })
    })

    return {
      labels: [],
      datasets: [
        {
          label: '',
          barPercentage: 0.96,
          maxBarThickness: 14,
          hoverBackgroundColor: (context: any, label?: string) => {
            if (!context && label !== undefined) {
              return (getConfigByChartType() as any)[label]?.color
            } else if (['admDivision', 'ground', 'aboveGround', 'underGround'].includes(context?.raw?.y)) {
              if (parseInt(context?.raw?.valueIx) % 2 === 0) {
                return (getConfigByChartType() as any)[context?.raw?.y]?.color1
              }
              return (getConfigByChartType() as any)[context?.raw?.y]?.color2
            }
            return (getConfigByChartType() as any)[context?.raw?.y]?.color
          },
          backgroundColor: (context: any, label?: string) => {
            if (!context && label !== undefined) {
              return (getConfigByChartType() as any)[label]?.color
            } else if (['admDivision', 'ground', 'aboveGround', 'underGround'].includes(context?.raw?.y)) {
              if (parseInt(context?.raw?.valueIx) % 2 === 0) {
                return (getConfigByChartType() as any)[context?.raw?.y]?.color1
              }
              return (getConfigByChartType() as any)[context?.raw?.y]?.color2
            }
            return (getConfigByChartType() as any)[context?.raw?.y]?.color
          },
          data: chartData,
        } as any,
      ],
    }
  }, [data, chartType])
}

export default useGanttChartData
