import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, selectTaskSettings, setCreateTask, setTaskId, setTaskStatus } from 'store/DNLayersSlice'
import { createDNLayersTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { resetValidation } from 'store/validationSlice'
import { IErrorParams } from 'store/types'

const useCreateDNLayersTask = () => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const taskSettings = useSelector(selectTaskSettings)

  const tryToCreateTask = async () => {
    if (trunkPipelineId && projectId) {
      dispatch(resetValidation())
      const pipeTypesArr: definitions['XYZTilesRun'] = { offset_types: [] }
      taskSettings?.layers.map((item) => {
        const matches = item.id.match(/\d+/g)
        item.isIncluded &&
          matches &&
          pipeTypesArr.offset_types.push({
            diameter__mm: matches[0] as unknown as definitions['XYZTilesOffsetType']['diameter__mm'],
            class_: matches[1] as unknown as definitions['XYZTilesOffsetType']['class_'],
          })
      })
      await createDNLayersTask(projectId, trunkPipelineId, pipeTypesArr)
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data?.length > 0) {
              dispatch(
                setBlockingWindow({
                  type: 'ERROR',
                  params: {
                    title: 'Ошибка запуска расчета',
                    message: (
                      <div className='error-window__errors-list-container'>
                        <div>При запуске расчета возникли следующие ошибки:</div>
                        <ul className='error-window__errors-list'>
                          {(err.response.data as string[])?.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ),
                  } as IErrorParams,
                }),
              )
            } else if (
              (err.response.data?.offset_types as Record<string, any>)?.non_field_errors.length > 0 &&
              (err.response.data?.offset_types as Record<string, any>)?.non_field_errors[0] ===
                'Ensure this field has at least 1 elements.'
            ) {
              dispatch(
                setBlockingWindow({
                  type: 'ERROR',
                  params: {
                    title: 'Ошибка запуска расчета',
                    message: 'Настройки расчета содержат пустой список параметров',
                  } as IErrorParams,
                }),
              )
            } else {
              let message = ''
              if (
                err.response.data.detail ===
                'Pipelines are too long. Total length of pipelines must be less than 10000km.'
              ) {
                message = 'Общая длина трубопровода не должна превышать 10000 км.'
              } else if (
                err.response.data.detail === 'Pipelines are too long. Length of each pipeline must be less than 3000km.'
              ) {
                message = 'Длина каждого участка трубопровода не должна превышать 3000 км.'
              }
              dispatch(
                setBlockingWindow({
                  params: {
                    title: 'Ошибка запуска расчета',
                    message: message || 'Непредвиденная ошибка',
                  } as IErrorParams,
                  type: 'ERROR',
                }),
              )
            }
          } else
            dispatch(
              setBlockingWindow({
                type: 'ERROR',
                params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              }),
            )
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateDNLayersTask
