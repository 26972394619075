import React from 'react'
import { useSelector } from 'react-redux'
import { selectTaskCalculationProgress } from 'store/model10Slice'

const Progress: React.FC = () => {
  const taskCalculationProgress = useSelector(selectTaskCalculationProgress)
  /*usePrompt({
    when: true,
    message:
      navigator.language === 'ru'
        ? 'Вы действительно хотите покинуть страницу? \nПрогресс расчета будет утерян.'
        : 'Are you sure you want to leave this page? \nCalculation progress will be lost.',
  })

  React.useEffect(() => {
    window.onbeforeunload = () => {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])*/

  return (
    <>
      <h1 className={'project-list__projects-title no-margin'}>Гидравлический расчет</h1>
      <div className='progress__loader-container model10-loader-container'>
        <div className='progress__loader' />
        <div className={'progress__percentage'}>{taskCalculationProgress || 0}%</div>
      </div>
      <div className={'progress__hint normal-text'}>{'Выполняется гидравлический расчет'}</div>
    </>
  )
}

export default Progress
