import { matrix } from '../utils'

export const ReductionNodeIcon = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  const p = new Path2D()
  const path = new Path2D(
    'M11.5202 19.4676L13.0703 17.1913L8.16667 0H19.8333L14.9297 17.1913L16.4798 19.4676L28 14.9012V26L17.3056 21.7609H10.6944L0 26V14.9012L11.5202 19.4676Z',
  )
  ctx.fillStyle = 'white'
  p.addPath(path, matrix(x - 10, y - 19, 1, 1))
  ctx.fill(p, 'evenodd')
  const p2 = new Path2D()
  const path2 = new Path2D(
    'M2.3 15.8129L10.8792 19.2135L11.3034 19.3817L11.5202 19.4676L13.0703 17.1913L12.8041 16.2581L8.82272 2.3L8.16667 0H10.5584H17.4416H19.8333L19.1773 2.3L15.1959 16.2581L14.9297 17.1913L16.4798 19.4676L16.6966 19.3817L17.1208 19.2135L25.7 15.8129L28 14.9012V17.3753V23.5259V26L25.7 25.0883L17.3056 21.7609H10.6944L2.3 25.0883L0 26V23.5259V17.3753V14.9012L2.3 15.8129ZM16.7855 2.3L14 12.0656L11.2145 2.3H16.7855ZM25.7 22.6142L20.2416 20.4506L25.7 18.287V22.6142ZM2.3 18.287L7.75836 20.4506L2.3 22.6142V18.287Z',
  )
  ctx.fillStyle = '#0074BC'
  p2.addPath(path2, matrix(x - 10, y - 19, 1, 1))
  ctx.fill(p2, 'evenodd')
}
