import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { removeSelectedItems } from 'store/directorySlice'
import { IBlockingWindow, IDeleteConfirmParams } from '../../../store/types'

interface IDeleteItemsProps {
  onDeleteItems(): void
}

const useDeleteItems = ({ onDeleteItems }: IDeleteItemsProps) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IDeleteConfirmParams)

  useEffect(() => {
    if (
      blockingWindowParams?.objectType === 'HANDBOOK_ITEMS' &&
      blockingWindow.type === 'DELETE_CONFIRM' &&
      blockingWindowParams.isConfirmed
    ) {
      dispatch(removeSelectedItems())
      dispatch(resetBlockingWindow())
      onDeleteItems()
    }
  }, [blockingWindowParams?.isConfirmed])

  return
}

export default useDeleteItems
