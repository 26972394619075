import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getRouteProfileHeights } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { IErrorParams } from 'store/types'
import { setProfileHeights } from 'store/profileSlice'
import { chartType } from '../components/ProfileChart'

const useGetRouteProfileHeights = (
  params:
    | {
        start_distance__km?: number
        end_distance__km?: number
      }
    | undefined,
  chartType: chartType,
) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const [profile, setProfile] = React.useState<definitions['RouteProfileRetrieveHeights'][]>()
  const [dataIsLoading, setDataIsLoading] = React.useState<boolean>(false)
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  const tryToGetRouteProfile = async () => {
    if (trunkPipelineId && projectId) {
      await getRouteProfileHeights(projectId, trunkPipelineId, params)
        .then((response) => {
          setProfile(response.data)
          dispatch(setProfileHeights(response.data))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: {
                title: 'Ошибка получения высотного профиля трассы',
                message: 'Непредвиденная ошибка',
              } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
      setDataIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (chartType !== 'NONE') {
      timerId.current = setInterval(async () => {
        setDataIsLoading(true)
      }, 2000)
      tryToGetRouteProfile().then(() => {
        if (timerId.current) {
          clearInterval(timerId.current)
        }
      })
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
      }
    }
  }, [projectId, params?.start_distance__km, params?.end_distance__km])

  return { profileData: profile, profileIsLoading: dataIsLoading }
}

export default useGetRouteProfileHeights
