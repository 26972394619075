import { useEffect } from 'react'
import { IMap, mapMode, mapSubmode } from 'store/types'
import { useDispatch, useSelector } from 'react-redux'
import { setNodeCreation, resetNodeCreation, setSelectedGroup, selectSelectedGroup } from 'store/mapSlice'
import { resetShowElementInfo, resetShowIntermediateElementInfo } from 'store/projectSlice'

interface ISwitchModeProps {
  mode: mapMode
  submode: mapSubmode
  rules: any
  setResetReferenceLineDrawing(value: boolean): void
}
const useSwitchMode = (props?: ISwitchModeProps) => {
  const dispatch = useDispatch()
  const selectedGroup = useSelector(selectSelectedGroup)
  useEffect(() => {
    if (props?.rules.current) {
      switch (props?.submode) {
        case 'base':
        case 'select_area':
          switch (props?.mode) {
            case 'view':
              props.rules.current = {
                select: true,
                selectLine: true,
                selectGroup: false,
                editLine: false,
                points: false,
                segment: false,
                referenceLine: false,
                referencePoint: false,
                dragAlong: false,
              }
              dispatch(resetShowIntermediateElementInfo())
              dispatch(
                setSelectedGroup(
                  selectedGroup.filter(
                    (i) =>
                      !['SEGMENT', 'POINTS', ...(selectedGroup.length > 1 ? ['SOURCE', 'SINK'] : [])].includes(i.type),
                  ),
                ),
              )
              break
            case 'edit':
              props.rules.current = {
                select: true,
                selectLine: false,
                selectGroup: true,
                editLine: true,
                points: true,
                segment: true,
                referenceLine: true,
                referencePoint: false,
                dragAlong: false,
              }
              break
          }
          dispatch(resetNodeCreation())
          //dispatch(resetShowIntermediateElementInfo())
          break
        case 'ruler':
          switch (props?.mode) {
            case 'view':
              props.rules.current = {
                select: true,
                selectLine: true,
                selectGroup: false,
                editLine: false,
                points: false,
                segment: false,
                referenceLine: false,
                referencePoint: false,
                dragAlong: false,
              }
              break
            case 'edit':
              props.rules.current = {
                select: true,
                selectLine: false,
                selectGroup: true,
                editLine: true,
                points: true,
                segment: true,
                referenceLine: true,
                referencePoint: false,
                dragAlong: false,
              }
              break
          }
          dispatch(resetNodeCreation())
          props?.setResetReferenceLineDrawing(true)
          dispatch(setSelectedGroup([]))
          dispatch(resetShowIntermediateElementInfo())
          break
        case 'creation':
          switch (props?.mode) {
            case 'view':
              props.rules.current = {
                select: true,
                selectLine: false,
                selectGroup: false,
                editLine: false,
                points: false,
                segment: false,
                referenceLine: false,
                referencePoint: false,
                dragAlong: false,
              }
              dispatch(resetShowElementInfo())
              dispatch(setSelectedGroup([]))
              dispatch(resetShowIntermediateElementInfo())
              break
            case 'edit':
              props.rules.current = {
                select: true,
                selectLine: false,
                selectGroup: false,
                editLine: false,
                points: true,
                segment: false,
                referenceLine: true,
                referencePoint: false,
                dragAlong: false,
              }
              break
          }
          props?.setResetReferenceLineDrawing(true)
          break
        case 'add_point':
          props.rules.current = {
            select: false,
            selectLine: false,
            selectGroup: true,
            editLine: true,
            points: true,
            segment: false,
            referenceLine: true,
            referencePoint: false,
            dragAlong: false,
          }
          dispatch(
            setNodeCreation({
              editObject: 'POINTS',
            }),
          )
          props?.setResetReferenceLineDrawing(true)
          dispatch(setSelectedGroup([]))
          dispatch(resetShowIntermediateElementInfo())
          break
        case 'reference_line':
          props.rules.current = {
            select: false,
            selectLine: false,
            selectGroup: true,
            editLine: true,
            points: true,
            segment: true,
            referenceLine: true,
            referencePoint: true,
            dragAlong: false,
          }
          dispatch(resetNodeCreation())
          dispatch(setSelectedGroup([]))
          dispatch(resetShowIntermediateElementInfo())
          break
        case 'dragAlong':
          switch (props?.mode) {
            case 'view':
              props.rules.current = {
                select: true,
                selectLine: true,
                selectGroup: false,
                editLine: false,
                points: false,
                segment: false,
                referenceLine: false,
                referencePoint: false,
                dragAlong: true,
              }
              break
            case 'edit':
              props.rules.current = {
                select: true,
                selectLine: false,
                selectGroup: true,
                editLine: true,
                points: true,
                segment: true,
                referenceLine: true,
                referencePoint: false,
                dragAlong: true,
              }
              break
          }
      }
    }
  }, [props?.mode, props?.submode])
  return
}

export default useSwitchMode
