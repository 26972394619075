import { useDispatch, useSelector } from 'react-redux'
import {
  selectDownloadResults,
  selectTaskResults,
  selectTaskStatus,
  setDownloadResults,
  setTaskResults,
} from 'store/economicSlice'
import { getEconomicResult, getFileById } from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'

const useGetResults = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const taskResults = useSelector(selectTaskResults)
  const downloadResults = useSelector(selectDownloadResults)
  const [fileId, setFileId] = React.useState<string>()

  const tryToGetResults = async () => {
    if (trunkPipelineId && projectId)
      await getEconomicResult(projectId, trunkPipelineId).then((response) => {
        dispatch(setTaskResults(response.data))
        setFileId(response.data.file_id)
      })
  }

  const tryToDownloadResults = async () => {
    if (trunkPipelineId && projectId)
      if (fileId) {
        getFileById(fileId).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response?.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `economics-results.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
      }
  }

  React.useEffect(() => {
    if (taskStatus === 'Рассчитан' && !taskResults) tryToGetResults().then()
  }, [taskStatus])

  React.useEffect(() => {
    if (downloadResults)
      tryToDownloadResults().then(() => {
        dispatch(setDownloadResults(false))
      })
  }, [downloadResults])

  return
}

export default useGetResults
