import { useMemo, useState } from 'react'
import { twoDigitsFormatter } from 'pages/utils'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { IHeatingStationProps, IReductionNodeProps, IStationProps } from '../types'
export const useGetDataSource = () => {
  const taskResults = useSelector(selectTaskResults)
  const [dataSource, setData] = useState<(IStationProps | IReductionNodeProps | IHeatingStationProps)[]>([])
  const [Q, setQ] = useState<string>('')
  const [P, setP] = useState<string>('')
  const [t, setT] = useState<string>('')
  useMemo(() => {
    const source = taskResults.pipelines.find((pipe) => pipe.start_point__km === 0)
    setQ(twoDigitsFormatter.format(source?.gas_transportation_amount__Mm3_per_day || 0))
    setT(twoDigitsFormatter.format(source?.start_point_temperature__K || 0))
    setP(twoDigitsFormatter.format(source?.start_point_pressure__MPa || 0))
    const _data: (IStationProps | IHeatingStationProps | IReductionNodeProps)[] = []
    taskResults.pipelines.forEach((item) => {
      const station =
        item.end_node.node_type === 'КС' && taskResults.compressorStations.find((obj) => obj.id === item.end_node.id)
      if (station) {
        _data.push({
          type: 'КС',
          name: station.name,
          distance: twoDigitsFormatter.format(item.end_point__km || 0),
          plants: [
            {
              gpu_name: station.first_plant.n_gpu_name,
              p: `${twoDigitsFormatter.format(station.first_plant.suction_pressure__Mpa)}/${twoDigitsFormatter.format(
                station.first_plant.first_stage.discharge_pressure__MPa,
              )}/${
                station.first_plant.second_stage
                  ? twoDigitsFormatter.format(station.first_plant.second_stage.discharge_pressure__MPa)
                  : '...'
              }/${twoDigitsFormatter.format(station.first_plant.outlet_pressure__MPa)}`,
              e: `${twoDigitsFormatter.format(station.first_plant.compression_factor)}`,
              t: `${twoDigitsFormatter.format(station.first_plant.suction_temperature__C)}/${twoDigitsFormatter.format(
                station.first_plant.first_stage.discharge_temperature__C,
              )}/${
                station.first_plant.second_stage
                  ? twoDigitsFormatter.format(station.first_plant.second_stage.discharge_temperature__C)
                  : '...'
              }/${twoDigitsFormatter.format(station.first_plant.outlet_temperature__C)}`,
              n: `${twoDigitsFormatter.format(station.first_plant.consumed_power__MW)}`,
              k: `${twoDigitsFormatter.format(station.first_plant.power_usage_coefficient)}`,
            },
          ],
        })
        if (station.second_plant) {
          ;(_data[_data.length - 1] as IStationProps).plants.push({
            gpu_name: station.second_plant.n_gpu_name,
            p: `${twoDigitsFormatter.format(station.second_plant.suction_pressure__Mpa)}/${twoDigitsFormatter.format(
              station.second_plant.first_stage.discharge_pressure__MPa,
            )}/${
              station.second_plant.second_stage
                ? twoDigitsFormatter.format(station.second_plant.second_stage.discharge_pressure__MPa)
                : '...'
            }/${twoDigitsFormatter.format(station.second_plant.outlet_pressure__MPa)}`,
            e: `${twoDigitsFormatter.format(station.second_plant.compression_factor)}`,
            t: `${twoDigitsFormatter.format(station.second_plant.suction_temperature__C)}/${twoDigitsFormatter.format(
              station.second_plant.first_stage.discharge_temperature__C,
            )}/${
              station.second_plant.second_stage
                ? twoDigitsFormatter.format(station.second_plant.second_stage.discharge_temperature__C)
                : '...'
            }/${twoDigitsFormatter.format(station.second_plant.outlet_temperature__C)}`,
            n: `${twoDigitsFormatter.format(station.second_plant.consumed_power__MW)}`,
            k: `${twoDigitsFormatter.format(station.second_plant.power_usage_coefficient)}`,
          })
        }
      }
      const reductionNode =
        item.end_node.node_type === 'Узел редуцирования' &&
        taskResults.reductionNodes.find((obj) => obj.id === item.end_node.id)
      if (reductionNode) {
        _data.push({
          name: reductionNode.name,
          type: 'Узел редуцирования',
          distance: twoDigitsFormatter.format(item.end_point__km || 0),
          props: {
            p: `${twoDigitsFormatter.format(reductionNode?.in_pressure__MPa)}/${twoDigitsFormatter.format(
              reductionNode?.out_pressure__MPa,
            )}`,
            t: `${twoDigitsFormatter.format(reductionNode?.in_temperature__K)}/${twoDigitsFormatter.format(
              reductionNode?.out_temperature__K,
            )}`,
          },
        })
      }
      const heatingStation =
        item.end_node.node_type === 'Узел нагрева' &&
        taskResults.heatingStations.find((obj) => obj.id === item.end_node.id)
      if (heatingStation) {
        _data.push({
          name: heatingStation.name,
          type: 'Узел нагрева',
          distance: twoDigitsFormatter.format(item.end_point__km || 0),
          props: {
            p: `${twoDigitsFormatter.format(heatingStation?.in_pressure__MPa)}/${twoDigitsFormatter.format(
              heatingStation?.out_pressure__MPa,
            )}`,
            t: `${twoDigitsFormatter.format(heatingStation?.in_temperature__K)}/${twoDigitsFormatter.format(
              heatingStation?.out_temperature__K,
            )}`,
          },
        })
      }
    })
    setData(_data)
  }, [taskResults])
  return { Q, P, t, dataSource }
}
