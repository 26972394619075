import { useEffect } from 'react'
import {
  selectACUChoices,
  selectCompressorStations,
  selectGPUChoices,
  setUpdatedElement,
  updateCompressorStation,
} from 'store/projectSlice'
import update from 'immutability-helper'
import { selectBlockingWindow, resetBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { definitions } from 'generated/apiTypes'
import { IBlockingWindow, ISelectParams } from 'store/types'

const useSaveHandbookItem = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ISelectParams)
  const gpuChoices = useSelector(selectGPUChoices)
  const acuChoices = useSelector(selectACUChoices)
  const compressorStations = useSelector(selectCompressorStations) as definitions['CompressorStation'][]

  const updateStore = (
    updatedField: { [key: string]: any },
    compressorStation: definitions['CompressorStation'] | undefined,
  ) => {
    if (compressorStation) {
      dispatch(
        updateCompressorStation({
          ...compressorStation,
          ...updatedField,
        } as definitions['CompressorStation']),
      )
    }
  }

  useEffect(() => {
    if (
      blockingWindowParams?.objectType &&
      ['GPU', 'ACU'].includes(blockingWindowParams.objectType) &&
      blockingWindow.type === 'SELECT' &&
      blockingWindowParams.isConfirmed
    ) {
      const compressorStation = compressorStations?.find((station) =>
        station.compressor_plants.find((plant) => plant.id === blockingWindowParams.plantID),
      )
      if (blockingWindowParams.plantID && compressorStation) {
        dispatch(
          setUpdatedElement({
            elementID: blockingWindowParams.plantID,
            type: 'COMPRESSOR_PLANT',
            params: {
              [`${blockingWindowParams.objectType.toLowerCase()}_id`]: blockingWindowParams.objectID as string,
            },
          }),
        )
        const choices = blockingWindowParams.objectType === 'GPU' ? gpuChoices : acuChoices
        const plantIx = compressorStation?.compressor_plants.findIndex(
          (plant) => plant.id === blockingWindowParams.plantID,
        )
        if (plantIx !== undefined && plantIx > -1 && compressorStation) {
          updateStore(
            {
              compressor_plants: update(compressorStation.compressor_plants, {
                [plantIx]: {
                  $set: {
                    ...compressorStation.compressor_plants[plantIx],
                    [blockingWindowParams.objectType.toLowerCase()]: choices.find(
                      (item) => item.id === blockingWindowParams.objectID,
                    ),
                  },
                } as any,
              }),
            },
            compressorStation,
          )
        }
      }
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow])
  return
}

export default useSaveHandbookItem
