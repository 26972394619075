import React, { useContext } from 'react'
import Button from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectDNLayers, selectErrorMessage, selectTaskStatus, selectUpdateCount } from 'store/DNLayersSlice'
import { AnyLayer } from '../types'
import LayerRadioButton, { ILayerOptionProps } from './layerRadioButton'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { MapContext } from '../map'
import WarningIcon from '../../../images/WarningIcon'
import PopupMenuLayout from '../../Dropdown/PopupMenuLayout'
import { arabicToRoman } from '../utils'

const DNLayers: React.FC = () => {
  const dispatch = useDispatch()
  const mapContext = useContext(MapContext)
  const DNLayers = useSelector(selectDNLayers) as definitions['XYZTiles']
  const status = useSelector(selectTaskStatus)
  const updateCount = useSelector(selectUpdateCount)
  const errorMessage = useSelector(selectErrorMessage)
  const [options, setOptions] = React.useState<ILayerOptionProps[]>([
    { id: 'none', name: 'Не отображать', disabled: false },
  ])
  const [selectedOptionId, setSelectedOptionId] = React.useState<string>('none')
  const [show, setShow] = React.useState<boolean>(false)

  React.useEffect(() => {
    const newOptions: ILayerOptionProps[] = [...[{ id: 'none', name: 'Не отображать', disabled: false }]]
    let visibleLayerId = 'none'
    DNLayers?.existing_xyz_tiles.map((item) => {
      const layer = mapContext?.getLayer(`DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`) as AnyLayer
      if (layer) {
        newOptions?.push({ id: layer.id, name: (layer?.metadata as any)?.name, disabled: false })
        if (mapContext.getLayoutProperty(layer.id, 'visibility') === 'visible') {
          visibleLayerId = layer.id
        }
      }
    })
    DNLayers?.missing_xyz_tiles.map((item) => {
      newOptions?.push({
        id: `DN_${item.offset_type.diameter__mm}_${item.offset_type.class_}`,
        name: `DN ${item.offset_type.diameter__mm} (${arabicToRoman(parseInt(item.offset_type.class_))})`,
        disabled: true,
      })
    })
    setSelectedOptionId(visibleLayerId)
    setOptions(newOptions)
  }, [DNLayers, updateCount])

  const onCalcClick = () => {
    dispatch(setBlockingWindow({ type: 'DN_LAYERS_TASK' }))
  }

  const toggleCalcErrorMessage = () => {
    setShow(!show)
  }

  const getErrorMessage = (): string => {
    if (errorMessage?.includes('внутренняя ошибка')) {
      return `Последний расчет завершился с\xa0внутренней ошибкой`
    } else if (errorMessage?.includes('Nextgis')) {
      return 'Последний расчет завершился с\xa0ошибкой NextGIS'
    }
    return 'Последний расчет завершился с\xa0ошибкой'
  }

  return options?.length > 1 ? (
    <div className={'layer-control__column second'}>
      <div className={'layer-control__column-title header4'}>
        <div className={'layer-control__warning-wrapper'}>
          МДР
          {status === 'Ошибка' && (
            <button className={'layer-control__dn-layers-warning-btn'} onClick={toggleCalcErrorMessage}>
              <WarningIcon className={'layer-control__dn-layers-warning-icn'} />
              {show && (
                <PopupMenuLayout closeMenu={() => setShow(false)}>
                  <div className={'layer-control__dn-layers-warning-text field-label-text'}>{getErrorMessage()}</div>
                </PopupMenuLayout>
              )}
            </button>
          )}
        </div>
        <Button mode={'primary-light'} className={'layer-control__calc-btn'} onClick={onCalcClick}>
          Рассчитать
        </Button>
      </div>
      <div className={'layer-control__list-container'}>
        {options?.map((option) => (
          <LayerRadioButton
            key={option.id}
            id={option.id}
            value={selectedOptionId}
            name={option.name}
            disabled={option.disabled}
            setSelectedOptionId={setSelectedOptionId}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default DNLayers
