import React, { useEffect } from 'react'
import { definitions } from 'generated/apiTypes'
import Dropdown from 'components/Dropdown/Dropdown'
import IntegerInput from 'components/Input/integerInput'
import NumberInput from 'components/Input/numberInput'
import Checkbox from 'components/Checkbox'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IBlockingWindow, ISelectParams } from 'store/types'

interface IPlantProps {
  plant?: definitions['CSPlacementProperties']['draft_compressor_station']['first_compressor_plant']
  setPlant(value: number | string | boolean | Record<string, any>, fieldName?: string): void
  plantIx: number
}

const Plant: React.FC<IPlantProps> = ({ plant, setPlant, plantIx }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as ISelectParams)

  const openHandbook = (id: string, type: 'ACU' | 'GPU') => {
    dispatch(
      setBlockingWindow({
        type: 'SELECT',
        params: { objectID: id, objectType: type } as ISelectParams,
      }),
    )
  }

  useEffect(() => {
    if (
      blockingWindowParams?.objectType &&
      ['GPU', 'ACU'].includes(blockingWindowParams.objectType) &&
      blockingWindow.type === 'SELECT' &&
      blockingWindowParams.isConfirmed
    ) {
      if (blockingWindowParams.objectID)
        setPlant(
          { id: blockingWindowParams.objectID, name: blockingWindowParams.objectName },
          `${blockingWindowParams.objectType.toLowerCase()}`,
        )
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow])

  return (
    <>
      <div className={'cs-settings__grid-row'}>
        <div
          className={'choices_dropdown'}
          onClick={() => openHandbook(plant?.gpu.id ? (plant?.gpu.id as string) : '', 'GPU')}
        >
          <Dropdown options={[]} selectedValue={plant?.gpu} displayedField={'name'} label={'ГПА'} />
        </div>
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_gpu_first_stage`}
          value={plant?.n_gpu_first_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_gpu_first_stage'}
          setValue={setPlant}
          label={'Кол-во ГПА на 1 ступени'}
        />
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_gpu_second_stage`}
          value={plant?.n_gpu_second_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_gpu_second_stage'}
          setValue={setPlant}
          label={'Кол-во ГПА на 2 ступени'}
        />
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_gpu_total`}
          value={plant?.n_gpu_total}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_gpu_total'}
          setValue={setPlant}
          label={'Общее кол-во ГПА'}
        />
      </div>
      <div className={'cs-settings__divider'} />
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_max_temperature`}
          value={plant?.max_temperature__C}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'max_temperature__C'}
          unit={'°C'}
          setValue={setPlant}
          label={'Макс. темп. газа на выходе КС'}
          signed
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_temperature_gtf`}
          value={plant?.temperature_gtf__C}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'temperature_gtf__C'}
          unit={'°C'}
          setValue={setPlant}
          label={'Расчетная температура атмосферного воздуха на входе ГТУ'}
          infoText={
            <>
              Расчетная температура атмосферного воздуха на входе ГТУ (t)
              <br />
              <br />
              Рассчитывается следующим образом:
              <br />t = Tср + 5, где Tср - средняя температура атм. воздуха расчетного календарного периода, опр. СНиП
              23-01
            </>
          }
          signed
        />
      </div>
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          id={'compressor-stations-placement'}
          value={plant?.temperature_ambient__C}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'temperature_ambient__C'}
          unit={'°C'}
          setValue={setPlant}
          label={'Темпер. наружного воздуха'}
          disabled={plant?.cooling_level}
          noEmpty={!plant?.cooling_level}
          signed
        />
        <div className={'cs-settings__checkbox-container normal-text'}>
          <Checkbox
            checkedValue={plant?.cooling_level || false}
            setCheckedValue={(value) => setPlant(value, 'cooling_level')}
          />
          <div>Рассчитать на “уровне охлаждения”</div>
        </div>
      </div>
      <div className={'cs-settings__divider'} />
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_gas_inflow`}
          unit={'млн.куб.м/сутки'}
          value={plant?.gas_inflow__Mm3_per_day}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'gas_inflow__Mm3_per_day'}
          setValue={setPlant}
          signed
          label={'Приток/Отбор газа перед КС'}
          infoText={'Приток/отбор газа перед КС (отбор со знаком минус)'}
          noEmpty
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_min_allowable_relative_value`}
          value={plant?.min_allowable_relative_value}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'min_allowable_relative_value'}
          setValue={setPlant}
          infoText={'Минимально допустимое значение относительных оборотов нагнетателя'}
          label={
            <>
              n<sub>min</sub>
            </>
          }
          signed
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_max_allowable_relative_value`}
          value={plant?.max_allowable_relative_value}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'max_allowable_relative_value'}
          setValue={setPlant}
          infoText={'Максимально допустимое значение относительных оборотов нагнетателя'}
          label={
            <>
              n<sub>max</sub>
            </>
          }
          signed
        />
      </div>
      <div className={'cs-settings__divider'} />
      <div className={'cs-settings__grid-row'}>
        <div
          className={'choices_dropdown'}
          onClick={() => openHandbook(plant?.acu.id ? (plant?.acu.id as string) : '', 'ACU')}
        >
          <Dropdown options={[]} selectedValue={plant?.acu} displayedField={'name'} label={'АВО'} />
        </div>
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_aca_first_stage`}
          value={plant?.n_aca_first_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_aca_first_stage'}
          setValue={setPlant}
          label={'Кол-во паралл. АВО I '}
          infoText={'Количество параллельно включенных АВО на первой ступени охлаждения'}
        />
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_throws_aca_first_stage`}
          value={plant?.n_throws_aca_first_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_throws_aca_first_stage'}
          setValue={setPlant}
          label={'Кол-во ходов в АВО I '}
        />
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_aca_second_stage`}
          value={plant?.n_aca_second_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_aca_second_stage'}
          setValue={setPlant}
          label={'Кол-во паралл. АВО II '}
          infoText={'Количество параллельно включенных АВО на второй ступени охлаждения'}
        />
      </div>
      <div className={'cs-settings__grid-row'}>
        <IntegerInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_n_throws_aca_second_stage`}
          value={plant?.n_throws_aca_second_stage}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'n_throws_aca_second_stage'}
          label={'Кол-во ходов в АВО II'}
          setValue={setPlant}
        />
      </div>
      <div className={'cs-settings__divider'} />
      <div className={'cs-settings__grid-row'}>
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_inlet_pressure_drop`}
          value={plant?.inlet_pressure_drop}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'inlet_pressure_drop'}
          setValue={setPlant}
          label={'Потери давления на входе цеха'}
          unit={'МПа'}
          signed
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_exit_pressure_drop`}
          value={plant?.exit_pressure_drop}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'exit_pressure_drop'}
          setValue={setPlant}
          label={'Потери давления на нагнетании'}
          unit={'МПа'}
          signed
        />
        <NumberInput
          id={'compressor-stations-placement'}
          key={`compressor-stations-placement_aca_pressure_drop`}
          value={plant?.aca_pressure_drop}
          level1FieldName={'draft_compressor_station'}
          level2FieldName={plantIx === 0 ? 'first_compressor_plant' : 'second_compressor_plant'}
          fieldName={'aca_pressure_drop'}
          setValue={setPlant}
          label={'Потери давления в обвязке АВО'}
          unit={'МПа'}
          signed
        />
      </div>
    </>
  )
}

export default Plant
