import * as React from 'react'

function InfoIcon(props) {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 11C8.48528 11 10.5 8.98528 10.5 6.5C10.5 4.01472 8.48528 2 6 2C3.51472 2 1.5 4.01472 1.5 6.5C1.5 8.98528 3.51472 11 6 11Z'
        stroke='#8896A4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6 4.5H6.005' stroke='#8896A4' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 6.5H6V8.5H6.5' stroke='#8896A4' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default InfoIcon
