import { ChartOptions } from 'chart.js'

export const seriesColor = '#0074BC'

export const options = (
  axisYLabel: string,
  axisXLabel: string,
  maxX: number,
  afterBuildTicks: { value: number; ks: boolean }[],
): ChartOptions<'line'> => {
  return {
    //aspectRatio: datasetLength > 80 ? 1.2 : 3,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          pointStyle: 'line',
          usePointStyle: true,
        },
        align: 'start',
      },
    },
    scales: {
      y: {
        border: { display: false },
        grid: {
          color: '#E4E9EF',
        },
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
          /*callback: (value) => {
            if (value === 0) return value
            if (Math.abs(value as number) < 0.01 || Math.abs(value as number) > 99999) {
              return numeral(value).format('0.0[00]e+0')
            }
            return numeral(value).format('0[.]0[00]')
          },*/
        },
        title: {
          display: true,
          text: axisYLabel,
          color: '#AAAAAA',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
        },
      },
      x: {
        type: 'linear',
        max: maxX,
        afterBuildTicks: (axis) =>
          (axis.ticks = [
            ...afterBuildTicks.map((item) => ({
              value: item.value,
              label: 'spec',
            })),
            ...axis.ticks,
          ]),
        grid: {
          color: (c) => {
            if (c.tick.label === '') return 'rgba(255,127,72,0.4)'
            else if (c.tick.value === afterBuildTicks[0].value || c.tick.value === maxX) return 'transparent'
            return '#E4E9EF'
          },
        },
        border: { display: false, dash: [2, 4] },
        ticks: {
          padding: 8,
          color: '#999999',
          font: {
            size: 10,
            family: 'Inter, sans-serif',
          },
          autoSkip: true,
          minRotation: 0,
          stepSize: 10,
          autoSkipPadding: 1,
          callback: function (value, i, arr) {
            if (arr[i].label?.includes('spec')) return ''
            else return arr[i].value?.toFixed(0)
          },
        },
        title: {
          display: true,
          text: axisXLabel,
          color: '#AAAAAA',
          font: {
            size: 10,
            weight: '500',
            family: 'Inter, sans-serif',
          },
        },
      },
    },
  }
}
