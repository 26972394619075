import * as React from 'react'

function FileUploadedIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_928_20460)'>
        <path
          d='M12.167 2.5V5.83333C12.167 6.05435 12.2548 6.26631 12.4111 6.42259C12.5674 6.57887 12.7793 6.66667 13.0003 6.66667H16.3337'
          stroke='#00BC84'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.667 17.5H6.33366C5.89163 17.5 5.46771 17.3244 5.15515 17.0118C4.84259 16.6993 4.66699 16.2754 4.66699 15.8333V4.16667C4.66699 3.72464 4.84259 3.30072 5.15515 2.98816C5.46771 2.67559 5.89163 2.5 6.33366 2.5H12.167L16.3337 6.66667V15.8333C16.3337 16.2754 16.1581 16.6993 15.8455 17.0118C15.5329 17.3244 15.109 17.5 14.667 17.5Z'
          stroke='#00BC84'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 12.5L9.66667 14.1667L13 10.8333'
          stroke='#00BC84'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_928_20460'>
          <rect width='20' height='20' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FileUploadedIcon
