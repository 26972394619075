import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { IBlockingWindow, IDeleteConfirmParams } from 'store/types'
import { deleteProjectByIDRequest } from 'services/apiRequests'
import { selectProjectsList, setProjectsList } from 'store/projectsListSlice'
import { useNavigate } from 'react-router-dom'
interface IDeleteWindow {
  closeWindow(): void
}
export const DeleteWindow: React.FC<IDeleteWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow
  const blockingWindowParams = blockingWindow?.params && (blockingWindow.params as IDeleteConfirmParams)
  const projectsList = useSelector(selectProjectsList)
  const navigate = useNavigate()

  const titleByObjectType = () => {
    switch (blockingWindowParams?.objectType) {
      case 'PROJECT':
        return 'проекта'
      case 'PIPE':
        return 'нитей трубопровода'
      case 'COMPRESSOR_STATIONS':
        return 'компрессорной станции'
      case 'REFERENCE_NODES':
        return 'опорного узла'
      case 'HEATING_STATIONS':
        return 'узла нагрева'
      case 'HANDBOOK_ITEMS':
        return 'строк'
      case 'NODES_REDUCTIONS':
        return 'узла редуцирования газа'
      case 'GROUP_VIEW':
        return 'группы объектов'
      case 'GROUP':
        return 'группы точек'
      default:
        return ''
    }
  }
  const messageByObjectType = () => {
    switch (blockingWindowParams?.objectType) {
      case 'PROJECT':
        return 'проект'
      case 'PIPE':
        return 'нити'
      case 'COMPRESSOR_STATIONS':
        return 'компрессорную станцию'
      case 'REFERENCE_NODES':
        return 'опорный узел'
      case 'HANDBOOK_ITEMS':
        return 'выбранные строки'
      case 'NODES_REDUCTIONS':
        return 'узел редуцирования'
      case 'HEATING_STATIONS':
        return 'узел нагрева'
      case 'GROUP':
        return 'выделенные точки'
      case 'GROUP_VIEW':
        return 'выделенные объекты'
      default:
        return ''
    }
  }
  const clarifyingTextByObjectType = () => {
    switch (blockingWindowParams?.objectType) {
      case 'GROUP':
        return 'Основные объекты, попавшие в выборку, удалены не будут.'
      default:
        return ''
    }
  }

  const deleteObject = () => {
    switch (blockingWindowParams?.objectType) {
      case 'PROJECT':
        deleteProjectByIDRequest(blockingWindowParams?.objectID as string).then(() => {
          dispatch(
            setProjectsList(projectsList.filter((project: any) => project.id !== blockingWindowParams?.objectID)),
          )
          closeWindow()
          navigate('/')
        })
        break
      case 'HEATING_STATIONS':
      case 'REFERENCE_NODES':
      case 'NODES_REDUCTIONS':
      case 'COMPRESSOR_STATIONS':
      case 'PIPE':
      case 'HANDBOOK_ITEMS':
      case 'GROUP_VIEW':
      case 'GROUP':
        dispatch(setBlockingWindow({ params: { ...blockingWindowParams, isConfirmed: true } as IDeleteConfirmParams }))
    }
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container delete-confirm'>
        <div className={'blocking-window__header'}>
          {`Удаление ${titleByObjectType()}`}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          {`Вы действительно хотите удалить ${messageByObjectType()}`}
          {blockingWindowParams?.objectName !== undefined && ` “${blockingWindowParams.objectName}“`}?
          <br />
          {clarifyingTextByObjectType()}
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button mode={'warn'} onClick={deleteObject}>
            Удалить
          </Button>
        </div>
      </div>
    </div>
  )
}
