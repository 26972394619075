import React from 'react'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { definitions } from 'generated/apiTypes'
import { generateUID, twoDigitsFormatter } from 'pages/utils'
import { Table } from 'components/Table/Table/Table'
import { ITableColumn } from 'components/Table/types'

const columns = [
  {
    title: '',
  },
  {
    title: 'Первая ступень компримирования',
  },
  {
    title: 'Вторая ступень компримирования',
  },
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: false,
  },
  {
    title: (
      <>
        Произв. ГПА,
        <br />
        м3/мин
      </>
    ),
    type: 'Text',
    key: 'first_stage__gpu_performance',
    editable: false,
  },
  {
    title: (
      <>
        Отн-ое число
        <br />
        оборотов
      </>
    ),
    type: 'Text',
    key: 'first_stage__relative_rotational_speed',
    editable: false,
  },
  {
    title: 'Степ. сжат.',
    type: 'Text',
    key: 'first_stage__compression_ratio',
    editable: false,
  },
  {
    title: 'КПД ЦБН',
    type: 'Text',
    key: 'first_stage__cfb_efficiency',
    editable: false,
  },
  {
    title: (
      <>
        Раб. мощность
        <br />
        ГПА, кВт
      </>
    ),
    type: 'Text',
    key: 'first_stage__n_gpu_operating_power',
    editable: false,
  },
  {
    title: (
      <>
        Давление
        <br />
        нагнетания,
        <br />
        МПа
      </>
    ),
    type: 'Text',
    key: 'first_stage__discharge_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Темп-ра
        <br />
        нагн., К
      </>
    ),
    type: 'Text',
    key: 'first_stage__discharge_temperature',
    editable: false,
  },
  {
    title: (
      <>
        Произв. ГПА,
        <br />
        м3/мин
      </>
    ),
    type: 'Text',
    key: 'second_stage__gpu_performance',
    editable: false,
  },
  {
    title: (
      <>
        Отн-ое число
        <br />
        оборотов
      </>
    ),
    type: 'Text',
    key: 'second_stage__relative_rotational_speed',
    editable: false,
  },
  {
    title: 'Степ. сжат.',
    type: 'Text',
    key: 'second_stage__compression_ratio',
    editable: false,
  },
  {
    title: 'КПД ЦБН',
    type: 'Text',
    key: 'second_stage__cfb_efficiency',
    editable: false,
  },
  {
    title: (
      <>
        Раб. мощность
        <br />
        ГПА, кВт
      </>
    ),
    type: 'Text',
    key: 'second_stage__n_gpu_operating_power',
    editable: false,
  },
  {
    title: (
      <>
        Давление
        <br />
        нагнетания,
        <br />
        МПа
      </>
    ),
    type: 'Text',
    key: 'second_stage__discharge_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Темп-ра
        <br />
        нагн., К
      </>
    ),
    type: 'Text',
    key: 'second_stage__discharge_temperature',
    editable: false,
  },
]
export const ResultTable2: React.FC = () => {
  const taskResults = useSelector(selectTaskResults)
  const getDataSource = (stationList: definitions['HydroResultRetrieveCompressorStation'][]) => {
    const data: any = []
    stationList.forEach((station) => {
      data.push({
        id: generateUID(),
        name: station.name,
        first_stage__gpu_performance: twoDigitsFormatter.format(
          station.first_plant.first_stage?.gpu_performance__m3_per_min,
        ),
        first_stage__relative_rotational_speed: twoDigitsFormatter.format(
          station.first_plant.first_stage?.relative_rotational_speed,
        ),
        first_stage__compression_ratio: twoDigitsFormatter.format(station.first_plant.first_stage?.compression_ratio),
        first_stage__cfb_efficiency: twoDigitsFormatter.format(station.first_plant.first_stage?.cfb_efficiency),
        first_stage__n_gpu_operating_power: twoDigitsFormatter.format(
          station.first_plant.first_stage?.n_gpu_operating_power__KW,
        ),
        first_stage__discharge_pressure: twoDigitsFormatter.format(
          station.first_plant.first_stage?.discharge_pressure__MPa,
        ),
        first_stage__discharge_temperature: twoDigitsFormatter.format(
          station.first_plant.first_stage?.discharge_temperature__C,
        ),
        second_stage__gpu_performance: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.gpu_performance__m3_per_min)
          : '',
        second_stage__relative_rotational_speed: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.relative_rotational_speed)
          : '',
        second_stage__compression_ratio: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.compression_ratio)
          : '',
        second_stage__cfb_efficiency: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.cfb_efficiency)
          : '',
        second_stage__n_gpu_operating_power: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.n_gpu_operating_power__KW)
          : '',
        second_stage__discharge_pressure: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.discharge_pressure__MPa)
          : '',
        second_stage__discharge_temperature: station.first_plant.second_stage
          ? twoDigitsFormatter.format(station.first_plant.second_stage.discharge_temperature__C)
          : '',
      })
      if (station.second_plant)
        data.push({
          id: generateUID(),
          name: station.name,
          first_stage__gpu_performance: twoDigitsFormatter.format(
            station.second_plant.first_stage?.gpu_performance__m3_per_min,
          ),
          first_stage__relative_rotational_speed: twoDigitsFormatter.format(
            station.second_plant.first_stage?.relative_rotational_speed,
          ),
          first_stage__compression_ratio: twoDigitsFormatter.format(
            station.second_plant.first_stage?.compression_ratio,
          ),
          first_stage__cfb_efficiency: twoDigitsFormatter.format(station.second_plant.first_stage?.cfb_efficiency),
          first_stage__n_gpu_operating_power: twoDigitsFormatter.format(
            station.second_plant.first_stage?.n_gpu_operating_power__KW,
          ),
          first_stage__discharge_pressure: twoDigitsFormatter.format(
            station.second_plant.first_stage?.discharge_pressure__MPa,
          ),
          first_stage__discharge_temperature: twoDigitsFormatter.format(
            station.second_plant.first_stage?.discharge_temperature__C,
          ),
          second_stage__gpu_performance: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.gpu_performance__m3_per_min)
            : '',
          second_stage__relative_rotational_speed: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.relative_rotational_speed)
            : '',
          second_stage__compression_ratio: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.compression_ratio)
            : '',
          second_stage__cfb_efficiency: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.cfb_efficiency)
            : '',
          second_stage__n_gpu_operating_power: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.n_gpu_operating_power__KW)
            : '',
          second_stage__discharge_pressure: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.discharge_pressure__MPa)
            : '',
          second_stage__discharge_temperature: station.second_plant.second_stage
            ? twoDigitsFormatter.format(station.second_plant.second_stage.discharge_temperature__C)
            : '',
        })
    })
    return data
  }
  return (
    <Table
      columns={columns as ITableColumn[]}
      dataSource={getDataSource(taskResults.compressorStations)}
      className={'station-result-table-2'}
    />
  )
}
