import React from 'react'

import { getBlackPipePricesHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItem } from 'store/directorySlice'

const useGetBlackPipePrices = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetBlackPipePrices = async () => {
    await getBlackPipePricesHandbook().then((res) => {
      dispatch(setDirectoryItem(res.data))
    })
  }

  React.useEffect(() => {
    tryToGetBlackPipePrices().then()
  }, [requestData])

  return
}

export default useGetBlackPipePrices
