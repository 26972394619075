import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectReferenceLines,
  selectReferenceLineCreation,
  setReferenceLineCreation,
  setReferenceLines,
  resetReferenceLineCreation,
  setSelectedGroup,
  setMapPipelines,
} from '../../store/mapSlice'

import { MapContext } from './map'
import { feature, featureCollection, lineString, coordReduce, point, getCoords } from '@turf/turf'
import {
  generateUID,
  getSourceData,
  lineLayoutProps,
  lineReferenceLineHoverLayerProps,
  lineReferenceLineLayerProps,
  lineReferenceLineSelectedLayerProps,
  circleReferenceLineLayerProps,
  circleReferenceLineHoverLayerProps,
  circleReferenceLineSelectedLayerProps,
} from './utils'
import { Layer } from './Layer'
import { Feature } from 'geojson'
import {
  createReferenceLineRequest,
  getPipelinesByProjectIDRequest,
  getReferenceLinesRequest,
  updateReferenceLineRequest,
} from 'services/apiRequests'
import { selectProjectID, setPipelines, setShowElementInfo } from 'store/projectSlice'
import { MapEvent } from './types'
import { GeoJSONSource } from 'maplibre-gl'
interface IReferenceLinesProps {
  edit: boolean
  visible: boolean
  resetDrawing: boolean
  setResetDrawing(value: boolean): void
}
export const ReferenceLineLayers: React.FC<IReferenceLinesProps> = ({
  edit,
  visible,
  resetDrawing,
  setResetDrawing,
}) => {
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()
  const referenceLines = useSelector(selectReferenceLines)
  const projectId = useSelector(selectProjectID) as string
  const referenceLineCreation = useSelector(selectReferenceLineCreation)
  const dragFeatures = useRef<any | undefined>()
  const startDragPoint = useRef<number[]>([0, 0])
  const isDragging = useRef(false)
  useEffect(() => {
    if (resetDrawing) {
      if (referenceLineCreation.draw) {
        dispatch(resetReferenceLineCreation())
        dispatch(setReferenceLines(referenceLines.slice(0, -1)))
      }
      setResetDrawing(false)
    }
  }, [resetDrawing])
  const drawingLine = (e: MapEvent) => {
    const source = mapContext?.getSource('reference_line__source') as GeoJSONSource
    referenceLineCreation.coordinates &&
      source?.setData(
        featureCollection([
          ...getSourceData(source).features.slice(0, -1),
          lineString([referenceLineCreation.coordinates[0] as any, e.lngLat.toArray()], { type: 'REFERENCE_LINE' }),
        ]),
      )
  }
  const finishDrawingLine = async (e: MapEvent) => {
    if (
      mapContext?.queryRenderedFeatures(e.point, {
        layers: [
          'source-hover__layer',
          'sink-hover__layer',
          'reference_nodes-hover__layer',
          'compressor_stations-hover__layer',
          'points-hover__layer',
          'nodes_reductions-hover__layer',
          'segment-hover__layer',
        ],
      }).length > 0
    ) {
      setResetDrawing(true)
      return
    }
    const source = mapContext?.getSource('reference_line__source') as GeoJSONSource
    await createReferenceLineRequest(projectId, {
      line: getSourceData(source).features.slice(-1)[0].geometry as any,
    }).then(() => {
      getReferenceLinesRequest(projectId).then((response) => {
        dispatch(setReferenceLines(response.data))
      })
      getPipelinesByProjectIDRequest(projectId).then((response) => {
        dispatch(setMapPipelines(response.data))
      })
    })
    mapContext?.off('mousemove', drawingLine)
    dispatch(setReferenceLineCreation({ draw: false }))
  }
  useEffect(() => {
    if (referenceLineCreation.draw) {
      dispatch(
        setReferenceLines([
          ...referenceLines,
          { id: generateUID(), line: lineString(referenceLineCreation.coordinates as any).geometry as any },
        ]),
      )
      mapContext?.on('mousemove', drawingLine)
      mapContext?.once('mouseup', finishDrawingLine)
    }
    return () => {
      mapContext?.off('mouseup', finishDrawingLine)
      mapContext?.off('mousemove', drawingLine)
    }
  }, [referenceLineCreation.draw])
  const [referenceLinesSource, referencePointsSource] = useMemo(() => {
    const [_pipelines, _points]: [Feature[], Feature[]] = [[], []]
    referenceLines.forEach((pipeline: any) => {
      _pipelines.push(
        feature(pipeline.line, {
          id: pipeline.id,
          type: 'REFERENCE_LINE',
          zIndex: 0,
        }),
      )
      _points.push(
        ...(coordReduce(
          pipeline.line,
          (prev: any[] | undefined, curr) => {
            prev &&
              curr &&
              prev.push(
                feature(point(curr).geometry, {
                  id: `${pipeline.id}/p${prev.length}`,
                  lineId: pipeline.id,
                  type: 'REFERENCE_POINT',
                  index: prev.length,
                  zIndex: 1,
                }),
              )
            return prev || []
          },
          [],
        ) as Feature[]),
      )
    })
    return [_pipelines, _points]
  }, [referenceLines])

  const handleOnMouseDown = (e: MapEvent) => {
    e.preventDefault()
    startDragPoint.current = e.lngLat.toArray()
    if (e.features[0] && e.features[0].properties.type === 'REFERENCE_LINE') {
      dragFeatures.current = getSourceData(
        mapContext?.getSource('reference_point__source') as GeoJSONSource,
      ).features.filter((p) => p.properties!.lineId === e.features[0].properties.id)
    } else {
      dragFeatures.current = [e.features[0]]
    }
    mapContext?.on('mousemove', dragPoint)
    mapContext?.once('mouseup', handleOnMouseUp)
  }

  const dragPoint = (e: MapEvent) => {
    const deltaLat = startDragPoint.current[1] - e.lngLat.lat
    const deltaLng = startDragPoint.current[0] - e.lngLat.lng
    if (!isDragging.current) isDragging.current = true
    startDragPoint.current = e.lngLat.toArray()
    const lineSource = mapContext?.getSource('reference_line__source') as GeoJSONSource
    const pointSource = mapContext?.getSource('reference_point__source') as GeoJSONSource
    for (const dragFeature of dragFeatures.current) {
      const dragPoint = getSourceData(pointSource).features.find((p) => p.properties!.id === dragFeature.properties.id)
      const dragLine = getSourceData(lineSource).features.find(
        (p) => p.properties!.id === dragFeature.properties.lineId,
      )
      dragPoint!.geometry = point([
        getCoords(dragPoint!.geometry as any)[0] - deltaLng,
        getCoords(dragPoint!.geometry as any)[1] - deltaLat,
      ]).geometry
      dragLine!.geometry = lineString([
        ...getCoords(dragLine!.geometry as any).slice(0, dragFeature.properties.index),
        getCoords(dragPoint!.geometry as any),
        ...getCoords(dragLine!.geometry as any).slice(dragFeature.properties.index + 1),
      ]).geometry
    }
    pointSource.setData(getSourceData(pointSource))
    lineSource.setData(getSourceData(lineSource))
  }
  const handleOnMouseUp = async () => {
    if (isDragging.current) {
      const referenceLine = getSourceData(
        mapContext?.getSource('reference_line__source') as GeoJSONSource,
      ).features.find((l) => l.properties!.id === dragFeatures.current[0].properties.lineId)
      referenceLine &&
        (await updateReferenceLineRequest(projectId, referenceLine.properties!.id, {
          line: referenceLine.geometry,
        } as any).then(() => {
          getReferenceLinesRequest(projectId).then((response) => {
            dispatch(setReferenceLines(response.data))
          })
          getPipelinesByProjectIDRequest(projectId).then((response) => {
            dispatch(setMapPipelines(response.data))
          })
        }))
    } else {
      const type = dragFeatures.current.length > 1 ? 'REFERENCE_LINE' : 'REFERENCE_POINT'
      const id =
        dragFeatures.current.length > 1
          ? dragFeatures.current[0].properties.lineId
          : dragFeatures.current[0].properties.id
      dispatch(setSelectedGroup([{ id, type }]))
    }
    isDragging.current = false
    dragFeatures.current = []
    mapContext?.off('mousemove', dragPoint)
  }
  return (
    <>
      <Layer
        sourceID={'reference_line__source'}
        baseLayer={'reference_line-hover__layer'}
        features={[]}
        layerType={'line'}
        handleMouseDown={handleOnMouseDown}
        paint={lineReferenceLineHoverLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
        visible={edit ? 'visible' : 'none'}
      />
      <Layer
        sourceID={'reference_line__source'}
        baseLayer={'reference_line-selected__layer'}
        features={[]}
        layerType={'line'}
        paint={lineReferenceLineSelectedLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
        visible={edit ? 'visible' : 'none'}
      />
      <Layer
        sourceID={'reference_point__source'}
        baseLayer={'reference_point__layer'}
        features={referencePointsSource}
        layerType={'circle'}
        paint={circleReferenceLineLayerProps}
        source
        visible={edit ? 'visible' : 'none'}
      />
      <Layer
        sourceID={'reference_point__source'}
        baseLayer={'reference_point-hover__layer'}
        handleMouseDown={handleOnMouseDown}
        features={[]}
        layerType={'circle'}
        paint={circleReferenceLineHoverLayerProps}
        filter={['==', 'id', '']}
        visible={edit ? 'visible' : 'none'}
      />
      <Layer
        sourceID={'reference_point__source'}
        baseLayer={'reference_point-selected__layer'}
        features={[]}
        layerType={'circle'}
        paint={circleReferenceLineSelectedLayerProps}
        filter={['==', 'id', '']}
        visible={edit ? 'visible' : 'none'}
      />
      <Layer
        sourceID={'reference_line__source'}
        baseLayer={'reference_line__layer'}
        features={referenceLinesSource}
        layout={lineLayoutProps}
        layerType={'line'}
        source
        paint={lineReferenceLineLayerProps}
        visible={visible ? 'visible' : 'none'}
      />
    </>
  )
}
