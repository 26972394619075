import * as React from 'react'

function WarningIcon(props) {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x='5' y='3' width='2' height='7' fill='white' />
      <path
        d='M5.97098 1C6.21155 1.00017 6.44854 1.05839 6.6618 1.16972C6.87506 1.28105 7.05831 1.44221 7.19598 1.6395L7.24998 1.7215L11.4655 8.7585C11.5918 8.9775 11.6605 9.22497 11.6651 9.47775C11.6698 9.73052 11.6102 9.98034 11.492 10.2038C11.3738 10.4273 11.2009 10.6172 10.9893 10.7556C10.7778 10.8941 10.5346 10.9766 10.2825 10.9955L10.1825 11H1.75448C1.50207 10.997 1.25454 10.9301 1.03496 10.8056C0.815375 10.6811 0.630892 10.503 0.498709 10.288C0.366526 10.0729 0.290946 9.82791 0.279015 9.57577C0.267084 9.32363 0.319191 9.07257 0.430478 8.846L0.480978 8.7515L4.69348 1.719C4.82672 1.49951 5.01426 1.31804 5.23802 1.19211C5.46178 1.06617 5.71421 1.00001 5.97098 1ZM6.00598 8L5.94248 8.0035C5.82095 8.01795 5.70895 8.07648 5.62769 8.16798C5.54642 8.25949 5.50154 8.37762 5.50154 8.5C5.50154 8.62238 5.54642 8.74051 5.62769 8.83202C5.70895 8.92352 5.82095 8.98205 5.94248 8.9965L6.00098 9L6.06448 8.9965C6.186 8.98205 6.29801 8.92352 6.37927 8.83202C6.46054 8.74051 6.50542 8.62238 6.50542 8.5C6.50542 8.37762 6.46054 8.25949 6.37927 8.16798C6.29801 8.07648 6.186 8.01795 6.06448 8.0035L6.00598 8ZM6.00098 4C5.87851 4.00002 5.76031 4.04498 5.66879 4.12636C5.57727 4.20774 5.51881 4.31987 5.50448 4.4415L5.50098 4.5V6.5L5.50448 6.5585C5.51893 6.68002 5.57746 6.79203 5.66896 6.87329C5.76047 6.95456 5.8786 6.99944 6.00098 6.99944C6.12336 6.99944 6.24149 6.95456 6.333 6.87329C6.4245 6.79203 6.48302 6.68002 6.49748 6.5585L6.50098 6.5V4.5L6.49748 4.4415C6.48315 4.31987 6.42468 4.20774 6.33316 4.12636C6.24165 4.04498 6.12345 4.00002 6.00098 4Z'
        fill='#FC7818'
      />
    </svg>
  )
}

export default WarningIcon
