import React from 'react'

export const columns = (editMode: boolean): any[] => [
  {
    title: (
      <>
        Минимальные допустимые
        <br />
        расстояния, м
      </>
    ),
    type: 'Text',
    key: 'area_type',
    editable: false,
    className: 'offsets-table__header',
    sticky: true,
  },
  {
    title: 'Класс трубопровода I',
  },
  {
    title: 'Класс трубопровода II',
  },
  {
    type: 'Number',
    title: 'DN (0:300)',
    key: 'class_1/offset_0_300__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN [300:600)',
    key: 'class_1/offset_300_600__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN [600:800)',
    key: 'class_1/offset_600_800__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN [800:1000)',
    key: 'class_1/offset_800_1000__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN [1000:1200)',
    key: 'class_1/offset_1000_1200__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN [1200:1400)',
    key: 'class_1/offset_1200_1400__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN (0:300]',
    key: 'class_2/offset_0_300__m',
    editable: editMode,
  },
  {
    type: 'Number',
    title: 'DN (300:2000]',
    key: 'class_2/offset_300_2000__m',
    editable: editMode,
  },
]
