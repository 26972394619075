import { ITableColumn } from 'components/Table/types'
import { Table } from 'components/Table/Table/Table'
import React, { useMemo } from 'react'
import { definitions } from 'generated/apiTypes'
import { readOnlyColumns, readOnlyStrippedDownTableColumns } from 'pages/Directory/components/GPU/tableConfig'
import { IGPUChoicesProps } from './types'
import 'components/BlockWindow/SelectWindow/ChoicesWindow.scss'
import { readonlyFields } from '../../../pages/Directory/components/GPU/types'
import classNames from 'classnames'

export const GPUChoicesTable: React.FC<IGPUChoicesProps> = ({ directoryItems, selectedID, setSelectedID }) => {
  const getDataSource = (data: definitions['HandbookGPU'][]) => {
    return data.map((item) => {
      return {
        selected: selectedID,
        ...(item.type === 'По формуле'
          ? {
              ...item,
              ...Object.fromEntries(readonlyFields.map((field) => [field, ''])),
            }
          : item),
      }
    })
  }
  const dataSource = useMemo(() => getDataSource(directoryItems as any), [directoryItems, selectedID])
  const filtered = dataSource.every((item) => item.type === 'По формуле')
  const currentColumns = [
    {
      title: '',
      type: 'Select',
      key: 'selected',
      editable: true,
      sticky: true,
    },
    ...(filtered ? readOnlyStrippedDownTableColumns : readOnlyColumns),
  ]
  return (
    <Table
      className={classNames('choices-table__container gpu', filtered && 'stripped_down')}
      columns={currentColumns as ITableColumn[]}
      dataSource={dataSource}
      setValue={setSelectedID}
    />
  )
}
