import * as React from 'react'

function ResizeIcon(props) {
  return (
    <svg width={8} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_1126_21214)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.33333 0.500061C1.79357 0.500061 2.16667 0.873157 2.16667 1.33339V14.6667C2.16667 15.127 1.79357 15.5001 1.33333 15.5001C0.873096 15.5001 0.5 15.127 0.5 14.6667V1.33339C0.5 0.873157 0.873096 0.500061 1.33333 0.500061Z'
          fill='#8896A4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.33333 0.5C6.79357 0.5 7.16667 0.873096 7.16667 1.33333V14.6667C7.16667 15.1269 6.79357 15.5 6.33333 15.5C5.8731 15.5 5.5 15.1269 5.5 14.6667V1.33333C5.5 0.873096 5.8731 0.5 6.33333 0.5Z'
          fill='#8896A4'
        />
      </g>
      <defs>
        <clipPath id='clip0_1126_21214'>
          <rect width='8' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ResizeIcon
