import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { ITasksSlice } from './types'

export const initialState: ITasksSlice = {
  tasks: [],
}

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks: (state, action: PayloadAction<ITasksSlice['tasks']>) => {
      return { ...state, tasks: action.payload }
    },
    resetTasksSliceState: (state: ITasksSlice) => {
      state.tasks = initialState.tasks
    },
  },
})

export const { setTasks, resetTasksSliceState } = tasksSlice.actions

export const selectTasks = (state: RootState) => state.tasks.tasks

export default tasksSlice.reducer
