import React from 'react'
// @ts-ignore
import { ReactComponent as Logo } from 'images/LogoBlue.svg'
import Button from 'components/Button'
import './notFoundView.scss'
import { useNavigate } from 'react-router-dom'

interface INotFoundViewProps {
  title: string
  message: string
}

const NotFoundView: React.FC<INotFoundViewProps> = ({ title, message }) => {
  const navigate = useNavigate()
  return (
    <div className={'not-found-view__container'}>
      <div className={'not-found-view__form'}>
        <Logo />
        <div className={'not-found-view__content'}>
          <div className={'not-found-view__title header1'}>{title}</div>
          <div className={'not-found-view__message normal-text'}>{message}</div>
        </div>
        <div>
          <Button onClick={() => navigate('/')}>Перейти на главную</Button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundView
