import React, { useMemo } from 'react'
import { RowsTable } from 'components/Table/RowsTable/RowsTable'
import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { definitions } from 'generated/apiTypes'
import useChartData from '../hooks/useChartData'
import { Line } from 'react-chartjs-2'
import { options } from '../Chart/options'
import { ITableColumn } from 'components/Table/types'

const columns = [
  {
    title: 'Давление, МПа',
    type: 'Text',
    key: 'pressure',
    editable: false,
    sticky: true,
  },
  {
    title: 'Точка трассы, км',
    type: 'Text',
    key: 'distance',
    editable: false,
    sticky: true,
  },
]

type PressureResultsType = { id: string; pressure: number; distance: number; unknown: boolean; ks: boolean }

const Pressure: React.FC = () => {
  const taskResults = useSelector(selectTaskResults)

  const getData = (pipelineList: definitions['HydraulicResultRetrievePipeline'][]) => {
    const data: PressureResultsType[] = [
      {
        id: pipelineList[0].id + 1,
        pressure: pipelineList[0].start_point_pressure__MPa,
        distance: pipelineList[0].start_point__km,
        unknown: pipelineList[0].start_node === null,
        ks: pipelineList[0].start_node && pipelineList[0].start_node.node_type === 'КС',
      },
    ]
    pipelineList.forEach((pipeline, index) => {
      if (index > 0)
        if (pipeline.start_node && pipeline.start_node.node_type === 'КС') {
          data.push({
            id: pipeline.id,
            pressure: pipeline.start_point_pressure__MPa,
            distance: pipeline.start_point__km,
            unknown: false,
            ks: true,
          })
        } else if (pipeline.start_node === null) {
          data.push({
            id: pipeline.id,
            pressure: pipeline.start_point_pressure__MPa,
            distance: pipeline.start_point__km,
            unknown: true,
            ks: false,
          })
        }
      data.push({
        id: pipeline.id,
        pressure: pipeline.finish_point_pressure__MPa,
        distance: pipeline.end_point__km,
        unknown: pipeline.end_node === null,
        ks: pipeline.end_node && pipeline.end_node.node_type === 'КС',
      })
    })
    return {
      tableData: data.map((item) => {
        return {
          id: item.id,
          pressure: item.pressure?.toFixed(2),
          distance: `${item.distance?.toFixed(2)}${item.ks ? ' (КС)' : item.unknown ? ' (Неизвестный)' : ''}`,
        }
      }),
      sourceData: data,
    }
  }

  const { tableData, sourceData } = useMemo(
    () => (taskResults.pipelines.length > 0 ? getData(taskResults.pipelines) : { tableData: [], sourceData: [] }),
    [taskResults.pipelines],
  )
  const chartData = useChartData('PRESSURE')

  return (
    <>
      {sourceData.length > 0 && (
        <div className={'results__chart-container'}>
          <Line
            data={chartData}
            options={options(
              columns[0].title,
              columns[1].title,
              taskResults.pipelines[taskResults.pipelines.length - 1]?.end_point__km || 0,
              sourceData.map((item) => {
                return { value: item.distance, ks: item.ks }
              }),
            )}
          />
        </div>
      )}
      <div className={'results__under-chart-container'}>
        <RowsTable className={'rows-table__container'} columns={columns as ITableColumn[]} dataSource={tableData} />
      </div>
    </>
  )
}

export default Pressure
