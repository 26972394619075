import { definitions } from 'generated/apiTypes'
import { RowsTable } from 'components/Table/RowsTable/RowsTable'
import React from 'react'
import { columnsPart1, columnsPart2, strippedDownTableColumnsPart1, strippedDownTableColumnsPart2 } from './tableConfig'
interface IGPUCardProps {
  item?: definitions['HandbookGPU']
}
export const GPUCard: React.FC<IGPUCardProps> = ({ item }) => {
  if (item) {
    return (
      <div className={'gpu-card__container'}>
        <div className={'gpu-card__name header3'}>{item.name}</div>
        <div className={'gpu-card__tables'}>
          <div>
            <RowsTable
              columns={item.type === 'По формуле' ? strippedDownTableColumnsPart1 : columnsPart1}
              dataSource={[item || {}]}
            />
          </div>
          <RowsTable
            columns={item.type === 'По формуле' ? strippedDownTableColumnsPart2 : columnsPart2}
            dataSource={[item || {}]}
          />
        </div>
      </div>
    )
  }
  return <></>
}
