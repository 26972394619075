import { useSelector } from 'react-redux'
import { getAutoCSDumpsId, getFileById } from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'

interface IGetDumps {
  requestData: boolean
  setRequestData(value: boolean): void
}
const useGetDumps = (props: IGetDumps) => {
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToGetDumps = async () => {
    if (trunkPipelineId && projectId)
      getAutoCSDumpsId(projectId, trunkPipelineId).then((fileIdResponse) => {
        if (fileIdResponse.data.file_id) {
          getFileById(fileIdResponse.data.file_id).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response?.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `cs-placement-dumps-${trunkPipelineId}.zip`)
            document.body.appendChild(link)
            link.click()
            props.setRequestData(false)
          })
        }
      })
  }

  React.useEffect(() => {
    props.requestData && tryToGetDumps()
  }, [props.requestData])

  return
}

export default useGetDumps
