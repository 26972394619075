import { EditElementPanel } from '../editElementPanel'
import { definitions } from 'generated/apiTypes'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateSink,
  setUpdatedElement,
  selectProjectID,
  selectLoadingHeightNodeID,
  setLoadingHeightNodeID,
  setPipelines,
  selectProject,
} from 'store/projectSlice'
import NumberInput from 'components/Input/numberInput'
import React from 'react'
import { getCoords, round } from '@turf/turf'
import CoordinateInput from 'components/Input/coordinateInput'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import UnlockedIcon from 'images/UnlockedIcon'
import LockedIcon from 'images/LockedIcon'
import {
  getNodesByProjectIDRequest,
  getPipelineByIDRequest,
  getSinkByIDRequest,
  updateGISHeight,
  updateSinkByIDRequest,
} from 'services/apiRequests'
import { selectMapMode, setMapNodes } from 'store/mapSlice'
import classNames from 'classnames'
import axios, { AxiosResponse } from 'axios'

interface ISinkEditPanel {
  sink: definitions['Sink'] | undefined
}
export const SinkEditPanel: React.FC<ISinkEditPanel> = ({ sink }) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const project = useSelector(selectProject)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const mapMode = useSelector(selectMapMode)
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateSink({
        ...sink,
        ...updatedField,
      } as definitions['Sink']),
    )
  }
  const updateCoordinates = (coordinates: [number, number]) => {
    const central_point = {
      ...sink?.central_point,
      coordinates,
    }
    updateStore({ central_point })
    dispatch(
      setUpdatedElement({
        elementID: sink?.id as string,
        type: 'SINK',
        params: { central_point },
      }),
    )
  }
  const setValue = (value: number | boolean, label: string) => {
    updateStore({ [label]: value })
    dispatch(
      setUpdatedElement({
        elementID: sink?.id as string,
        type: 'SINK',
        params: { [label]: value },
      }),
    )
  }
  const setHeight = (value: number) => {
    updateStore({ height__m: round(value) })
    if (projectId && sink?.id) {
      updateSinkByIDRequest(projectId, sink.id, {
        height__m: round(value),
      }).then(() => {
        getSinkByIDRequest(projectId, sink.id).then((res) => {
          dispatch(updateSink(res.data))
        })
        getNodesByProjectIDRequest(projectId).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
        // @ts-ignore
        const req: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] = project?.detail?.trunk_pipeline.pipelines
          .filter((item) => item.start_node.id === sink.id || item.end_node.id === sink.id)
          ?.map((pipeline) => getPipelineByIDRequest(projectId, pipeline.id))
        axios.all(req).then((resArr) => {
          dispatch(setPipelines(resArr.map((res) => res.data)))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectId && sink?.id) {
      if (value) dispatch(setLoadingHeightNodeID(sink.id))
      updateSinkByIDRequest(projectId, sink.id, {
        allow_to_request_gis_height: value,
      })
        .then(() => {
          getSinkByIDRequest(projectId, sink.id)
            .then((res) => {
              dispatch(updateSink(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectId && sink?.id) {
      dispatch(setLoadingHeightNodeID(sink.id))
      updateGISHeight(projectId, sink.id)
        .then(() => {
          getSinkByIDRequest(projectId, sink.id)
            .then((res) => {
              dispatch(updateSink(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  return (
    <EditElementPanel title={'Конечная точка трубопровода'}>
      <div className={'edit-element-panel__table'}>
        <div className={'common-row edit-element-panel__lock-row'}>
          <div className={'header3 primary-text'}>Координаты</div>
          {mapMode !== 'edit' && (
            <button
              className={classNames('edit-element-panel__lock-btn square-btn-28', sink?.lock && 'locked')}
              onClick={() => setValue(!sink?.lock, 'lock')}
            >
              {sink?.lock ? <LockedIcon /> : <UnlockedIcon />}
            </button>
          )}
        </div>
        <CoordinateInput
          id={sink?.id}
          field={'latitude'}
          label={'lat'}
          disabled={mapMode !== 'edit' && sink?.lock}
          value={sink?.central_point ? getCoords(sink.central_point as any)[1] : ''}
          setValue={(value) => updateCoordinates([getCoords(sink?.central_point as any)[0], value])}
        />
        <CoordinateInput
          id={sink?.id}
          field={'longitude'}
          label={'lon'}
          disabled={mapMode !== 'edit' && sink?.lock}
          value={sink?.central_point ? getCoords(sink.central_point as any)[0] : ''}
          setValue={(value) => updateCoordinates([value, getCoords(sink?.central_point as any)[1]])}
        />
        <NumberInput
          value={sink?.height__m || 0}
          unit={'м'}
          label={'Высотная отметка'}
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={sink?.gis_height_error}
          setValue={setHeight}
          signed
          loading={loadingHeightNodeID === sink?.id}
          fieldName={'height__m'}
          disabled={sink?.allow_to_request_gis_height || (mapMode !== 'edit' && sink?.lock)}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            disabled={mapMode !== 'edit' && sink?.lock}
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={sink?.allow_to_request_gis_height || false}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {sink?.allow_to_request_gis_height && (
              <Button
                className={'edit-element-panel__redo-btn'}
                mode={'secondary'}
                onClick={updateHeight}
                disabled={mapMode !== 'edit' && sink?.lock}
              >
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
        <div className={'common-row'}>
          <hr />
        </div>
        <div className={'common-row'}>
          <div className={'header3 primary-text'}>Параметры</div>
        </div>
        <NumberInput
          id={sink?.id}
          key={`${sink?.id}_pressure`}
          value={sink?.pressure__MPa!}
          unit={'МПа'}
          accuracy={2}
          fieldName={'pressure__MPa'}
          setValue={setValue}
          label={'Давление'}
          signed
          noEmpty
        />
        <div />
      </div>
    </EditElementPanel>
  )
}
