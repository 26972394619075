import * as React from 'react'

function MinimizeIcon(props) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10 12.6667V11.3333C10 10.9797 10.1405 10.6406 10.3905 10.3905C10.6406 10.1405 10.9797 10 11.3333 10H12.6667'
        stroke='white'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 3.3335V4.66683C10 5.02045 10.1405 5.35959 10.3905 5.60964C10.6406 5.85969 10.9797 6.00016 11.3333 6.00016H12.6667'
        stroke='white'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.3335 10H4.66683C5.02045 10 5.35959 10.1405 5.60964 10.3905C5.85969 10.6406 6.00016 10.9797 6.00016 11.3333V12.6667'
        stroke='white'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.3335 6.00016H4.66683C5.02045 6.00016 5.35959 5.85969 5.60964 5.60964C5.85969 5.35959 6.00016 5.02045 6.00016 4.66683V3.3335'
        stroke='white'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default MinimizeIcon
