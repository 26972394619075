import React, { useState } from 'react'

import { getRegions } from 'services/apiRequests'

import { definitions } from 'generated/apiTypes'

const useGetRegions = () => {
  const [regions, setRegions] = useState<definitions['NextgisRegionName'][]>([])

  const tryToGetNextGISRegions = async () => {
    await getRegions().then((res) => {
      setRegions(res.data)
    })
  }

  React.useEffect(() => {
    tryToGetNextGISRegions().then()
  }, [])

  return regions
}

export default useGetRegions
