import React from 'react'
import '../styleGuide.scss'

const Colors: React.FC = () => {
  return (
    <div className='style-guide__panel'>
      <div className='style-guide__panel-title'>Colors</div>
      <div className='style-guide__panel-subtitle'>Background & Text</div>
      <div className={'style-guide__rows'}>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-primary' />
          <p>bg-primary</p>
          <p className={'color-primary'}>color-primary</p>
          <p className={'color-primary-selected'}>color-primary-selected</p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-primary-light' />
          <p>bg-primary-light</p>
          <p className={'color-primary-light'}>color-primary-light</p>
          <p className={'color-primary-light-selected'}>color-primary-light-selected</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-border' />
          <p>bg-border</p>
          <p className={'color-border'}>color-border</p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-white' />
          <p>bg-white</p>
          <p className={'color-white bg-primary'} style={{ width: 'fit-content' }}>
            color-white
          </p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-alert' />
          <p>bg-alert</p>
          <p className={'color-alert'}>color-alert</p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-blue' />
          <p>bg-blue</p>
          <p className={'color-blue'}>color-blue</p>
        </div>
      </div>
      <div className='style-guide__panel-subtitle'>Background</div>
      <div className={'style-guide__rows'}>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-grey' />
          <p>bg-grey</p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-secondary' />
          <p>bg-secondary</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-alert-background' />
          <p>bg-alert-background</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-warning-background' />
          <p>bg-warning-background</p>
        </div>
        <div className='style-guide__circle-container interactive'>
          <div className='style-guide__circle bg-btn-active' />
          <p>bg-btn-active</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-disabled' />
          <p>bg-disabled</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-1' />
          <p>bg-col-1</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-2' />
          <p>bg-col-2</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-3' />
          <p>bg-col-3</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-4' />
          <p>bg-col-4</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-5' />
          <p>bg-col-5</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-col-6' />
          <p>bg-col-6</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-green' />
          <p>bg-green</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-purple' />
          <p>bg-purple</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle bg-orange' />
          <p>bg-orange</p>
        </div>
      </div>
      <div className='style-guide__panel-subtitle'>Text</div>
      <div className={'style-guide__rows'}>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--primary-text-color)' }} />
          <p className={'color-primary-text'}>color-primary-text</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--secondary-text-color)' }} />
          <p className={'color-secondary-text'}>color-secondary-text</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--dark-secondary-color)' }} />
          <p className={'color-dark-secondary'}>color-dark-secondary</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--dark-bg-secondary-text)' }} />
          <p className={'color-dark-bg-secondary-text'}>color-dark-bg-secondary-text</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--text-color-1)' }} />
          <p className={'color-text-1'}>color-text-1</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--text-color-2)' }} />
          <p className={'color-text-2'}>color-text-2</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--text-color-3)' }} />
          <p className={'color-text-3'}>color-text-3</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--text-color-4)' }} />
          <p className={'color-text-4'}>color-text-4</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--text-color-5)' }} />
          <p className={'color-text-5'}>color-text-5</p>
        </div>
        <div className='style-guide__circle-container'>
          <div className='style-guide__circle' style={{ backgroundColor: 'var(--warning-color)' }} />
          <p className={'color-warning'}>color-warning</p>
        </div>
      </div>
    </div>
  )
}

export default Colors
