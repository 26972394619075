import React from 'react'
import './intermediateElement.scss'
import { useDispatch, useSelector } from 'react-redux'
import { resetShowIntermediateElementInfo, selectShowIntermediateElementInfo } from 'store/projectSlice'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import PointElement from './pointElement'
import { IUpdateCoordsProps } from '../hooks/useUpdateCoords'
import SegmentElement from './segmentElement'

interface IIntermediateElementProps {
  updatedCoords: IUpdateCoordsProps[]
}

const IntermediateElement: React.FC<IIntermediateElementProps> = ({ updatedCoords }) => {
  const dispatch = useDispatch()
  const intermediateElementInfo = useSelector(selectShowIntermediateElementInfo)

  const getTitleByType = () => {
    switch (intermediateElementInfo?.objectType) {
      case 'POINTS':
        return 'Параметры точки'
      case 'SEGMENT':
        return 'Параметры сегмента'
      default:
        return ''
    }
  }

  const onClose = () => {
    dispatch(resetShowIntermediateElementInfo())
  }

  return intermediateElementInfo?.objectId && intermediateElementInfo?.objectType ? (
    <div className={'intermediate-element__container'}>
      <div className={'intermediate-element__title header3'}>
        {getTitleByType()}
        <CloseBtn close={onClose} iconClassNames={'intermediate-element__close-icn'} />
      </div>
      {intermediateElementInfo?.objectType === 'POINTS' && <PointElement updatedCoords={updatedCoords} />}
      {intermediateElementInfo?.objectType === 'SEGMENT' && <SegmentElement />}
    </div>
  ) : (
    <></>
  )
}

export default IntermediateElement
