import { useEffect } from 'react'

interface IUseOldLibraryProps {
  setUseNewLibrary(value: boolean): void
}

export const useOldLibrary = ({ setUseNewLibrary }: IUseOldLibraryProps) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 76) {
      setUseNewLibrary(false)
    }
  }
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.keyCode === 76) {
      setUseNewLibrary(true)
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])
}
