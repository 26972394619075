import React from 'react'
import Button from 'components/Button'
import DownloadIcon from 'images/DownloadIcon'
import RedoIcon from 'images/RedoIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetModel10SliceState,
  selectDownloadResults,
  selectErrorMessage,
  selectTaskStatus,
  setDownloadResults,
} from '../../../store/model10Slice'
import useGetResults from './hooks/useGetResults'
import CalcStatus from '../../../components/CalcStatus'

interface IResultsProps {
  settingsFile: File | undefined
  GPUFile: File | undefined
  ACUFile: File | undefined
  setSettingsFile(file: File | undefined): void
  setGPUFile(file: File | undefined): void
  setACUFile(file: File | undefined): void
}

const Results: React.FC<IResultsProps> = ({
  settingsFile,
  ACUFile,
  GPUFile,
  setSettingsFile,
  setACUFile,
  setGPUFile,
}) => {
  const dispatch = useDispatch()
  const downloadResults = useSelector(selectDownloadResults)
  const taskStatus = useSelector(selectTaskStatus)
  const errorMessage = useSelector(selectErrorMessage)

  useGetResults()

  const recalculate = () => {
    if (settingsFile) setSettingsFile(undefined)
    if (ACUFile) setACUFile(undefined)
    if (GPUFile) setGPUFile(undefined)
    dispatch(resetModel10SliceState())
  }

  const getResults = () => {
    dispatch(setDownloadResults(true))
  }

  return (
    <>
      <div className='results__top-row'>
        <div className={'results__status-wrapper'}>
          <h1 className={'project-list__projects-title no-margin'}>Гидравлический расчет</h1>
          <CalcStatus status={taskStatus} />
        </div>
        <Button mode={'secondary'} onClick={recalculate}>
          <RedoIcon />
          Пересчитать
        </Button>
      </div>
      {taskStatus === 'Рассчитан' && (
        <Button className={'results__btn'} onClick={getResults} disabled={downloadResults}>
          <DownloadIcon />
          Скачать расчетную таблицу
        </Button>
      )}
      {taskStatus === 'Ошибка' && <div className={'results__error normal-text'}>{errorMessage}</div>}
    </>
  )
}

export default Results
