import React from 'react'
import { handleStorage } from '../../../services/handleStorage'
import { useNavigate } from 'react-router-dom'

const useRedirect = (): boolean => {
  const navigate = useNavigate()
  const [res, setRes] = React.useState<boolean>(false)

  React.useEffect(() => {
    const token = handleStorage.getToken()
    if (token) navigate('/')
    else setRes(true)
  }, [])

  return res
}

export default useRedirect
