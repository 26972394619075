import { useDispatch, useSelector } from 'react-redux'
import { selectTaskStatus, setErrorMessage, setTaskStatus } from 'store/hydraulicSlice'
import { getHydraulicTaskStatus } from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { IErrorParams } from 'store/types'

const useCheckTaskStatus = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToGetTaskStatus = async () => {
    if (trunkPipelineId && projectId)
      return await getHydraulicTaskStatus(projectId, trunkPipelineId)
        .then((response) => {
          dispatch(setTaskStatus(response.data.status))
          dispatch(setErrorMessage(response.data.error_message))
          return response.data
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              params: { title: 'Непредвиденная ошибка', message: 'Непредвиденная ошибка' } as IErrorParams,
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (taskStatus && ['Рассчитан', 'Рассчитано частично'].includes(taskStatus)) tryToGetTaskStatus()
  }, [taskStatus])

  return
}

export default useCheckTaskStatus
