import React from 'react'

import { getACUHandbook } from 'services/apiRequests'

import { useDispatch } from 'react-redux'
import { setDirectoryItems, setId, setVersionId } from 'store/directorySlice'

const useGetACU = (requestData: boolean | undefined) => {
  const dispatch = useDispatch()

  const tryToGetACU = async () => {
    await getACUHandbook().then((res) => {
      dispatch(setId(res.data.id))
      dispatch(setVersionId(res.data.id))
      dispatch(
        setDirectoryItems(
          res.data.units.map((unit) => {
            return {
              ...unit,
              modified: false,
            }
          }),
        ),
      )
    })
  }

  React.useEffect(() => {
    tryToGetACU().then()
  }, [requestData])

  return
}

export default useGetACU
